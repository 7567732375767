import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { yup } from "util/yupLocale";
import ArrayForm from "../../ArrayForm";

import { prevStep, nextStep } from "features/transactions/slice";
import { updateTransaction } from "features/transactions/services";
import { updateAuthorities, loadAuthoritiesData, updateCapital, loadCapitalData } from "features/companyData/services";
import { loadCompanyDatas } from "features/companyData/services";
import numberFormat from "functions/numberFormat";

const SerArtHisseDagilimi = ({}) => {
  const dispatch = useDispatch();

  const { step } = useSelector((state) => state.transactions);
  const isRegistered = step.registered;
  // get last ten years only year like "2021"
  const partners = useSelector((state) => state.companyData.partners);

  const partnerComboOptions = partners.map((partner) => ({
    name: partner.adSoyad,
    id: partner.tcVkn,
  }));

  const getPersonFromPartners = (name) => {
    const partner = partners.find((partner) => partner.adSoyad === name);
    const deepCopy = JSON.parse(JSON.stringify(partner));
    const newObj = {};
    newObj.adSoyad = deepCopy.adSoyad;
    newObj.toplamPayAdedi = deepCopy.toplamPayAdedi;
    newObj.tcVkn = deepCopy.tcVkn;
    return newObj;
  };

  const companyType = useSelector((state) => state.companies?.selectedCompany?.companyType);

  const schemaObj = {
    adSoyad: yup.string().required().label("Ortak Ad Soyad").meta({
      type: "text",
      className: "sm:w-2/3",
      component: "combobox",
      options: partnerComboOptions,
      onChange: getPersonFromPartners,
      updateOtherFields: false,
    }),
    tcVkn: yup.string().required().label("TC Kimlik No").min(11).max(11).meta({
      type: "text",
      className: "sm:w-2/3",
      component: "input",
    }),
  };

  if (companyType === "Limited") {
    schemaObj.toplamPayAdedi = yup.string().required().label("Toplam Pay Adedi").meta({
      type: "number",
      currency: "Adet",
      className: "sm:w-2/3",
      component: "number",
    });
  }

  if (companyType === "Anonim") {
    schemaObj.namaYaziliPayAdedi = yup.string().required().label("Nama Yazılı Pay Adedi").meta({
      type: "number",
      currency: "Adet",
      className: "sm:w-2/3",
      component: "number",
    });
    schemaObj.hamilineYaziliPayAdedi = yup.string().required().label("Hamiline Yazılı Pay Adedi").meta({
      type: "number",
      currency: "Adet",
      className: "sm:w-2/3",
      component: "number",
    });
  }

  // const schema = "mudurAtama": yup.array().of(yup.object().shape(schemaObj))
  const schema = yup.object().shape({
    serArtHisseDagilimi: yup.array().of(yup.object().shape(schemaObj)),
  });

  const onUpdate = async (form) => {
    const deepCopy = JSON.parse(JSON.stringify(form));
    if (deepCopy.namaYaziliPayAdedi || deepCopy.hamilineYaziliPayAdedi) {
      deepCopy.toplamPayAdedi = Number(deepCopy.namaYaziliPayAdedi) + Number(deepCopy.hamilineYaziliPayAdedi);
    }

    const toplamPay = deepCopy.serArtHisseDagilimi.reduce((acc, item) => {
      return acc + Number(item.toplamPayAdedi);
    }, 0);

    const toplamPayNama = deepCopy.serArtHisseDagilimi.reduce((acc, item) => {
      return acc + Number(item.namaYaziliPayAdedi);
    }, 0);

    const toplamPayHamiline = deepCopy.serArtHisseDagilimi.reduce((acc, item) => {
      return acc + Number(item.hamilineYaziliPayAdedi);
    }, 0);

    deepCopy.toplamPayAdedi = toplamPay;
    deepCopy.namaYaziliPayAdedi = toplamPayNama;
    deepCopy.hamilineYaziliPayAdedi = toplamPayHamiline;

    await dispatch(
      updateTransaction({
        data: {
          serArtHisseDagilimi: deepCopy,
        },
      })
    );
    dispatch(nextStep());
  };

  const onPrev = () => {
    dispatch(prevStep());
  };

  useEffect(() => {
    dispatch(loadAuthoritiesData());
    dispatch(loadCapitalData());
  }, []);

  const serArtHisseDagilimi = useSelector(
    (state) => state.transactions.selectedTransaction?.serArtHisseDagilimi?.serArtHisseDagilimi
  );

  const defaultItem = {
    adSoyad: "",
    tcVkn: "",
  };

  useEffect(() => {
    dispatch(loadCompanyDatas({ documentName: "capital" }));
  }, []);

  const capital = useSelector((state) => state.companyData.capital);
  const totalCapital = capital?.totalCapital || 0;

  const sermayeArtRaporBilgileri = useSelector((state) => state.transactions.selectedTransaction?.sermayeArtRaporBilgileri);
  const serArtOzVarlikTespit = useSelector((state) => state.transactions.selectedTransaction?.serArtOzVarlikTespit);
  const serArtSermayeOdemeleri = useSelector((state) => state.transactions.selectedTransaction?.serArtSermayeOdemeleri);
  const serArtGecmisYilKar = useSelector((state) => state.transactions.selectedTransaction?.serArtGecmisYilKar);
  const serArtOrtaklardanAlacaklar = useSelector((state) => state.transactions.selectedTransaction?.serArtOrtaklardanAlacaklar);
  const serArtNakit = useSelector((state) => state.transactions.selectedTransaction?.serArtNakit);

  const gecmisYilKariArttirim = Number(serArtGecmisYilKar?.toplamSermayeArttirimi) || 0;
  const ortakArttirim = Number(serArtOrtaklardanAlacaklar?.toplamSermayeArttirimi) || 0;
  const nakdiArttirim = Number(serArtNakit?.toplamSermayeArttirimi) || 0;
  const toplamArttirim = gecmisYilKariArttirim + ortakArttirim + nakdiArttirim;
  const birPayinDegeri = Number(capital?.birPayDegeri) || 0;

  const sonSermaye = totalCapital + toplamArttirim;

  const toplamPaySonSermaye = sonSermaye / birPayinDegeri;

  return (
    <div className="mt-10 md:col-span-2 md:mt-0">
      <div className="mb-2 flex flex-col items-center justify-center bg-white py-2 shadow sm:rounded-md">
        <div className="text-base text-gray-600">Mevcut Sermaye: {numberFormat(totalCapital, 2, true)} TL</div>
        <div className="text-base text-gray-600">Bir Payın Değeri: {numberFormat(birPayinDegeri, 2, true)} TL</div>
        <div className="text-base text-gray-600">Toplam Sermaye Arttırımı: {numberFormat(toplamArttirim, 2, true)} TL</div>
        <hr className="my-2" />
        <div className="text-base text-gray-600">
          Arttırılan Sermayeye Göre Toplam Pay Adedi: {numberFormat(toplamPaySonSermaye, 2, true)}
        </div>
      </div>
      <ArrayForm
        schema={schema}
        onSubmit={onUpdate}
        initData={serArtHisseDagilimi}
        itemLabel="serArtHisseDagilimi"
        defaultItem={defaultItem}
        title="Yeni Hisse Dağılımı"
        description={"Sermaye arttırımı tamamlandıktan sonra hisse dağılımı nasıl olacak?"}
        onPrev={onPrev}
        isRegistered={isRegistered}
        totalFields={[{ label: "Yeni Toplam Pay Adedi", fieldName: "toplamPayAdedi" }]}
      />
    </div>
  );
};

export default SerArtHisseDagilimi;
