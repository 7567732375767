import React, { useState, useEffect } from "react";

import { useSelector, useDispatch } from "react-redux";
import { CheckCircleIcon, ExclamationCircleIcon, TrashIcon } from "@heroicons/react/20/solid";
import { DocumentTextIcon } from "@heroicons/react/24/outline";

// import numberFormat from "functions/numberFormat";
import { dateForRender } from "functions/dateFormat";
import { router } from "features/navigation/Navigator";

import { allTransactionsSelector } from "features/transactions/slice";
import { loadTransactions } from "./services";

import { setSteps, selectTransaction } from "features/transactions/slice";
import { steps } from "features/transactions/steps";
import ROUTES from "features/navigation/routes";
import DeleteTransaction from "features/transactions/DeleteTransaction";

const List = ({ mode = "draft" }) => {
  // itemsSelector selectAll
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      loadTransactions({
        status: mode,
      })
    );
  }, [mode]);

  const renderData = useSelector(allTransactionsSelector);

  // sort renderData as documentCreationDate.seconds asc
  const sortedData = renderData.sort((a, b) => {
    return b.documentCreationDate?.seconds - a.documentCreationDate?.seconds;
  });

  const { companyType, title } = useSelector((state) => state.companies.selectedCompany);

  const statusIndicator = {
    draft: "Taslak",
    documentsCreated: "Belgeler Hazır",
  };

  const handleOnSelectitem = (selectedtransaction) => {
    // filter steps as companyType
    if (selectedtransaction.status === "documentsCreated") {
      const filteredSteps = steps.filter((step) => {
        const { companyTypes, transactionTypes } = step;
        const isCompanyTypeMatch = companyTypes.includes(companyType);
        const isTransactionTypeMatch = transactionTypes.some((transactionType) =>
          selectedtransaction.transactionTypes.includes(transactionType)
        );
        return isCompanyTypeMatch && (isTransactionTypeMatch || transactionTypes.length === 0);
      });

      dispatch(setSteps(filteredSteps));
      dispatch(dispatch(selectTransaction({ ...selectedtransaction })));
      router.navigate(ROUTES.TRANSACTIONS_SHOWDOC.path);
    } else if (selectedtransaction.status === "draft") {
      const filteredSteps = steps.filter((step) => {
        const { companyTypes, transactionTypes } = step;
        const isCompanyTypeMatch = companyTypes.includes(companyType);
        const isTransactionTypeMatch = transactionTypes.some((transactionType) =>
          selectedtransaction.transactionTypes.includes(transactionType)
        );
        return isCompanyTypeMatch && (isTransactionTypeMatch || transactionTypes.length === 0);
      });

      dispatch(setSteps(filteredSteps));
      dispatch(dispatch(selectTransaction({ ...selectedtransaction })));
      router.navigate(ROUTES.TRANSACTION_EDIT.path);
    }
  };

  //   if (error) return <div>error</div>;

  if (!sortedData || !sortedData.length) return <div className="text-center text-gray-500">Kayıt Bulunamadı</div>;

  return (
    <div className=" overflow-hidden bg-white shadow sm:rounded-md ">
      <ul className="divide-y divide-gray-200 ">
        {sortedData.map((item) => (
          <li key={item.id} className="  ">
            <span className="block  hover:bg-gray-50">
              <div className="flex items-center px-4 py-3 sm:px-6">
                <div className="flex min-w-0 flex-1 items-center">
                  <div className="flex-shrink-0">
                    <DocumentTextIcon className="h-12 w-12 text-gray-400" aria-hidden="true" />
                  </div>
                  <div className="min-w-0 flex-1  px-4 md:grid md:grid-cols-12 md:gap-4">
                    <div className=" col-span-8 cursor-pointer" onClick={() => handleOnSelectitem(item)}>
                      <p className="truncate text-sm font-medium text-secondary">{item.transactionTypes.join(", ")}</p>
                      <p className="mt-2 flex items-center text-sm text-gray-500">
                        {/* <span className="truncate">{item.itemInfo?.documentNumber} </span>
                        <span className="truncate">{item.desc}</span> */}
                      </p>
                    </div>
                    <div className="text-right md:col-span-4  md:block">
                      <span className="text-sm text-gray-600">
                        <time>{dateForRender(item?.createdAt)}</time>
                      </span>
                      <div className="items-right mt-2 flex justify-end text-sm text-gray-500">
                        <DeleteTransaction
                          id={item.id}
                          status={mode}
                          navigateTo={mode === "draft" ? ROUTES.TRANSACTIONS_DRAFT.path : ROUTES.TRANSACTIONS_DONE.path}
                        />
                        <span className=" ml-2 text-right text-sm text-gray-500">
                          {item.status === "draft" && (
                            <ExclamationCircleIcon
                              className="mr-1.5 h-5 w-5 flex-shrink-0 text-yellow-400"
                              aria-hidden="true"
                            />
                          )}
                          {item.status === "documentsCreated" && (
                            <CheckCircleIcon className="mr-1.5 h-5 w-5 flex-shrink-0 text-green-400" aria-hidden="true" />
                          )}
                        </span>
                        <span className=" text-right text-sm text-gray-500">{statusIndicator[item.status]}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </span>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default List;
