import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { getAuth, onAuthStateChanged } from "firebase/auth";

import { loadProfile } from "./slice";

const auth = getAuth();

const useAuthListener = () => {
    const dispatch = useDispatch();
    const [user, setUser] = useState();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user) {
                setUser(user);
                (async () => {
                    const profile = await dispatch(loadProfile());
                    if (profile?.payload?.uid) setLoading(false);
                })();
            } else setLoading(false);
        });
        return () => unsubscribe();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return { user, loading };
};

export default useAuthListener;