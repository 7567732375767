import { forwardRef } from "react";
import { ExclamationTriangleIcon } from "@heroicons/react/20/solid";

const TextArea = (props, ref) => {
  // input types
  // input
  // label-top
  //  label-left

  const { name, label, type, divClass, error, helpMessage, placeholder = "", className, inputType, ...rest } = props;
  const color = error
    ? " focus:ring-red-500 focus:border-red-500 border-red-300 dark:border-red-400 "
    : " focus:ring-primary focus:border-primary border-gray-300 ";

  let inputClass = " shadow-sm block w-full sm:text-sm border border-gray-300 rounded-md dark:bg-gray-600 dark:text-gray-50 ";

  let divClassName = divClass;
  if (inputType === "label-top") {
    inputClass = " mt-1 block w-full shadow-sm sm:text-sm  rounded-md dark:bg-gray-600 dark:text-gray-50 ";
    divClassName = divClass || "col-span-6 sm:col-span-4";
  }

  if (inputType === "label-left") {
    inputClass = " w-full min-w-0 rounded-md sm:text-sm dark:bg-gray-600 dark:text-gray-50 ";
    divClassName = divClass || " sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start   py-4 sm:my-2 ";
  }

  return (
    <>
      <div className={divClassName}>
        <InputLabel label={label} inputType={inputType} name={name} />
        <div className=" col-span-2 block w-full flex-1 ">
          <textarea
            type={type || "text"}
            className={inputClass + " " + color + " " + className}
            name={name}
            id={name}
            placeholder={!inputType || inputType === "input" ? label : placeholder}
            {...rest}
            ref={ref}
          />
          <ErrorMessage error={error} />
        </div>
      </div>
    </>
  );
};

export default forwardRef(TextArea);

const InputLabel = (props) => {
  const { label, inputType, name } = props;
  let className = "sr-only";
  if (inputType === "label-top") className = "block text-sm font-medium text-gray-700 dark:text-gray-200 ";
  if (inputType === "label-left") className = "block text-sm font-medium text-gray-700 dark:text-gray-200 sm:mt-px sm:pt-2";

  if (!label) return null;
  return (
    <label htmlFor={name} className={className}>
      {label}
    </label>
  );
};

const ErrorMessage = (props) => {
  const { error } = props;
  if (!error) return null;
  return (
    <div className="mt-0 flex py-2 text-xs text-red-600 dark:text-red-400 " id="email-error">
      <ExclamationTriangleIcon className="mr-1 h-4  w-4 text-red-500 dark:text-red-400 " aria-hidden="true" /> {error}
    </div>
  );
};
