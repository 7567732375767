import { useSelector, useDispatch } from "react-redux";

import { yup } from "util/yupLocale";
import Form from "features/transactions/Form";
import { updateCompanyData } from "features/companyData/services";
import { prevStep, nextStep } from "features/transactions/slice";
import yupObjGenerator from "features/transactions/yupObjGenerator";

const CompanyAdress = ({}) => {
  const { transactionTypes } = useSelector((state) => state.transactions.selectedTransaction);

  const schemaObj = {
    merkezIl: yup
      .string()
      .required()
      .label("Merkez İl")
      .meta({
        type: "text",
        className: "sm:w-2/3",
        component: "input",
        transactionTypes: [
          "Adres Değişikliği",
          "Unvan Değişikliği",
          "Tasfiye Başlangıcı",
          "Tasfiye Sonu",
          "Merkez Nakli",
          "Müdür Atama",
          "Müdür İstifa",
          "Hisse Devri",
          "Şube Açılışı",
          "Şube Kapanışı",
          "Sermaye Arttırımı",
          "Olağan Genel Kurul",
          "Yönetim Kurulu Üye İstifa",
        ],
      }),
    merkezIlce: yup
      .string()
      .required()
      .label("Merkez İlçe")
      .meta({
        type: "text",
        className: "sm:w-2/3",
        component: "input",
        transactionTypes: [
          "Adres Değişikliği",
          "Unvan Değişikliği",
          "Tasfiye Başlangıcı",
          "Tasfiye Sonu",
          "Merkez Nakli",
          "Müdür Atama",
          "Müdür İstifa",
          "Hisse Devri",
          "Şube Açılışı",
          "Şube Kapanışı",
          "Sermaye Arttırımı",
          "Olağan Genel Kurul",
          "Yönetim Kurulu Üye İstifa",
        ],
      }),
    address: yup
      .string()
      .required()
      .label("Merkez Adres")
      .min(5)
      .meta({
        type: "text",
        className: "sm:w-3/3",
        component: "textarea",
        transactionTypes: [
          "Adres Değişikliği",
          "Unvan Değişikliği",
          "Tasfiye Başlangıcı",
          "Tasfiye Sonu",
          "Merkez Nakli",
          "Müdür Atama",
          "Müdür İstifa",
          "Hisse Devri",
          "Şube Açılışı",
          "Şube Kapanışı",
          "Sermaye Arttırımı",
          "Olağan Genel Kurul",
          "Yönetim Kurulu Üye İstifa",
        ],
      }),
  };

  const schema = yupObjGenerator({
    yup,
    schemaObj,
    transactionTypes,
  });

  const dispatch = useDispatch();

  const onUpdate = async (form) => {
    await dispatch(updateCompanyData(form));
    dispatch(nextStep());
  };

  const onPrev = () => {
    dispatch(prevStep());
  };

  const company = useSelector((state) => state.companies.selectedCompany);
  const { step } = useSelector((state) => state.transactions);
  const isRegistered = step.registered;

  return (
    <Form
      schema={schema}
      onSubmit={onUpdate}
      title="Firma Bilgileri"
      initData={company}
      description={"Tescilli Firma Bilgilerini Giriniz"}
      onPrev={onPrev}
      isRegistered={isRegistered}
    />
  );
};

export default CompanyAdress;
