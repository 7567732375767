import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Outlet, useNavigate } from "react-router-dom";

import ROUTES from "features/navigation/routes";
import { loadSubsData } from "features/auth/slice";

const Subs = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const companyID = useSelector((state) => state.companies?.selectedCompany?.id);
  useEffect(() => {
    dispatch(loadSubsData({ companyID }));
  }, []);

  const { subscription } = useSelector((state) => state.auth);
  const credit = subscription?.credits || 0;
  // const companyID = useSelector((state) => state.companies.selectedCompany.id);

  const navigateToServices = () => {
    navigate(ROUTES.SERVICES.path);
  };

  if (!subscription || Object.keys(subscription).length === 0) return;

  return (
    <div className="mt-4 cursor-pointer text-right text-sm text-gray-600 sm:mt-0 sm:ml-4" onClick={navigateToServices}>
      {credit >= 25 && (
        <span className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
          {credit} kontör
        </span>
      )}

      {credit < 25 && credit >= 5 && (
        <span className="inline-flex items-center rounded-md bg-yellow-50 px-2 py-1 text-xs font-medium text-yellow-800 ring-1 ring-inset ring-yellow-600/20">
          {credit} kontör
        </span>
      )}

      {credit < 5 && credit > 0 && (
        <span className="inline-flex items-center rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/10">
          {credit} kontör
        </span>
      )}

      {credit <= 0 && (
        <span className="inline-flex items-center rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/10">
          Kontörünüz bitti yüklemek için tıklayın
        </span>
      )}

      {/* {subscription?.remainingTrialDays > 0 && <p>Deneme sürenizin bitmesine {subscription?.remainingTrialDays} gün kaldı</p>}
      {subscription?.remainingSubsDays > 0 && <p>Üyelik yenilemeye {subscription?.remainingSubsDays} gün kaldı</p>} */}
    </div>
  );
};

export default Subs;
