import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { yup } from "util/yupLocale";
import Form from "features/transactions/Form";

import { prevStep, nextStep } from "features/transactions/slice";
import { updateTransaction } from "features/transactions/services";

const SerArtOzVarlikTespit = () => {
  const dispatch = useDispatch();
  const schema = yup.object({
    aktifToplami: yup.string().required().label("Aktif Toplamı").meta({
      type: "text",
      className: "sm:w-2/3",
      component: "number",
    }),
    borclarToplami: yup.string().required().label("Borçlar Toplamı").meta({
      type: "text",
      className: "sm:w-2/3",
      component: "number",
    }),
  });

  const serArtOzVarlikTespit = useSelector((state) => state.transactions.selectedTransaction?.serArtOzVarlikTespit);

  // SerArtOrtaklardanAlacaklar
  const onUpdate = async (form) => {
    // form.toplamSermayeArttirimi = form.sermayeArttirimTutari;
    form.ozVarlik = Number(form.aktifToplami) - Number(form.borclarToplami);
    await dispatch(
      updateTransaction({
        data: {
          serArtOzVarlikTespit: form,
        },
      })
    );
    dispatch(nextStep());
  };

  const onPrev = () => {
    dispatch(prevStep());
  };

  return (
    <Form
      schema={schema}
      onSubmit={onUpdate}
      onPrev={onPrev}
      title="Öz varlık tespiti"
      description={"Öz varlık tespit"}
      initData={serArtOzVarlikTespit}
    />
  );
};

export default SerArtOzVarlikTespit;
