import { Outlet } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";

import { router } from "features/navigation/Navigator";
import MobileMenu from "features/layout/navMobile/MobileMenu";
import SideBar from "features/layout/navSideBar/SideBar";
import Header from "features/layout/header/Header";
import { loadSubsData } from "features/auth/slice";
// import HeadAlert from "features/layout/header/HeadAlert";
import { logEvent, analytics } from "features/firebase";

const Home = () => {
  const dispatch = useDispatch();
  const { darkMode, wideMode } = useSelector((state) => state.ui);
  const { navigateToPage } = useSelector((state) => state.companies);
  const { email, displayName } = useSelector((state) => state.auth.user);
  const companyID = useSelector((state) => state.companies?.selectedCompany?.id);

  // logEvent(analytics, "page_view", { page_path: window.location.pathname });
  logEvent(analytics, `page_view`, { page_path: window.location.pathname, email: email, displayName: displayName });
  // add to session id

  useEffect(() => {
    if (navigateToPage) router.navigate(navigateToPage);
  }, [navigateToPage]);

  useEffect(() => {
    if (!companyID) return;
    dispatch(loadSubsData({ companyID }));
  }, [companyID]);

  return (
    <div className={darkMode ? "dark" : ""}>
      <div className="min-h-screen bg-gray-50 dark:bg-gray-800">
        <SideBar />
        <MobileMenu />
        {/* if you chance width sidebar also chance main - left padding */}
        <main className={wideMode ? "flex h-full flex-col" : "flex h-full flex-col lg:pl-52"}>
          {/* <HeadAlert wideMode={wideMode} /> */}
          <Header />
          <div className="px-5 pt-3">
            <Outlet />
          </div>
        </main>
      </div>
    </div>
  );
};

export default Home;
