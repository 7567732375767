import React, { useState, useEffect } from "react";
import { useNavigate, Link, useSearchParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import InputMask from "react-input-mask";

import ROUTES from "features/navigation/routes";
import { signUp } from "features/auth/slice";
import Logo from "features/ui/Logo";
import Input from "features/ui/Input";
import Button from "features/ui/Button";
import { yup, yupResolver, useForm, Controller } from "util/yupLocale";
import Modal from "features/ui/Modal";
import AcikRizaBeyani from "features/auth/AcikRizaBeyani";
import AydinlatmaMetni from "features/auth/AydinlatmaMetni";

// if localehost
const isLocalhost = window.location.hostname === "localhost";

const schema = yup
  .object({
    displayName: yup.string().required().label("Ad Soyad"),
    email: yup.string().email().required().label("E-Posta"),
    phoneNumber: yup.string().required().label("Telefon Numarası"),
    // password and password2 are must be same
    password: yup
      .string()
      .required()
      .min(6)
      .label("Parola")
      .oneOf([yup.ref("password2"), null], "Parolalar eşleşmiyor"),
    password2: yup
      .string()
      .required()
      .min(6)
      .label("Parolayı Doğrula")
      .oneOf([yup.ref("password"), null], "Parolalar eşleşmiyor"),
  })
  .required();

const SignUp = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [openPrivacyPolicy, setOpenPrivacyPolicy] = useState(false);
  const [isAgreed, setIsAgreed] = useState(false);
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const [inviteMode, setInviteMode] = useState(false);
  let [searchParams] = useSearchParams();
  const companyID = searchParams.get("company");
  const inviteID = searchParams.get("invite");
  const emailQ = searchParams.get("email");

  useEffect(() => {
    setValue("email", emailQ);
    setInviteMode(inviteID && companyID);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isLocalhost) {
      setValue("displayName", "Furkan Şevki ARICI");
      setValue("email", "f@a.com");
      setValue("phoneNumber", "0532 123 45 67");
      setValue("password", "111111");
      setValue("password2", "111111");
    }
  }, [isLocalhost, setValue]);

  const loading = useSelector((state) => state.auth.loading);

  const onSignUp = async (form) => {
    if (inviteMode) form.inviteID = inviteID;
    await dispatch(signUp(form));
    // await new Promise((resolve) => setTimeout(resolve, 1000));
    if (inviteMode)
      navigate({ pathname: ROUTES.INVITE.path, search: "?company=" + companyID + "&invite=" + inviteID + "&email=" + emailQ });
    else navigate(ROUTES.COMPANIES_NEW.path, { replace: true });
  };

  return (
    <div className="flex min-h-screen justify-center bg-gray-50 py-12 px-4 pt-10 sm:px-6 md:pt-30 lg:px-8">
      <div className="w-full max-w-md space-y-8">
        <div>
          <Logo className="mx-auto h-80 w-auto -m-10" />
          {/* <h2 className="mt-1 text-center text-lg font-extrabold text-primary"></h2> */}
          <h2 className="mt-6 text-center text-3xl font-extrabold text-info">Kaydolun</h2>
          <p className="mt-2 text-center text-sm text-gray-600">
            hesabınız varsa{" "}
            {inviteMode ? (
              <Link
                className="font-medium text-primary hover:text-info"
                to={ROUTES.SIGN_IN.path + "?company=" + companyID + "&invite=" + inviteID + "&email=" + emailQ}
              >
                {" "}
                Giriş yapın
              </Link>
            ) : (
              <Link className="font-medium text-primary hover:text-info" to={ROUTES.SIGN_IN.path}>
                {" "}
                Giriş yapın
              </Link>
            )}
          </p>
        </div>
        <form className="mt-8 space-y-6" onSubmit={handleSubmit(onSignUp)}>
          <input type="hidden" name="remember" defaultValue="true" />
          <div className="-space-y-px rounded-md shadow-sm">
            <Input
              className="rounded-t-md"
              label="Ad Soyad"
              type="displayName"
              error={errors.displayName?.message}
              disabled={loading}
              {...register("displayName")}
            />
            <Input
              label="E-Posta"
              type="e-mail"
              error={errors.email?.message}
              disabled={loading || emailQ}
              {...register("email")}
            />
            <Controller
              control={control}
              name="phoneNumber"
              render={({ field: { onChange, value, ref } }) => (
                <InputMask
                  mask="0 (599) 999 99 99"
                  value={value}
                  label="Cep Telefonu"
                  error={errors.phoneNumber?.message}
                  onChange={onChange}
                  ref={ref}
                  disabled={loading}
                >
                  {(inputProps) => (
                    <Input
                      {...inputProps}

                      // {...register("phoneNumber")}
                    />
                  )}
                </InputMask>
              )}
            />
            <Input
              type="password"
              autocomplete="new-password"
              label="Parola"
              error={errors.password?.message}
              disabled={loading}
              {...register("password")}
            />
            <Input
              type="password"
              autocomplete="new-password"
              label="Parolayı doğrula"
              error={errors.password2?.message}
              disabled={loading}
              {...register("password2")}
              className="rounded-b-md"
            />
          </div>

          <div className="flex items-center">
            <input
              type="checkbox"
              name="agreement"
              id="agreement"
              checked={isAgreed}
              className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
              onChange={(e) => setIsAgreed(e.target.checked)}
            />
            <label htmlFor="agreement2" className="ml-4">
              <Modal
                title="K.V.K.K Aydıntlatma Metni ve Açık Rıza Beyanını"
                modalButonLaber="K.V.K.K Aydıntlatma Metni ve Açık Rıza Beyanını"
                buttonAsLink={true}
                setOpen={setOpenPrivacyPolicy}
                open={openPrivacyPolicy}
                closeClickOutside={true}
                modalClass="relative mt-12 inline-block transform overflow-hidden rounded-lg bg-white  p-4 text-left align-bottom shadow-xl transition-all sm:mt-2 sm:w-8/12 sm:align-top print:mt-1"
              >
                <AcikRizaBeyani displayName={watch("displayName")} />
                <AydinlatmaMetni />
              </Modal>
              'ni okudum kabul ediyorum.
            </label>
          </div>

          <div>
            <Button
              className="group relative flex w-full focus:ring-primary"
              loading={loading}
              disabled={!isAgreed}
              type="submit"
              onClick={handleSubmit(onSignUp)}
            >
              Kayıt
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SignUp;
