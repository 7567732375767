import React, { useState, useEffect } from "react";
import { useNavigate, Link, useSearchParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import ROUTES from "features/navigation/routes";
import { signIn, resetPassword } from "features/auth/slice";
import Logo from "features/ui/Logo";
import Input from "features/ui/Input";
import Button from "features/ui/Button";
import { yup, yupResolver, useForm } from "util/yupLocale";

const schema = yup
  .object({
    email: yup.string().email().label("ePosta").required(),
    password: yup.string().label("parola").required().min(6),
  })
  .required();

const SignIn = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const loading = useSelector((state) => state.auth.loading);

  const [inviteMode, setInviteMode] = useState(false);
  let [searchParams] = useSearchParams();
  const companyID = searchParams.get("company");
  const inviteID = searchParams.get("invite");
  const emailQ = searchParams.get("email");

  useEffect(() => {
    setValue("email", emailQ);
    setInviteMode(inviteID && companyID);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSignIn = async (form) => {
    if (inviteMode) form.inviteID = inviteID;
    await dispatch(signIn(form));
    await new Promise((resolve) => setTimeout(resolve, 1000));
    navigate(ROUTES.COMPANIES_SELECT.path, { replace: true });
    if (inviteMode)
      navigate({ pathname: ROUTES.INVITE.path, search: "?company=" + companyID + "&invite=" + inviteID + "&email=" + emailQ });
    else navigate(ROUTES.HOME.path, { replace: true });
  };

  const onResetPassword = async (e) => {
    e.preventDefault();
    const email = watch("email");
    if (!email) {
      alert("Lütfen önce e-posta adresinizi girin");
      return;
    }
    await dispatch(resetPassword({ email }));
  };

  return (
    <div className="flex min-h-screen justify-center bg-gray-50 px-4 pt-10 sm:px-6 md:pt-36 lg:px-8">
      <div className="w-full max-w-md space-y-8 ">
        <div>
          <Logo className="mx-auto h-80 w-auto -m-10" />
          {/* <h2 className="mt-1 text-center text-lg font-extrabold text-primary"></h2> */}

          <h2 className="mt-6 text-center text-3xl font-extrabold text-info">Giriş yapın</h2>
          <p className="mt-2 text-center text-sm text-gray-600">
            veya{" "}
            {inviteMode ? (
              <Link
                className="font-medium text-primary hover:text-info"
                to={ROUTES.SIGN_UP.path + "?company=" + companyID + "&invite=" + inviteID + "&email=" + emailQ}
              >
                Şimdi kaydolun
              </Link>
            ) : (
              <Link className="font-medium text-primary hover:text-info" to={ROUTES.SIGN_UP.path}>
                Şimdi kaydolun
              </Link>
            )}
          </p>
        </div>
        <form className="mt-8 space-y-6" onSubmit={handleSubmit(onSignIn)}
        // press enter to submit
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            e.preventDefault();
            handleSubmit(onSignIn)();
          }
        }}
        >
          <input type="hidden" name="remember" defaultValue="true" />
          <div className="-space-y-px rounded-md shadow-sm">
            <Input
              className="relative block w-full appearance-none rounded-none rounded-t-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-primary focus:outline-none focus:ring-primary sm:text-sm"
              label="E-Posta"
              type="e-mail"
              disabled={loading || emailQ}
              error={errors.email?.message}
              {...register("email")}
            />
            <Input
              className="relative block w-full appearance-none rounded-none rounded-b-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-primary focus:outline-none focus:ring-primary sm:text-sm"
              label="Parola"
              type="password"
              disabled={loading}
              error={errors.password?.message}
              {...register("password")}
            />
          </div>

          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <input
                className="h-4 w-4 rounded border-gray-300 text-primary focus:ring-primary"
                id="remember-me"
                type="checkbox"
              />
              <label className="ml-2 block text-sm text-gray-900" htmlFor="remember-me">
                Beni hatırla
              </label>
            </div>

            <div className="text-sm">
              <button
                className="font-medium text-primary hover:text-info"
                // to={ROUTES.RESET_PASSWORD.path}
                // prevent press enter to submit
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                    return
                  }
                }}
                onClick={onResetPassword}
              >
                Şifremi Unuttum
              </button>
            </div>
          </div>

          <div>
            <Button
              className="group relative flex w-full focus:ring-primary"
              loading={loading}
              type="submit"
              onClick={handleSubmit(onSignIn)}
            >
              Giriş
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SignIn;
