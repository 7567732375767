import { useEffect } from "react";

import { yup, yupResolver, useForm } from "util/yupLocale";
import ComboboxInput from "features/ui/ComboboxImput";
import Input from "features/ui/Input";
import Button from "features/ui/Button";
import Select from "features/ui/Select";
import FormLayout from "features/ui/FormLayout";
import TextArea from "features/ui/TextArea";
import NumberInput from "features/ui/NumberInput";

const Form = ({ schema, onSubmit, title, description, initData, onPrev, isRegistered }) => {
  const {
    register,
    handleSubmit,
    reset,
    control,
    setValue,
    watch,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema || yup.object({})),
  });

  const fields = Object.keys(schema.fields);
  const formBgColor = "";
  //isRegistered ? "bg-solitaire-100" : "bg-white";
  const formBadge = isRegistered ? (
    <span className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
      Tescilli Mevcut Bilgiler
    </span>
  ) : (
    <span className="inline-flex items-center rounded-md bg-yellow-50 px-2 py-1 text-xs font-medium text-yellow-800 ring-1 ring-inset ring-yellow-600/20">
      Değişecek Yeni Bilgiler
    </span>
  );

  useEffect(() => {
    if (!initData) return;
    fields.forEach((field) => {
      setValue(field, initData[field]);
      if (!initData[field]) {
        setValue(field, schema.fields[field].spec.meta.defaultValue);
      }
    });
    // reset(initData);
  }, [initData]);

  return (
    <div className="mt-10 md:col-span-2 md:mt-0">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="overflow-hidden shadow sm:rounded-md ">
          <div className={`col-span-12 overflow-y-scroll  shadow sm:rounded-lg ${formBgColor || "bg-white"}`}>
            {" "}
            <FormLayout title={title} description={description} formBadge={formBadge}>
              {fields.map((field) => {
                const fieldOjb = schema.fields[field];
                const { component, className, type, options, disabled, placeholder, defaultValue, onChange,condition } =
                  fieldOjb.spec?.meta;
                const { label } = fieldOjb.spec;
                let hidden = false;
                if (condition?.field) {
                  const conditionValue = watch(`${condition.field}`);
                  hidden = conditionValue !== condition.value;
                }
    

                if (hidden) return null;
                if (component === "input") {
                  return (
                    <Input
                      inputType="label-top"
                      className={className}
                      label={label}
                      placeholder={placeholder}
                      type={type}
                      error={errors[field]?.message}
                      {...register(field)}
                    />
                  );
                } else if (component === "textarea") {
                  return (
                    <TextArea
                      inputType="label-top"
                      className={className}
                      placeholder={placeholder}
                      label={label}
                      // type={type}
                      error={errors[field]?.message}
                      {...register(field)}
                    />
                  );
                } else if (component === "select") {
                  return (
                    <Select
                      inputType="label-top"
                      className={className}
                      label={label}
                      placeholder={placeholder}
                      // type={type}
                      error={errors[field]?.message}
                      {...register(field)}
                      options={options}
                      disabled={disabled}
                    />
                  );
                } else if (component === "number") {
               
                  return (
                    <NumberInput
                      inputType="label-top"
                      className={className}
                      label={label}
                      placeholder={placeholder}
                      // type={type}
                      error={errors[field]?.message}
                      {...register(field)}
                      options={options}
                      disabled={disabled}
                      initValue={initData?.[field] || defaultValue}
                      // changeCallback={(value) => {
                      //   setValue(`${itemLabel}.${index}.${itemField}`, value);
                      //   calculateTotals();
                      // }}
                      // {...register(`${itemLabel}.${index}.${itemField}`)}
                      // defaultValue={field?.[itemField] || defaultValue}
                      // error={errors?.[itemLabel]?.[index]?.[itemField]?.message}
                      changeCallback={(value) => {
                        setValue(`${field}`, value);
                      }}
                    />
                  );
                } else if (component === "combobox") {
                  const formData = getValues();
                  console.log("test1");
                  return (
                    <ComboboxInput
                      data={options}
                      label={label}
                      createNew={false}
                      initValue={{ name: formData[field], id: formData[field] }}
                      setCallback={(obj) => {
                        if (!obj) return;
                        const id = obj?.id;
                        if (!id) return;
                        const name = obj?.name;
                        // set name to form

                        const data = onChange(name);

                        // set data to form with all fields
                        // if (updateOtherFields === false ) return
                        const newFormData = { ...formData, ...data };
                        console.log("newFormData", newFormData);
                        reset(newFormData);
                      }}
                      queryCallback={(query) => {
                        if (!query) return;
                        setValue(`${formData[field]}`, query);
                      }}
                    />
                  );
                } else {
                  return null;
                }
              })}
            </FormLayout>
          </div>

          <div className="space-x-2 bg-gray-50 px-4 py-3 text-right dark:bg-gray-700 sm:px-6">
            <Button color="secondary" type="button" onClick={onPrev}>
              Geri
            </Button>
            <Button type="submit">Kaydet</Button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default Form;
