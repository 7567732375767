import { useSelector, useDispatch } from "react-redux";

import { yup } from "util/yupLocale";
import Form from "features/transactions/Form";
import { updateCompanyData } from "features/companyData/services";
import { prevStep, nextStep } from "features/transactions/slice";
import yupObjGenerator from "features/transactions/yupObjGenerator";

const CompanyInfo = ({}) => {
  const { transactionTypes } = useSelector((state) => state.transactions.selectedTransaction);

  const schemaObj = {
    title: yup
      .string()
      .required()
      .label("Ünvan")
      .min(5)
      .meta({
        type: "text",
        className: "sm:w-3/3",
        component: "input",
        transactionTypes: [
          "Adres Değişikliği",
          "Unvan Değişikliği",
          "Tasfiye Başlangıcı",
          "Tasfiye Sonu",
          "Merkez Nakli",
          "Müdür Atama",
          "Müdür İstifa",
          "Hisse Devri",
          "Şube Açılışı",
          "Şube Kapanışı",
          "Sermaye Arttırımı",
          "Olağan Genel Kurul",
          "Yönetim Kurulu Üye İstifa",
        ],
      }),
    companyType: yup
      .string()
      .required()
      .label("Firma Türü")
      .meta({
        type: "text",
        className: "sm:w-2/3",
        component: "select",
        options: [
          { value: "Şahıs Firması", label: "Şahıs Firması" },
          { value: "Limited", label: "Limited" },
          { value: "Anonim", label: "Anonim" },
        ],
        disabled: true,
        transactionTypes: [
          "Adres Değişikliği",
          "Unvan Değişikliği",
          "Tasfiye Başlangıcı",
          "Tasfiye Sonu",
          "Merkez Nakli",
          "Müdür Atama",
          "Müdür İstifa",
          "Hisse Devri",
          "Şube Açılışı",
          "Şube Kapanışı",
          "Sermaye Arttırımı",
          "Olağan Genel Kurul",
          "Yönetim Kurulu Üye İstifa",
        ],
      }),
    tcVkn: yup
      .string()
      .required()
      .label("Vergi No / TC Kimlik No")
      .min(10)
      .max(11)
      .meta({
        type: "text",
        className: "sm:w-2/3",
        component: "input",
        transactionTypes: [
          "Adres Değişikliği",
          "Unvan Değişikliği",
          "Tasfiye Başlangıcı",
          "Tasfiye Sonu",
          "Merkez Nakli",
          "Müdür Atama",
          "Müdür İstifa",
          "Hisse Devri",
          "Şube Açılışı",
          "Şube Kapanışı",
          "Sermaye Arttırımı",
          "Olağan Genel Kurul",
          "Yönetim Kurulu Üye İstifa",
        ],
      }),
    taxAdmin: yup
      .string()
      .required()
      .label("Vergi Dairesi")
      .min(5)
      .meta({
        type: "text",
        className: "sm:w-2/3",
        component: "input",
        transactionTypes: [
          "Adres Değişikliği",
          "Unvan Değişikliği",
          "Tasfiye Başlangıcı",
          "Tasfiye Sonu",
          "Merkez Nakli",
          "Müdür Atama",
          "Müdür İstifa",
          "Hisse Devri",
          "Şube Açılışı",
          "Şube Kapanışı",
          "Sermaye Arttırımı",
          "Olağan Genel Kurul",
          "Yönetim Kurulu Üye İstifa",
        ],
      }),
    ticaretSicilMudurlugu: yup
      .string()
      .required()
      .label("Ticaret Sicil Müdürlüğü")
      .min(5)
      .meta({
        type: "text",
        className: "sm:w-2/3",
        component: "input",
        defaultValue: "İstanbul Ticaret Sicil Müdürlüğü",
        transactionTypes: [
          "Adres Değişikliği",
          "Unvan Değişikliği",
          "Tasfiye Başlangıcı",
          "Tasfiye Sonu",
          "Merkez Nakli",
          "Müdür Atama",
          "Müdür İstifa",
          "Hisse Devri",
          "Şube Açılışı",
          "Şube Kapanışı",
          "Sermaye Arttırımı",
          "Olağan Genel Kurul",
          "Yönetim Kurulu Üye İstifa",
        ],
      }),
    ticaretSicilNo: yup
      .string()
      .required()
      .label("Ticaret Sicil No")
      .min(3)
      .max(16)
      .meta({
        type: "text",
        className: "sm:w-2/3",
        component: "input",
        transactionTypes: [
          "Adres Değişikliği",
          "Unvan Değişikliği",
          "Tasfiye Başlangıcı",
          "Tasfiye Sonu",
          "Merkez Nakli",
          "Müdür Atama",
          "Müdür İstifa",
          "Hisse Devri",
          "Şube Açılışı",
          "Şube Kapanışı",
          "Sermaye Arttırımı",
          "Olağan Genel Kurul",
          "Yönetim Kurulu Üye İstifa",
        ],
      }),
    mersisNo: yup
      .string()
      .required()
      .label("Mersis No")
      .min(16)
      .max(16)
      .meta({
        type: "text",
        className: "sm:w-2/3",
        component: "input",
        transactionTypes: [
          "Adres Değişikliği",
          "Unvan Değişikliği",
          "Tasfiye Başlangıcı",
          "Tasfiye Sonu",
          "Merkez Nakli",
          "Müdür Atama",
          "Müdür İstifa",
          "Hisse Devri",
          "Şube Açılışı",
          "Şube Kapanışı",
          "Sermaye Arttırımı",
          "Olağan Genel Kurul",
          "Yönetim Kurulu Üye İstifa",
        ],
      }),
    faaliyetKonusu: yup
      .string()
      .required()
      .label("Faaliyet Konusu (Nace Kodu Açıklaması)")
      .min(10)
      .meta({
        type: "text",
        className: "sm:w-2/3",
        component: "textarea",
        transactionTypes: ["Sermaye Arttırımı"],
      }),
  };

  const schema = yupObjGenerator({
    yup,
    schemaObj,
    transactionTypes,
  });

  const dispatch = useDispatch();

  const onUpdate = async (form) => {
    await dispatch(updateCompanyData(form));
    dispatch(nextStep());
  };

  const onPrev = () => {
    dispatch(prevStep());
  };

  const company = useSelector((state) => state.companies.selectedCompany);
  const { step } = useSelector((state) => state.transactions);
  const isRegistered = step.registered;

  return (
    <Form
      schema={schema}
      onSubmit={onUpdate}
      title="Firma Bilgileri"
      initData={company}
      description={"Tescilli Firma Bilgilerini Giriniz"}
      onPrev={onPrev}
      isRegistered={isRegistered}
    />
  );
};

export default CompanyInfo;
