import { createAsyncThunk } from '@reduxjs/toolkit';
import { httpsCallable } from 'firebase/functions';
import { db, auth, storage, functions, } from "features/firebase";
import {
    doc,
    getDoc,
    updateDoc,
    collection, setDoc, getDocs
} from "firebase/firestore";

import { onSelectCompany } from 'features/companies/services';

export const updateCompanyData = createAsyncThunk("companies/updateCompanyData", async (payload, { dispatch, getState }) => {
    const form = payload;
    const selectedCompanyID = getState().companies.selectedCompany.id
    const companyRef = doc(db, `companies/${selectedCompanyID}`);
    await updateDoc(companyRef, form);
    await dispatch(onSelectCompany({ id: selectedCompanyID }));
    return form;
});

export const updateCapital = createAsyncThunk("companies/updateCapital", async (payload, { dispatch, getState }) => {
    const form = payload;
    const selectedCompanyID = getState().companies.selectedCompany.id
    const capitalRef = doc(db, `companies/${selectedCompanyID}/data/capital`);
    const capitalSnapshot = await getDoc(capitalRef);
    try {
        // if capital exists, update it
        if (capitalSnapshot.exists()) {
            await updateDoc(capitalRef, form);
        }
        // if capital does not exist, create it
        else {
            await setDoc(capitalRef, form);
        }


    } catch (error) {
        console.log(error);

    }
    return form;
});

export const updateCompanyDatas = createAsyncThunk("companies/updateCompanyDatas", async (payload, { dispatch, getState }) => {
    const { form, documentName } = payload;
    const selectedCompanyID = getState().companies.selectedCompany.id
    const ref = doc(db, `companies/${selectedCompanyID}/data/${documentName}`);
    const snapShot = await getDoc(ref);
    try {
        // if capital exists, update it
        if (snapShot.exists()) {
            await updateDoc(ref, form);
        }
        // if capital does not exist, create it
        else {
            await setDoc(ref, form);
        }


    } catch (error) {
        console.log(error);

    }
    return form;
});

export const loadCompanyDatas = createAsyncThunk("companies/loadCompanyDatas", async (payload, { dispatch, getState }) => {
    const { documentName } = payload;
    const selectedCompanyID = getState().companies.selectedCompany.id
    const ref = doc(db, `companies/${selectedCompanyID}/data/${documentName}`);
    const snapShot = await getDoc(ref);
    const data = snapShot.data();
    return { data, documentName };
});

export const updateAuthorities = createAsyncThunk("companies/updateAuthorities", async (payload, { dispatch, getState }) => {
    const form = payload;
    const selectedCompanyID = getState().companies.selectedCompany.id
    const authoritiesRef = doc(db, `companies/${selectedCompanyID}/data/authorities`);
    const authoritiesSnapshot = await getDoc(authoritiesRef);
    try {
        // if authorities exists, update it
        if (authoritiesSnapshot.exists()) {
            await updateDoc(authoritiesRef, form);
        }
        // if authorities does not exist, create it
        else {
            await setDoc(authoritiesRef, form);
        }
    } catch (error) {
        console.log(error);
    }
    return form;
});

export const loadCapitalData = createAsyncThunk("companies/loadCapitalData", async (payload, { dispatch, getState }) => {
    const selectedCompanyID = getState().companies.selectedCompany.id
    const capitalRef = doc(db, `companies/${selectedCompanyID}/data/capital`);
    const capitalSnapshot = await getDoc(capitalRef);
    const capitalData = capitalSnapshot.data();
    return capitalData;
});

export const loadAuthoritiesData = createAsyncThunk("companies/loadAuthoritiesData", async (payload, { dispatch, getState }) => {
    const selectedCompanyID = getState().companies.selectedCompany.id
    const authoritiesRef = doc(db, `companies/${selectedCompanyID}/data/authorities`);
    const authoritiesSnapshot = await getDoc(authoritiesRef);
    const authoritiesData = authoritiesSnapshot.data();
    return authoritiesData;
});

export const updateSgkMudurlugu = createAsyncThunk("companies/updateSgkMudurlugu", async (payload, { dispatch, getState }) => {
    const form = payload;
    const selectedCompanyID = getState().companies.selectedCompany.id
    const sgkMudurluguRef = doc(db, `companies/${selectedCompanyID}/data/sgkMudurlugu`);
    const sgkMudurluguSnapshot = await getDoc(sgkMudurluguRef);

    try {
        // if sgkMudurlugu exists, update it
        if (sgkMudurluguSnapshot.exists()) {
            await updateDoc(sgkMudurluguRef, form);
        }
        // if sgkMudurlugu does not exist, create it
        else {
            await setDoc(sgkMudurluguRef, form);
        }
    } catch (error) {
        console.log(error);
    }
    return form;
});

export const loadSgkMudurlugu = createAsyncThunk("companies/loadSgkMudurlugu", async (payload, { dispatch, getState }) => {
    const selectedCompanyID = getState().companies.selectedCompany.id
    const sgkMudurluguRef = doc(db, `companies/${selectedCompanyID}/data/sgkMudurlugu`);
    const sgkMudurluguSnapshot = await getDoc(sgkMudurluguRef);
    const sgkMudurluguData = sgkMudurluguSnapshot.data();
    return sgkMudurluguData;
});

export const catchCpaInfoFromOtherCompany = createAsyncThunk("companies/catchCpaInfoFromOtherCompany", async (payload, { dispatch, getState }) => {
    const { documentName } = payload;
    const res = await dispatch(loadCompanyDatas({ documentName }))
    const data = res.payload.data;

    if (data) {

        return { data, documentName };
    }

    const { uid } = auth.currentUser;
    const userCompanyListPath = `users/${uid}/permissions/companies`
    const userCompanyListRef = doc(db, userCompanyListPath);
    const userCompanyListSnapshot = await getDoc(userCompanyListRef);
    const userCompanyListData = userCompanyListSnapshot.data();

    const companyIDs = [];

    for (const company in userCompanyListData) {
        const companyData = userCompanyListData[company]
        companyIDs.push(companyData.id)
    }

    let cpaInfo = {};
    for (const id of companyIDs) {
        const cpaInfoRef = doc(db, `companies/${id}/data/cpaInfo`);
        const cpaInfoSnapshot = await getDoc(cpaInfoRef);
        const cpaInfoData = cpaInfoSnapshot.data();
        const isExistCpaInfo = cpaInfoSnapshot.exists();
        if (isExistCpaInfo) {
            cpaInfo = cpaInfoData;
            break;
        }
    }

    return { data: cpaInfo, documentName };

});