import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { QuestionMarkCircleIcon } from "@heroicons/react/20/solid";

import Modal from "features/ui/Modal";

import { httpsCallable } from "firebase/functions";
import { functions } from "features/firebase";

const Help = () => {
  const [open, setOpen] = useState(false);

  const [feedback, setFeedback] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setMessage("");
  }, []);

  const handleChange = (event) => {
    setFeedback(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    // Assuming you have an API endpoint to submit the feedback
    // You can replace the console.log statement with your actual API call
    setLoading(true);

    const supportMessage = httpsCallable(functions, "supportMessage");
    await supportMessage({ message: feedback });

    // Provide appropriate feedback message to the user
    if (feedback.toLowerCase().includes("suggestion")) {
      setMessage("Thank you for your suggestion!");
    } else if (feedback.toLowerCase().includes("complaint")) {
      setMessage("We apologize for the inconvenience. Our team will look into it.");
    } else {
      setMessage("Mesajınızı aldık. En kısa zamanda dönüş yapacağız.");
    }

    setTimeout(() => {
      setOpen(false);
    }, 2000);

    setLoading(false);
    setFeedback("");
  };

  return (
    <div className="mt-4 mr-2 sm:mr-0 cursor-pointer text-right text-sm text-gray-600 sm:mt-0 sm:ml-4" >
    <Modal
      title="Destek"
      modalButtonComponent={<QuestionMarkCircleIcon className="h-5 w-5 cursor-pointer text-blue-400" aria-hidden="true" />}
      buttonAsLink={true}
      setOpen={setOpen}
      open={open}
      closeClickOutside={true}
      modalClass="relative mt-12 inline-block transform overflow-hidden rounded-lg bg-white  p-4 text-left align-bottom shadow-xl transition-all sm:mt-2 sm:w-8/12 sm:align-top print:mt-1"
    >
      <div className="p-4">
        <h2 className="mb-2 text-lg font-semibold">Destek</h2>
        <form onSubmit={handleSubmit}>
          <label htmlFor="feedback" className="mb-2 block">
            Soru veya yorumlarınız mı var ? Lütfen bize bildirin.
          </label>
          <textarea
            id="feedback"
            className="w-full rounded border p-2"
            value={feedback}
            onChange={handleChange}
            placeholder="Mesajınızı buraya yazın..."
            rows={4}
            disabled={loading}
            required
          ></textarea>
          <button type="submit" disabled={loading} className="mt-4 rounded bg-blue-500 px-4 py-2 text-white">
            Gönder
          </button>
        </form>
        {message && <p className="mt-4">{message}</p>}
      </div>
    </Modal>
    </div>
  );
};

export default Help;
