import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { yup } from "util/yupLocale";
import Form from "features/transactions/Form";

import { prevStep, nextStep } from "features/transactions/slice";
import { updateTransaction } from "features/transactions/services";

const schema = yup.object({
  sermayeArttirimTutari: yup.string().required().label("Nakit Sermaye Arttırım Tutarı").meta({
    type: "text",
    className: "sm:w-2/3",
    component: "number",
  }),
});

const SerArtNakit = ({}) => {
  const dispatch = useDispatch();

  const { serArtNakit } = useSelector((state) => state.transactions.selectedTransaction);

  // SerArtOrtaklardanAlacaklar
  const onUpdate = async (form) => {
    form.toplamSermayeArttirimi = form.sermayeArttirimTutari;

    await dispatch(
      updateTransaction({
        data: {
          serArtNakit: form,
        },
      })
    );
    dispatch(nextStep());
  };

  const onPrev = () => {
    dispatch(prevStep());
  };

  return (
    <Form
      schema={schema}
      onSubmit={onUpdate}
      onPrev={onPrev}
      title="Arrtırılan Nakit Seramye"
      description={
        "Nakit sermaye arttırımı tutarını giriniz. Nakit sermaye arttırımı yapmayacaksanız 0 giriniz."
      }
      initData={serArtNakit}
    />
  );
};

export default SerArtNakit;
