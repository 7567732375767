import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { yup } from "util/yupLocale";
import Form from "../../Form";
import { prevStep, nextStep } from "features/transactions/slice";
import { updateTransaction } from "features/transactions/services";
import Alert from "features/ui/Alert";

const schema = yup.object({
  newAddress: yup.string().required().label("Yeni Adres").min(5).meta({
    type: "text",
    className: "sm:w-3/3",
    component: "textarea",
  }),
});

const Newaddress = ({}) => {
  const dispatch = useDispatch();

  const onUpdate = async (form) => {
    const deepCopyForm = JSON.parse(JSON.stringify(form));
    // await dispatch(setNewAddress(deepCopyForm));
    await dispatch(
      updateTransaction({
        data: {
          newAddress: deepCopyForm,
        },
      })
    );
    dispatch(nextStep());
  };

  const onPrev = () => {
    dispatch(prevStep());
  };

  const { newAddress } = useSelector((state) => state.transactions.selectedTransaction);

  return (
    <div className="mt-10 md:col-span-2 md:mt-0">
    <Alert>
      Adres Değişikliğinde kararda gireceğiniz adres mersise kaydettiğiniz adres ile birebir aynı olmalıdır. Tescilde sorun yaşamamak için önce mersise giriş yapıp ardından mersis ekranından aldığınzı adres metnini buraya yapıştırınız.
    </Alert>
      <Form
        schema={schema}
        onSubmit={onUpdate}
        title="Yeni Adres"
        initData={newAddress}
        description={"Yeni Adresi giriniz."}
        onPrev={onPrev}
      />
    </div>
  );
};

export default Newaddress;
