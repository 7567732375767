import React, { useState, useEffect } from "react";
import Start from "features/transactions/Start";
import { useSelector } from "react-redux";

const Dashboard = ({}) => {
  const selectedCompany = useSelector((state) => state.companies.selectedCompany);

  if (!selectedCompany) {
    return (
      <>
        <p className="text-center text-2xl font-bold text-gray-700">Lütfen bir firma seçiniz</p>
      </>
    );
  }

  return (
    <>
      <Start />
    </>
  );
};

export default Dashboard;
