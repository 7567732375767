import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { yup } from "util/yupLocale";
import ArrayForm from "../../ArrayForm";

import { prevStep, nextStep } from "features/transactions/slice";
import { updateTransaction } from "features/transactions/services";
import { updateAuthorities, loadAuthoritiesData, updateCapital, loadCapitalData } from "features/companyData/services";

const YonetimKuruluSec = ({}) => {
  const dispatch = useDispatch();

  const partners = useSelector((state) => state.companyData.partners);
  const { step } = useSelector((state) => state.transactions);
  const isRegistered = step.registered;

  const partnerComboOptions = partners.map((partner) => ({
    name: partner.adSoyad,
    id: partner.tcVkn,
  }));

  const getPersonFromPartners = (name) => {
    const partner = partners.find((partner) => partner.adSoyad === name);
    return partner;
  };

  const { transactionTypes } = useSelector((state) => state.transactions.selectedTransaction);
  const schemaObj = {
    gercekTuzel: yup
      .string()
      .required()
      .label("Gerçek/Tüzel")
      .meta({
        type: "select",
        className: "sm:w-2/3",
        component: "select",
        defaultValue: "Gerçek",
        options: [
          { value: "Gerçek", label: "Gerçek" },
          { value: "Tüzel", label: "Tüzel" },
        ],
      }),
    // if gercekTuzel === "Tüzel"
    ticaretSicilMudurlugu: yup
      .string()
      .label("Ticaret Sicil Müdürlüğü")
      .meta({
        type: "text",
        className: "sm:w-2/3",
        component: "input",
        condition: {
          field: "gercekTuzel",
          value: "Tüzel",
        },
      })
      .when("gercekTuzel", {
        is: "Tüzel",
        then: yup.string().required(),
      }),
    tuzelAdres: yup
      .string()
      .label("Tüzel Adres")
      .meta({
        type: "text",
        className: "sm:w-2/3",
        component: "input",
        condition: {
          field: "gercekTuzel",
          value: "Tüzel",
        },
      })
      .when("gercekTuzel", {
        is: "Tüzel",
        then: yup.string().required(),
      }),
    ticaretSicilNo: yup
      .string()
      .label("Ticaret Sicil No")
      .meta({
        type: "text",
        className: "sm:w-2/3",
        component: "input",
        condition: {
          field: "gercekTuzel",
          value: "Tüzel",
        },
      })
      .when("gercekTuzel", {
        is: "Tüzel",
        then: yup.string().required(),
      }),
    title: yup
      .string()
      .label("Şirket Ünvanı")
      .meta({
        type: "text",
        className: "sm:w-2/3",
        component: "input",
        condition: {
          field: "gercekTuzel",
          value: "Tüzel",
        },
      })
      .when("gercekTuzel", {
        is: "Tüzel",
        then: yup.string().required(),
      }),

    yetkiTuru: yup
      .string()
      .required()
      .label("Yetki Türü")
      .meta({
        type: "select",
        className: "sm:w-2/3",
        component: "select",
        options: [
          { value: "Yönetim Kurulu Başkanı", label: "Yönetim Kurulu Başkanı" },
          { value: "Yönetim Kurulu Başkan Yardımcısı", label: "Yönetim Kurulu Başkan Yardımcısı" },
          { value: "Yönetim Kurulu Üyesi", label: "Yönetim Kurulu Üyesi" },
        ],
      }),
    sure: yup
      .string()
      .required()
      .label("Süre")
      .meta({
        type: "select",
        className: "sm:w-2/3",
        component: "select",
        options: [
          { value: "1 yıl süre ile", label: "1 yıl süre ile" },
          { value: "2 yıl süre ile", label: "2 yıl süre ile" },
          { value: "3 yıl süre ile", label: "3 yıl süre ile" },
          //   { value: "4 yıl süre ile", label: "4 yıl süre ile" },
          //   { value: "5 yıl süre ile", label: "5 yıl süre ile" },
          //   { value: "6 yıl süre ile", label: "6 yıl süre ile" },
          //   { value: "7 yıl süre ile", label: "7 yıl süre ile" },
          //   { value: "8 yıl süre ile", label: "8 yıl süre ile" },
          //   { value: "9 yıl süre ile", label: "9 yıl süre ile" },
          //   { value: "10 yıl süre ile", label: "10 yıl süre ile" },
          //   { value: "Süresiz olarak", label: "Süresiz olarak" },
        ],
      }),

    adSoyad: yup.string().required().label("Ad Soyad").meta({
      type: "text",
      className: "sm:w-2/3",
      component: "combobox",
      options: partnerComboOptions,
      onChange: getPersonFromPartners,
    }),
    uyruk: yup.string().required().label("Uyruk").meta({
      type: "text",
      className: "sm:w-2/3",
      component: "input",
      defaultValue: "T.C.",
    }),
    tcVkn: yup.string().required().label("TC Kimlik No").min(11).max(11).meta({
      type: "text",
      className: "sm:w-2/3",
      component: "input",
    }),

    adres: yup.string().required().label("Adres").meta({
      type: "text",
      className: "sm:w-2/3",
      component: "input",
    }),
    munferidMusterek: yup
      .string()
      .required()
      .label("Münferit/Müşterek")
      .meta({
        type: "select",
        className: "sm:w-2/3",
        component: "select",
        options: [
          { value: "Münferit", label: "Münferit" },
          { value: "Müşterek", label: "Müşterek" },
        ],
      }),
    // if munferidMusterek === "Müşterek"
    musterekKisiler: yup
      .string()
      .label("Müşterek Kişiler")
      .meta({
        type: "text",
        className: "sm:w-2/3",
        component: "input",
        ifNotRequiredHide: true,
        condition: {
          field: "munferidMusterek",
          value: "Müşterek",
        },
      })
      .when("munferidMusterek", {
        is: "Müşterek",
        then: yup.string().required(),
      }),
  };

  // const schema = "yonetimKuruluAtama": yup.array().of(yup.object().shape(schemaObj))
  const schema = yup.object().shape({
    yonetimKuruluAtama: yup.array().of(yup.object().shape(schemaObj)),
  });

  const onUpdate = async (form) => {
    await dispatch(
      updateTransaction({
        data: {
          yonetimKuruluAtama: form,
        },
      })
    );
    dispatch(nextStep());
  };

  const onPrev = () => {
    dispatch(prevStep());
  };

  useEffect(() => {
    dispatch(loadAuthoritiesData());
    dispatch(loadCapitalData());
  }, []);

  const yonetimKuruluAtamaVeri = useSelector(
    (state) => state.transactions.selectedTransaction?.yonetimKuruluAtama?.yonetimKuruluAtama
  );

  const defaultItem = {
    adSoyad: "",
    tcVkn: "",
  };

  return (
    <ArrayForm
      schema={schema}
      onSubmit={onUpdate}
      title="Yönetim Kurulu Atama"
      initData={yonetimKuruluAtamaVeri}
      itemLabel="yonetimKuruluAtama"
      defaultItem={defaultItem}
      description={"Yeni Yönetim Kurulu bilgilerini Giriniz"}
      onPrev={onPrev}
      isRegistered={isRegistered}
    />
  );
};

export default YonetimKuruluSec;
