import { createAsyncThunk } from '@reduxjs/toolkit';
import { httpsCallable } from 'firebase/functions';
import { db, auth, storage, functions } from "features/firebase";
import {
    doc,
    getDoc,
    updateDoc, getDocs,
    collection,
} from "firebase/firestore";


export const getCompanyLevha = createAsyncThunk("companies/getLevha", async (payload, { dispatch }) => {
    const { tcVkn, taxAdmin } = payload;
    const getLevha = httpsCallable(functions, "getLevha");
    const levha = await getLevha({ tcVkn, taxAdmin });
    return levha.data;
});

export const loadAuthorizedCompanies = createAsyncThunk("companies/loadAuthorizedCompanies", async (payload, { dispatch }) => {
    const { uid } = auth.currentUser;
    const userCompanyListPath = `users/${uid}/permissions/companies`
    const userCompanyListRef = doc(db, userCompanyListPath);
    const userCompanyListSnapshot = await getDoc(userCompanyListRef);
    const userCompanyListData = userCompanyListSnapshot.data();

    const companyList = [];

    // if (window.location.hostname.includes("localhost")) {
    //     const companyListRef = collection(db, "companies");
    //     const companyListSnapshot = await getDocs(companyListRef);
    //     companyListSnapshot.forEach((companySnapshot) => {
    //         const companyData = companySnapshot.data();
    //         companyList.push(companyData);
    //     });

    // }


    for (const company in userCompanyListData) {
        const companyData = userCompanyListData[company]
        companyList.push(companyData);
    }

    if (companyList.length === 1) {
        const companyData = companyList[0]
        await dispatch(onSelectCompany({ ...companyData }));
    }
    return companyList;
});

export const onSelectCompany = createAsyncThunk("companies/onSelectCompany", async (payload, { dispatch, getState }) => {
    let { ref, id } = payload;
    if (!ref) ref = doc(db, `companies/${id}`);
    const companySnapshot = await getDoc(ref);
    const companyData = companySnapshot.data();
    companyData.startDate = companyData.startDate.substring(0, 10);
    // save selected company id to local storage
    localStorage.setItem("selectedCompanyId", id);
    return companyData;
});

export const createNewCompany = createAsyncThunk("companies/createNewCompany", async (payload, { dispatch }) => {
    console.log(payload);
    const addCompany = httpsCallable(functions, "addCompany");
    const company = await addCompany(payload);
    // if error occured, throw it
    if (company.data.error) {
        throw new Error(company.data.error);
    } else {
        await dispatch(loadAuthorizedCompanies());
        await dispatch(onSelectCompany({ ...company.data }));
    }

    return company.data;
});

export const updateCompanyOnDb = createAsyncThunk("companies/updateCompanyOnDb", async (payload, { dispatch }) => {

    const updateCompany = httpsCallable(functions, "updateCompany");
    const company = await updateCompany(payload);
    // if error occured, throw it
    if (company.data.error) {
        throw new Error(company.data.error);
    } else {
        await dispatch(loadAuthorizedCompanies());
        await dispatch(onSelectCompany({ ...company.data }));
    }
    return company.data;
});

export const companyTypeEstimator = createAsyncThunk("companies/companyTypeEstimator", async (payload) => {
    const title = payload;

    let companyType = "Şahıs Firması";
    if (title.toLocaleLowerCase().includes("limite")) {
        companyType = "Limited";
    } else if (title.toLocaleLowerCase().includes("ltd")) {
        companyType = "Limited";
    } else if (title.toLocaleLowerCase().includes("l.t.d")) {
        companyType = "Limited";
    } else if (title.toLocaleLowerCase().includes("anonim")) {
        companyType = "Anonim";
    } else if (title.toLocaleLowerCase().includes("aş")) {
        companyType = "Anonim";
    } else if (title.toLocaleLowerCase().includes("a.ş")) {
        companyType = "Anonim";
    }

    return companyType;
});



