import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { yup } from "util/yupLocale";
import Form from "features/transactions/Form";

import { prevStep, nextStep } from "features/transactions/slice";
import { updateTransaction } from "features/transactions/services";
import { loadCompanyDatas } from "features/companyData/services";
import numberFormat from "functions/numberFormat";

const schema = yup.object({
  raporTarihi: yup
    .string()
    .required()
    .label("Rapor Tarihi")
    .meta({
      type: "date",
      className: "sm:w-2/3",
      component: "input",
      defaultValue: new Date().toISOString().slice(0, 10),
    }),
  maliVeriTarihi: yup
    .string()
    .required()
    .label("Mali Verilerin Tarihi (mizan/bilanço)")
    .meta({
      type: "date",
      className: "sm:w-2/3",
      component: "input",
      defaultValue: new Date().toISOString().slice(0, 10),
    }),
  ozvarlikRaporNo: yup.string().required().label("Öz varlık tespit rapor numarası").min(1).meta({
    type: "text",
    className: "sm:w-2/3",
    component: "input",
    placeholder: "2023-01",
  }),
  gecmisDonemKarRaporNo: yup.string().required().label("Geçmiş dönem karları tespit rapor numarası").min(1).meta({
    type: "text",
    className: "sm:w-2/3",
    component: "input",
  }),
  ortaklardanAlacaklarRaporNo: yup.string().required().label("Ortaklardan alacaklar tespit rapor numarası").min(1).meta({
    type: "text",
    className: "sm:w-2/3",
    component: "input",
  }),
  maddeNo: yup.string().required().label("Ana sözleşme sermaye madde numarası").min(1).meta({
    type: "text",
    className: "sm:w-2/3",
    component: "input",
    placeholder: "6",
  }),
});

const SerArtRapor = ({}) => {
  const dispatch = useDispatch();

  // const {
  //   sermayeArtRaporBilgileri,
  //   serArtGecmisYilKar,
  //   serArtOrtaklardanAlacaklar,
  //   serArtOzVarlikTespit,
  //   serArtNakit,
  //   serArtSermayeOdemeleri,
  // } = useSelector((state) => state.transactions.selectedTransaction);

  const sermayeArtRaporBilgileri = useSelector((state) => state.transactions.selectedTransaction?.sermayeArtRaporBilgileri);
  const serArtGecmisYilKar = useSelector((state) => state.transactions.selectedTransaction?.serArtGecmisYilKar);
  const serArtOrtaklardanAlacaklar = useSelector((state) => state.transactions.selectedTransaction?.serArtOrtaklardanAlacaklar);
  const serArtOzVarlikTespit = useSelector((state) => state.transactions.selectedTransaction?.serArtOzVarlikTespit);
  const serArtNakit = useSelector((state) => state.transactions.selectedTransaction?.serArtNakit);
  const serArtSermayeOdemeleri = useSelector((state) => state.transactions.selectedTransaction?.serArtSermayeOdemeleri);


  const onUpdate = async (form) => {
    const deepCopyForm = JSON.parse(JSON.stringify(form));
    // await dispatch(setOrtaklarKarariBilgileri(deepCopyForm));
    await dispatch(
      updateTransaction({
        data: {
          sermayeArtRaporBilgileri: deepCopyForm,
        },
      })
    );
    dispatch(nextStep());
  };

  const capital = useSelector((state) => state.companyData.capital);
  const totalCapital = capital?.totalCapital || 0;

  const gecmisYilKariArttirim = Number(serArtGecmisYilKar?.toplamSermayeArttirimi) || 0;
  const ortakArttirim = Number(serArtOrtaklardanAlacaklar?.toplamSermayeArttirimi) || 0;
  const nakdiArttirim = Number(serArtNakit?.toplamSermayeArttirimi) || 0;
  const toplamArttirim = gecmisYilKariArttirim + ortakArttirim + nakdiArttirim;

  const sonSermaye = totalCapital + toplamArttirim;

  const onPrev = () => {
    dispatch(prevStep());
  };

  useEffect(() => {
    dispatch(loadCompanyDatas({ documentName: "capital" }));
  }, []);

  return (
    <div className="mt-10 md:col-span-2 md:mt-0">
      <div className="mb-2 flex flex-col items-center justify-center bg-white py-2 shadow sm:rounded-md">
        <div className="text-base text-gray-600">Mevcut Sermaye: {numberFormat(totalCapital, 2, true)} TL</div>
        <div className="text-base text-gray-600">Toplam Sermaye Arttırımı: {numberFormat(toplamArttirim, 2, true)} TL</div>
        <div className="text-base text-gray-600">
          Sermaye Arttırımı Sonrası Toplam Sermaye: {numberFormat(sonSermaye, 2, true)} TL
        </div>
        <hr className="my-2" />
        <div className="text-base text-gray-600">Nakit Sermaye Arttırımı: {numberFormat(nakdiArttirim, 2, true)} TL</div>
        <div className="text-base text-gray-600">
          Geçmiş Yıl Karlarından Sermaye Arttırımı: {numberFormat(gecmisYilKariArttirim, 2, true)} TL
        </div>
        <div className="text-base text-gray-600">
          Ortaklardan Alacaklardan Sermaye Arttırımı: {numberFormat(ortakArttirim, 2, true)} TL
        </div>
      </div>

      <Form
        schema={schema}
        onSubmit={onUpdate}
        onPrev={onPrev}
        title="Rapor Bilgileri"
        description="Sermaye arttırım raporları için oluşturulacak rapor numaraları ve rapor tarihlerini giriniz."
        initData={sermayeArtRaporBilgileri}
      />
    </div>
  );
};

export default SerArtRapor;
