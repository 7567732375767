import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { useState } from "react";

import Subs from "features/layout/header/Subs";
import Help from "features/layout/header/Help";
import useCrumbs from "features/layout/header/useCrumbs";
import BreadCrumbs from "features/layout/header/BreadCrumbs";
import GlobalSearch from "features/search/GlobalSearch";

import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";

const Header = () => {
  const location = useLocation();
  const pathname = location?.pathname;
  const { wideMode } = useSelector((state) => state.ui);
  const { title, data } = useCrumbs({ pathname });

  const [openSearch, setOpenSearch] = useState(false);

  return (
    <div
      className={`z-10 flex h-20 items-center justify-between border-b border-gray-200 bg-gray-50 px-4 py-3 dark:border-gray-600 sm:px-5 lg:px-5`}
    >
      {/* <button
        type="button"
        className=" ring:ring-radical-red-500 ring:ring-opacity-50 mr-3 rounded-md border bg-gray-100 px-3 py-2 focus:outline-none focus:ring-2 focus:ring-radical-red-500 focus:ring-offset-2"
        onClick={() => setOpenSearch(!openSearch)}
      >
        <MagnifyingGlassIcon className="h-4 w-4 rounded-r-md text-gray-400" aria-hidden="true" />
        <GlobalSearch open={openSearch} setOpen={setOpenSearch} />
      </button> */}

      <div className="min-w-0 flex-1">
        <BreadCrumbs data={data} />
      </div>
      <Help />
      <Subs />
    </div>
  );
};

export default Header;
