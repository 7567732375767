import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { prevStep, nextStep } from "features/transactions/slice";
import { loadCompanyDatas } from "features/companyData/services";
import { updateTransaction } from "features/transactions/services";

import Button from "features/ui/Button";
import CheckListForm from "features/transactions/CheckListForm";

const SubeKapanisSec = () => {
  const dispatch = useDispatch();

  const onUpdate = async (form) => {
    const deepCopyForm = JSON.parse(JSON.stringify(form));
    // await dispatch(setOrtaklarKarariBilgileri(deepCopyForm));
    await dispatch(
      updateTransaction({
        data: {
          subeKapanis: deepCopyForm,
        },
      })
    );
    dispatch(nextStep());
  };

  const onPrev = () => dispatch(prevStep());

  useEffect(() => {
    dispatch(loadCompanyDatas({ documentName: "subeler" }));
  }, []);

  const subeler = useSelector((state) => state.companyData.subeler?.subeler);

  const isSubeExist = subeler?.length > 0;

  const { SubeKapanis } = useSelector((state) => state.transactions.selectedTransaction);
  const initData = SubeKapanis;

  const dcSgk = JSON.parse(JSON.stringify(subeler || []));

  const data = dcSgk.map((item) => {
    return {
      label: item.subeUnvan,
      description: `${item.subeAdress} - ${item.subeIlce} - ${item.subeIl}`,
      obj: item,
      id: item.subeUnvan,
    };
  });

  return (
    <div className="mt-10 md:col-span-2 md:mt-0">
      {/* <div className="overflow-hidden shadow sm:rounded-md">
        <div className=" col-span-12 overflow-y-scroll  bg-white shadow sm:rounded-lg">
          <div className="gap grid-cols-6 gap-3 border-b p-4 sm:grid sm:p-6">
            <div className="col-span-2 p-2 "></div>
            <div className="col-span-4  space-y-3 px-2 ">
              {" "}
              <Toggle
                // enabled, setEnabled, label, type
                label="SGK İşyeri Var mı?"
                enabled={isSubeExist}
                setEnabled={setisSubeExist}
                type="label-left"
              />
            </div>
          </div>
        </div>
      </div> */}
      {isSubeExist ? (
        <CheckListForm
          onSubmit={onUpdate}
          title="SGK Bilgileri"
          initData={initData}
          dataList={data}
          itemLabel="subeKapanis"
          description={"ilgili adreste yer alan sgk işyerlerini seçin"}
          onPrev={onPrev}
        />
      ) : (
        <>
          <div className="overflow-hidden shadow sm:rounded-md">
            <div className=" col-span-12 overflow-y-scroll  bg-white shadow sm:rounded-lg">
              <div className="gap grid-cols-6 gap-3 border-b p-4 sm:grid sm:p-6">
                <div className="col-span-2 p-2 "></div>
                <div className="col-span-4  space-y-3 px-2 "> sgk dosyası yok bu adımı geçebilirsiniz.</div>
              </div>
            </div>
          </div>

          <div className="overflow-hidden shadow sm:rounded-md">
            <div className="space-x-2 bg-gray-50 px-4 py-3 text-right dark:bg-gray-700 sm:px-6">
              <Button color="secondary" type="button" onClick={onPrev}>
                Geri
              </Button>
              <Button onClick={() => dispatch(onUpdate())}>Kaydet</Button>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default SubeKapanisSec;
