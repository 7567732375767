import { Link } from "react-router-dom";

import ROUTES from "features/navigation/routes";

const NotMatch = () => {
  return (
    <div className="min-h-screen bg-white px-4 py-16 sm:px-6 sm:py-24 md:grid md:place-items-center lg:px-8">
      <div className="mx-auto max-w-max">
        <main className="sm:flex">
          <p className="text-4xl font-extrabold text-primary sm:text-5xl">404</p>
          <div className="sm:ml-6">
            <div className="sm:border-l sm:border-gray-200 sm:pl-6">
              <h1 className="text-4xl font-extrabold tracking-tight text-gray-900 sm:text-5xl">Sayfa Bulunamadı</h1>
              <p className="mt-1 text-base text-gray-500">Lütfen adres satırını kontrol edip tekrar deneyin.</p>
            </div>
            <div className="mt-10 flex space-x-3 sm:border-l sm:border-transparent sm:pl-6">
              <Link
                className="inline-flex items-center rounded-md border border-transparent bg-primary px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-secondary focus:outline-none focus:ring-2 focus:ring-radical-red-500 focus:ring-offset-2"
                to={ROUTES.HOME.path}
              >
                Anasayfaya Git
              </Link>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default NotMatch;
