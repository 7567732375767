import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { currentStatusSelector } from "features/transactions/slice";
import { CheckIcon } from "@heroicons/react/20/solid";

const StatusProgress = ({}) => {
  const { step: currentStep, steps } = useSelector((state) => state.transactions);

  const selectedTransaction = useSelector((state) => state.transactions.selectedTransaction);

  const { creditToBeSpend } = selectedTransaction;
  // create steps array from allStatus
  const [activeSteps, setActiveSteps] = useState([]);

  useEffect(() => {
    const _steps = steps.map((item) => {
      let statusState = "upcoming";
      if (item.completed === true) statusState = "complete";
      if (item === currentStep) statusState = "current";
      return {
        name: item.name,
        href: item.href,
        status: statusState,
        //component: item.component,
        description: item.description,
        isActive: item.isActive,
        itemColor: item.registered ? "bg-solitaire-100" : "bg-white",
      };
    });

    const active = _steps.filter((item) => item.isActive);
    setActiveSteps(active);
  }, [steps, currentStep]);

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }
  return (
    <nav aria-label="Progress">
      {/* render here creditToBeSpend */}
      <div className="flex items-center justify-center space-x-3 px-2 py-4">
        <span className="inline-flex items-center rounded-md bg-yellow-50 px-2 py-1 text-xs font-medium text-yellow-800 ring-1 ring-inset ring-yellow-600/20">
          {creditToBeSpend} Kontör Kullanılacak
        </span>
        <span className="inline-flex items-center rounded-md bg-yellow-50 px-2 py-1 text-xs font-medium text-yellow-800 ring-1 ring-inset ring-yellow-600/20">
          {selectedTransaction.selectedTransactions.length} İşlem
        </span>
      </div>

      <ol className="overflow-hidden">
        {activeSteps.map((step, stepIdx) => (
          <li key={step.name} className={classNames(stepIdx !== steps.length - 1 ? "pb-4" : "", "relative")}>
            {step.status === "complete" ? (
              <>
                {stepIdx !== steps.length - 1 ? (
                  <div className="absolute left-4 top-4 -ml-px mt-0.5 h-full w-0.5 bg-indigo-600" aria-hidden="true" />
                ) : null}
                <a href={step.href} className="group relative flex items-start">
                  <span className="flex h-9 items-center">
                    <span className="relative z-10 flex h-8 w-8 items-center justify-center rounded-full bg-indigo-600 group-hover:bg-indigo-800">
                      <CheckIcon className="h-5 w-5 text-white" aria-hidden="true" />
                    </span>
                  </span>
                  <span className="ml-4 flex min-w-0 flex-col">
                    <span className="text-sm font-medium">{step.name}</span>
                    <span className="text-sm text-gray-500">{step.description}</span>
                  </span>
                </a>
              </>
            ) : step.status === "current" ? (
              <>
                {stepIdx !== steps.length - 1 ? (
                  <div className="absolute left-4 top-4 -ml-px mt-0.5 h-full w-0.5 bg-gray-300" aria-hidden="true" />
                ) : null}
                <a href={step.href} className="group relative flex items-start" aria-current="step">
                  <span className="flex h-9 items-center" aria-hidden="true">
                    <span className="relative z-10 flex h-8 w-8 items-center justify-center rounded-full border-2 border-indigo-600 bg-white">
                      <span className="h-2.5 w-2.5 rounded-full bg-indigo-600" />
                    </span>
                  </span>
                  <span className="ml-4 flex min-w-0 flex-col">
                    <span className="text-sm font-medium text-indigo-600">{step.name}</span>
                    <span className="text-sm text-gray-500">{step.description}</span>
                  </span>
                </a>
              </>
            ) : (
              <>
                {stepIdx !== steps.length - 1 ? (
                  <div className="absolute left-4 top-4 -ml-px mt-0.5 h-full w-0.5 bg-gray-300" aria-hidden="true" />
                ) : null}
                <a href={step.href} className="group relative flex items-start">
                  <span className="flex h-9 items-center" aria-hidden="true">
                    <span className="relative z-10 flex h-8 w-8 items-center justify-center rounded-full border-2 border-gray-300 bg-white group-hover:border-gray-400">
                      <span className="h-2.5 w-2.5 rounded-full bg-transparent group-hover:bg-gray-300" />
                    </span>
                  </span>
                  <span className="ml-4 flex min-w-0 flex-col">
                    <span className="text-sm font-medium text-gray-500">{step.name}</span>
                    <span className="text-sm text-gray-500">{step.description}</span>
                  </span>
                </a>
              </>
            )}
          </li>
        ))}
      </ol>
    </nav>
  );
};

export default StatusProgress;
