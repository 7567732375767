import { createSlice, createEntityAdapter, createSelector } from '@reduxjs/toolkit'

import notify from 'features/ui/notify'

import { loadUsers, deleteUserFromCompany, checkIsEmailInUse, acceptInvite, inviteUser } from './services'

const userAdapter = createEntityAdapter()

const initialState = userAdapter.getInitialState({
    // statuses: 'idle' | 'loading' | 'succeeded' | 'failed'
    status: 'idle',
    error: null,
    invite: null
})

export const userSelector = userAdapter.getSelectors(state => state.users)

const userSlice = createSlice({
    name: 'users',
    initialState,
    reducers: {
        addUser: userAdapter.addOne,
        removeUser: userAdapter.removeOne,
        updateUser: userAdapter.updateOne,
        setUsers: userAdapter.setAll,
        setUsersStatus: (state, action) => {
            state.status = action.payload
        }
    },
    extraReducers: (builder) => {
        builder.addCase(loadUsers.pending, (state, action) => {
            state.status = 'loading'
        })
            .addCase(loadUsers.fulfilled, (state, action) => {
                state.status = 'succeeded'
                userAdapter.addMany(state, action.payload)
            })
            .addCase(loadUsers.rejected, (state, action) => {
                state.status = 'failed'
                state.error = action.error.message
            })
            .addCase(deleteUserFromCompany.pending, (state, action) => {
                state.status = 'loading'
            })
            .addCase(deleteUserFromCompany.fulfilled, (state, action) => {
                state.status = 'succeeded'
                userAdapter.removeOne(state, action.payload)
            })
            .addCase(deleteUserFromCompany.rejected, (state, action) => {
                state.status = 'failed'
                state.error = action.error.message
                notify(action.error.message, "error")
            })
            .addCase(checkIsEmailInUse.pending, (state, action) => {
                state.status = 'loading'
            })
            .addCase(checkIsEmailInUse.fulfilled, (state, action) => {
                state.status = 'succeeded'
                state.invite = action.payload
            })
            .addCase(checkIsEmailInUse.rejected, (state, action) => {
                state.status = 'failed'
                state.error = action.error.message
                notify(action.error.message, "error")
            })
            .addCase(acceptInvite.pending, (state, action) => {
                state.status = 'loading'
            })
            .addCase(acceptInvite.fulfilled, (state, action) => {
                state.status = 'succeeded'
            })
            .addCase(acceptInvite.rejected, (state, action) => {
                state.status = 'failed'
                state.error = action.error.message
                notify(action.error.message, "error")
            })
            .addCase(inviteUser.pending, (state, action) => {
                state.status = 'loading'
            })
            .addCase(inviteUser.fulfilled, (state, action) => {
                state.status = 'succeeded'
                // userAdapter.addOne(state, action.payload)
            })

    }
})

export const {
    addUser,
    removeUser,
    updateUser,
    setUsers,
    setUsersStatus
} = userSlice.actions

export default userSlice.reducer