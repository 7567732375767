import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import Modal from "features/ui/Modal";
import PrivacyPolicy from "features/shop/PrivacyPolicy";
import TermsOfSale from "features/shop/TermsOfSale";

const Bag = ({ allowCheckout, isAgreed, setIsAgreed, showContrats }) => {
  const bag = useSelector((state) => state.shop.bag);

  const totalProductsPrice = bag.reduce((acc, product) => acc + product.price, 0);
  const totalVat = bag.reduce((acc, product) => acc + product.price * (product.vatRate / 100), 0);
  const total = totalProductsPrice + totalVat;

  const [openPrivacyPolicy, setOpenPrivacyPolicy] = useState(false);
  const [openTermsOfSale, setOpenTermsOfSale] = useState(false);

  return (
    <>
      <h1 className="sr-only">Sipariş Bilgileri</h1>

      <section
        aria-labelledby="summary-heading"
        className="bg-gray-50 px-4 pt-16 pb-10 sm:px-6 lg:col-start-2 lg:row-start-1 lg:bg-transparent lg:px-0 lg:pb-16"
      >
        <div className="mx-auto max-w-lg lg:max-w-none">
          <h2 id="summary-heading" className="text-lg font-medium text-gray-900">
            Satınalma Özeti
          </h2>

          <ul className="divide-y divide-gray-200 text-sm font-medium text-gray-900">
            {bag.length > 0 &&
              bag.map((service) => (
                <li key={service.id} className="flex items-start space-x-4 py-6">
                  {/* <img
                    src={product.imageSrc}
                    alt={product.imageAlt}
                    className="h-20 w-20 flex-none rounded-md object-cover object-center"
                  /> */}
                  <div className="flex-auto space-y-1">
                    <h3>{service.name}</h3>
                    <p className="text-gray-500">{service.description}</p>
                  </div>
                  <p className="flex-none text-base font-medium">{service.price} TL</p>
                </li>
              ))}
          </ul>
          <dl className="hidden space-y-6 border-t border-gray-200 pt-6 text-sm font-medium text-gray-900 lg:block">
            <div className="flex items-center justify-between">
              <dt className="text-gray-600">Toplam</dt>
              <dd>{totalProductsPrice} TL</dd>
            </div>

            <div className="flex items-center justify-between">
              <dt className="text-gray-600">KDV</dt>
              <dd>{totalVat} TL</dd>
            </div>

            <div className="flex items-center justify-between border-t border-gray-200 pt-6">
              <dt className="text-base">Genel Toplam</dt>
              <dd className="text-base">{total} TL</dd>
            </div>
          </dl>

          <dl className="space-y-6 border-t border-gray-200 pt-6 text-sm font-medium text-gray-900">
            {showContrats && (
              <div className="flex items-center justify-between">
                {/* satınalma işlemini yaprak Gizlilik Ve Güvenlik Politikası, Mesafeli Satış Sözleşmesi'ni kabul etmiş sayılırsınız. */}
                {/* add checkbux */}

                <input
                  type="checkbox"
                  name="agreement"
                  id="agreement"
                  checked={isAgreed}
                  className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                  onChange={(e) => setIsAgreed(e.target.checked)}
                />
                <label htmlFor="agreement2" className="ml-4">
                  <Modal
                    title="Gizlilik Ve Güvenlik Politikası"
                    modalButonLaber="Gizlilik Ve Güvenlik Politikası"
                    buttonAsLink={true}
                    setOpen={setOpenPrivacyPolicy}
                    open={openPrivacyPolicy}
                    closeClickOutside={true}
                    modalClass="relative mt-12 inline-block transform overflow-hidden rounded-lg bg-white  p-4 text-left align-bottom shadow-xl transition-all sm:mt-2 sm:w-8/12 sm:align-top print:mt-1"
                  >
                    {<PrivacyPolicy />}
                  </Modal>
                  ,{" "}
                  <Modal
                    title="Mesafeli Satış Sözleşmesi"
                    modalButonLaber="Mesafeli Satış Sözleşmesi"
                    buttonAsLink={true}
                    setOpen={setOpenTermsOfSale}
                    open={openTermsOfSale}
                    closeClickOutside={true}
                    modalClass="relative mt-12 inline-block transform overflow-hidden rounded-lg bg-white  p-4 text-left align-bottom shadow-xl transition-all sm:mt-2 sm:w-8/12 sm:align-top print:mt-1"
                  >
                    {<TermsOfSale bag={bag} totalProductsPrice={totalProductsPrice} totalVat={totalVat} total={total} />}
                  </Modal>
                  'ni okudum kabul ediyorum.
                </label>
              </div>
            )}
          </dl>
        </div>
        {allowCheckout || null}
      </section>
    </>
  );
};

export default Bag;
