import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { yup } from "util/yupLocale";
import Form from "../../Form";
import { prevStep, nextStep } from "features/transactions/slice";
import { updateTransaction } from "features/transactions/services";

const schema = yup.object({
  
  subeIl: yup.string().required().label("Şube İl").meta({
    type: "text",
    className: "sm:w-2/3",
    component: "input",
  }),
  subeIlce: yup.string().required().label("Şube İlçe").meta({
    type: "text",
    className: "sm:w-2/3",
    component: "input",
  }),
  subeAdress: yup.string().required().label("Şube Adres").min(5).meta({
    type: "text",
    className: "sm:w-3/3",
    component: "textarea",
  }),
  subeUnvan: yup.string().required().label("Şube Ek Unvanı").min(5).meta({
    type: "text",
    className: "sm:w-3/3",
    component: "input",
    placeholder: "Örnek: Alaçatı Şubesi - Uzun ünvan yazmayın sadece şube kısmını belirtin",
  }),
  subeSermaye: yup.string().label("Şube Sermayesi").min(1).meta({
    type: "text",
    className: "sm:w-3/3",
    component: "input",
    defaultValue: "0",
  }),
});

const SubeAcilisi = ({}) => {
  const dispatch = useDispatch();

  const onUpdate = async (form) => {
    const deepCopyForm = JSON.parse(JSON.stringify(form));
    // await dispatch(setNewAddress(deepCopyForm));
    await dispatch(
      updateTransaction({
        data: {
          subeAcilisi: deepCopyForm,
        },
      })
    );
    dispatch(nextStep());
  };

  const onPrev = () => {
    dispatch(prevStep());
  };
  const { address } = useSelector((state) => state.companies.selectedCompany);
  const { subeAcilisi } = useSelector((state) => state.transactions.selectedTransaction);

  const initVal = JSON.parse(JSON.stringify(subeAcilisi || {})) || {};
  initVal.merkezAdress = address || "";

  return (
    <Form
      schema={schema}
      onSubmit={onUpdate}
      title="Şube Açılışı"
      initData={initVal}
      description={"Şube Bilgilerini Giriniz."}
      onPrev={onPrev}
    />
  );
};

export default SubeAcilisi;
