import React, { useState } from "react";

const Tooltip = ({
  children,
  toolTipComponent,
  componentClassname = "fixed z-50 mt-2  max-w-sm whitespace-pre-wrap rounded-md bg-yellow-100 py-2 px-4 text-sm  font-normal text-gray-600",
}) => {
  const [showTooltip, setShowTooltip] = useState(false);

  const handleMouseEnter = () => {
    setShowTooltip(true);
  };

  const handleMouseLeave = () => {
    setShowTooltip(false);
  };

  return (
    <div className="relative" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      {children}
      {showTooltip && <div className={componentClassname}>{toolTipComponent}</div>}
    </div>
  );
};

export default Tooltip;
