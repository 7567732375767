import { createSlice, createEntityAdapter, createSelector } from '@reduxjs/toolkit'

import { updateTransaction, loadTransactions, loadTransactionFiles, createDocument, removeTransactionFromDb, copyTransaction } from 'features/transactions/services'
const transactionAdapter = createEntityAdapter()

const initialState = transactionAdapter.getInitialState({
    // statuses: 'idle' | 'loading' | 'succeeded' | 'failed'
    status: 'idle',
    error: null,
    selectedTransaction: null,
    attachments: [],
    steps: null,
    step: null,
    stepIndex: 0
})
/*
{
   id: 2,
   name: "Ortaklar",
   component: <Partners />,
   isActive: true,
 },
*/
export const transactionsSelector = transactionAdapter.getSelectors(state => state.companies)
export const currentStatusSelector = state => state.step

// create selector get all transactions
export const allTransactionsSelector = createSelector(
    transactionAdapter.getSelectors(state => state.transactions).selectAll,
    transactions => transactions
)

const transactionsSlice = createSlice({
    name: 'transactions',
    initialState,
    reducers: {
        addTransaction: transactionAdapter.addOne,
        // updateTransaction: transactionAdapter.updateOne,
        removeTransaction: transactionAdapter.removeOne,
        selectTransaction: (state, action) => {
            state.selectedTransaction = action.payload
            // if selectedTransaction.stepIndex is not null
            // set stepIndex to selectedTransaction.stepIndex
            if (action.payload.stepIndex) {
                state.stepIndex = action.payload.stepIndex
                state.step = state.steps[action.payload.stepIndex]
            }
        },
        setStep: (state, action) => {
            state.step = action.payload
            const stepIndex = state.steps.findIndex(item => item.id === action.payload.id)
            state.stepIndex = stepIndex
        },
        setSteps: (state, action) => {
            state.steps = action.payload
            state.step = action.payload[0]
        },
        nextStep: (state, action) => {
            const steps = state.steps
            const activeSteps = steps.filter(item => item.isActive)
            const index = activeSteps.findIndex(item => item.id === state.step.id)
            if (index < activeSteps.length - 1) {
                state.step = activeSteps[index + 1]
                state.stepIndex = index + 1
            }
            // set allStatus item to completed
            if (index > -1) {
                state.steps[index].status = "completed"
            }
        },
        prevStep: (state, action) => {
            const steps = state.steps
            const index = steps.findIndex(item => item.id === state.step.id)
            if (index > 0) {
                state.step = steps[index - 1]
                state.stepIndex = index - 1
            }
        },
        toggleStep: (state, action) => {

            const stepName = action.payload
            const steps = state.steps
            const index = steps.findIndex(item => item.name === stepName)
            if (index > -1) {
                state.steps[index].isActive = !state.steps[index].isActive
            }

        },
        setStepToActive: (state, action) => {
            const stepName = action.payload
            const steps = state.steps
            const index = steps.findIndex(item => item.name === stepName)
            if (index > -1) {
                state.steps[index].isActive = true
            }
        },
        setStepToNotActive: (state, action) => {
            const stepName = action.payload
            const steps = state.steps
            const index = steps.findIndex(item => item.name === stepName)
            if (index > -1) {
                state.steps[index].isActive = false
            }
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(updateTransaction.pending, (state, action) => {
                state.status = 'loading'
            })
            .addCase(updateTransaction.fulfilled, (state, action) => {
                state.status = 'succeeded'
                state.selectedTransaction = {
                    ...state.selectedTransaction,
                    ...action.payload
                }
            })
            .addCase(updateTransaction.rejected, (state, action) => {
                state.status = 'failed'
                state.error = action.error.message
            })
            .addCase(loadTransactions.pending, (state, action) => {
                state.status = 'loading'
            })
            .addCase(loadTransactions.fulfilled, (state, action) => {
                state.status = 'succeeded'
                // remove all transactions
                transactionAdapter.removeAll(state)
                // add all transactions
                transactionAdapter.setAll(state, action.payload)
            })
            .addCase(loadTransactions.rejected, (state, action) => {
                state.status = 'failed'
                state.error = action.error.message
            })
            .addCase(loadTransactionFiles.pending, (state, action) => {
                state.status = 'loading'
            })
            .addCase(loadTransactionFiles.fulfilled, (state, action) => {
                state.status = 'succeeded'
                state.attachments = action.payload
            })
            .addCase(loadTransactionFiles.rejected, (state, action) => {
                state.status = 'failed'
                state.error = action.error.message
            })
            .addCase(createDocument.pending, (state, action) => {
                state.status = 'loading'
            })
            .addCase(createDocument.fulfilled, (state, action) => {
                state.status = 'succeeded'
            })
            .addCase(createDocument.rejected, (state, action) => {
                state.status = 'failed'
                state.error = action.error.message
            })
            .addCase(removeTransactionFromDb.pending, (state, action) => {
                state.status = 'loading'
            })

            .addCase(removeTransactionFromDb.fulfilled, (state, action) => {
                state.status = 'succeeded'
            })
            .addCase(removeTransactionFromDb.rejected, (state, action) => {
                state.status = 'failed'
                state.error = action.error.message
            })
            .addCase(copyTransaction.pending, (state, action) => {
                state.status = 'loading'
            })
            .addCase(copyTransaction.fulfilled, (state, action) => {
                state.status = 'succeeded'
            })
            .addCase(copyTransaction.rejected, (state, action) => {
                state.status = 'failed'
                state.error = action.error.message
            })
    }
})


export const {
    addTransaction,
    // updateTransaction,
    removeTransaction,
    selectTransaction,
    setStep,
    setSteps,
    nextStep,
    prevStep,
    toggleStep,
    setStepToActive,
    setStepToNotActive

} = transactionsSlice.actions

export default transactionsSlice.reducer