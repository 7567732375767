import { Menu } from "@headlessui/react";
import { Bars3Icon } from "@heroicons/react/24/outline";
import { useSelector, useDispatch } from "react-redux";

import ProfileMenu from "features/layout/navProfileMenu/ProfileMenu";
import { setSidebarOpen } from "features/ui/slice";

const MobileMenu = () => {
  const dispatch = useDispatch();
  const { wideMode } = useSelector((state) => state.ui);

  const handleSetSidebarOpen = () => dispatch(setSidebarOpen(true));

  let mmClass =
    "sticky bg-gray-100 top-0 z-30 flex h-16 flex-shrink-0  border-b border-gray-200 dark:border-gray-600 lg:hidden";
  if (wideMode)
    mmClass = "sticky bg-gray-100 top-0 z-30 flex h-16 flex-shrink-0  border-b border-gray-200 dark:border-gray-600";

  let buttonClass =
    "border-r border-gray-200 px-4 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-radical-red-500 dark:border-gray-600 lg:hidden";
  if (wideMode)
    buttonClass =
      "border-r border-gray-200 px-4 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-radical-red-500 dark:border-gray-600";

  return (
    <div className={mmClass}>
      <button type="button" className={buttonClass} onClick={handleSetSidebarOpen}>
        <span className="sr-only">Open sidebar</span>
        <Bars3Icon className="h-6 w-6" aria-hidden="true" />
      </button>
      <div className="flex flex-1 justify-between px-4 sm:px-6 lg:px-8">
        <div className="flex flex-1"></div>
        <div className="flex items-center">
          <Menu as="div" className="relative ml-3">
            <ProfileMenu type="Mobile" />
          </Menu>
        </div>
      </div>
    </div>
  );
};

export default MobileMenu;
