import { createSlice, createEntityAdapter } from '@reduxjs/toolkit'

import { loadCapitalData, loadAuthoritiesData, loadSgkMudurlugu, loadCompanyDatas,catchCpaInfoFromOtherCompany } from './services'

export const companyDataSlice = createSlice({
    name: 'companyData',
    initialState: {
        status: 'idle',
        error: null,
        capital: {},
        partners: [],
        authorities: [],
        sgkInfo: {},
        subeler: [],
    },
    reducers: {
        setCapital: (state, action) => {
            state.capital = action.payload
        },
        resetAll: (state) => {
            state.status = 'idle'
            state.error = null
            state.capital = {}
            state.partners = []
            state.authorities = []
            state.sgkInfo = {}
            state.subeler = []
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(loadCapitalData.pending, (state, action) => {
                state.status = 'loading'
            })
            .addCase(loadCapitalData.fulfilled, (state, action) => {
                state.status = 'succeeded'
                state.capital = action.payload.capital
                state.partners = action.payload.partners
            })
            .addCase(loadCapitalData.rejected, (state, action) => {
                state.status = 'failed'
                state.error = action.error.message
            })
            .addCase(loadAuthoritiesData.pending, (state, action) => {
                state.status = 'loading'
            })
            .addCase(loadAuthoritiesData.fulfilled, (state, action) => {
                state.status = 'succeeded'
                state.authorities = action.payload.authorities
            })
            .addCase(loadAuthoritiesData.rejected, (state, action) => {
                state.status = 'failed'
                state.error = action.error.message
            })
            .addCase(loadSgkMudurlugu.pending, (state, action) => {
                state.status = 'loading'
            })
            .addCase(loadSgkMudurlugu.fulfilled, (state, action) => {
                state.status = 'succeeded'
                state.sgkInfo = action.payload
            })
            .addCase(loadSgkMudurlugu.rejected, (state, action) => {
                state.status = 'failed'
                state.error = action.error.message
            })
            .addCase(loadCompanyDatas.pending, (state, action) => {
                state.status = 'loading'
            })
            .addCase(loadCompanyDatas.fulfilled, (state, action) => {
                state.status = 'succeeded'
                state[action.payload.documentName] = action.payload.data
            })
            .addCase(loadCompanyDatas.rejected, (state, action) => {
                state.status = 'failed'
                state.error = action.error.message
            })
            .addCase(catchCpaInfoFromOtherCompany.pending, (state, action) => {
                state.status = 'loading'
            })
            .addCase(catchCpaInfoFromOtherCompany.fulfilled, (state, action) => {
                state.status = 'succeeded'
                state[action.payload.documentName] = action.payload.data
            })
            .addCase(catchCpaInfoFromOtherCompany.rejected, (state, action) => {
                state.status = 'failed'
                state.error = action.error.message
            })
    }
})

export const { setCapital, resetAll } = companyDataSlice.actions

export default companyDataSlice.reducer

