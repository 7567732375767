import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { yup } from "util/yupLocale";
import ArrayForm from "features/transactions/ArrayForm";
import yupObjGenerator from "features/transactions/yupObjGenerator";

import { prevStep, nextStep, setStepToActive, setStepToNotActive } from "features/transactions/slice";
import { updateSgkMudurlugu, loadSgkMudurlugu } from "features/companyData/services";
import Toggle from "features/ui/Toggle";
import Button from "features/ui/Button";

// 269200707999999903424-80/000

const SgkIsyerleri = ({}) => {
  const dispatch = useDispatch();

  const [isSgkExists, setIsSgkExists] = useState(true);
  const merkezAdres = useSelector((state) => state.companies?.selectedCompany?.address);
  const { step } = useSelector((state) => state.transactions);
  const isRegistered = step.registered;

  const schemaObj = {
    sgkMudurlugu: yup
      .string()
      .label("SGK Müdürlüğü")
      .required("İşyerinin bağlı bulunduğu SGK Müdürlüğünü girmelisiniz.")
      .meta({
        type: "text",
        className: "sm:w-2/3",
        component: "input",
        transactionTypes: ["Adres Değişikliği", "Merkez Nakli"],
      }),
    sgkSicilNo: yup
      .string()
      .required()
      .label("SGK Sicil No")
      .min(7)
      .max(28)
      .meta({
        type: "text",
        className: "sm:w-2/3",
        component: "input",
        transactionTypes: ["Adres Değişikliği", "Merkez Nakli"],
      }),
    address: yup
      .string()
      .required()
      .label("Tescilli Adres")
      .meta({
        type: "text",
        className: "sm:w-2/3",
        component: "input",
        defaultValue: merkezAdres,
        transactionTypes: ["Adres Değişikliği", "Merkez Nakli"],
      }),
  };

  const { transactionTypes } = useSelector((state) => state.transactions.selectedTransaction);

  const schema = yupObjGenerator({
    yup,
    schemaObj,
    transactionTypes,
    arrName: "sgkIsyerleri",
  });

  const onUpdate = async (form) => {
    if (!form) {
      form = {};
      form.sgkIsyerleri = [];
    }
    form.isSgkExists = isSgkExists;
    if (isSgkExists) {
      dispatch(setStepToActive("Adresteki SGK İşyerleri"));
    } else {
      dispatch(setStepToNotActive("Adresteki SGK İşyerleri"));
    }
    await dispatch(updateSgkMudurlugu(form));
    dispatch(nextStep());
  };

  const onPrev = () => {
    dispatch(prevStep());
  };

  useEffect(() => {
    dispatch(loadSgkMudurlugu());
  }, []);

  const sgkInfo = useSelector((state) => state.companyData?.sgkInfo);
  const sgkIsyerleri = sgkInfo?.sgkIsyerleri;

  useEffect(() => {
    if (!sgkIsyerleri) return;
    if (sgkInfo?.isSgkExists === undefined) return;
    setIsSgkExists(sgkInfo.isSgkExists);
  }, [sgkInfo]);

  const defaultItem = {
    sgkMudurlugu: "",
    sgkSicilNo: "",
    address: merkezAdres,
  };

  return (
    <div className="mt-10 md:col-span-2 md:mt-0">
      <div className="overflow-hidden shadow sm:rounded-md">
        <div className=" col-span-12 overflow-y-scroll  bg-white shadow sm:rounded-lg">
          <div className="gap grid-cols-6 gap-3 border-b p-4 sm:grid sm:p-6">
            <div className="col-span-2 p-2 "></div>
            <div className="col-span-4  space-y-3 px-2 ">
              {" "}
              <Toggle
                // enabled, setEnabled, label, type
                label="SGK İşyeri Var mı?"
                enabled={isSgkExists}
                setEnabled={setIsSgkExists}
                type="label-left"
              />
            </div>
          </div>
        </div>
      </div>
      {isSgkExists ? (
        <ArrayForm
          schema={schema}
          onSubmit={onUpdate}
          title="SGK Bilgileri"
          initData={sgkIsyerleri}
          itemLabel="sgkIsyerleri"
          defaultItem={defaultItem}
          description={"SGK işyeri dosyası var ise bilgileri giriniz."}
          onPrev={onPrev}
          isRegistered={isRegistered}
        />
      ) : (
        <div className="overflow-hidden shadow sm:rounded-md">
          <div className="space-x-2 bg-gray-50 px-4 py-3 text-right dark:bg-gray-700 sm:px-6">
            <Button color="secondary" type="button" onClick={onPrev}>
              Geri
            </Button>
            <Button onClick={() => dispatch(onUpdate())}>Kaydet</Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default SgkIsyerleri;
