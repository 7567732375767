import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { yup } from "util/yupLocale";
import ArrayForm from "../../ArrayForm";

import { prevStep, nextStep } from "features/transactions/slice";
import { updateTransaction } from "features/transactions/services";
import { updateAuthorities, loadAuthoritiesData, updateCapital, loadCapitalData } from "features/companyData/services";
import Toggle from "features/ui/Toggle";
import Button from "features/ui/Button";

const SerArtGecmisYilKar = ({}) => {
  const dispatch = useDispatch();

  const { step } = useSelector((state) => state.transactions);
  const [isExists, setIsExists] = useState(true);
  const isRegistered = step.registered;
  // get last ten years only year like "2021"
  const years = Array.from({ length: 20 }, (_, i) => new Date().getFullYear() - i);

  const schemaObj = {
    yil: yup
      .string()
      .required()
      .label("Yılı")
      .meta({
        type: "select",
        className: "sm:w-2/3",
        component: "select",
        options: years.map((year) => ({ value: year, label: year })),
      }),
    tutar: yup.string().required().label("Tutar").meta({
      type: "text",
      className: "sm:w-2/3",
      component: "number",
    }),
    yevmiyeTarihi: yup.string().required().label("Yevmiye Tarihi").meta({
      type: "date",
      className: "sm:w-2/3",
      component: "input",
    }),
    yevmiyeNo: yup.string().required().label("Yevmiye No").meta({
      type: "text",
      className: "sm:w-2/3",
      component: "input",
    }),
  };

  // const schema = "mudurAtama": yup.array().of(yup.object().shape(schemaObj))
  const schema = yup.object().shape({
    serArtGecmisYilKar: yup.array().of(yup.object().shape(schemaObj)),
  });

  const onUpdate = async (form) => {
    if (!isExists) {
      // eğer geçmiş yıl karları kullanılmayacaksa
      // toplam sermaye arttırımı 0 olacak
      // ve defterler boş olacak
      const newObj = {};
      newObj.toplamSermayeArttirimi = 0;
      newObj.defterler = [];
      await dispatch(
        updateTransaction({
          data: {
            serArtGecmisYilKar: newObj,
          },
        })
      );
      dispatch(nextStep());
      return;
    }
    form.toplamSermayeArttirimi = form.serArtGecmisYilKar.reduce((acc, item) => {
      return acc + Number(item.tutar);
    }, 0);

    // kayitlarin ait oldugu defterler
    form.defterler = form.serArtGecmisYilKar.map((item) => {
      return item.yevmiyeTarihi.split("-").reverse().join("").slice(4, 8);
    });

    // unique defterler
    form.defterler = [...new Set(form.defterler)];

    await dispatch(
      updateTransaction({
        data: {
          serArtGecmisYilKar: form,
        },
      })
    );
    dispatch(nextStep());
  };

  const onPrev = () => {
    dispatch(prevStep());
  };

  useEffect(() => {
    dispatch(loadAuthoritiesData());
    dispatch(loadCapitalData());
  }, []);

  const serArtGecmisYilKar = useSelector(
    (state) => state.transactions.selectedTransaction?.serArtGecmisYilKar?.serArtGecmisYilKar
  );

  const defaultItem = {
    adSoyad: "",
    tcVkn: "",
  };

  return (
    <div className="mt-10 md:col-span-2 md:mt-0">
      <div className="overflow-hidden shadow sm:rounded-md">
        <div className=" col-span-12 overflow-y-scroll  bg-white shadow sm:rounded-lg">
          <div className="gap grid-cols-6 gap-3 border-b p-4 sm:grid sm:p-6">
            <div className="col-span-2 p-2 "></div>
            <div className="col-span-4  space-y-3 px-2 ">
              {" "}
              <Toggle
                // enabled, setEnabled, label, type
                label="Geçmiş Yıl Karları Kullanılack Mı?"
                enabled={isExists}
                setEnabled={setIsExists}
                type="label-left"
              />
            </div>
          </div>
        </div>
      </div>
      {isExists ? (
        <ArrayForm
          schema={schema}
          onSubmit={onUpdate}
          initData={serArtGecmisYilKar}
          itemLabel="serArtGecmisYilKar"
          defaultItem={defaultItem}
          title="Geçmiş yıl karları"
          description={
            "İlgili sermaye arttırımında kullanılacak 570 nolu hesapta görünen geçmiş yıl karları bilgilerini yıl bazında giriniz"
          }
          onPrev={onPrev}
          isRegistered={isRegistered}
          totalFields={[{ label: "Sermaye arttırımının kardan karşılanan kısmı", fieldName: "tutar" }]}
        />
      ) : (
        <div className="overflow-hidden shadow sm:rounded-md">
          <div className="space-x-2 bg-gray-50 px-4 py-3 text-right dark:bg-gray-700 sm:px-6">
            <Button color="secondary" type="button" onClick={onPrev}>
              Geri
            </Button>
            <Button onClick={() => dispatch(onUpdate())}>Kaydet</Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default SerArtGecmisYilKar;
