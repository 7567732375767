import Logo from "features/ui/Logo";
import Loader from "features/ui/Loader";

const Loading = ({ text }) => {
  return (
    <div className="flex min-h-screen flex-col items-center justify-center bg-gray-50">
      <div className="flex flex-col items-center justify-start">
        <Logo className="h-36" />
        <div className="pt-2">
          <p className="text-sm font-medium text-gray-500">Lütfen bekleyin...</p>
          {text ? <p>{text}</p> : null}
        </div>
        <div className="h-28 text-center">
          <Loader type="1" className="ml-10" />
        </div>
      </div>
    </div>
  );
};

export default Loading;
