import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { yup } from "util/yupLocale";
import Form from "features/transactions/Form";

import { prevStep, nextStep } from "features/transactions/slice";
import { updateTransaction } from "features/transactions/services";
import { loadAuthoritiesData, loadCapitalData } from "features/companyData/services";

const GenelKurulTutanak = ({}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadCapitalData());
    dispatch(loadAuthoritiesData());
  }, []);

  const partners = useSelector((state) => state.companyData.partners);
  const authorities = useSelector((state) => state.companyData.authorities);

  const allPersons = JSON.parse(JSON.stringify([...partners, ...authorities]));
  // filter all person with unique tcVkn
  const uniquePersons = allPersons.filter((person, index, self) => index === self.findIndex((p) => p.tcVkn === person.tcVkn));

  const partnerComboOptions = uniquePersons.map((partner) => ({
    name: partner.adSoyad,
    id: partner.tcVkn,
  }));

  const getPersonFromPartners = (name) => {
    const partner = uniquePersons.find((partner) => partner.adSoyad === name);
    const newObj = {};
    newObj.toplantiBaskani = partner.adSoyad || "";
    newObj.toplantiBaskanitcVkn = partner.tcVkn || "";
    return newObj;
  };

  const schema = yup.object({
    gundemKararNo: yup.string().required().label("Gündem Y.K. Kararı Karar No").min(1).max(10).meta({
      type: "text",
      className: "sm:w-2/3",
      component: "input",
      placeholder: "2023-01",
    }),
    gundemKararTarihi: yup
      .string()
      .required()
      .label("Gündem Y.K. Kararı Karar Tarihi")
      .meta({
        type: "date",
        className: "sm:w-2/3",
        component: "input",
        defaultValue: new Date().toISOString().slice(0, 10),
      }),
    toplantiBaskani: yup.string().required().label("Toplantı Başkanı Ad Soyad").meta({
      type: "text",
      className: "sm:w-2/3",
      component: "combobox",
      options: partnerComboOptions,
      onChange: getPersonFromPartners,
    }),
    tutanakNo: yup.string().required().label("Tutanak No").min(1).max(10).meta({
      type: "text",
      className: "sm:w-2/3",
      component: "input",
      placeholder: "2023-01",
    }),
    tutanakTarihi: yup
      .string()
      .required()
      .label("Tutanak Tarihi")
      .meta({
        type: "date",
        className: "sm:w-2/3",
        component: "input",
        defaultValue: new Date().toISOString().slice(0, 10),
      }),

    toplantiTuru: yup
      .string()
      .required()
      .label("Toplantı Türü")
      .meta({
        type: "select",
        className: "sm:w-2/3",
        component: "select",
        options: [
          { value: "Olağan", label: "Olağan" },
          { value: "Olağanüstü", label: "Olağanüstü" },
        ],
      }),
    icYonergeEkle: yup
      .string()
      .required()
      .label("İç Yönerge Maddesi Ekle")
      .meta({
        type: "select",
        className: "sm:w-2/3",
        component: "select",
        options: [
          { value: "Hayır", label: "Hayır" },
          { value: "Evet", label: "Evet" },
        ],
      }),
    bakanlikTemsilcisiVarMi: yup
      .string()
      .required()
      .label("Bakanlık Temsilcisi Var Mı?")
      .meta({
        type: "select",
        className: "sm:w-2/3",
        component: "select",
        options: [
          { value: "Hayır", label: "Hayır" },
          { value: "Evet", label: "Evet" },
        ],
      }),
    bakanlikTemsilcisiAdSoyad: yup
      .string()
      .label("Bakanlık Temsilcisi Ad Soyad")
      .meta({
        type: "text",
        className: "sm:w-2/3",
        component: "input",
        condition: {
          field: "bakanlikTemsilcisiVarMi",
          value: "Evet",
        },
      })
      .when("bakanlikTemsilcisiVarMi", {
        is: "Evet",
        then: yup.string().required(),
      }),
    bakanlikGorevSayisi: yup
      .string()
      .label("Bakanlık Temsilcisi Görevlendirme Sayısı")
      .meta({
        type: "text",
        className: "sm:w-2/3",
        component: "input",
        condition: {
          field: "bakanlikTemsilcisiVarMi",
          value: "Evet",
        },
      })
      .when("bakanlikTemsilcisiVarMi", {
        is: "Evet",
        then: yup.string().required(),
      }),
    bakanlikGorevlendirmeTarihi: yup
      .string()
      .label("Bakanlık Temsilcisi Görevlendirme Tarihi")
      .meta({
        type: "date",
        className: "sm:w-2/3",
        component: "input",
        condition: {
          field: "bakanlikTemsilcisiVarMi",
          value: "Evet",
        },
      })
      .when("bakanlikTemsilcisiVarMi", {
        is: "Evet",
        then: yup.string().required(),
      }),
  });

  const { genelKurul, transactionId } = useSelector((state) => state.transactions.selectedTransaction);

  const onUpdate = async (form) => {
    const deepCopyForm = JSON.parse(JSON.stringify(form));
    // await dispatch(setgenelKurul(deepCopyForm));
    if (deepCopyForm.bakanlikTemsilcisiVarMi === "Hayır") {
      deepCopyForm.bakanlikTemsilcisiAdSoyad = "";
      deepCopyForm.bakanlikGorevSayisi = "";
      deepCopyForm.bakanlikGorevlendirmeTarihi = "";
    }

    await dispatch(
      updateTransaction({
        data: {
          genelKurul: deepCopyForm,
        },
      })
    );
    dispatch(nextStep());
  };

  const onPrev = () => {
    dispatch(prevStep());
  };

  return (
    <Form
      schema={schema}
      onSubmit={onUpdate}
      onPrev={onPrev}
      title="Genel Kurul Toplantı Tutanağı"
      description="Tutanak bilgilerini giriniz, başkanı belirleyiniz. Gündem kararı için yönetim kurulu karar metni hazırlanacak bu nedenden dolayı gündem karar numarası ve tarihi giriniz."
      initData={genelKurul}
    />
  );
};

export default GenelKurulTutanak;
