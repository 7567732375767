import React, { useState, useEffect } from "react";
import { forwardRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import notify from "features/ui/notify";
import { uploadUserPhotoToStorage } from "features/auth/slice";

import Button from "features/ui/Button";

const UserPhotoUpload = (props, ref) => {
  const dispatch = useDispatch();
  const { name, label, divClass } = props;

  const user = useSelector((state) => state.auth.user);
  const [image, setImage] = useState(null);
  const [file, setFile] = useState(null);

  const onFileSelect = (e) => {
    const file = e.target.files[0];
    if (file && file.type.includes("image")) {
      if (file.size > 5000 * 1024) return notify("Profil Resmi 5000 KB'ı geçemez", "error");
      setFile(file);
      setImage({
        preview: URL.createObjectURL(e.target.files[0]),
        raw: e.target.files[0],
      });
    } else {
      notify("Lütfen geçerli bir resim dosyası seçiniz", "error");
    }
  };

  const handleOnUpload = () => {
    dispatch(uploadUserPhotoToStorage(file));
  };

  return (
    <div className={divClass || "py-4 sm:my-2 sm:grid sm:grid-cols-3   sm:items-start sm:gap-4 "}>
      {label && (
        <label className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2" htmlFor={name}>
          {label}
        </label>
      )}
      <div className="col-span-2 block w-full flex-1">
        <div className="flex items-center">
          <span className="h-12 w-12 overflow-hidden rounded-full bg-gray-100">
            {image?.preview ? (
              <img src={image.preview} alt="dummy" width="300" height="300" />
            ) : (
              <img className=" flex-shrink-0 rounded-full bg-gray-300" src={user.photoURL} alt="" />
            )}
          </span>

          <input id="file" type="file" className="hidden" onChange={onFileSelect} />
          <label htmlFor="file">
            <span
              tabIndex="0"
              role="button"
              aria-controls="filename"
              className="ml-4 rounded-md border border-gray-300 bg-white py-2 px-3 text-xs font-medium leading-4 text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2"
            >
              Fotoğrafı Değiştir
            </span>
            {file && (
              <Button
                type="button"
                className="ml-4 rounded-md border border-gray-300  py-2 px-3 text-xs font-medium leading-4 text-gray-100 shadow-sm  focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2"
                onClick={handleOnUpload}
              >
                Fotoğrafı Kaydet
              </Button>
            )}
          </label>
        </div>
      </div>
    </div>
  );
};

export default forwardRef(UserPhotoUpload);
