import CompanyInfo from 'features/transactions/formComponents/registered/CompanyInfo'
import CompanyAdress from 'features/transactions/formComponents/registered/CompanyAdress'
import Subeler from 'features/transactions/formComponents/registered/Subeler'
import Partners from 'features/transactions/formComponents/registered/Partners'
import Authorities from 'features/transactions/formComponents/registered/Authorities';
import SgkIsyerleri from 'features/transactions/formComponents/registered/SgkIsyerleri'
import Capital from 'features/transactions/formComponents/registered/Capital'
import CpaInfo from 'features/transactions/formComponents/registered/CpaInfo'
import DefterBilgileri from 'features/transactions/formComponents/registered/DefterBilgileri'

import Karar from 'features/transactions/formComponents/newData/Karar'
import GenelKurulTutanak from 'features/transactions/formComponents/newData/GenelKurulTutanak'
import NewAddress from 'features/transactions/formComponents/newData/NewAddress'
import NewAddressNakil from 'features/transactions/formComponents/newData/NewAddressNakil'
import SgkIsyeriSec from 'features/transactions/formComponents/newData/SgkIsyeriSec'
import MudurAtama from 'features/transactions/formComponents/newData/MudurAtama'
import MudurIstifa from 'features/transactions/formComponents/newData/MudurIstifa'
import HisseDevri from 'features/transactions/formComponents/newData/HisseDevri'
import SubeAcilisi from 'features/transactions/formComponents/newData/SubeAcilisi'
import SubeMudurAtama from 'features/transactions/formComponents/newData/SubeMudurAtama'
import SubeKapanisSec from 'features/transactions/formComponents/newData/SubeKapanisSec'
import TasfiyeBaslangic from 'features/transactions/formComponents/newData/TasfiyeBaslangic'
import TasfiyeSonu from 'features/transactions/formComponents/newData/TasfiyeSonu'
import UnvanDegisikligi from 'features/transactions/formComponents/newData/UnvanDegisikligi'
import SerArtGecmisYilKar from 'features/transactions/formComponents/newData/SerArtGecmisYilKar'
import SerArtOzVarlikTespit from 'features/transactions/formComponents/newData/SerArtOzVarlikTespit'
import SerArtSermayeOdemeleri from 'features/transactions/formComponents/newData/SerArtSermayeOdemeleri'
import SerArtOrtaklardanAlacaklar from 'features/transactions/formComponents/newData/SerArtOrtaklardanAlacaklar'
import SerArtRapor from 'features/transactions/formComponents/newData/SerArtRapor'
import SerArtNakit from 'features/transactions/formComponents/newData/SerArtNakit'
import SerArtHisseDagilimi from 'features/transactions/formComponents/newData/SerArtHisseDagilimi'
import YonetimKuruluIbra from 'features/transactions/formComponents/newData/YonetimKuruluIbra'
import YonetimKuruluSec from 'features/transactions/formComponents/newData/YonetimKuruluSec'
import YonetimKuruluIstifa from 'features/transactions/formComponents/newData/YonetimKuruluIstifa'


import Summary from 'features/transactions/formComponents/Summary'

export const steps = [
    {
        id: 100,
        name: "Firma Bilgileri",
        component: <CompanyInfo />,
        isActive: true,
        registered: true,
        companyTypes: ["Şahıs Firması", "Limited", "Anonim"],
        transactionTypes: []
    },
    {
        id: 101,
        name: "Firma Adresi",
        component: <CompanyAdress />,
        isActive: true,
        registered: true,
        companyTypes: ["Şahıs Firması", "Limited", "Anonim"],
        transactionTypes: []
    },
    {
        id: 102,
        name: "Şubeler",
        component: <Subeler />,
        isActive: true,
        registered: true,
        companyTypes: ["Limited", "Anonim"],
        transactionTypes: ["Şube Kapanışı"]
    },

    {
        id: 110,
        name: "Ortaklar",
        component: <Partners />,
        isActive: true,
        registered: true,
        companyTypes: ["Limited", "Anonim"],
        transactionTypes: []
    },
    {
        id: 111,
        name: "Tescilli Sermaye",
        component: <Capital />,
        isActive: true,
        registered: true,
        companyTypes: ["Limited", "Anonim"],
        transactionTypes: ["Sermaye Arttırımı"]
    },
    {
        id: 120,
        name: "Yetkililer",
        component: <Authorities />,
        isActive: true,
        registered: true,
        companyTypes: ["Limited", "Anonim"],
        transactionTypes: []
    },
    {
        id: 130,
        name: "Sgk İşyeri Bilgileri",
        component: <SgkIsyerleri />,
        isActive: true,
        registered: true,
        companyTypes: ["Şahıs Firması", "Limited", "Anonim"],
        transactionTypes: ["Adres Değişikliği", "Merkez Nakli"]
    },
    {
        id: 190,
        name: "Mali Müşavir Bilgileri",
        component: <CpaInfo />,
        isActive: true,
        registered: true,
        companyTypes: ["Limited", "Anonim"],
        transactionTypes: ["Sermaye Arttırımı"]
    },


    {
        id: 200,
        name: "Yeni Adres",
        component: <NewAddress />,
        isActive: true,
        registered: false,
        companyTypes: ["Şahıs Firması", "Limited", "Anonim"],
        transactionTypes: ["Adres Değişikliği"]
    },
    {
        id: 203,
        name: "Yeni Adres Merkez Nakli",
        component: <NewAddressNakil />,
        isActive: true,
        registered: false,
        companyTypes: ["Limited", "Anonim"],
        transactionTypes: ["Merkez Nakli"]

    },
    {
        id: 201,
        name: "Adresteki SGK İşyerleri",
        component: <SgkIsyeriSec />,
        isActive: true,
        registered: false,
        companyTypes: ["Şahıs Firması", "Limited", "Anonim"],
        transactionTypes: ["Adres Değişikliği", "Merkez Nakli"]
    },
    {
        id: 210,
        name: "Müdür Atama",
        component: <MudurAtama />,
        isActive: true,
        registered: false,
        companyTypes: ["Limited"],
        transactionTypes: ["Müdür Atama"],
    },
    {
        id: 211,
        name: "Müdür İstifa",
        component: <MudurIstifa />,
        isActive: true,
        registered: false,
        companyTypes: ["Limited"],
        transactionTypes: ["Müdür İstifa"],
    },

    {
        id: 215,
        name: "Yönetim Kurulu Atama",
        component: <YonetimKuruluSec />,
        isActive: true,
        registered: false,
        companyTypes: ["Anonim"],
        transactionTypes: ["Yönetim Kurulu Üye Atama"],
    },
    {
        id: 216,
        name: "Yönetim Kurulu İstifa",
        component: <YonetimKuruluIstifa />,
        isActive: true,
        registered: false,
        companyTypes: ["Anonim"],
        transactionTypes: ["Yönetim Kurulu Üye İstifa"],
    },

    {
        id: 220,
        name: "Hisse Devri",
        component: <HisseDevri />,
        isActive: true,
        registered: false,
        companyTypes: ["Limited", "Anonim"],
        transactionTypes: ["Hisse Devri"],
    },

    {
        id: 230,
        name: "Şube Açılışı",
        component: <SubeAcilisi />,
        isActive: true,
        registered: false,
        companyTypes: ["Limited", "Anonim"],
        transactionTypes: ["Şube Açılışı"],
    },
    {
        id: 231,
        name: "Şube Müdür Atama",
        component: <SubeMudurAtama />,
        isActive: true,
        registered: false,
        companyTypes: ["Limited", "Anonim"],
        transactionTypes: ["Şube Açılışı"],
    },

    {
        id: 235,
        name: "Şube Kapanışı Seç",
        component: <SubeKapanisSec />,
        isActive: true,
        registered: false,
        companyTypes: ["Limited", "Anonim"],
        transactionTypes: ["Şube Kapanışı"],
    },
    {
        id: 250,
        name: "Unvan Değişikliği",
        component: <UnvanDegisikligi />,
        isActive: true,
        registered: false,
        companyTypes: ["Limited", "Anonim"],
        transactionTypes: ["Unvan Değişikliği"],
    },

    {
        id: 290,
        name: "Tasfiye Başlangıcı",
        component: <TasfiyeBaslangic />,
        isActive: true,
        registered: false,
        companyTypes: ["Limited", "Anonim"],
        transactionTypes: ["Tasfiye Başlangıcı"],
    },
    {
        id: 291,
        name: "Tasfiye Sonu",
        component: <TasfiyeSonu />,
        isActive: true,
        registered: false,
        companyTypes: ["Limited", "Anonim"],
        transactionTypes: ["Tasfiye Sonu"],
    },

    {
        id: 300,
        name: "Geçmiş Yıl Karları",
        component: <SerArtGecmisYilKar />,
        isActive: true,
        registered: false,
        companyTypes: ["Limited", "Anonim"],
        transactionTypes: ["Sermaye Arttırımı"],
    },
    {
        id: 301,
        name: "Ortaklardan Alacaklar",
        component: <SerArtOrtaklardanAlacaklar />,
        isActive: true,
        registered: false,
        companyTypes: ["Limited", "Anonim"],
        transactionTypes: ["Sermaye Arttırımı"],
    },
    {
        id: 302,
        name: "Nakit Sermaye Arttırımı",
        component: <SerArtNakit />,
        isActive: true,
        registered: false,
        companyTypes: ["Limited", "Anonim"],
        transactionTypes: ["Sermaye Arttırımı"],
    },
    {
        id: 310,
        name: "Öz Varlık Tespit",
        component: <SerArtOzVarlikTespit />,
        isActive: true,
        registered: false,
        companyTypes: ["Limited", "Anonim"],
        transactionTypes: ["Sermaye Arttırımı"],
    },
    {
        id: 311,
        name: "Sermaye Ödemeleri",
        component: <SerArtSermayeOdemeleri />,
        isActive: true,
        registered: false,
        companyTypes: ["Limited", "Anonim"],
        transactionTypes: ["Sermaye Arttırımı"],
    },
    {
        id: 318,
        name: "Sermaye Arttırım Raporları",
        component: <SerArtRapor />,
        isActive: true,
        registered: false,
        companyTypes: ["Limited", "Anonim"],
        transactionTypes: ["Sermaye Arttırımı"],
    },
    {
        id: 319,
        name: "Yeni Hisse Dağılımı",
        component: <SerArtHisseDagilimi />,
        isActive: true,
        registered: false,
        companyTypes: ["Limited", "Anonim"],
        transactionTypes: ["Sermaye Arttırımı"],
    },


    {
        id: 790,
        name: "Defter Bilgileri",
        component: <DefterBilgileri />,
        isActive: true,
        registered: false,
        companyTypes: ["Limited", "Anonim"],
        transactionTypes: ["Sermaye Arttırımı"]
    },
    {
        id: 800,
        name: "Genel Kurul Kararı",
        component: <Karar />,
        isActive: true,
        registered: false,
        companyTypes: ["Limited"],
        transactionTypes: []
    },
    {
        id: 803,
        name: "Yönetim Kurulu İbra",
        component: <YonetimKuruluIbra />,
        isActive: true,
        registered: false,
        companyTypes: ["Anonim"],
        transactionTypes: ["Olağan Genel Kurul"]
    },

    {
        id: 802,
        name: "Genel Kurul Toplantı Tutanağı",
        component: <GenelKurulTutanak />,
        isActive: true,
        registered: false,
        companyTypes: ["Anonim"],
        transactionTypes: ["Olağan Genel Kurul", "Sermaye Arttırımı", "Ana Sözleşme Değişikliği", "Merkez Nakli", "Yönetim Kurulu Üye Atama", "Yönetim Kurulu Üye İstifa", "Tasfiye Başlangıcı", "Tasfiye Sonu", "Unvan Değişikliği"]
    },
    {
        id: 801,
        name: "Yönetim Kurulu Kararı",
        component: <Karar />,
        isActive: true,
        registered: false,
        companyTypes: ["Anonim"],
        transactionTypes: ["Yönetim Kurulu Üye Atama", "Adres Değişikliği", "Hisse Devri", "Şube Açılışı", "Şube Kapanışı"]
    },
    {
        id: 999,
        name: "Özet",
        component: <Summary />,
        isActive: true,
        registered: false,
        companyTypes: ["Şahıs Firması", "Limited", "Anonim"],
        transactionTypes: []
    },
]