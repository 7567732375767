import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { yup, yupResolver, useForm } from "util/yupLocale";
import ROUTES from "features/navigation/routes";
import CompanyInfo from "features/companies/CompanyInfo";

import { getCompanyLevha, companyTypeEstimator, createNewCompany, updateCompanyOnDb } from "features/companies/services";

const schema = yup.object({
  tcVkn: yup.string().required().label("Vergi No / TC Kimlik No").min(10).max(11),
  taxAdmin: yup.string().required().label("Vergi Dairesi").min(5),
  title: yup.string().required().label("Ünvan").min(5),
  address: yup.string().required().label("Adres"),
  startDate: yup.date().required().label("İşe Başlama Tarihi"),
  companyType: yup.string().required().label("Firma Türü Seçmelisiniz"),
  naceCode: yup.string().label("Nace Kodu"),
});

const CompanyEdit = ({ mode }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    reset,
    control,
    setValue,
    watch,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const { selectedCompany, status, companyList, error } = useSelector((state) => state.companies);
  const [levha, setLevha] = useState(null);
  const loading = status === "loading";

  const [errorMessage, setErrorMessage] = useState(null);

  const companyType = watch("companyType");
  useEffect(() => {
    if (companyType === "Şahıs Firması") {
      setErrorMessage(
        "Uygulama şimdilik şahıs firmaları için kullanılamamaktadır. Limited veya Anonim şirket eklemeye çalışın."
      );
    } else {
      setErrorMessage(null);
    }
  }, [companyType]);

  const onGetDataFromIvd = async () => {
    let tcVkn = getValues("tcVkn");
    // delete all spaces from tcVkn
    tcVkn = tcVkn.replace(/\s/g, "");
    // const data = await dispatch(getCompanyLevha({ tcVkn, taxAdmin: getValues("taxAdmin") }));
    // const levha = data?.payload 
    const levha = {}
    setLevha(levha);
    reset({
      tcVkn: levha.tckn || levha.vkn,
      taxAdmin: levha.vdadi,
      title: levha.unvan + levha.adsoyad,
      naceCode: levha.nacekodu,
      address: levha.adres,
      startDate:
        levha.isebaslamatarihi.substring(0, 4) +
        "-" +
        levha.isebaslamatarihi.substring(4, 6) +
        "-" +
        levha.isebaslamatarihi.substring(6, 8),
    });
  };

  const onSaveCompany = async (form) => {
    console.log(form);
    if (mode === "new") {
      const result = await dispatch(createNewCompany(form));
      if (result?.error) return;
      navigate(ROUTES.HOME.path);
    } else if (mode === "edit") {
      await dispatch(updateCompanyOnDb(form));
      navigate(ROUTES.HOME.path);
    }
  };

  const handleDeleteCompany = async () => {
    // await dispatch(deleteCompany());
    // navigate(ROUTES.HOME.path);
  };

  useEffect(() => {
    if (mode === "edit") return;
    const title = watch("title");
    const asyncFunc = async (title) => {
      const result = await dispatch(companyTypeEstimator(title));
      const companyType = result.payload;
      setValue("companyType", companyType);
    };
    asyncFunc(title);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch("title")]);

  useEffect(() => {
    if (mode === "new") {
      reset({
        tcVkn: "",
        taxAdmin: "",
        title: "",
        address: "",
        startDate: "",
        companyType: "",
        naceCode: "",
      });
      // dispatch(setLoading(false));
    } else if (mode === "edit") {
      reset(selectedCompany);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCompany, mode]);

  return (
    <div className="mt-2 sm:mt-0">
       
      <CompanyInfo
        mode={mode}
        handleSubmit={handleSubmit}
        onSaveCompany={onSaveCompany}
        loading={loading}
        errors={errors}
        register={register}
        control={control}
        onGetDataFromIvd={onGetDataFromIvd}
        handleDeleteCompany={handleDeleteCompany}
        selectedCompany={selectedCompany}
        errorMessage={errorMessage}
      />
    </div>
  );
};

export default CompanyEdit;
