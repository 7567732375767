import React, { useState, useEffect } from "react";

const UserPhoto = (props) => {
  const { url, className = "h-10 w-10 flex-shrink-0 rounded-full bg-gray-300" } = props;

  const [error, setError] = useState(false);
  const onError = () => setError(true);

  useEffect(() => {
    setError(false);
  }, [url]);

  const Svg = (
    <svg className="h-full w-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
      <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
    </svg>
  );
  if (error) return <div className={"h-8 w-8 overflow-hidden rounded-full bg-gray-100"}>{Svg}</div>;
  return <img className={className} src={url} onError={onError} alt="ProfilePhoto" />;
};

export default UserPhoto;

// const createUserPhotoUrl = (displayName) => {
//   if (!displayName) return null;
//   const initials = displayName
//     .match(/(\b\S)?/g)
//     .join("")
//     .match(/(^\S|\S$)?/g)
//     .join("")
//     .toUpperCase();

//   const ppColor = Math.floor(Math.random() * (6 - 0 + 1) + 0);
//   return (
//     "https://i0.wp.com/avatar-management--avatars.us-west-2.prod.public.atl-paas.net/initials/" +
//     initials +
//     "-" +
//     ppColor +
//     ".png?ssl=1"
//   );
// };
