import React, { useRef } from "react";
import { Disclosure } from "@headlessui/react";
import { useLocation, Link } from "react-router-dom";
import { ChevronDownIcon, ChevronUpIcon, ChevronDoubleLeftIcon, ChevronDoubleRightIcon } from "@heroicons/react/20/solid";

import sideBarItems from "features/navigation/sideBarItems";

const SideBarNav = ({ setWideMode, wideMode }) => {
  const pathName = useLocation()?.pathname;
  const handleToggleWideMode = () => {
    setWideMode(!wideMode);
  };
  const activeClass = "bg-gray-100 text-gray-900 dark:bg-gray-900 dark:text-gray-50";
  const activeClass2 = "bg-gray-300 text-gray-900 dark:bg-gray-800 dark:text-gray-50";
  const subMenuClass = "text-xs bg-gray-200 dark:bg-gray-800";

  const inActiveClass =
    "text-gray-600 hover:bg-gray-50 hover:text-gray-900 dark:text-gray-300 dark:hover:bg-gray-800 dark:hover:text-gray-100";
  const linkClass = "group flex items-center px-4 py-2 font-medium";

  const buttonRefs = useRef([]);
  const openedRef = useRef(null);

  const clickRecent = (index) => {
    const clickedButton = buttonRefs.current[index];
    if (clickedButton === openedRef.current) {
      openedRef.current = null;
      return;
    }
    if (Boolean(openedRef.current?.getAttribute("data-value"))) {
      openedRef.current?.click();
    }
    openedRef.current = clickedButton;
  };

  return (
    <nav className="no-scrollbar mt-6 h-full overflow-scroll">
      <div className="grid grid-cols-1 content-between gap-5">
        <div>
          {sideBarItems.map((item, index) => (
            <Disclosure key={index}>
              {({ open }) => {
                return (
                  <div className=" border-b-2 border-gray-50  dark:border-gray-800">
                    <Disclosure.Button className="w-full" as="div">
                      {item.children ? (
                        <div
                          // close others
                          data-value={open}
                          ref={(ref) => {
                            buttonRefs.current[index] = ref;
                          }}
                          onClick={() => clickRecent(index)}
                          // close others
                          className={`${
                            open ? activeClass : inActiveClass
                          } group flex items-center justify-between px-4 py-2 text-sm font-medium`}
                        >
                          <MenuButton item={item} open={open} />
                        </div>
                      ) : (
                        <Link key={item.name} to={item.path || "#"} className={linkClass + " text-sm " + inActiveClass}>
                          <MenuButton item={item} />
                        </Link>
                      )}
                    </Disclosure.Button>
                    <Disclosure.Panel className={activeClass}>
                      {item.children &&
                        item.children.map((subItem) => (
                          <Link
                            key={subItem.name}
                            to={subItem.path}
                            className={`${
                              subItem.path === pathName ? activeClass2 : inActiveClass
                            }  ${linkClass}  ${subMenuClass}`}
                          >
                            <MenuButton item={subItem} size="4" />
                          </Link>
                        ))}
                    </Disclosure.Panel>
                  </div>
                );
              }}
            </Disclosure>
          ))}
        </div>

        <div className="hidden cursor-pointer pt-4 lg:block">
          <span onClick={handleToggleWideMode} className="text-sm font-medium text-gray-500">
            {wideMode ? (
              <div className="flex">
                <ChevronDoubleRightIcon className="mx-4 h-5 w-5  text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
                Menüyü Göster
              </div>
            ) : (
              <div className="flex">
                <ChevronDoubleLeftIcon className="mx-4 h-5 w-5  text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
                Menüyü Gizle
              </div>
            )}
          </span>
        </div>
        <div className="space-y-4 px-4 text-center">
          <p className="text-sm text-gray-500">2023</p>
        </div>
      </div>
    </nav>
  );
};

export default SideBarNav;

const MenuButton = (props) => {
  const { item, size = "5", open } = props;
  return (
    <>
      <div className="flex py-0.5">
        {item.icon && (
          <item.icon
            className={`${
              item.current ? "text-gray-500" : "text-gray-400 group-hover:text-gray-500"
            }  mr-3 h-${size} w-${size} flex-shrink-0`}
            aria-hidden="true"
          />
        )}
        {item.name}
      </div>
      {item.children && !open && (
        <ChevronDownIcon className="h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
      )}

      {item.children && open && (
        <ChevronUpIcon className="h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
      )}
    </>
  );
};
