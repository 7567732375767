import {
    createSlice, createAsyncThunk,
    // createSelector
} from "@reduxjs/toolkit";
import { db, auth, storage, functions } from "features/firebase";


import {
    getFirestore,
    doc,
    collection,
    query,
    getDocs,
    updateDoc,

} from "firebase/firestore";
import { httpsCallable } from "firebase/functions";


export const shopSlice = createSlice({
    name: "shop",
    initialState: {
        loading: false,
        products: [],
        bag: [],
        checkoutForm: null,
    },

    reducers: {
        setLoading: (state, action) => {
            state.loading = action.payload;
        },
        setProducts: (state, action) => {
            state.products = action.payload;
        },
        setBag: (state, action) => {
            state.bag = action.payload;
        },
        setCheckoutForm: (state, action) => {
            state.checkoutForm = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(loadProducts.rejected, (state, action) => {
            state.loading = false;
            console.error(action);
        });
        builder.addCase(updatebillingInfo.rejected, (state, action) => {
            state.loading = false;
            console.error(action);
        });
    },


});

export const loadProducts = createAsyncThunk("shop/loadProducts", async (payload, { dispatch }) => {
    const db = getFirestore();

    const productsDatColRef = collection(db, "utils", "products", "data");
    // get products from productsDatColRef
    const q = query(productsDatColRef);
    const querySnapshot = await getDocs(q);
    const products = [];
    querySnapshot.forEach((doc) => {
        products.push(doc.data());
    });
    dispatch(setProducts(products));
});

export const createPayment = createAsyncThunk("shop/Payment", async (payload, { dispatch, getState }) => {
    dispatch(setLoading(true));

    const bag = getState().shop.bag;
    // const { tcVkn, taxAdmin } = payload;
    const createPaymentOnCall = httpsCallable(functions, "createPaymentOnCall");
    const result = await createPaymentOnCall({ bag });

    dispatch(setCheckoutForm(result.data));
    dispatch(setLoading(false));

});

export const createPaymentBank = createAsyncThunk("shop/PaymentBank", async (payload, { dispatch, getState }) => {
    dispatch(setLoading(true));

    const bag = getState().shop.bag;
    // const { tcVkn, taxAdmin } = payload;
    const createPaymentBankOnCall = httpsCallable(functions, "createPaymentBankOnCall");
    const result = await createPaymentBankOnCall({ bag });

    dispatch(setCheckoutForm(result.data));
    dispatch(setLoading(false));

});

export const customerPaymentNotice = createAsyncThunk("shop/customerPaymentNotice", async (payload, { dispatch, getState }) => {
    dispatch(setLoading(true));
    
    const token = getState().shop.checkoutForm;

    const customerPaymentNoticeOnCall = httpsCallable(functions, "customerPaymentNotice");
    const result = await customerPaymentNoticeOnCall({ token });
    dispatch(setLoading(false));
    return
});

export const updatebillingInfo = createAsyncThunk("shop/updatebillingInfo", async (payload, { dispatch, getState }) => {
    dispatch(setLoading(true));

    const updatebillingInfoOnCall = httpsCallable(functions, "updatebillingInfo");
    const result = await updatebillingInfoOnCall({ billingInfo: payload.form });
    
    dispatch(setLoading(false));
});


export const { setLoading, setProducts, setBag, setCheckoutForm } = shopSlice.actions;

export default shopSlice.reducer;