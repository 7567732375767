import { forwardRef } from "react";
import { ExclamationTriangleIcon } from "@heroicons/react/20/solid";
// ExclamationCircleIcon
const Input = (props, ref) => {
  // input types
  // input
  // label-top
  // label-left

  const {
    name = "",
    label,
    type,
    divClass,
    error,
    helpMessage,
    disabled,
    placeholder = "",
    className,
    inputType,
    additionalclassname,
    ...rest
  } = props;

  // if rest.value is undefined, set it to empty string
  // if (rest.value === undefined || rest.value === null) rest.value = "";

  let color = "focus:ring-primary focus:border-primary border-gray-300 dark:bg-gray-600 dark:text-gray-50 ";
  if (error)
    color = "focus:ring-red-500 focus:border-red-500 border-red-300 dark:border-red-400 dark:bg-gray-600 dark:text-gray-50";
  if (disabled)
    color = "focus:ring-primary focus:border-primary border-gray-300 bg-gray-100 dark:bg-gray-700 dark:text-gray-50 ";

  let inputClass =
    " appearance-none rounded-none relative block w-full px-3 py-2 border placeholder-gray-500 text-gray-900 focus:outline-none focus:z-10 sm:text-sm ";

  let divClassName = divClass;
  if (inputType === "label-top") {
    inputClass = " mt-1 block w-full shadow-sm sm:text-sm rounded-md ";
    divClassName = divClass || "col-span-6 sm:col-span-4 ";
  }

  if (inputType === "label-left") {
    inputClass = " w-full min-w-0 rounded-md sm:text-sm  ";
    divClassName = divClass || " sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start py-1 sm:my-1 ";
  }

  return (
    <div className={divClassName}>
      <InputLabel label={label} inputType={inputType} name={name} additionalclassname={additionalclassname} />
      <div className=" col-span-2 block w-full flex-1 ">
        <input
          type={type || "text"}
          className={inputClass + " " + color + " " + className}
          name={name}
          id={name}
          disabled={disabled}
          placeholder={!inputType || inputType === "input" ? label : placeholder}
          {...rest}
          ref={ref}
        />

        <ErrorMessage error={error} />
      </div>
    </div>
  );
};

export default forwardRef(Input);

const InputLabel = (props) => {
  const { label, inputType, name, additionalclassname } = props;
  let className = "sr-only";
  if (inputType === "label-top") className = "block text-sm font-medium text-gray-700 dark:text-gray-200 ";
  if (inputType === "label-left") className = "block text-sm font-medium text-gray-700 dark:text-gray-200 sm:mt-px sm:pt-1";
  if (!label) return null;
  return (
    <label htmlFor={name} className={additionalclassname ? className + " " + additionalclassname : className}>
      {label}
    </label>
  );
};

const ErrorMessage = (props) => {
  const { error } = props;
  if (!error) return null;
  return (
    <div className="mt-0 flex py-2 text-xs text-red-600 dark:text-red-400 " id="email-error">
      <ExclamationTriangleIcon className="mr-1 h-4  w-4 text-red-500 dark:text-red-400 " aria-hidden="true" /> {error}
    </div>
  );
};
