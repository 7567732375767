const yupObjGenerator = ({ yup, schemaObj, transactionTypes, arrName }) => {
    // İşlem türlerine göre alanları yönetmek
    const filteredSchemaObj = {};
    for (const key in schemaObj) {
        const field = schemaObj[key];

        const fieldTransactionTypes = field.spec.meta?.transactionTypes || [];

        // İşlem türü, alanın transactionTypes özelliğinde mevcut mu?
        const isMatch = transactionTypes.some((type) => fieldTransactionTypes.includes(type));

        // Alanın gösterilmesi gerekiyorsa filteredYupObj'a ekle
        if (isMatch) {
            filteredSchemaObj[key] = field;
        }
    }

    if (arrName) {
        return yup.object().shape({
            [arrName]: yup.array().of(yup.object().shape(filteredSchemaObj)),
        });
    } else {
        return yup.object(filteredSchemaObj);
    }
};

export default yupObjGenerator;