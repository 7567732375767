import { createBrowserRouter, RouterProvider, Outlet } from "react-router-dom";
import PrivateRoute from "features/navigation/PrivateRoute";

import Home from "features/layout/Home";
import SignIn from "features/auth/SignIn";
import SignUp from "features/auth/SignUp";
import NotMatch from "features/navigation/NotMatch";
import ROUTES from "./routes";

import CompanySelect from "features/companies/companySelect";
import CompanyEdit from "features/companies/CompanyEdit";

import Users from "features/users/Users";
import UserEdit from "features/users/UserEdit";
import Invite from "features/users/Invite";
import Profile from "features/auth/Profile";
import ChangePassword from "features/auth/ChangePassword";

import NewTransaction from "features/transactions/Start";
import List from "features/transactions/List";
import ShowDocuments from "features/transactions/ShowDocuments";

import Islem from "features/transactions/formComponents";
import Dashboard from "features/layout/Dashboard";

import Payment from "features/shop/payment/Index";
import Services from "features/shop/services/Index";
import Shop from "features/shop/Index";
import BillingInfo from "features/shop/BillingInfo";
import BankPayment from "features/shop/BankPayment";

import Admin from "features/admin/Index";
import AdminPayments from "features/admin/payments/Index";
import AdminPaymentDetail from "features/admin/payments/Detail";
import AdminUsers from "features/admin/users/Index";
import AdminUserDetail from "features/admin/users/Detail";
import Statistics from "features/admin/statistics/Index";

const PR = (component) => <PrivateRoute>{component}</PrivateRoute>;

export const navElements = [
  {
    ...ROUTES.SIGN_UP,
    element: <SignUp />,
  },
  {
    ...ROUTES.SIGN_IN,
    element: <SignIn />,
  },
  {
    ...ROUTES.INVITE,
    element: <Invite />,
  },
  {
    ...ROUTES.ADMIN,
    element: PR(<Admin />),
    children: [
      {
        ...ROUTES.ADMIN_PAYMENTS,
        element: <AdminPayments />,

      },
      {
        ...ROUTES.ADMIN_PAYMENTS_DETAIL,
        element: <AdminPaymentDetail />,
      },
      {
        ...ROUTES.ADMIN_USERS,
        element: <AdminUsers />,
      },
      {
        ...ROUTES.ADMIN_USERS_DETAIL,
        element: <AdminUserDetail />,
      },
      {
        ...ROUTES.ADMIN_STATISTICS,
        element: <Statistics />,
      },
    ],
  },
  {
    ...ROUTES.COMPANIES_SELECT,
    element: PR(<CompanySelect />),
  },
  /*
        <Route path={ROUTES.SHOP.path} element={PR(<Shop />)}>
          <Route path={ROUTES.CHECKOUT.path} element={PR(<Payment />)} />
          <Route path={ROUTES.SERVICES.path} element={PR(<Services />)} />
          <Route path={ROUTES.BILLINGINFO.path} element={PR(<BillingInfo />)} />
        </Route>
  */
  {
    ...ROUTES.SHOP,
    element: PR(<Shop />),
    children: [
      {
        ...ROUTES.SERVICES,
        element: <Services />,
      },
      {
        ...ROUTES.CHECKOUT,
        element: <Payment />,
      },
      {
        ...ROUTES.BILLINGINFO,
        element: <BillingInfo />,
      },
      {
        ...ROUTES.BANKPAYMENT,
        element: <BankPayment />,
      },
    ],
  },
  {
    ...ROUTES.HOME,
    element: PR(<Home />),
    errorElement: <NotMatch />,
    children: [
      {
        ...ROUTES.DASHBOARD,
        element: <Dashboard />,
      },
      {
        ...ROUTES.TRANSACTIONS,
        element: <Outlet />,
        // element: <NewTransaction />,
        children: [
          {
            ...ROUTES.TRANSACTION_START,
            element: <NewTransaction />,
          },
          {
            ...ROUTES.TRANSACTIONS_DRAFT,
            element: <List mode="draft" />,
          },
          {
            ...ROUTES.TRANSACTIONS_DONE,
            element: <List mode="documentsCreated" />,
          },
          {
            ...ROUTES.TRANSACTIONS_SHOWDOC,
            element: <ShowDocuments />,
          },
          {
            ...ROUTES.TRANSACTION_EDIT,
            element: <Islem />,
          },
        ],
      },
      {
        ...ROUTES.SETTINGS,
        element: <Outlet />,
        children: [
          {
            ...ROUTES.COMPANIES_NEW,
            element: <CompanyEdit mode={"new"} />,
          },
          {
            ...ROUTES.COMPANIES_EDIT,
            element: <CompanyEdit mode={"edit"} />,
          },
          {
            ...ROUTES.USERS,
            element: <Users />,
          },
          {
            ...ROUTES.USER_NEW,
            element: <UserEdit mode={"new"} />,
          },
          {
            ...ROUTES.USER_EDIT,
            element: <UserEdit mode={"edit"} />,
          },
          {
            ...ROUTES.PROFILE,
            element: <Profile />,
          },
          {
            ...ROUTES.CHANGE_PASSWORD,
            element: <ChangePassword />,
          },
        ],
      },
    ],
  },
];

export const router = createBrowserRouter(navElements);
const Navigator = () => {
  return <RouterProvider router={router} />;
};

export default Navigator;
