import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import StatusProgress from "features/transactions/StatusProgress";
import Loading from "features/ui/Loading";
import { currentStatusSelector } from "features/transactions/slice";

const Islem = ({}) => {
  // const dispatch = useDispatch();

  const { step } = useSelector((state) => state.transactions);
  const status = useSelector((state) => state.transactions.status);
  const loading = status === "loading";

  if (loading) return <Loading />;

  if (!step) return null;

  return (
    <>
      <div className="pt-3 md:grid md:grid-cols-3 md:gap-6">
        {step.component}
        <div className="mt-5 sm:mt-0 md:col-span-1 md:pl-5 ">
          <StatusProgress />
        </div>
      </div>
    </>
  );
};

export default Islem;
