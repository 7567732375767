import { createSlice, createEntityAdapter } from '@reduxjs/toolkit'

import { getCompanyLevha, createNewCompany, loadAuthorizedCompanies, onSelectCompany, updateCompanyOnDb } from './services'

import notify from 'features/ui/notify'
import ROUTES from "features/navigation/routes";


const companyAdapter = createEntityAdapter()

const initialState = companyAdapter.getInitialState({
    // statuses: 'idle' | 'loading' | 'succeeded' | 'failed'
    status: 'idle',
    levhaStatus: 'idle',
    navigateToPage: null,
    error: null,
    selectedCompany: null,
    searchParam: "",
})

export const companySelector = companyAdapter.getSelectors(state => state.companies)

export const filterCompanies = (state) => {
    const companies = companySelector.selectAll(state)
    const searchParam = state.companies.searchParam
    if (searchParam === "") return companies
    return companies.filter(company => company.title.toLocaleLowerCase("tr").includes(searchParam?.toLocaleLowerCase("tr")))
}

const companiesSlice = createSlice({
    name: 'companies',
    initialState,
    reducers: {
        addCompany: companyAdapter.addOne,
        removeCompany: companyAdapter.removeOne,
        updateCompany: companyAdapter.updateOne,
        setCompanies: companyAdapter.setAll,
        setCompaniesStatus: (state, action) => {
            state.status = action.payload
        },
        setSelectedCompany: (state, action) => {
            state.selectedCompany = action.payload
        },
        setSearchParam: (state, action) => {
            state.searchParam = action.payload
        },
        setNavigateToPage: (state, action) => {
            state.navigateToPage = action.payload
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getCompanyLevha.pending, (state, action) => {
            state.levhaStatus = 'loading'
        })
            .addCase(getCompanyLevha.fulfilled, (state, action) => {
                state.levhaStatus = 'succeeded'
            })
            .addCase(getCompanyLevha.rejected, (state, action) => {
                state.levhaStatus = 'failed'
                state.error = action.error.message
            })
            .addCase(createNewCompany.pending, (state, action) => {
                state.status = 'loading'
            })
            .addCase(createNewCompany.fulfilled, (state, action) => {
                state.status = 'succeeded'
                state.levhaStatus = 'idle'
                notify('Şirket başarıyla oluşturuldu', 'success')
            })
            .addCase(createNewCompany.rejected, (state, action) => {
                state.status = 'failed'
                state.error = action.error.message
                notify(action.error.message, 'error')
            })
            .addCase(loadAuthorizedCompanies.pending, (state, action) => {
                state.currentCompany = null
                // remove all companies from entity adapter
                // companyAdapter.removeAll(state)
                state.status = 'loading'
            })
            .addCase(loadAuthorizedCompanies.fulfilled, (state, action) => {
                const loadedCompanies = action.payload
                companyAdapter.setAll(state, loadedCompanies)
                if (loadedCompanies.length === 0) {
                    state.navigateToPage = ROUTES.COMPANIES_NEW.path
                    state.selectedCompany = null
                }
                // else if (loadedCompanies.length === 1) {
                //     state.navigateToPage = null
                // } 
                else {
                    state.navigateToPage = ROUTES.COMPANIES_SELECT.path
                }
                state.status = 'succeeded'
            })
            .addCase(loadAuthorizedCompanies.rejected, (state, action) => {
                state.status = 'failed'
                state.error = action.error.message
            })
            .addCase(onSelectCompany.pending, (state, action) => {
                // state.status = 'loading'
            })
            .addCase(onSelectCompany.fulfilled, (state, action) => {
                // state.status = 'succeeded'
                state.selectedCompany = action.payload
                state.navigateToPage = null
            })
            .addCase(onSelectCompany.rejected, (state, action) => {
                // state.status = 'failed'
                state.error = action.error.message
            })
            .addCase(updateCompanyOnDb.fulfilled, (state, action) => {
                state.status = 'succeeded'
                notify('Şirket başarıyla güncellendi', 'success')
            })
            .addCase(updateCompanyOnDb.rejected, (state, action) => {
                state.status = 'failed'
                state.error = action.error.message
                notify(action.error.message, 'error')
            })
            .addCase(updateCompanyOnDb.pending, (state, action) => {
                state.status = 'loading'
            })
    }
})

export const {
    addCompany,
    removeCompany,
    updateCompany,
    setCompanies,
    setCompaniesStatus,
    setSelectedCompany,
    setSearchParam,setNavigateToPage
} = companiesSlice.actions

export default companiesSlice.reducer