import React, { useState, useEffect } from "react";

import { useSelector, useDispatch } from "react-redux";
import { ExclamationTriangleIcon } from "@heroicons/react/20/solid";
import { useNavigate } from "react-router-dom";

import { updatebillingInfo, createPayment, createPaymentBank, customerPaymentNotice } from "features/shop/slice";
import ROUTES from "features/navigation/routes";
import Bag from "./Bag";

export default function BankPayment() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { user } = useSelector((state) => state.auth);

  const { checkoutForm } = useSelector((state) => state.shop);
  const [isAgreed, setIsAgreed] = useState(false);

  const bankDescriptionKode = checkoutForm;

  const handleCopyToClipboard = (e) => {
    const iban = e.target.outerText;
    console.log(iban);
    navigator.clipboard.writeText(iban);
    alert("kopyalandı");
  };

  const handleSendPaymentCompleted = () => {
    console.log("handleSendPaymentCompleted");
    dispatch(customerPaymentNotice());
    navigate(ROUTES.HOME.path);
  };

  return (
    <div className="bg-white ">
      {/* Background color split screen for large screens */}
      <div className=" hidden h-full w-1/2 bg-white lg:block" aria-hidden="true" />
      <div className=" right-0 hidden h-full w-1/2 bg-gray-50 lg:block" aria-hidden="true" />

      <div className="relative mx-auto grid max-w-7xl grid-cols-1 gap-x-16 lg:grid-cols-2 lg:px-8 xl:gap-x-48">
        <Bag showContrats={true} isAgreed={true} setIsAgreed={setIsAgreed} />
        <div className="py-16 px-4 sm:px-6 lg:px-0 lg:pt-24">
          <div className="">
            <div className="mx-auto max-w-lg lg:max-w-none">
              <p className="text-base text-gray-500">
                <span className="mr-3 font-medium text-gray-900">Banka Adı:</span> Yapı Kredi Bankası
              </p>
              <p className="mt-2 text-base text-gray-500">
                <span className="mr-3 font-medium text-gray-900">İban:</span>
                <span //onClick={(e) => handleCopyToClipboard(e)}
                >
                  TR57 0006 7010 0000 0079 1085 86
                </span>
              </p>
              <p className="mt-2 text-base text-gray-500">
                <span className="mr-3 font-medium text-gray-900">Şirket Adı:</span>
                <span //onClick={(e) => handleCopyToClipboard(e)}
                >
                  Script Yazılım Limited Şirketi
                </span>
              </p>
              <p className="mt-2 text-base text-gray-500">
                <span className="mr-3 font-medium text-gray-900">Vergi Dairesi:</span> Erenköy Vergi Dairesi
              </p>
              <p className="mt-2 text-base text-gray-500">
                <span className="mr-3 font-medium text-gray-900">Vergi Numarası:</span> 7570913378
              </p>

              <p className="mt-4 text-base text-red-500">
                <span className="mr-3 font-medium text-red-900">Havale /Eft Açıklaması:</span>{" "}
                <span className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-base font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                  {bankDescriptionKode}
                </span>
              </p>

              <p className="mt-4 text-base text-red-500">
                Ödemeyi yukarıdaki banka hesabına yapakren açıklama kısmana
                <span className="mx-3 font-medium text-red-900">{bankDescriptionKode}</span>
                girmeyi unutmayınız. Ödeme tarafımızıa ulaştıkan sonra işleminiz tamamlanacaktır.
              </p>

              <p className="mt-4 text-base text-gray-500">ödemeyi tamamladıktan sonra aşağıdaki butona tıklayınız.</p>

              <div className="mt-6">
                <button
                  onClick={handleSendPaymentCompleted}
                  type="submit"
                  className="inline-flex items-center justify-center rounded-md border border-transparent bg-blue-600 px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-blue-700"
                >
                  Ödemeyi Yaptım
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const ErrorMessage = (props) => {
  const { error } = props;
  if (!error) return null;
  return (
    <div className="mt-0 flex py-2 text-xs text-red-600 dark:text-red-400 " id="email-error">
      <ExclamationTriangleIcon className="mr-1 h-4  w-4 text-red-500 dark:text-red-400 " aria-hidden="true" /> {error}
    </div>
  );
};
