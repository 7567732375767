import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { yup } from "util/yupLocale";
import ArrayForm from "features/transactions/ArrayForm";

import { prevStep, nextStep } from "features/transactions/slice";
import { updateCompanyDatas, loadCompanyDatas } from "features/companyData/services";
import yupObjGenerator from "features/transactions/yupObjGenerator";

const schemaObj = {
  subeIl: yup
    .string()
    .required()
    .label("Şube İl")
    .meta({
      type: "text",
      className: "sm:w-2/3",
      component: "input",
      transactionTypes: ["Adres Değişikliği", "Şube Açılışı", "Şube Kapanışı", "Müdür Atama", "Müdür İstifa", "Hisse Devri"],
    }),
  subeIlce: yup
    .string()
    .required()
    .label("Şube İlçe")
    .meta({
      type: "text",
      className: "sm:w-2/3",
      component: "input",
      transactionTypes: ["Adres Değişikliği", "Şube Açılışı", "Şube Kapanışı", "Müdür Atama", "Müdür İstifa", "Hisse Devri"],
    }),
  subeAdress: yup
    .string()
    .required()
    .label("Şube Adres")
    .min(5)
    .meta({
      type: "text",
      className: "sm:w-3/3",
      component: "textarea",
      transactionTypes: ["Adres Değişikliği", "Şube Açılışı", "Şube Kapanışı", "Müdür Atama", "Müdür İstifa", "Hisse Devri"],
    }),
  subeUnvan: yup
    .string()
    .required()
    .label("Şube Ek Unvanı")
    .min(5)
    .meta({
      type: "text",
      className: "sm:w-3/3",
      component: "input",
      placeholder: "Örnek: Alaçatı Şubesi - Uzun ünvan yazmayın sadece şube kısmını belirtin",
      transactionTypes: ["Adres Değişikliği", "Şube Açılışı", "Şube Kapanışı", "Müdür Atama", "Müdür İstifa", "Hisse Devri"],
    }),
};

const Subeler = ({}) => {
  const dispatch = useDispatch();

  const { transactionTypes } = useSelector((state) => state.transactions.selectedTransaction);

  const schema = yupObjGenerator({
    yup,
    schemaObj,
    transactionTypes,
    arrName: "subeler",
  });

  const { step } = useSelector((state) => state.transactions);
  const isRegistered = step.registered;

  const onUpdate = async (form) => {
    const deepCopy = JSON.parse(JSON.stringify(form));

    await dispatch(
      updateCompanyDatas({
        form: deepCopy,
        documentName: "subeler",
      })
    );
    dispatch(loadCompanyDatas({ documentName: "subeler" }));
    dispatch(nextStep());
  };

  const onPrev = () => {
    dispatch(prevStep());
  };

  useEffect(() => {
    dispatch(loadCompanyDatas({ documentName: "subeler" }));
  }, []);

  const subeler = useSelector((state) => state.companyData.subeler?.subeler);

  const defaultItem = {
    adSoyad: "",
    tcVkn: "",
  };

  return (
    <ArrayForm
      schema={schema}
      onSubmit={onUpdate}
      title="Ortak Bilgileri"
      initData={subeler}
      itemLabel="subeler"
      defaultItem={defaultItem}
      description={"Tescilli Ortak Bilgilerini Giriniz"}
      onPrev={onPrev}
      isRegistered={isRegistered}
    />
  );
};

export default Subeler;
