import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { yup } from "util/yupLocale";
import ArrayForm from "../../ArrayForm";

import { prevStep, nextStep } from "features/transactions/slice";
import { updateTransaction } from "features/transactions/services";
import { updateAuthorities, loadAuthoritiesData, updateCapital, loadCapitalData } from "features/companyData/services";
import { updateCompanyDatas, loadCompanyDatas } from "features/companyData/services";

const DefterBilgileri = ({}) => {
  const dispatch = useDispatch();

  const { step } = useSelector((state) => state.transactions);
  const isRegistered = step.registered;
  // get last ten years only year like "2021"
  const years = Array.from({ length: 20 }, (_, i) => new Date().getFullYear() - i);

  const schemaObj = {
    yil: yup
      .string()
      .required()
      .label("Yılı")
      .meta({
        type: "select",
        className: "sm:w-2/3",
        component: "select",
        options: years.map((year) => ({ value: year, label: year })),
      }),
    eDeferMi: yup
      .string()
      .required()
      .label("E-defter mi?")
      .meta({
        type: "select",
        className: "sm:w-2/3",
        component: "select",
        options: [
          { value: "eDefter", label: "eDefter" },
          { value: "Noter Tasdikli Defter", label: "Noter Tasdikli Defter" },
        ],
      }),
    tasdikMakami: yup
      .string()
      .label("Tasdik Makamı")
      .meta({
        type: "text",
        className: "sm:w-2/3",
        component: "input",
        condition: {
          field: "eDeferMi",
          value: "Noter Tasdikli Defter",
        },
      })
      .when("eDeferMi", {
        is: "Noter Tasdikli Defter",
        then: yup.string().required(),
      }),
    yevmiyeDefteriTasdikTarihi: yup
      .string()
      .label("Yevmiye Defteri Tasdik Tarihi")
      .meta({
        type: "date",
        className: "sm:w-2/3",
        component: "input",
        condition: {
          field: "eDeferMi",
          value: "Noter Tasdikli Defter",
        },
      })
      .when("eDeferMi", {
        is: "Noter Tasdikli Defter",
        then: yup.string().required(),
      }),
      yevmiyeDefteriYevmiyeNo: yup
      .string()
      .label("Yevmiye Defteri Yevmiye No")
      .meta({
        type: "text",
        className: "sm:w-2/3",
        component: "input",
        condition: {
          field: "eDeferMi",
          value: "Noter Tasdikli Defter",
        },
      })
      .when("eDeferMi", {
        is: "Noter Tasdikli Defter",
        then: yup.string().required(),
      }),
    kebirTasdikTarihi: yup
      .string()
      .label("Defteri Kebir Tasdik Tarihi")
      .meta({
        type: "date",
        className: "sm:w-2/3",
        component: "input",
        condition: {
          field: "eDeferMi",
          value: "Noter Tasdikli Defter",
        },
      })
      .when("eDeferMi", {
        is: "Noter Tasdikli Defter",
        then: yup.string().required(),
      }),
    kebirYevmiyeNo: yup
      .string()
      .label("Defteri Kebir Yevmiye No")
      .meta({
        type: "text",
        className: "sm:w-2/3",
        component: "input",
        condition: {
          field: "eDeferMi",
          value: "Noter Tasdikli Defter",
        },
      })
      .when("eDeferMi", {
        is: "Noter Tasdikli Defter",
        then: yup.string().required(),
      }),
    envanterTasdikTarihi: yup
      .string()
      .label("Envanter Defteri Tasdik Tarihi")
      .meta({
        type: "date",
        className: "sm:w-2/3",
        component: "input",
        condition: {
          field: "eDeferMi",
          value: "Noter Tasdikli Defter",
        },
      })
      .when("eDeferMi", {
        is: "Noter Tasdikli Defter",
        then: yup.string().required(),
      }),
    envanterYevmiyeNo: yup
      .string()
      .label("Envanter Defteri Yevmiye No")
      .meta({
        type: "text",
        className: "sm:w-2/3",
        component: "input",
        condition: {
          field: "eDeferMi",
          value: "Noter Tasdikli Defter",
        },
      })
      .when("eDeferMi", {
        is: "Noter Tasdikli Defter",
        then: yup.string().required(),
      }),
  };

  // const schema = "mudurAtama": yup.array().of(yup.object().shape(schemaObj))
  const schema = yup.object().shape({
    defterBilgileri: yup.array().of(yup.object().shape(schemaObj)),
  });

  const onUpdate = async (form) => {
    const deepCopy = JSON.parse(JSON.stringify(form));

    await dispatch(
      updateCompanyDatas({
        form: deepCopy,
        documentName: "defterBilgileri",
      })
    );
    dispatch(loadCompanyDatas({ documentName: "defterBilgileri" }));
    dispatch(nextStep());
  };

  const onPrev = () => {
    dispatch(prevStep());
  };

  useEffect(() => {
    dispatch(loadCompanyDatas({ documentName: "defterBilgileri" }));
  }, []);

  const defterBilgileri = useSelector((state) => state.companyData?.defterBilgileri?.defterBilgileri);


  const defaultItem = {
    adSoyad: "",
    tcVkn: "",
  };

  return (
    <ArrayForm
      schema={schema}
      onSubmit={onUpdate}
      initData={defterBilgileri}
      itemLabel="defterBilgileri"
      defaultItem={defaultItem}
      title="Defter bilgileri"
      description={"Sermaye arttırımı işlemleri için kullanılan yevmiye kayıtlarını içeren defter bilgilerini giriniz."}
      onPrev={onPrev}
      isRegistered={isRegistered}
    />
  );
};

export default DefterBilgileri;
