import { useState, useEffect } from "react";
import { MagnifyingGlassIcon, ExclamationTriangleIcon } from "@heroicons/react/20/solid";
import { Combobox } from "@headlessui/react";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const ComboboxInput = ({
  data,
  label,
  initValue,
  setCallback,
  createNewCallback,

  hideLabel = true,
  createNewOnBlur = true,
  placeholder,

  queryCallback,
  createNew = true,
}) => {
  const blankValue = { id: 0, name: "" };
  const [query, setQuery] = useState("");
  const [selectedPerson, setSelectedPerson] = useState(blankValue);
  const errorValue = { id: -1, name: `"${query.substring(20, 0)}" Yeni ${label} eklemek İçin Tıklayın` };

  useEffect(() => {
    if (initValue && initValue.id) setSelectedPerson(initValue);
  }, []);

  useEffect(() => {
    // if selectedPerson equal to initValue return
    if (selectedPerson.id === initValue?.id) return;
    setCallback && setCallback(selectedPerson);
  }, [selectedPerson]);

  useEffect(() => {
    queryCallback && queryCallback(query);
  }, [query]);

  const createFilteredData = (query, data) => {
    if (!data || !data.length) return [];
    if (!query) return [];
    // delete all space from query and toLocaleLowerCase
    if (query?.length) query = query?.replace(/\s/g, "").toLocaleLowerCase("tr-TR");
    // if query is empty return all data
    // if (query === "") return data;
    if (query === "") return [];
    // filter data
    return data.filter((item) => {
      let searchText = item?.searchText || item?.title || item?.name;
      searchText = searchText?.toString();
      searchText = searchText?.replace(/\s/g, "").toLocaleLowerCase("tr-TR");
      if (searchText.length) return searchText?.includes(query);
      else return false;
    });
  };
  const filteredData = createFilteredData(query, data);

  return (
    <Combobox as="div" value={selectedPerson} onChange={setSelectedPerson}>
      {hideLabel && <Combobox.Label className="block text-sm font-medium text-gray-700">{label}</Combobox.Label>}
      <div className="relative mt-1">
        <Combobox.Input
          placeholder={placeholder}
          className="w-full rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 shadow-sm focus:border-primary focus:outline-none focus:ring-1 focus:ring-primary sm:text-sm"
          onChange={(e) => {
            const value = e.target.value;
            if (value === "") {
              setQuery("");
              setSelectedPerson(blankValue);
            } else setQuery(value);
          }}
          onBlur={(e) => {
            createNewOnBlur && filteredData.length === 0 && createNew && createNewCallback(query);
          }}
          displayValue={(item) => item?.name || query}
        />

        <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
          {selectedPerson.id === 0 && query && <ExclamationTriangleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />}
          <MagnifyingGlassIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
        </Combobox.Button>

        {filteredData.length === 0 && createNew && (
          <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            <Combobox.Option
              value={query}
              className="relative cursor-default select-none "
              onClick={() => createNewCallback(query)}
            >
              {({ active, selected }) => (
                <>
                  <span
                    className={classNames(
                      "block truncate rounded-md bg-primary px-2 py-1 text-white ",
                      selected && "font-semibold"
                    )}
                  >
                    {errorValue.name}
                  </span>
                </>
              )}
            </Combobox.Option>
          </Combobox.Options>
        )}

        {filteredData.length === 0 && !createNew && (
          <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            <Combobox.Option value={query} className="relative cursor-default select-none ">
              {({ active, selected }) => (
                <>
                  <span
                    className={classNames("bg-gray-white block truncate rounded-md px-2 py-1 ", selected && "font-semibold")}
                  >
                    "sonuç bulunamadı"
                  </span>
                </>
              )}
            </Combobox.Option>
          </Combobox.Options>
        )}

        {filteredData.length > 0 && (
          <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {filteredData.map((item) => {
              if (item.name === "") return null;
              return (
                <Combobox.Option
                  key={item.id}
                  value={item}
                  className={({ active }) =>
                    classNames(
                      "relative cursor-default select-none py-2 pl-3 pr-9",
                      active ? "bg-secondary text-white" : "text-gray-900"
                    )
                  }
                >
                  {({ active, selected }) => (
                    <>
                      <span className={classNames("block truncate", selected && "font-semibold")}>{item.name}</span>
                    </>
                  )}
                </Combobox.Option>
              );
            })}
          </Combobox.Options>
        )}
      </div>
    </Combobox>
  );
};

export default ComboboxInput;
