import React, { useState, useEffect } from "react";
import Input from "features/ui/Input";

const NumberInput = ({ currency, digit = 2, changeCallback, initValue = null, ...rest }) => {
  const [amount, setAmount] = useState(initValue);

  useEffect(() => {
    let init = initValue || null;
    // if init value straing convert to number
    if (typeof init === "string") {
      setAmount(Number(init));
      return;
    }
    setAmount(initValue);
  }, [initValue]);

  rest.className =  "";
  return (
    <div>
      <div className="relative mt-1 rounded-md sm:w-2/3" >
        <Input
          {...rest}
          //   className="block w-full rounded-md border-gray-300 pr-10 focus:border-radical-red-500 focus:ring-radical-red-500 sm:text-sm"
          value={
            typeof amount === "number"
              ? amount.toLocaleString("tr-TR", { minimumFractionDigits: digit, maximumFractionDigits: digit })
              : amount
          }
          onChange={(e) => {
            // allow only numbers and comma "," last two digits
            const regex = /^[0-9]*[.,]?[0-9]{0,2}$/;
            if (regex.test(e.target.value)) {
              setAmount(e.target.value);
              // changeCallback && changeCallback(e.target.value);
              // convert comma "," to dot "."
            }
          }}
          onBlur={(e) => {
            // delete all dot then convert comma "," to dot "." regex
            let number = e.target.value.replace(/\./g, "").replace(",", ".");
            number = Number(number);
            setAmount(number);
            changeCallback && changeCallback(number);
          }}
        />
        {currency && (
          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2 pt-5">
            <span className="text-gray-400">{currency}</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default NumberInput;
