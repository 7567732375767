
import { connectFirestoreEmulator } from "firebase/firestore";
import { connectAuthEmulator } from "firebase/auth";
import { connectStorageEmulator } from "firebase/storage";
import { connectFunctionsEmulator } from "firebase/functions";



const emulatorSetting = ({
    runEmilator = true,
    db,
    auth,
    storage,
    functions,
    firebase
}) => {
    if (!runEmilator) return;
    if (window.location.hostname.includes("localhost")) window.self.FIREBASE_APPCHECK_DEBUG_TOKEN = true;

    // const appCheck = initializeAppCheck(firebase, {
    //   provider: new ReCaptchaV3Provider('6Lfq-p8iAAAAAKZpnnwSiqjNyZnkqYwBC-dniRE4'),
    //   isTokenAutoRefreshEnabled: true
    // });

   

    if (window.location.hostname.includes("localhost")) {
        connectFirestoreEmulator(db, "localhost", 8080);
        connectAuthEmulator(auth, "http://localhost:9099");
        connectStorageEmulator(storage, "localhost", 9199);
        connectFunctionsEmulator(functions, "localhost", 5001);
    }
};

export default emulatorSetting;