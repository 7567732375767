import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { prevStep, nextStep } from "features/transactions/slice";
import { updateTransaction } from "features/transactions/services";
import { loadAuthoritiesData, loadCapitalData } from "features/companyData/services";

import Button from "features/ui/Button";
import CheckListForm from "features/transactions/CheckListForm";

const MudurIstifa = () => {
  const dispatch = useDispatch();

  const onUpdate = async (form) => {
    await dispatch(
      updateTransaction({
        data: {
          mudurIstifa: form,
        },
      })
    );
    dispatch(nextStep());
  };

  const onPrev = () => {
    dispatch(prevStep());
  };

  useEffect(() => {
    dispatch(loadAuthoritiesData());
    dispatch(loadCapitalData());
  }, []);

  const authoritiesInfo = useSelector((state) => state.companyData?.authorities);
  const isAuthoritiesExist = authoritiesInfo?.length > 0;

  const { mudurIstifa } = useSelector((state) => state.transactions?.mudurIstifa) || [];

  const dcData = JSON.parse(JSON.stringify(authoritiesInfo || []));

  const data = dcData.map((item) => {
    return {
      label: item.adSoyad,
      description: `${item.tcVkn} - ${item.yetkiTuru}`,
      obj: {
        ...item,
        id: item.tcVkn,
      },
      id: item.tcVkn,
    };
  });

  const initData = mudurIstifa;

  return (
    <div className="mt-10 md:col-span-2 md:mt-0">
      {isAuthoritiesExist ? (
        <CheckListForm
          onSubmit={onUpdate}
          title="Müdür İstifa"
          initData={initData}
          dataList={data}
          itemLabel="sgkIsyerleri"
          description={"İstifası kabul edilen müdürleri seçin"}
          onPrev={onPrev}
        />
      ) : (
        <>
          <div className="overflow-hidden shadow sm:rounded-md">
            <div className=" col-span-12 overflow-y-scroll  bg-white shadow sm:rounded-lg">
              <div className="gap grid-cols-6 gap-3 border-b p-4 sm:grid sm:p-6">
                <div className="col-span-2 p-2 "></div>
                <div className="col-span-4  space-y-3 px-2 ">
                  {" "}
                  Mevcut müdürler tanımlı değil. Lüfen önce müdürleri sisteme ekleyin.
                </div>
              </div>
            </div>
          </div>

          <div className="overflow-hidden shadow sm:rounded-md">
            <div className="space-x-2 bg-gray-50 px-4 py-3 text-right dark:bg-gray-700 sm:px-6">
              <Button color="secondary" type="button" onClick={onPrev}>
                Geri
              </Button>
              <Button onClick={() => dispatch(onUpdate())}>Kaydet</Button>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default MudurIstifa;
