import { useSelector, useDispatch } from "react-redux";
import React, { useState, useEffect } from "react";

import { yup } from "util/yupLocale";
import Form from "features/transactions/Form";
import { updateCompanyDatas, loadCompanyDatas } from "features/companyData/services";
import { prevStep, nextStep } from "features/transactions/slice";
import yupObjGenerator from "features/transactions/yupObjGenerator";
import numberFormat from "functions/numberFormat";

const Capital = ({}) => {
  const { transactionTypes } = useSelector((state) => state.transactions.selectedTransaction);

  const schemaObj = {
    tescilTarihi: yup
      .string()
      .required()
      .label("Son Sermaye Tescil Tarihi")
      .meta({
        type: "date",
        className: "sm:w-2/3",
        component: "input",
        transactionTypes: ["Sermaye Arttırımı", "Olağan Genel Kurul", "Yönetim Kurulu Üye İstifa", "Tasfiye Başlangıcı"],
      }),
    gazeteSayisi: yup
      .string()
      .required()
      .label("Ticaret Sicil Gazete Sayısı")
      .meta({
        type: "text",
        className: "sm:w-2/3",
        component: "input",
        transactionTypes: ["Sermaye Arttırımı", "Olağan Genel Kurul", "Yönetim Kurulu Üye İstifa", "Tasfiye Başlangıcı"],
      }),
    gazeteSayfa: yup
      .string()
      .required()
      .label("Ticaret Sicil Gazete Sayfası")
      .meta({
        type: "text",
        className: "sm:w-2/3",
        component: "input",
        transactionTypes: ["Sermaye Arttırımı", "Olağan Genel Kurul", "Yönetim Kurulu Üye İstifa", "Tasfiye Başlangıcı"],
      }),
  };

  const schema = yupObjGenerator({
    yup,
    schemaObj,
    transactionTypes,
  });

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadCompanyDatas({ documentName: "capital" }));
  }, []);

  const onUpdate = async (form) => {
    const deepCopy = JSON.parse(JSON.stringify(form));

    await dispatch(
      updateCompanyDatas({
        form: deepCopy,
        documentName: "capital",
      })
    );
    dispatch(loadCompanyDatas({ documentName: "capital" }));
    dispatch(nextStep());
  };

  const onPrev = () => {
    dispatch(prevStep());
  };

  const { step } = useSelector((state) => state.transactions);
  const isRegistered = step.registered;

  const capital = useSelector((state) => state.companyData.capital);
  const totalCapital = capital?.totalCapital || 0;
  return (
    <div className="mt-10 md:col-span-2 md:mt-0">
      <div className="flex flex-col items-center justify-center">
        <div
          className="text-base text-gray-600
      "
        >
          Toplam Sermaye: {numberFormat(totalCapital, 2, true)} TL
        </div>
      </div>

      <Form
        schema={schema}
        onSubmit={onUpdate}
        title="Sermaye Bilgileri"
        initData={capital}
        description={"Tescil Olmuş Sermaye Bilgilerini Giriniz"}
        onPrev={onPrev}
        isRegistered={isRegistered}
      />
    </div>
  );
};

export default Capital;
