import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import InputMask from "react-input-mask";

import Input from "features/ui/Input";
import Button from "features/ui/Button";
import Toggle from "features/ui/Toggle";
import Loading from "features/ui/Loading";
import ROUTES from "features/navigation/routes";

import { userSelector } from "features/users/slice";
import UserRoleSelector from "features/users/UserRoleSelector";
import UserModuleSelector from "features/users/UserModuleSelector";

import { inviteUser, deleteUserFromCompany } from "features/users/services";
import { yup, yupResolver, useForm, Controller } from "util/yupLocale";

const schema = yup.object({
  displayName: yup.string().required().label("Ad Soyad").min(5),
  email: yup.string().email().required().label("E-Posta"),
  phoneNumber: yup.string().required().label("Telefon Numarası"),
  isActive: yup.string().required().label("Kullanici Durumu"),
  // userType: yup.string().required().label("Kullanici Turu"),
});

const UserEdit = ({ mode }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { uid } = useParams();

  const {
    register,
    reset,
    watch,
    setValue,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const isActiveWatch = watch("isActive");
  const userList = userSelector.selectAll(useSelector((state) => state));
  const status = useSelector((state) => state.users.status);
  const loading = status === "loading";
  const userData = userList.find((user) => user.id === uid);

  const [role, setRole] = useState("user");
  const [modules, setModules] = useState({});

  const onDeleteUser = async () => {
    await dispatch(deleteUserFromCompany({ uid }));
    navigate(ROUTES.USERS.path);
  };
  const onSaveUser = async (form) => {
    form.phoneNumber = form.phoneNumber.replace(/[^0-9]/g, "");
    form.phoneNumber = "+9" + form.phoneNumber;
    // if form.isActive "true" or "false" string replace with boolean
    form.isActive = form.isActive === "true" ? true : false;
    const userObj = {
      ...form,
      role: role,
      modules: modules,
    };

    if (mode === "new") {
      await dispatch(inviteUser(userObj));
    } else {
      console.log(uid, role, modules);
    }
    // await dispatch(Companies.onSaveUser({ form: { ...form, uid }, mode }));
    // await dispatch(Companies.onLoadUsers());
    navigate(ROUTES.USERS.path);
  };
  // useEffect(async () => await dispatch(Companies.onLoadUsers()), [uid]);

  useEffect(() => {
    (async () => {
      // await dispatch(Companies.onLoadUsers());
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uid]);

  useEffect(() => {
    if (!userData) return;
    if (mode === "edit") {
      reset(
        {
          displayName: userData.displayName,
          email: userData.email,
          phoneNumber: userData.phoneNumber,
          userType: userData.userType,
          isActive: userData.isActive,
        },
        { keepDirty: true, keepTouched: false }
      );
      setRole(userData.role);
      setModules(userData.modules);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userList]);

  if (loading) return <Loading  />;

  return (
    <div className="mt-10 sm:mt-0">
      <div className="md:grid md:grid-cols-3 md:gap-6">
        <div className="mt-5 md:col-span-2 md:mt-0">
          <form onSubmit={handleSubmit(onSaveUser)}>
            <div className="overflow-hidden shadow sm:rounded-md">
              <div className="bg-white px-4 py-5 dark:bg-gray-900 sm:p-6">
                <NewUserForm mode={mode} register={register} errors={errors} control={control} />
                <EditUserInfo mode={mode} userData={userData} />
                <div className="  border-t border-gray-200 dark:border-gray-500">
                  <h3 className=" py-4 text-center text-lg dark:text-gray-200">Kullanıcı Yetkileri</h3>
                  {role !== "owner" && <ToggleActive isActiveWatch={isActiveWatch} setValue={setValue} />}

                  {isActiveWatch && role !== "owner" && (
                    <div className="">
                      <UserRoleSelector callback={setRole} initValue={role} />
                    </div>
                  )}
                  {role === "owner" && (
                    <div
                      className="block py-4 text-center text-sm font-medium text-gray-900 dark:text-gray-200
                    sm:my-2"
                    >
                      Hesap Sahibi
                    </div>
                  )}
                  {isActiveWatch && !(role === "admin" || role === "owner") && (
                    <div>
                      <UserModuleSelector callback={setModules} initValue={modules} />
                    </div>
                  )}
                </div>
              </div>

              <div className="bg-gray-50 px-4 py-3 text-right dark:bg-gray-700 sm:px-6">
                <Button
                  className={mode === "new" && "hidden"}
                  disabled={role === "owner"}
                  color="danger"
                  onClick={onDeleteUser}
                >
                  Kullanıcıyı Sil
                </Button>
                <Button className="mx-2" color="secondary" onClick={handleSubmit(onSaveUser)}>
                  Vazgeç
                </Button>
                <Button
                  disabled={role === "owner" || (mode === "new" && isActiveWatch !== true)}
                  type="submit"
                  onClick={handleSubmit(onSaveUser)}
                >
                  {mode === "new" ? "Davet et" : "Kaydet"}
                </Button>
              </div>
            </div>
          </form>
        </div>
        <div className="mt-5 sm:mt-0 md:col-span-1">
          <div className="px-4 sm:px-0">
            {mode === "new" ? (
              <>
                <h3 className="text-lg font-medium leading-6 text-gray-900 dark:text-white">Kullanıcı Ekle</h3>
                <p className="mt-5 text-sm text-gray-600 dark:text-gray-400">
                  Ortaklarınızı, Mali Müşaviirnizi veya çalışanlarınızı Aposkal kullanıcısı olarak ekleyebilir verilerinizi
                  kolyaca paylaşabilirisniz.
                </p>
              </>
            ) : (
              <>
                <h3 className="text-lg font-medium leading-6 text-gray-900 dark:text-white">Kullanıcı Düzenle</h3>

                <p className="mt-5 text-sm text-gray-600 dark:text-gray-400">
                  Kullanıcıların yetkilerini düzenleyebilir, Erişimi kısıtlayabilir veya kullanıcıları tamamen silebilirsiniz.
                </p>
                <p className="mt-5 text-sm text-gray-600 dark:text-gray-400">
                  Kullanıcı eklendikten ve daveti kabul ettikten sonra e posta adresi buradan değiştirilemez. Kullanıcı e
                  postasına ulaşamıyorsa ilgili kullanıcıyı silip yeni bir kullanıcı olarak yeniden ekleyin.
                </p>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserEdit;

const NewUserForm = ({ mode, register, errors, control }) => {
  if (mode === "edit") return null;
  return (
    <>
      <Input
        inputType="label-left"
        label="Ad Soyad"
        error={errors.displayName?.message}
        disabled={mode === "edit"}
        {...register("displayName")}
      />
      <Input
        inputType="label-left"
        label="e Posta"
        error={errors.email?.message}
        disabled={mode === "edit"}
        {...register("email")}
      />
      <Controller
        control={control}
        name="phoneNumber"
        render={({ field: { onChange, value, ref } }) => (
          <InputMask
            mask="0 (599) 999 99 99"
            value={value}
            label="Cep Telefonu"
            inputType="label-left"
            error={errors.phoneNumber?.message}
            onChange={onChange}
            ref={ref}
          >
            {(inputProps) => <Input {...inputProps} />}
          </InputMask>
        )}
      />
    </>
  );
};

const EditUserInfo = ({ mode, userData }) => {
  if (mode === "new") return null;
  const copyToClipboard = (e, link) => {
    e.preventDefault();
    const el = document.createElement("textarea");
    el.value = link || e.target.value;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
  };

  return (
    <div className="border-t border-gray-200  py-3 ">
      <dl className="sm:divide-y sm:divide-gray-200">
        <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
          <dt className="text-sm font-medium text-gray-500">Ad Soyad</dt>
          <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">{userData.displayName}</dd>
        </div>
        <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
          <dt className="text-sm font-medium text-gray-500">e Posta</dt>
          <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">{userData.email}</dd>
        </div>
        <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
          <dt className="text-sm font-medium text-gray-500">Cep Telefonu</dt>
          <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">{userData.phoneNumber}</dd>
        </div>
        {userData?.inviteURL && userData?.inviteStatus === "pending" && (
          <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Davet Linki</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
              {/* copy to clipboard {userData.inviteURL} when click here */}
              <span className="cursor-pointer text-blue-500" onClick={(e) => copyToClipboard(e, userData.inviteURL)}>
                Kopyala
              </span>
            </dd>
          </div>
        )}
      </dl>
    </div>
  );
};

const ToggleActive = ({ isActiveWatch, setValue }) => {
  return (
    <div className="py-4 dark:text-gray-200 sm:my-2 sm:grid sm:grid-cols-3 sm:items-start sm:gap-4">
      <label>Kullanıcı Durumu</label>
      <div className="col-span-2">
        <Toggle
          enabled={isActiveWatch}
          setEnabled={(enabled) => setValue("isActive", enabled)}
          label={
            isActiveWatch
              ? "Kullanıcı Aktif yetkilendirme yaptığınızdan emin olun."
              : "Kullanıcı Pasif firmanızın bilgilerine erişemez"
          }
        />
      </div>
    </div>
  );
};
