import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
        getAuth,
        createUserWithEmailAndPassword,
        signInWithEmailAndPassword,
        sendEmailVerification,
        updateProfile,
        updatePassword,
        sendPasswordResetEmail,
        fetchSignInMethodsForEmail,
        signInWithCustomToken,
        signOut as _signOut,
} from "firebase/auth";
import { db, auth, storage, functions } from "features/firebase";
import { getFirestore, doc, setDoc, getDoc, updateDoc, collection, getDocs } from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";
import { loadAuthorizedCompanies } from "features/companies/services";
import { getStorage, ref, getDownloadURL, uploadBytes } from "firebase/storage";


import notify from "features/ui/notify";


export const authSlice = createSlice({
        name: "auth",
        initialState: {
                user: {},
                subscription: {},
                loading: false,
                emailVerified: true,
        },
        reducers: {
                setLoading: (state, action) => {
                        state.loading = action.payload;
                },
                setUser: (state, action) => {
                        state.user = action.payload;
                },
                setEmailVerified: (state, action) => {
                        state.emailVerified = action.payload;
                },
                setSubscription: (state, action) => {
                        state.subscription = action.payload;
                }
        },
        extraReducers: (builder) => {
                builder.addCase(signUp.rejected, (state, action) => {
                        switch (action.error.code) {
                                case "functions/already-exists":
                                        if (action.error.message.includes("Email already in use")) {
                                                notify("Bu eposta daha önce kaydedilmiş Giriş yapmayı deneyin", "error");
                                        } else if (action.error.message.includes("Phone number already in use")) {
                                                notify("Bu telefon numarası daha önce kaydedilmiş Giriş yapmayı deneyin", "error");
                                        }
                                        break;
                                case "auth/email-already-in-use":
                                        notify("Bu eposta daha önce kaydedilmiş Giriş yapmayı deneyin", "error");
                                        break;
                                case "auth/email-already-exists":
                                        notify("Bu eposta daha önce kaydedilmiş Giriş yapmayı deneyin", "error");
                                        break;
                                case "auth/passwords-dont-match":
                                        notify("Parola doğrulama hatalı", "error");
                                        break;
                                default:
                                        notify(action.error.code, "error");
                                        break;
                        }

                        state.loading = false;
                });
                builder.addCase(signIn.rejected, (state, action) => {
                        switch (action.error.code) {
                                case "auth/wrong-password":
                                        notify("Parola Hatalı", "error");
                                        break;
                                case "auth/user-not-found":
                                        notify("Hesap bulunamadı - Yeni hesap oluşturun", "error");
                                        break;
                                case "auth/too-many-requests":
                                        notify("Çok fazla hatalı giriş denemesi bir süre bekleyin", "error");
                                // eslint-disable-next-line no-fallthrough
                                default:
                                        notify(action.error.code);
                                        break;
                        }

                        state.loading = false;
                });
                builder.addCase(signOut.rejected, (state, action) => {
                        console.error(action);
                });
                builder.addCase(loadProfile.rejected, (state, action) => {
                        console.error(action);
                });
                builder.addCase(saveProfile.rejected, (state, action) => {
                        console.error(action);
                });
                builder.addCase(changePassword.rejected, (state, action) => {
                        console.error(action);
                });
                builder.addCase(resetPassword.rejected, (state, action) => {
                        console.error(action);
                });
                builder.addCase(resendVerification.rejected, (state, action) => {
                        console.error(action);
                });
                builder.addCase(loadSubsData.rejected, (state, action) => {
                        console.error(action);
                });
                builder.addCase(uploadUserPhotoToStorage.rejected, (state, action) => {
                        console.log(action);
                });
                builder.addCase(onProfileUpdate.rejected, (state, action) => {
                        if (action.error.code === "functions/internal") {
                                if (action.error.message.includes("The user with the provided phone number already exists.")) {
                                        notify("Bu telefon numarası başka bir kullanıcı tarafından kullaılıyor", "error");
                                        state.loading = false;
                                }

                        } else {
                                notify(action.error.message, "error");
                                state.loading = false;
                        }
                });
        },
});

export const signUp = createAsyncThunk("auth/signUp", async (payload, { dispatch }) => {
        dispatch(setLoading(true));
        let { email, password, password2, displayName, inviteID = null, phoneNumber } = payload;
        // delete phoneNumber spacial characters and spaces
        phoneNumber = phoneNumber.replace(/[^0-9]/g, "");
        phoneNumber = "+9" + phoneNumber;

        // eslint-disable-next-line no-throw-literal
        if (password !== password2) throw { code: "auth/passwords-dont-match" };

        // call signup function from firebase

        // function region europe-west3
        const signUp = httpsCallable(functions, "signUp");
        const response = await signUp({ email, password, password2, displayName, inviteID, phoneNumber });

        if (response.data.error) throw response.data.error;

        const userData = response.data.profile
        if (userData.uid) {
                // const token = userData.token
                // sign in with token
                // await signInWithCustomToken(auth, token)
                // await dispatch(loadProfile({ userData }));
                await dispatch(signIn({ email, password }));
                await sendEmailVerification(auth.currentUser);

        }

        dispatch(setLoading(false));
});
export const signIn = createAsyncThunk("auth/signIn", async (payload, { dispatch }) => {
        dispatch(setLoading(true));
        const { email, password, inviteID } = payload;

        await signInWithEmailAndPassword(auth, email, password);

        if (inviteID) {
                const userRef = doc(db, "users", auth.currentUser.uid);
                await updateDoc(userRef, { inviteID });
        }

        dispatch(setLoading(false));
        await dispatch(loadProfile());
});
export const signOut = createAsyncThunk("auth/signOut", async (payload, { dispatch }) => {
        dispatch(setLoading(true));

        _signOut(auth);

        dispatch(setLoading(false));
});
export const loadProfile = createAsyncThunk("auth/loadProfile", async (payload, { dispatch }) => {
        dispatch(setLoading(true));

        const userRef = doc(db, "users", auth.currentUser.uid);
        const userSnapshot = await getDoc(userRef);
        const userDoc = payload?.userData || userSnapshot.data();

        const userData = {
                ...userDoc,
                uid: auth.currentUser.uid,
                email: auth.currentUser.email,
                emailVerified: auth.currentUser.emailVerified,
                displayName: auth.currentUser.displayName,
                phoneNumber: auth.currentUser.phoneNumber || userDoc?.phoneNumber,
                photoURL: auth.currentUser?.photoURL || userDoc?.photoURL,
        };
        const isEmailVerified = userData?.emailVerified || false;

        // const userSubsRef = doc(db, "users", auth.currentUser.uid, "subs", "data");
        // const subsDoc = await getDoc(userSubsRef);
        // const subsData = subsDoc.data();
        // dispatch(setSubscription(subsData));

        dispatch(setUser(userData));
        dispatch(loadSubsData());
        dispatch(setEmailVerified(isEmailVerified));
        await dispatch(loadAuthorizedCompanies());
        dispatch(setLoading(false));
        return userData;
});

export const loadProfileOnSignIn = createAsyncThunk("auth/loadProfileOnSignIn", async (payload, { dispatch }) => {
        dispatch(setLoading(true));
        let { userDoc } = payload;


        const userData = {
                ...userDoc
        };
        const isEmailVerified = userData?.emailVerified || false;

        await dispatch(setUser(userData));
        dispatch(loadSubsData());
        dispatch(setEmailVerified(isEmailVerified));
        dispatch(setLoading(false));
        return userData;
});
export const saveProfile = createAsyncThunk("auth/saveProfile", async (payload, { dispatch }) => {
        dispatch(setLoading(true));
        const form = payload;

        await updateProfile(auth.currentUser, {
                displayName: form.displayName,
                phoneNumber: form.phoneNumber,
                photoURL: form.photoURL,
                //inviteID: form?.inviteID || null,
        });
        // const userRef = doc(db, "users", auth.currentUser.uid);
        // await setDoc(userRef, form, { merge: true });

        notify("Profil basariyla kaydedildi", "success");
        dispatch(setLoading(false));
});

export const changePassword = createAsyncThunk("auth/changePassword", async (payload, { dispatch }) => {
        dispatch(setLoading(true));
        const { newPassword } = payload;

        await updatePassword(auth.currentUser, newPassword);

        dispatch(setLoading(false));
});
export const resetPassword = createAsyncThunk("auth/resetPassword", async (payload, { dispatch }) => {
        dispatch(setLoading(true));
        if (payload?.email) {
                try {
                        await sendPasswordResetEmail(auth, payload?.email);
                        notify("Şifre sıfırlama linki e-posta adresinize gönderildi", "success");
                } catch (error) {
                        notify(error.message, "error");
                }
                dispatch(setLoading(false));
        } else {
                await sendPasswordResetEmail(auth, auth.currentUser.email);
                notify("Şifre sıfırlama linki e-posta adresinize gönderildi", "success");
                dispatch(setLoading(false));
        }
});
export const resendVerification = createAsyncThunk("auth/resendVerification", async (payload, { dispatch }) => {
        dispatch(setLoading(true));
        await sendEmailVerification(auth.currentUser);
        dispatch(setLoading(false));
});

export const isEmailExist = createAsyncThunk("auth/isEmailExist", async (payload, { dispatch }) => {
        const email = payload;
        return await fetchSignInMethodsForEmail(auth, email);
});

export const loadSubsData = createAsyncThunk("auth/loadSubsData", async (payload, { dispatch, getState }) => {
        const companyID = payload?.companyID
        if (!companyID) return

        const { selectedCompany } = getState().companies;
        const { uid } = getState().auth.user;
        let subsData = {}

        if (!uid) return
        const createdBy = selectedCompany?.createdBy
        let isOwner = createdBy === uid;
        if (!companyID) isOwner = true;


        const userSubsRef = doc(db, "users", createdBy, "subs", "data");
        const subsDoc = await getDoc(userSubsRef);


        subsData = subsDoc.data() || {}
        const subsExprialDate = subsData?.subsExprialDate?.seconds * 1000 || null;
        const trialExprialDate = subsData?.trialExprialDate?.seconds * 1000 || null;

        // const LastDate = Math.max(subsExprialDate, trialExprialDate);
        let remainingTrialDays = trialExprialDate && Math.floor((trialExprialDate - Date.now()) / (1000 * 60 * 60 * 24));
        let remainingSubsDays = subsExprialDate && Math.floor((subsExprialDate - Date.now()) / (1000 * 60 * 60 * 24));

        if (remainingTrialDays < 0) remainingTrialDays = 0;
        if (remainingSubsDays < 0) remainingSubsDays = 0;

        subsData.remainingTrialDays = remainingTrialDays;
        subsData.remainingSubsDays = remainingSubsDays;

        subsData.isOwner = isOwner
        subsData.createdBy = createdBy
        dispatch(setSubscription(subsData));
        return subsData;
});

export const spendCreditOnFe = createAsyncThunk("auth/spendCreditOnFe", async (payload, { dispatch, getState }) => {
        const { subscription } = getState().auth

        const currentCredit = subscription?.credits || 0

        const newCredit = currentCredit - 1


        const newSubsData = {
                ...subscription,
                credits: newCredit
        }


        dispatch(setSubscription(newSubsData));
        return newSubsData;
});

export const onProfileUpdate = createAsyncThunk("auth/onProfileUpdate", async (payload, { dispatch, getState }) => {

        const { uid, displayName: _DisplayName, photoURL: _photoURL, email: _Email, phoneNumber: _phoneNumber } = getState().auth.user;
        const { displayName, photoURL, email, phoneNumber } = payload;

        const newObject = {}

        if (displayName && displayName !== _DisplayName) newObject.displayName = displayName
        // if (photoURL && photoURL !== _photoURL) newObject.photoURL = photoURL
        if (email && email !== _Email) newObject.email = email
        if (phoneNumber && phoneNumber !== _phoneNumber) newObject.phoneNumber = phoneNumber

        // if newObject is empty, return
        if (Object.keys(newObject).length === 0) return
        newObject.uid = uid

        try {

                const onProfileUpdate = httpsCallable(functions, "onProfileUpdate");
                const result = await onProfileUpdate(newObject);
                window.location.reload();
                return result;
        } catch (error) {
                throw error
        }

});

export const uploadUserPhotoToStorage = createAsyncThunk("auth/uploadUserPhotoToStorage", async (payload, { dispatch, getState }) => {
        const { uid } = getState().auth.user;
        const file = payload;

        const storage = getStorage();
        const storageRef = ref(storage);
        const fileStorageRef = ref(storageRef, `${uid}/profilePhoto`);

        const snapshot = await uploadBytes(fileStorageRef, file);
        const downloadURL = await getDownloadURL(snapshot.ref);


        const newObject = {}
        newObject.photoURL = downloadURL
        newObject.uid = uid


        const onProfileUpdate = httpsCallable(functions, "onProfileUpdate");
        const result = await onProfileUpdate(newObject);
        window.location.reload();
        return result;
});










export const { setLoading, setUser, setEmailVerified, setSubscription } = authSlice.actions;
export default authSlice.reducer;
