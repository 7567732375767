import { toast } from "react-toastify";
const notify = (msg, type = "info") => {
        toast[type](msg, {
                position: "top-right",
                autoClose: 2500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
        });
};

export default notify;
