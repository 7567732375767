/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";

import Button from "./Button";
import { useEffect } from "react";

export default function Modal(props) {
  const {
    children,
    closeClickOutside = false,
    modalButonLaber = "Aç",
    modalButtonComponent,
    openCallback,

    alwaysOpen = false,
    buttonAsLink = false,

    hiddenButton = false,
    open,
    setOpen,
    hiddenCloseButton = false,
    modalClass = "relative mt-12 inline-block transform overflow-hidden rounded-lg bg-white  p-2 text-left align-bottom shadow-xl transition-all sm:mt-24 sm:w-12/12 sm:align-top print:mt-1 w-full",
    closeCallback,
    ifError,
  } = props;

  const cancelButtonRef = useRef(null);

  const handleOpen = async () => {
    openCallback && (await openCallback());
    setOpen(true);
  };

  if (!open && hiddenButton) return null;

  if (!open)
    if (buttonAsLink) {
      return (
        <span className=" cursor-pointer text-primary" onClick={handleOpen}>
          {modalButtonComponent || modalButonLaber}
        </span>
      );
    } else {
      return (
        <Button size="sm" onClick={handleOpen}>
          {modalButtonComponent || modalButonLaber}
        </Button>
      );
    }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-40 overflow-y-auto"
        initialFocus={cancelButtonRef}
        onClose={
          closeClickOutside
            ? setOpen
            : () => {
                return null;
              }
        }
      >
        <div className="flex min-h-screen items-end justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:h-screen sm:align-middle" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className={modalClass}>
              <div>{children}</div>
              {!alwaysOpen && !hiddenCloseButton && (
                <div className="mt-5 flex justify-end space-x-2 print:hidden sm:mt-6">
                  <Button onClick={() => setOpen(false)} color="secondary">
                    Kapat
                  </Button>
                  {/* <Button
                    onClick={() => {
                      closeCallback && closeCallback();
                      !ifError && setOpen(false);
                    }}
                  >
                    Kaydet
                  </Button> */}
                </div>
              )}
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
