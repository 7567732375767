const numberFormat = (value, digit = 2, string = false) => {
    if (!value) return "0"
    value = Number(value);
    if (string) {
        return value.toLocaleString("tr-TR", { minimumFractionDigits: digit, maximumFractionDigits: digit });
    }

    let parameter = 1;
    if (digit > 0) {
        for (let i = 0; i < digit; i++) {
            parameter *= 10;
        }
    }

    return Math.round(value * parameter) / parameter;
};

const sayiOkunusu = (sayi, kurCinsi) => {
    sayi = Number(sayi)

    const birler = [
        "",
        "Bir",
        "İki",
        "Üç",
        "Dört",
        "Beş",
        "Altı",
        "Yedi",
        "Sekiz",
        "Dokuz"
    ];
    const onlar = [
        "",
        "On",
        "Yirmi",
        "Otuz",
        "Kırk",
        "Elli",
        "Altmış",
        "Yetmiş",
        "Seksen",
        "Doksan"
    ];
    const gruplar = [
        "",
        "Bin",
        "Milyon",
        "Milyar",
        "Trilyon",
        "Katrilyon",
        "Kentilyon",
        "Sekstilyon",
        "Septilyon",
        "Oktilyon",
        "Nonilyon",
        "Desilyon"
    ];

    let okunus = "";
    let kurusOkunus = "";

    // Sayıyı virgül veya nokta karakterine göre bölerek tam ve ondalık kısmını ayrıştırma
    const tamKisim = sayi.toString().split(/[\.,]/)[0];
    const ondalikKisim = sayi.toString().split(/[\.,]/)[1];
    // Tam kısmın uzunluğunu alarak grup sayısını belirleme
    let grupSayisi = Math.ceil(tamKisim.length / 3)


    // Tam kısmın solundan grupları belirleyerek okunuşa ekleme
    for (let i = grupSayisi; i > 0; i--) {
        const grupDegeri = tamKisim.substring(
            tamKisim.length - i * 3,
            tamKisim.length - (i - 1) * 3
        );
        const intGrupDegeri = parseInt(grupDegeri);
        if (intGrupDegeri) {
            // Grup değerinin okunuşuna, grubun hangi sıradaki grup olduğunu ekleyerek oluşan okunuşu birleştirme
            okunus +=
                birler[Math.floor(intGrupDegeri / 100)] +
                (birler[Math.floor(intGrupDegeri / 100)] ? " yüz " : "") +
                onlar[Math.floor((intGrupDegeri % 100) / 10)] + " " +
                birler[Math.floor(intGrupDegeri % 10)] + " " +
                gruplar[i - 1] + " ";

        }
    }

    // Ondalık kısmın okunuşunu hesaplama ve tam kısmın okunuşu ile birleştirme

    if (ondalikKisim) {
        for (let i = grupSayisi; i > 0; i--) {
            const grupDegeri = ondalikKisim?.substring(
                ondalikKisim.length - i * 3,
                ondalikKisim.length - (i - 1) * 3
            );
            const intGrupDegeri = parseInt(grupDegeri);
            if (intGrupDegeri) {
                // Grup değerinin okunuşuna, grubun hangi sıradaki grup olduğunu ekleyerek oluşan okunuşu birleştirme
                kurusOkunus +=
                    birler[Math.floor(intGrupDegeri / 100)] +
                    (birler[Math.floor(intGrupDegeri / 100)] ? " yüz " : "") +
                    onlar[Math.floor((intGrupDegeri % 100) / 10)] + " " +
                    birler[intGrupDegeri % 10] + " "
            }
        }
    }

    // Kur cinsine göre sonuç okunuşunu oluşturma
    switch (kurCinsi) {
        case "TRY":
            okunus += "Türk Lirası";
            kurusOkunus += "Kuruş";
            break;
        case "USD":
            okunus += "Amerikan Doları";
            kurusOkunus += "sent";
            break;
        case "EUR":
            okunus += "Euro";
            kurusOkunus += "sent";
            break;
        case "GBP":
            okunus += "İngiliz Sterlini";
            kurusOkunus += "pens";
            break;
        case "CHF":
            okunus += "İsviçre Frangı";
            kurusOkunus += "rappen";
            break;
        case "JPY":
            okunus += "Japon Yeni";
            kurusOkunus += "sen";
            break;
        case "CNY":
            okunus += "Çin Yuanı";
            kurusOkunus += "fen";
            break;
        default:
            okunus += "Türk Lirası";
            kurusOkunus += "Kuruş";
            break;
    }

    let result = okunus
    if (ondalikKisim) result += " - " + kurusOkunus
    return result;
};












export default numberFormat
export { sayiOkunusu } 