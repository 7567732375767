import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import "features/firebase";
import store from "features/store";
import Navigator from "features/navigation/Navigator";
import reportWebVitals from 'features/test/reportWebVitals';
import 'features/tailwind/index.css'

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
    <Provider store={store}>
      <ToastContainer />
      <Navigator />
    </Provider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
