import React, { useState, useEffect, useRef } from "react";

import { useSelector, useDispatch } from "react-redux";
import { yup, yupResolver, useForm, Controller } from "util/yupLocale";
import { ExclamationCircleIcon } from "@heroicons/react/solid";

import { createPayment } from "features/shop/slice";

import { useNavigate } from "react-router-dom";

import ROUTES from "features/navigation/routes";

import Bag from "../Bag";

import Loader from "features/ui/Loader";

export default function Example() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const checkoutForm = useSelector((state) => state.shop.checkoutForm);


  // if iframe respnse "OK" then redirect to success page

  const html = `<div id="iyzipay-checkout-form" className="responsive"></div>`;
  const iframeContent = checkoutForm?.checkoutFormContent + html;

  const url = checkoutForm?.paymentPageUrl;

  useEffect(() => {
    if (url) {
      // redirect to payment page url
      window.open(url, "_self");
    }
  }, [url]);



  if (!iframeContent) return null;

  return <Loader/>

}
