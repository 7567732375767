import {
  HomeIcon,
  ArrowTrendingUpIcon,
  ArrowTrendingDownIcon,
  BanknotesIcon,
  BuildingOffice2Icon,
  SwatchIcon,
  WrenchScrewdriverIcon,
  CalculatorIcon,
  ArrowsUpDownIcon,
  RectangleStackIcon,
  PlusIcon
} from '@heroicons/react/24/outline'
// import { MagnifyingGlassIcon } from '@heroicons/react/20/solid'

import ROUTES from "features/navigation/routes";


const sideBarItems = [
  { name: ROUTES.HOME.name, path: ROUTES.HOME.path, icon: HomeIcon, current: true },
  {
    name: "Firma Değiştir", path: ROUTES.COMPANIES_SELECT.path, icon: ArrowsUpDownIcon,
  },
  {
    name: "İşlem Başlat", path: ROUTES.TRANSACTION_START.path, icon: PlusIcon,
  },

  {
    name: "İşlemler", path: "#", icon: RectangleStackIcon, children: [
      { name: "Devam Eden", path: ROUTES.TRANSACTIONS_DRAFT.path, icon: null },
      { name: "Tamamlanan", path: ROUTES.TRANSACTIONS_DONE.path, icon: null }
    ]
  },

  {
    name: "Ayarlar", path: "#", icon: WrenchScrewdriverIcon, children: [
      { name: "Firma Bilgileri", path: ROUTES.COMPANIES_EDIT.path, icon: null },
       { name: "Kullanıcılar", path: ROUTES.USERS.path, icon: null },
      // { name: "Entegrasyon", path: ROUTES.NOT_MATCH.path, icon: null },
      // { name: "Hesap", path: ROUTES.NOT_MATCH.path, icon: null },
    ]
  },
];

export const sidebarItemsNames = sideBarItems.map(item => item.name);

export default sideBarItems;
