import { useEffect, useState } from "react";
import { CheckCircleIcon, ChevronRightIcon, EnvelopeIcon, ExclamationCircleIcon } from "@heroicons/react/20/solid";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import ROUTES from "features/navigation/routes";
import Button from "features/ui/Button";
import { loadUsers } from "features/users/services";
import { userSelector } from "features/users/slice";

import Tooltip from "features/ui/Tooltip";

const Users = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const userList = useSelector(userSelector.selectAll);

  const { subscription } = useSelector((state) => state.auth);
  const owner = subscription?.createdBy || null;

  const onAddUser = () => navigate(ROUTES.USER_NEW.path);

  // useEffect(async () => await dispatch(Companies.onLoadUsers()), [selectedCompany]);

  useEffect(() => {
    (async () => {
      await dispatch(loadUsers());
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className=" overflow-hidden shadow sm:rounded-md">
        <div className="border-b border-gray-200 bg-white px-4 py-5 dark:border-gray-500 dark:bg-gray-900 sm:px-6 ">
          <div className="-ml-4 -mt-2 flex flex-wrap items-center justify-between sm:flex-nowrap">
            <div className="ml-4 mt-2">
              <h3 className="text-lg font-medium leading-6 text-gray-900 dark:text-white">Kullanıcılar</h3>
            </div>
            <div className="ml-4 mt-2 flex-shrink-0">
              <Button onClick={onAddUser}>Kullanıcı Ekle</Button>
            </div>
          </div>
        </div>
        <ul className="divide-y divide-gray-200 bg-white dark:divide-gray-500 dark:bg-gray-900">
          {userList.map((user) => (
            <li key={user.uid}>
              <Link to={`/settings/users/${user.id}/edit`} className="block hover:bg-gray-50 dark:hover:bg-gray-700">
                <div className="flex items-center px-4 py-4 sm:px-6">
                  <div className="flex min-w-0 flex-1 items-center">
                    <div className="flex-shrink-0">
                      {user.photoURL && <img className="h-12 w-12 rounded-full" src={user.photoURL} alt="" />}
                    </div>
                    <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
                      <div>
                        <p className="truncate text-sm font-medium text-radical-red-600 dark:text-radical-red-400">
                          {user.displayName}{" "}
                          {user.uid === owner && (
                            // <span className="text-xs font-normal text-gray-500 dark:text-gray-300"> (Hesap Sahibi)</span>
                            // badge if mouse over show tooltip "Hesap Sahibi"
                            <div className="relative">
                              <Tooltip
                                toolTipComponent={<p>Hesap Sahibi kullanım süresini uzatabilir ve kontör yükleyebilir.</p>}
                              >
                                <span className="inline-flex items-center rounded-full bg-green-100 px-2.5 py-0.5 text-xs font-medium text-green-800">
                                  Hesap Sahibi
                                </span>
                              </Tooltip>
                            </div>
                          )}
                        </p>
                        <p className="mt-2 flex items-center text-sm text-gray-500 dark:text-gray-300">
                          <EnvelopeIcon className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                          <span className="truncate">{user.email}</span>
                        </p>
                      </div>

                      <div className="hidden md:block">
                        <div>
                          <p className="text-sm text-gray-900 dark:text-gray-300">{user.stage}</p>
                          <StateView userActive={user.isActive} inviteStatus={user?.inviteStatus} />
                          <UserTypeWiev role={user.role} />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <ChevronRightIcon className="h-5 w-5 text-gray-400 dark:text-gray-300" aria-hidden="true" />
                  </div>
                </div>
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </>
  );
};

export default Users;

const StateView = ({ userActive, inviteStatus }) => {
  if (inviteStatus === "pending")
    return (
      <p className="mt-2 flex items-center text-sm text-gray-500 dark:text-gray-300">
        <ExclamationCircleIcon className="mr-1.5 h-5 w-5 flex-shrink-0 text-yellow-400" aria-hidden="true" />
        Davet Onay Bekliyor
      </p>
    );
  if (userActive === "Yanıt Bekleniyor")
    return (
      <p className="mt-2 flex items-center text-sm text-gray-500 dark:text-gray-300">
        <ExclamationCircleIcon className="mr-1.5 h-5 w-5 flex-shrink-0 text-yellow-400" aria-hidden="true" />
        {userActive}
      </p>
    );
  else if (userActive === false)
    return (
      <p className="mt-2 flex items-center text-sm text-gray-500 dark:text-gray-300">
        <ExclamationCircleIcon className="mr-1.5 h-5 w-5 flex-shrink-0 text-red-400" aria-hidden="true" />
        Pasif
      </p>
    );
  else if (userActive === true)
    return (
      <p className="mt-2 flex items-center text-sm text-gray-500 dark:text-gray-300">
        <CheckCircleIcon className="mr-1.5 h-5 w-5 flex-shrink-0 text-green-400" aria-hidden="true" />
        Aktif
      </p>
    );
  else return null;
};

const UserTypeWiev = ({ role }) => {
  if (role === "owner")
    return (
      <p className="mt-2 flex items-center text-sm text-gray-500 dark:text-gray-300">
        {/* <ExclamationCircleIcon className="mr-1.5 h-5 w-5 flex-shrink-0 text-red-400" aria-hidden="true" /> */}
        Hesap Sahibi
      </p>
    );
  else if (role === "admin")
    return (
      <p className="mt-2 flex items-center text-sm text-gray-500 dark:text-gray-300">
        {/* <ExclamationCircleIcon className="mr-1.5 h-5 w-5 flex-shrink-0 text-red-400" aria-hidden="true" /> */}
        Yetkili Kullanıcı
      </p>
    );
  else if (role === "user")
    return (
      <p className="mt-2 flex items-center text-sm text-gray-500 dark:text-gray-300">
        {/* <CheckCircleIcon className="mr-1.5 h-5 w-5 flex-shrink-0 text-green-400" aria-hidden="true" /> */}
        Kullanıcı
      </p>
    );
  else if (role === "limitedUser")
    return (
      <p className="mt-2 flex items-center text-sm text-gray-500 dark:text-gray-300">
        {/* <CheckCircleIcon className="mr-1.5 h-5 w-5 flex-shrink-0 text-green-400" aria-hidden="true" /> */}
        Kısıtlı Kullanıcı
      </p>
    );
  else return null;
};
