import React, { useState, useEffect } from "react";
import Input from "features/ui/Input";
import { ChevronRightIcon } from "@heroicons/react/20/solid";
import Alert from "features/ui/Alert";
import { router } from "features/navigation/Navigator";
import { transactions, getTransactionCredits } from "features/transactions/transactionTypes";
import { steps } from "features/transactions/steps";
import { useDispatch, useSelector } from "react-redux";
import { setSteps, selectTransaction } from "features/transactions/slice";
import Button from "features/ui/Button";
import ROUTES from "features/navigation/routes";

const NewTransaction = ({}) => {
  const dispatch = useDispatch();

  const [query, setQuery] = useState("");
  const [results, setResults] = useState([...transactions]);
  const [selectedTransactions, setSelectedTransactions] = useState([]);

  const title = useSelector((state) => state.companies?.selectedCompany?.title);
  const companyType = useSelector((state) => state.companies?.selectedCompany?.companyType);
  const transactionsForCompanyType = transactions.filter((transaction) => transaction.companyTypes.includes(companyType));

  useEffect(() => {
    let _query = query.toLocaleLowerCase("tr-TR");
    // delete all spaces from query
    _query = _query.replace(/\s/g, "");
    const results = transactionsForCompanyType.filter((transaction) =>
      transaction.title.toLocaleLowerCase("tr-TR").replace(/\s/g, "").includes(_query)
    );
    setResults(results);
  }, [query]);

  const onSearch = (e) => {
    setQuery(e.target.value);
  };

  const onSelect = (item) => {
    // set steps to redux
    const steps = item.steps;
    dispatch(setSteps(steps));
    dispatch(selectTransaction(item.initData));
    router.navigate(item.link);
  };

  const onReset = () => {
    setQuery("");
  };

  const handleOnSelect = (item) => {
    if (item?.disabled) return;
    // if selectedTransactions includes item, remove it
    // else add it
    const _selectedTransactions = [...selectedTransactions];
    const index = _selectedTransactions.findIndex((selectedTransaction) => selectedTransaction.title === item.title);
    if (index > -1) {
      _selectedTransactions.splice(index, 1);
    }
    // if selectedTransactions length is 0, add item
    else if (_selectedTransactions.length === 0) {
      _selectedTransactions.push(item);
    }
    // if selectedTransactions length is not 0, check if item is in same group with other selectedTransactions
    else {
      const isSameGroup = _selectedTransactions.some((selectedTransaction) => selectedTransaction.group === item.group);
      if (isSameGroup) {
        _selectedTransactions.push(item);
      }
    }

    setSelectedTransactions(_selectedTransactions);
  };

  const onStartTransactions = () => {
    const _selectedTransactions = [...selectedTransactions];

    // filter steps as companyType
    const filteredSteps = steps.filter((step) => {
      const { companyTypes, transactionTypes } = step;
      const isCompanyTypeMatch = companyTypes.includes(companyType);
      const isTransactionTypeMatch = transactionTypes.some((transactionType) =>
        _selectedTransactions.some((selectedTransaction) => selectedTransaction.title === transactionType)
      );
      return isCompanyTypeMatch && (isTransactionTypeMatch || transactionTypes.length === 0);
    });

    dispatch(setSteps(filteredSteps));
    dispatch(
      selectTransaction({
        transactionTypes: selectedTransactions.map((item) => item.title),
        creditToBeSpend: getTransactionCredits(selectedTransactions.map((item) => item.title)),
        id: null,
        status: "draft",
        selectedTransactions: _selectedTransactions,
      })
    );
    router.navigate(ROUTES.TRANSACTION_EDIT.path);
  };

  const navigateToCompanySelect = () => {
    router.navigate(ROUTES.COMPANIES_SELECT.path);
  };

  if (!companyType)
    return (
      <Alert>
        <p className="cursor-pointer" onClick={navigateToCompanySelect}>
          Firma seçmeden işlem başlatılamaz lütfen firma seçiniz.
        </p>
      </Alert>
    );

  return (
    <div className="pt-3 md:grid md:grid-cols-6 md:gap-6">
      <div className="col-span-4 pt-3">
        <div className=" ">
          <div className="mb-2">
            <Input inputType="label-top" placeholder="Ara" onChange={onSearch} />
          </div>
          {results.length === 0 && (
            <Alert>
              <p className="cursor-pointer" onClick={onReset}>
                Sonuç Bulunamadı
              </p>
            </Alert>
          )}
          <Button onClick={onStartTransactions} disabled={selectedTransactions.length === 0}>
            Başlat
          </Button>
          <div className="mt-5 overflow-hidden bg-white shadow sm:rounded-md">
            <ul className="divide-y divide-gray-200">
              {results.map((item) => (
                <li key={item.title} onClick={() => handleOnSelect(item)}>
                  <span className="block cursor-pointer hover:bg-gray-50 hover:text-primary">
                    <div className="flex items-center px-2 py-2 sm:px-6">
                      <div key={item.id} className="relative flex items-start">
                        <div className="flex h-6 items-center">
                          <input
                            id={item.id}
                            aria-describedby={`${item.id}-description`}
                            name={item.id}
                            type="checkbox"
                            className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                            onChange={() => {
                              // handleSelect(obj);
                            }}
                            checked={selectedTransactions.findIndex((i) => i.id === item.id) !== -1}
                          />
                        </div>
                      </div>
                      <div className="min-w-0 flex-1 sm:flex sm:items-center sm:justify-between">
                        <div className="truncate">
                          <div className="flex text-sm">
                            <p className="ml-3 mt-1 truncate font-medium">{item.title}</p>
                            {item.disabled && <p className="ml-3 mt-1 truncate font-medium text-sky-500">Yakında...</p>}
                            <p className="ml-3 mt-1 truncate">{item.describtion}</p>
                          </div>
                          <div className="flex p-0.5">
                            <div className="flex items-center text-sm text-gray-500">
                              {/* <p className="ml-3 mt-1 truncate">{item.describtion}</p> */}
                            </div>
                          </div>
                        </div>
                      </div>

                      <span className="inline-flex items-center rounded-full bg-yellow-100 px-2.5 py-0.5 text-xs font-medium text-yellow-800">
                        {getTransactionCredits([item.title])} kontör
                      </span>

                      <div className="ml-5 flex-shrink-0">
                        {/* <ChevronRightIcon className="h-5 w-5 text-gray-400" aria-hidden="true" /> */}
                      </div>
                    </div>
                  </span>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      <div className="mt-5 space-y-3 rounded-md  border border-gray-200 bg-white sm:mt-0 md:col-span-2 md:p-5">
        <span className="block text-sm  text-gray-600">
          {title} - Tür:{companyType}
        </span>
        <span
          onClick={() => router.navigate("/settings/companies/edit")}
          className="block cursor-pointer  text-sm text-primary"
        >
          Türü değiştirmek için tıklayın
        </span>

        {/* render selectedTransactions titles seperate "," */}

        {selectedTransactions.length > 0 && (
          <div className="flex flex-wrap justify-start space-x-2">
            <span className="block text-sm  text-gray-600">Seçili İşlemler:</span>
            {selectedTransactions.map((item) => (
              <span className="block text-sm  text-gray-600">{item.title},</span>
            ))}
          </div>
        )}

        {/* selectedTransactions render end */}
      </div>
    </div>
  );
};

export default NewTransaction;
