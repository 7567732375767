import Loader from "features/ui/Loader";

const Button = (props) => {
  const { loading, children, size = "md", color = "primary", className, type = "button", disabled = false, ...rest } = props;

  const opacity = " opacity-50 ";
  const defaultClass =
    "inline-flex justify-center border border-transparent shadow-sm rounded-md  focus:outline-none focus:ring-2 focus:ring-offset-2";

  let sizeClass;
  if (size === "sm") sizeClass = " px-2.5 py-1.5 text-xs ";
  else if (size === "md") sizeClass = " px-4 py-2 text-sm font-medium ";
  else if (size === "lg") sizeClass = " px-6 py-3 text-base ";

  let colorClass;
  if (color === "primary") colorClass = "text-white bg-sky-600 hover:brightness-95 focus:ring-primary";
  else if (color === "secondary") colorClass = "text-sky-700 bg-sky-100 hover:brightness-95 focus:ring-secondary";
  else if (color === "tertiary") colorClass = "text-sky-700  hover:brightness-95 focus:ring-tertiary";
  else if (color === "danger") colorClass = "text-white bg-red-600 hover:brightness-95 focus:ring-primary";
  else if (color === "warning") colorClass = "text-white bg-orange-600 hover:brightness-95 focus:ring-primary";

  return (
    <button
      type={type}
      disabled={disabled}
      className={` ${sizeClass} ${colorClass}  ${defaultClass} ${className || ""} ${(disabled || loading) && opacity}`}
      {...rest}
    >
      {loading && (
        <span className="absolute inset-y-0 left-0 flex items-center pl-3">
          {/* <LockClosedIcon className="h-5 w-5 text-primary group-hover:text-primary" aria-hidden="true" /> */}
          <Loader size="12" colorName="white" />
        </span>
      )}
      {children}
    </button>
  );
};

export default Button;
