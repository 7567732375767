import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import InputMask from "react-input-mask";
import { Controller } from "react-hook-form";

import ROUTES from "features/navigation/routes";
import Input from "features/ui/Input";
import Button from "features/ui/Button";
import UserPhotoUpload from "features/auth/UserPhotoUpload";
import { onProfileUpdate, loadProfile } from "features/auth/slice";
import { yup, yupResolver, useForm } from "util/yupLocale";

const schema = yup.object({
  displayName: yup.string().required().label("Ad Soyad").min(5),
  email: yup.string().email().required().label("E-Posta"),
  phoneNumber: yup.string().required().label("Cep Telefonu"),
});

const Profile = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    control,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const user = useSelector((state) => state.auth.user);

  const onCancel = () => navigate(ROUTES.HOME.path, { replace: true });
  const navigateChangePassword = () => navigate(ROUTES.CHANGE_PASSWORD.path, { replace: true });

  const onSaveProfile = async (form) => {
    form.phoneNumber = form.phoneNumber.replace(/[^0-9]/g, "");
    form.phoneNumber = "+9" + form.phoneNumber;
    await dispatch(onProfileUpdate(form));
  };

  // do proper spread bcz of bug https://github.com/redux-form/redux-form/issues/2629
  useEffect(
    () =>
      reset({
        uid: user.uid,
        email: user.email,
        emailVerified: user.emailVerified,
        displayName: user.displayName,
        // delete phoneNumber first 2 chars
        phoneNumber: user.phoneNumber ? user.phoneNumber.slice(2) : "",
        photoURL: user.photoURL,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [user]
  );

  return (
    <div className="mt-10 sm:mt-0">
      <div className="md:grid md:grid-cols-3 md:gap-6">
        <div className="mt-5 md:col-span-2 md:mt-0">
          <form onSubmit={handleSubmit(onSaveProfile)}>
            <div className="overflow-hidden shadow sm:rounded-md">
              <div className="bg-white px-4 py-5 dark:bg-gray-900 sm:p-6">
                <Input
                  label="Ad Soyad"
                  inputType="label-left"
                  error={errors.displayName?.message}
                  {...register("displayName")}
                />
                <Input
                  label="E-Posta"
                  inputType="label-left"
                  error={errors.email?.message}
                  disabled={true}
                  {...register("email")}
                />

                <Controller
                  control={control}
                  name="phoneNumber"
                  render={({ field: { onChange, value, ref } }) => (
                    <InputMask
                      mask="0 (599) 999 99 99"
                      value={value}
                      label="Cep Telefonu"
                      inputType="label-left"
                      error={errors.phoneNumber?.message}
                      onChange={onChange}
                      ref={ref}
                    >
                      {(inputProps) => (
                        <Input
                          {...inputProps}
                          // {...register("phoneNumber")}
                        />
                      )}
                    </InputMask>
                  )}
                />

                <UserPhotoUpload name="photoURL" label="Profil Fotoğrafı" />
                <div className="text-right">
                  <Button type="submit" onClick={navigateChangePassword}>
                    Parola Değiştir
                  </Button>
                </div>
              </div>

              <div className="bg-gray-50 px-4 py-3 text-right dark:bg-gray-700 sm:px-6 ">
                <Button className="mx-2" color="secondary" onClick={onCancel}>
                  Vazgeç
                </Button>
                <Button type="submit" onClick={handleSubmit(onSaveProfile)}>
                  Kaydet
                </Button>
              </div>
            </div>
          </form>
        </div>
        <div className="mt-5 sm:mt-0 md:col-span-1">
          <div className="px-4 sm:px-0">
            <h3 className="text-lg font-medium leading-6 text-gray-900 dark:text-white ">Profil</h3>
            <p className="mt-1 text-sm text-gray-600 dark:text-gray-400">Profil Bilgileri Düzenle</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
