import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { yup } from "util/yupLocale";
import Form from "../../Form";
import { prevStep, nextStep } from "features/transactions/slice";
import { updateTransaction } from "features/transactions/services";

const schema = yup.object({
  yeniUnvan: yup.string().required().label("Yeni Ünvan").min(5).meta({
    type: "text",
    className: "sm:w-3/3",
    component: "input",
  }),
  sozlesmeMaddeNo: yup.string().required().label("Sözleşme Madde No").min(1).meta({
    type: "text",
    className: "sm:w-3/3",
    component: "input",
    placeholder: "2",
  }),
  // sozlesmeMaddeBaslik: yup.string().required().label("Sözleşme Madde Başlık").min(3).meta({
  //   type: "text",
  //   className: "sm:w-3/3",
  //   component: "textarea",
  // }),
});

const UnvanDegisikligi = ({}) => {
  const dispatch = useDispatch();

  const onUpdate = async (form) => {
    const deepCopyForm = JSON.parse(JSON.stringify(form));
    // await dispatch(setNewAddress(deepCopyForm));
    deepCopyForm.sozlesmeMaddeBaslik = "Şirketin Unvanı";
    await dispatch(
      updateTransaction({
        data: {
          unvanDegisikligi: deepCopyForm,
        },
      })
    );
    dispatch(nextStep());
  };

  const onPrev = () => {
    dispatch(prevStep());
  };

  const { unvanDegisikligi } = useSelector((state) => state.transactions.selectedTransaction);

  return (
    <Form
      schema={schema}
      onSubmit={onUpdate}
      title="Yeni Ünvan"
      initData={unvanDegisikligi}
      description={"Yeni Ünvanı giriniz."}
      onPrev={onPrev}
    />
  );
};

export default UnvanDegisikligi;
