import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { yup } from "util/yupLocale";
import ArrayForm from "../../ArrayForm";

import { prevStep, nextStep } from "features/transactions/slice";
import { updateTransaction } from "features/transactions/services";
import { updateAuthorities, loadAuthoritiesData, updateCapital, loadCapitalData } from "features/companyData/services";

const sonSermayeDurumuHesapla = (partners, hisseDevri) => {
  // Önceki sermaye durumunu kopyalayalım
  const deepCopyPartners = JSON.parse(JSON.stringify(partners));
  const sonSermayeDurumu = [...deepCopyPartners];

  let birPayinDegeri = 0;

  // Her bir hisse devri işlemini dolaşalım
  for (const devir of hisseDevri) {
    // Devreden ve devralan kisinin indekslerini bulalım
    const devredenIndex = sonSermayeDurumu.findIndex((kisi) => kisi.adSoyad === devir.devreden);
    const devralanIndex = sonSermayeDurumu.findIndex((kisi) => kisi.adSoyad === devir.devralan);
    // Devreden kişinin payını azaltalım ve devralan kişinin payını arttıralım
    sonSermayeDurumu[devredenIndex].toplamPayAdedi = Number(sonSermayeDurumu[devredenIndex].toplamPayAdedi);
    sonSermayeDurumu[devredenIndex].payToplamDeger = Number(sonSermayeDurumu[devredenIndex].payToplamDeger);

    birPayinDegeri = Number(sonSermayeDurumu[devredenIndex].birPayDegeri);

    sonSermayeDurumu[devredenIndex].toplamPayAdedi -= Number(devir.devredilenPay);
    sonSermayeDurumu[devredenIndex].payToplamDeger =
      Number(sonSermayeDurumu[devredenIndex].toplamPayAdedi) * Number(sonSermayeDurumu[devredenIndex].birPayDegeri);

    // devralan eski bir ortak ise
    if (devralanIndex > -1) {
      sonSermayeDurumu[devralanIndex].toplamPayAdedi = Number(sonSermayeDurumu[devralanIndex].toplamPayAdedi);

      sonSermayeDurumu[devralanIndex].toplamPayAdedi += Number(devir.devredilenPay);
      sonSermayeDurumu[devralanIndex].payToplamDeger =
        Number(sonSermayeDurumu[devralanIndex].toplamPayAdedi) * Number(sonSermayeDurumu[devralanIndex].birPayDegeri);
    } else {
      // devralan yeni bir ortak ise
      sonSermayeDurumu.push({
        adSoyad: devir.devralanAdSoyad,
        toplamPayAdedi: devir.devredilenPay,
      });
    }
  }
  const toplamPay = sonSermayeDurumu.reduce((acc, kisi) => acc + Number(kisi.toplamPayAdedi), 0);

  sonSermayeDurumu.map((kisi) => {
    if (!kisi.payToplamDeger) {
      kisi.birPayDegeri = birPayinDegeri;
      kisi.toplamPayAdedi = Number(kisi.toplamPayAdedi);
      kisi.payToplamDeger = kisi.toplamPayAdedi * birPayinDegeri;
    }
    return kisi;
  });

  const filteredSonSermaye = sonSermayeDurumu.filter((kisi) => kisi.toplamPayAdedi > 0);

  return {
    sonSermayeDurumu: filteredSonSermaye,
    toplamPay,
    birPayinDegeri,
  };
};

const HisseDevri = ({}) => {
  const dispatch = useDispatch();
  const companyType = useSelector((state) => state.companies.selectedCompany.companyType);

  const partners = useSelector((state) => state.companyData.partners);
  const { step } = useSelector((state) => state.transactions);
  const isRegistered = step.registered;

  const partnerComboOptions = partners.map((partner) => ({
    name: partner.adSoyad,
    id: partner.tcVkn,
  }));

  const getPersonFromPartners = (name) => {
    const partner = partners.find((partner) => partner.adSoyad === name);
    const deepCopy = JSON.parse(JSON.stringify(partner));
    const newObj = {};
    newObj.devreden = deepCopy.adSoyad;
    newObj.devredenTcVkn = deepCopy.tcVkn;
    newObj.devredenAdres = deepCopy.adres;
    return newObj;
  };

  const getPersonFromPartnersDevralan = (name) => {
    const partner = partners.find((partner) => partner.adSoyad === name);
    const deepCopy = JSON.parse(JSON.stringify(partner));
    const newObj = {};
    newObj.devralanAdSoyad = deepCopy.adSoyad;
    newObj.devralanTcVkn = deepCopy.tcVkn;
    newObj.devralanAdres = deepCopy.adres;
    newObj.devralanUyruk = deepCopy.uyruk;

    return newObj;
  };

  const { transactionTypes } = useSelector((state) => state.transactions.selectedTransaction);
  const schemaObj = {
    devreden: yup.string().required().label("Devreden Ad Soyad").meta({
      type: "text",
      className: "sm:w-2/3",
      component: "combobox",
      options: partnerComboOptions,
      onChange: getPersonFromPartners,
      updateOtherFields: false,
    }),
    
    devredilenPay: yup.string().required().label("Devredilen Toplam Pay Adedi").meta({
      type: "number",
      currency: "Adet",
      className: "sm:w-2/3",
      component: "number",
    }),

    gercekTuzel: yup
      .string()
      .required()
      .label("Devralan Gerçek/Tüzel")
      .meta({
        type: "select",
        className: "sm:w-2/3",
        component: "select",
        defaultValue: "Gerçek",
        options: [
          { value: "Gerçek", label: "Gerçek" },
          { value: "Tüzel", label: "Tüzel" },
        ],
      }),
    // if gercekTuzel === "Tüzel"
    devralanTitle: yup
      .string()
      .label("Devralan Şirket Ünvanı")
      .meta({
        type: "text",
        className: "sm:w-2/3",
        component: "input",
        condition: {
          field: "gercekTuzel",
          value: "Tüzel",
        },
      })
      .when("gercekTuzel", {
        is: "Tüzel",
        then: yup.string().required(),
      }),
    devralanTicaretSicilMudurlugu: yup
      .string()
      .label("Devralan Ticaret Sicil Müdürlüğü")
      .meta({
        type: "text",
        className: "sm:w-2/3",
        component: "input",
        condition: {
          field: "gercekTuzel",
          value: "Tüzel",
        },
      })
      .when("gercekTuzel", {
        is: "Tüzel",
        then: yup.string().required(),
      }),
    devralanTuzelAdres: yup
      .string()
      .label("Devralan Şirket Adresi")
      .meta({
        type: "text",
        className: "sm:w-2/3",
        component: "input",
        condition: {
          field: "gercekTuzel",
          value: "Tüzel",
        },
      })
      .when("gercekTuzel", {
        is: "Tüzel",
        then: yup.string().required(),
      }),
    devralanTicaretSicilNo: yup
      .string()
      .label("Devralan Ticaret Sicil No")
      .meta({
        type: "text",
        className: "sm:w-2/3",
        component: "input",
        condition: {
          field: "gercekTuzel",
          value: "Tüzel",
        },
      })
      .when("gercekTuzel", {
        is: "Tüzel",
        then: yup.string().required(),
      }),

    devralanAdSoyad: yup
      .string()
      .required()
      .label("Devralan Ad Soyad")
      .meta({
        type: "text",
        className: "sm:w-2/3",
        component: "combobox",
        options: partnerComboOptions,
        onChange: getPersonFromPartnersDevralan,
      })
      .when("gercekTuzel", {
        is: "Gerçek",
        then: yup.string().required(),
      }),
    devralanUyruk: yup.string().required().label("Devralan Uyruk").meta({
      type: "text",
      className: "sm:w-2/3",
      component: "input",
      defaultValue: "T.C.",
    }),
    devralanTcVkn: yup.string().required().label("Devralan TC Kimlik No").min(11).max(11).meta({
      type: "text",
      className: "sm:w-2/3",
      component: "input",
    }),

    devralanAdres: yup.string().required().label("Devralan Adres").meta({
      type: "text",
      className: "sm:w-2/3",
      component: "input",
    }),
    sozlesmeTuru: yup
      .string()
      .required()
      .label("Sözleşme Türü")
      .meta({
        type: "select",
        className: "sm:w-2/3",
        component: "select",
        options: [
          { value: "Noterde Hazırlanan", label: "Noterde Hazırlanan" },
          { value: "Ortaklar Arasında Adi Sözleşme", label: "Ortaklar Arasında Adi Sözleşme" },
        ],
      }),

    noter: yup
      .string()
      .label("Sözleşmenin Hazırlandığı Noter")
      .meta({
        type: "text",
        className: "sm:w-2/3",
        component: "input",
        placeholder: "Örnek: Kadiköy 30.Noterliği",
      })
      .when("sozlesmeTuru", {
        is: "Noterde Hazırlanan",
        then: yup.string().required(),
      }),
    noterTarih: yup
      .string()
      .required()
      .label("Sözleşme Tarihi")
      .meta({
        type: "date",
        className: "sm:w-2/3",
        component: "input",
        defaultValue: new Date().toISOString().slice(0, 10),
      }),
    noterNo: yup.string().required().label("Sözleşme No").meta({
      type: "text",
      className: "sm:w-2/3",
      component: "input",
    }),
  };

  // const schema = "hisseDevri": yup.array().of(yup.object().shape(schemaObj))
  const schema = yup.object().shape({
    hisseDevri: yup.array().of(yup.object().shape(schemaObj)),
  });

  const onUpdate = async (form) => {
    const hisseDevriVeri = form.hisseDevri;

    const sonSermaye = sonSermayeDurumuHesapla(partners, hisseDevriVeri);

    form.sonSermayeDurumu = sonSermaye.sonSermayeDurumu;
    form.toplamPay = sonSermaye.toplamPay;
    form.birPayinDegeri = sonSermaye.birPayinDegeri;

    form.hisseDevri.map((devir) => {
      const find = partners.find((partner) => partner.adSoyad === devir.devralanAdSoyad);
      if (find?.adSoyad) devir.eskiOrtak = "Şirket Ortaklarından";
      else devir.eskiOrtak = "Şirket Dışından";
    });
    await dispatch(
      updateTransaction({
        data: {
          hisseDevri: form,
        },
      })
    );
    dispatch(nextStep());
  };

  const onPrev = () => {
    dispatch(prevStep());
  };

  useEffect(() => {
    dispatch(loadAuthoritiesData());
    dispatch(loadCapitalData());
  }, []);

  const hisseDevriVeri = useSelector((state) => state.transactions.selectedTransaction?.hisseDevri?.hisseDevri);

  const defaultItem = {
    adSoyad: "",
    tcVkn: "",
  };

  return (
    <ArrayForm
      schema={schema}
      onSubmit={onUpdate}
      title="Yetkili Bilgileri"
      initData={hisseDevriVeri}
      itemLabel="hisseDevri"
      defaultItem={defaultItem}
      description={"Tescilli Yetkili Bilgilerini Giriniz"}
      onPrev={onPrev}
      isRegistered={isRegistered}
    />
  );
};

export default HisseDevri;
