import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { TrashIcon } from "@heroicons/react/20/solid";

import { router } from "features/navigation/Navigator";
import { removeTransactionFromDb, loadTransactions } from "features/transactions/services";
import Modal from "features/ui/Modal";

const DeleteTransaction = ({ modalButtonComponent, id, status ,navigateTo }) => {
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);

  const handleRemoveTransaction = async () => {
    await dispatch(removeTransactionFromDb({ id }));
    router.navigate(navigateTo);
    dispatch(
      loadTransactions({
        status,
      })
    );
    setShowModal(false);
  };

  return (
    <>
      <Modal
        open={showModal}
        setOpen={setShowModal}
        buttonAsLink={true}
        hiddenCloseButton={true}
        modalButtonComponent={
          modalButtonComponent || <TrashIcon className="h-5 w-5 flex-shrink-0 text-red-400" aria-hidden="true" />
        }
        modalClass="relative mt-12 inline-block transform overflow-hidden rounded-lg bg-white  p-2 text-left align-bottom shadow-xl transition-all sm:mt-24 sm:w-7/12 lg:w-4/12 sm:align-top print:mt-1 w-full"
      >
        <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
          <div className="sm:flex sm:items-start">
            <div className="mt-3 w-full text-center sm:mt-0 sm:ml-4 sm:text-left">
              <h3 className="text-lg font-medium leading-6 text-gray-900" id="modal-headline">
                Silme İşlemi
              </h3>
              <div className="mt-2">
                <p className="text-sm text-gray-500">Bu işlemi gerçekleştirmek istediğinizden emin misiniz?</p>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
          <button
            onClick={handleRemoveTransaction}
            type="button"
            className="inline-flex w-full justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none  sm:ml-3 sm:w-auto sm:text-sm"
          >
            Sil
          </button>
          <button
            onClick={() => setShowModal(false)}
            type="button"
            className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none  sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
          >
            İptal
          </button>
        </div>
      </Modal>
    </>
  );
};

export default DeleteTransaction;
