import { createSlice, createEntityAdapter } from '@reduxjs/toolkit';


// import notify from 'features/ui/notify';
import { getStatistics } from 'features/admin/services';



const initialState = {
    // statuses: 'idle' | 'loading' | 'succeeded' | 'failed'
    status: 'idle',
    error: null,
    userCount: 0,
    companyCount: 0,
    statisticsData: {},
}

const statisticsSlice = createSlice({
    name: 'statistics',
    initialState,
    reducers: {
        setUsersCount(state, action) {
            state.userCount = action.payload;
        },
        setCompaniesCount(state, action) {
            state.companyCount = action.payload;
        },
        setTransactionsCount(state, action) {
            state.transactionCount = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getStatistics.pending, (state, action) => {
                state.status = 'loading';
            })
            .addCase(getStatistics.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.userCount = action.payload.userCount;
                state.companyCount = action.payload.companyCount;
                state.statisticsData = action.payload.statisticsData;
            })
            .addCase(getStatistics.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
    }
})

export const { setUsersCount, setCompaniesCount, setTransactionsCount } = statisticsSlice.actions;

export default statisticsSlice.reducer;
