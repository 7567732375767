import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { yup } from "util/yupLocale";
import Form from "features/transactions/Form";

import { prevStep, nextStep } from "features/transactions/slice";
import { updateTransaction } from "features/transactions/services";

const schema = yup.object({
  kararNo: yup.string().required().label("Karar No").min(1).max(10).meta({
    type: "text",
    className: "sm:w-2/3",
    component: "input",
    placeholder: "2023-01",
  }),
  kararTarihi: yup
    .string()
    .required()
    .label("Karar Tarihi")
    .meta({
      type: "date",
      className: "sm:w-2/3",
      component: "input",
      defaultValue: new Date().toISOString().slice(0, 10),
    }),
});

const Karar = () => {
  const dispatch = useDispatch();

  const selectedCompany = useSelector((state) => state.companies.selectedCompany);
  const companyType = selectedCompany?.companyType;

  // const { ortaklarKarariBilgileri, transactionId } = useSelector((state) => state.adresDegisikligi);

  const { ortaklarKarariBilgileri, transactionId } = useSelector((state) => state.transactions.selectedTransaction);

  const onUpdate = async (form) => {
    const deepCopyForm = JSON.parse(JSON.stringify(form));
    // await dispatch(setOrtaklarKarariBilgileri(deepCopyForm));
    await dispatch(
      updateTransaction({
        data: {
          ortaklarKarariBilgileri: deepCopyForm,
        },
      })
    );
    dispatch(nextStep());
  };

  const onPrev = () => {
    dispatch(prevStep());
  };

  return (
    <Form
      schema={schema}
      onSubmit={onUpdate}
      onPrev={onPrev}
      title={companyType === "Anonim" ? "Yönetim Kurulu Kararı" : "Ortaklar Kurulu Karar Bilgileri"}
      description={
        companyType === "Anonim" ? "Yönetim Kurulu Kararı bilgilerini giriniz" : "Ortaklar Kurulu Karar Bilgilerini giriniz."
      }
      initData={ortaklarKarariBilgileri}
    />
  );
};

export default Karar;
