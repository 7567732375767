import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { yup } from "util/yupLocale";
import Form from "../../Form";
import { prevStep, nextStep } from "features/transactions/slice";
import { updateTransaction } from "features/transactions/services";

const TasfiyeBaslangic = ({}) => {
  const dispatch = useDispatch();

  const partners = useSelector((state) => state.companyData.partners);
  const authorities = useSelector((state) => state.companyData.authorities);

  const allPersons = JSON.parse(JSON.stringify([...partners, ...authorities]));
  // filter all person with unique tcVkn
  const uniquePersons = allPersons.filter((person, index, self) => index === self.findIndex((p) => p.tcVkn === person.tcVkn));

  const partnerComboOptions = uniquePersons.map((partner) => ({
    name: partner.adSoyad,
    id: partner.tcVkn,
  }));

  const getPersonFromPartners = (name) => {
    const partner = uniquePersons.find((partner) => partner.adSoyad === name);
    const newObj = {};
    newObj.tasfiyeMemuruTcVkn = partner.tcVkn || "";
    newObj.tasfiyeMemuruCepTelefonu = partner.cepTelefonu || "";
    newObj.tasfiyeMemuruAddress = partner.address || "";
    newObj.tasfiyeMemuruAdSoyad = partner.adSoyad || "";
    return newObj;
  };

  const schema = yup.object({
    tasfiyeBaslangici: yup.date().required().label("Tasfiye Başlangıç Tarihi").meta({
      type: "date",
      className: "sm:w-2/3",
      component: "input",
    }),
    tasfiyeMemuruAdSoyad: yup.string().required().label("Ad Soyad").meta({
      type: "text",
      className: "sm:w-2/3",
      component: "combobox",
      options: partnerComboOptions,
      onChange: getPersonFromPartners,
    }),

    tasfiyeMemuruTcVkn: yup.string().required().label("TC Kimlik No").min(11).max(11).meta({
      type: "text",
      className: "sm:w-2/3",
      component: "input",
    }),
    tasfiyeMemuruCepTelefonu: yup.string().required().label("Cep Telefonu").min(10).max(11).meta({
      type: "text",
      className: "sm:w-2/3",
      component: "input",
    }),

    tasfiyeMemuruAddress: yup.string().required().label("Tasfiye Memuru Adres").min(5).meta({
      type: "text",
      className: "sm:w-3/3",
      component: "textarea",
    }),
  });

  const onUpdate = async (form) => {
    const deepCopyForm = JSON.parse(JSON.stringify(form));
    // await dispatch(setNewAddress(deepCopyForm));
    await dispatch(
      updateTransaction({
        data: {
          tasfiyeGiris: deepCopyForm,
        },
      })
    );
    dispatch(nextStep());
  };

  const onPrev = () => {
    dispatch(prevStep());
  };

  const { tasfiyeGiris } = useSelector((state) => state.transactions.selectedTransaction);

  return (
    <Form
      schema={schema}
      onSubmit={onUpdate}
      title="Yeni Adres"
      initData={tasfiyeGiris}
      description={"Yeni Adresi giriniz."}
      onPrev={onPrev}
    />
  );
};

export default TasfiyeBaslangic;
