import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { loadProducts } from "features/shop/slice";
import { ExclamationTriangleIcon } from "@heroicons/react/20/solid";

import List from "features/shop/services/List";

import { useNavigate } from "react-router-dom";

import ROUTES from "features/navigation/routes";

const Services = () => {
  const dispatch = useDispatch();

  const { subscription } = useSelector((state) => state.auth);

  const isOwner = subscription?.isOwner || false;

  const { products } = useSelector((state) => state.shop);

  useEffect(() => {
    dispatch(loadProducts());
  }, []);

  if (!products?.length) return <div>Yükleniyor...</div>;

  return (
    <div className=" mx-auto max-w-4xl py-10">
      {/* {!isOwner && <Alert />} */}

      {/* triger dispatch(loadProducts()) button */}
      {/* <h3 className="text-center text-lg font-medium leading-6 text-gray-600">Kullanım sürenizi uzatın</h3> */}
      <List isOwner={isOwner} services={products.filter((x) => x.category === "subscription")} />

      <h3 className="text-center text-lg font-medium leading-6 text-gray-600">Kontör alın</h3>
      <List isOwner={isOwner} services={products.filter((x) => x.category === "kontor")} />
    </div>
  );
};

export default Services;

const Alert = () => {
  const navigate = useNavigate();

  const navigateToUsers = () => {
    navigate(ROUTES.USERS.path);
  };

  const navigateToHome = () => {
    navigate(ROUTES.HOME.path);
  };

  return (
    <div className="rounded-md bg-yellow-50 p-4">
      <div className="flex">
        <div className="flex-shrink-0">
          <ExclamationTriangleIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
        </div>
        <div className="ml-3">
          <h3 className="text-sm font-medium text-yellow-800">Hesap başka bir kullanıcı tarafından oluşturulmuş.</h3>
          <div className="mt-2 text-sm text-yellow-700">
            <p>Yanlızca hesap sahibi ödeme yapabilir.</p>
            <p>Aboneliği uzatması veya kontör alması için hesap sahibine ulaşın.</p>
          </div>
          <div className="mt-4">
            <div className="-mx-2 -my-1.5 flex">
              <button
                type="button"
                onClick={navigateToUsers}
                className="rounded-md bg-yellow-50 px-2 py-1.5 text-sm font-medium text-yellow-800 hover:bg-yellow-100 focus:outline-none focus:ring-2 focus:ring-yellow-600 focus:ring-offset-2 focus:ring-offset-yellow-50"
              >
                Kullanıcıları Görüntüle
              </button>
              <button
                type="button"
                onClick={navigateToHome}
                className="ml-3 rounded-md bg-yellow-50 px-2 py-1.5 text-sm font-medium text-yellow-800 hover:bg-yellow-100 focus:outline-none focus:ring-2 focus:ring-yellow-600 focus:ring-offset-2 focus:ring-offset-yellow-50"
              >
                Ana Sayfaya Dön
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
