import { ExclamationCircleIcon } from "@heroicons/react/20/solid";

const Alert = (props) => {
  const { children,color="yellow-50" } = props;
  
  return (
    <div className={`bg-${color} border-l-4 border-yellow-400 p-4 z-30`}>
      <div className="flex">
        <div className="flex-shrink-0">
          <ExclamationCircleIcon
            className="h-5 w-5 text-yellow-400"
            aria-hidden="true"
          />
        </div>
        <div className="ml-3">
          <span className="text-sm text-yellow-700">{children}</span>
        </div>
      </div>
    </div>
  );
};

export default Alert;