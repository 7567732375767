import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { getStatistics } from "features/admin/services";

const Statistics = ({}) => {
  const dispatch = useDispatch();
  const { userCount, companyCount, statisticsData } = useSelector((state) => state.statistics);

  useEffect(() => {
    dispatch(getStatistics());
  }, []);

  return (
    <>
      <div>Statistics</div>
      <div>userCount: {userCount}</div>

      <div>companyCount: {companyCount}</div>
      {/* <div>transactionCount: {transactionCount}</div> */}
      {/* 
        example of how to use statisticsData
        {
    "totalTransactions": 92
}
      */}
      {/* render all key on statisticsData object */}
      {Object.keys(statisticsData).map((key) => (
        <div key={key}>
          {key}: {statisticsData[key]}
        </div>
      ))}
    </>
  );
};

export default Statistics;
