import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setBag } from "features/shop/slice";
import { useNavigate } from "react-router-dom";

import ROUTES from "features/navigation/routes";

const ServiceList = ({ services, isOwner }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const sortedServicesByPrice = services.sort((a, b) => a.price - b.price);

  const handleSetServiceToBag = (service) => {
    dispatch(setBag([service]));
    navigate(ROUTES.BILLINGINFO.path);
  };

  if (!services?.length) return null;

  return (
    <ul className="grid grid-cols-1 gap-6 py-3 sm:grid-cols-2 lg:grid-cols-3">
      {sortedServicesByPrice.map((service) => (
        <li key={service.email} className="col-span-1 divide-y divide-gray-200 rounded-lg bg-white shadow">
          <div className="flex w-full items-center justify-between space-x-6 p-6">
            <div className="flex-1 truncate">
              <div className="flex items-center space-x-3">
                <h3 className="truncate text-sm font-medium text-gray-900">{service.name}</h3>
              </div>
              <p className="mt-1 truncate text-sm text-gray-500">{service.description}</p>
            </div>
          </div>
          <div>
            <div className="-mt-px flex divide-x divide-gray-200">
              <div className="flex w-0 flex-1">
                <p className="relative -mr-px inline-flex w-0 flex-1 items-center justify-center rounded-bl-lg border border-transparent py-4 text-sm font-medium text-gray-700 hover:text-gray-500">
                  {/* <EnvelopeIcon className="h-5 w-5 text-gray-400" aria-hidden="true" /> */}
                  <span className="ml-3">{service.price} TL</span>
                </p>
              </div>
              {isOwner && (
                <div
                  className="-ml-px flex w-0 flex-1 cursor-pointer"
                  onClick={() => {
                    handleSetServiceToBag(service);
                  }}
                >
                  <p className="relative inline-flex w-0 flex-1 items-center justify-center rounded-br-lg border border-transparent py-4 text-sm font-medium text-gray-700 hover:bg-primary  hover:text-white">
                    <span className="ml-3">Satın Al</span>
                  </p>
                </div>
              )}
            </div>
          </div>
        </li>
      ))}
    </ul>
  );
};

export default ServiceList;
