import { Fragment } from "react";
import { Dialog, Menu, Transition } from "@headlessui/react";
import { useSelector, useDispatch } from "react-redux";
import { XMarkIcon } from '@heroicons/react/24/outline';

import ProfileMenu from "features/layout/navProfileMenu/ProfileMenu";
import { setSidebarOpen, setWideMode } from "features/ui/slice";
import LogoRender from "./LogoRender";
import SideBarNav from "features/layout/navSideBar/SideBarNav";

const SideBar = () => {
  const dispatch = useDispatch();

  const { darkMode, sidebarOpen, wideMode } = useSelector((state) => state.ui);

  // let sidebarClass = "fixed inset-0 z-40 flex lg:hidden";
  let sidebarClass = "fixed inset-0 z-40 flex";
  if (darkMode) sidebarClass = `${sidebarClass} dark`;

  const handleSetSidebarOpen = (val) => dispatch(setSidebarOpen(val));
  const handleSetWideMode = (val) => dispatch(setWideMode(val));

  return (
    <>
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog className={sidebarClass} as="div" onClose={handleSetSidebarOpen}>
          {/* mobil sidebar layout */}
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
          </Transition.Child>
          <Transition.Child
            as={Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
          >
            <div className="relative flex w-52 flex-col bg-gray-100 pt-5 pb-4 dark:bg-gray-900">
              <Transition.Child
                as={Fragment}
                enter="ease-in-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in-out duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                {/* close button start */}
                <div className="absolute top-0 right-0 -mr-12 pt-2">
                  <button
                    type="button"
                    className="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                    onClick={() => handleSetSidebarOpen(false)}
                  >
                    <span className="sr-only">Close sidebar</span>
                    <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
                  </button>
                </div>
                {/* close button end */}
              </Transition.Child>

              <LogoRender />

              <SideBarNav wideMode={wideMode} setWideMode={handleSetWideMode} />
            </div>
          </Transition.Child>
          <div className="w-14 flex-shrink-0" aria-hidden="true"></div>
        </Dialog>
      </Transition.Root>

      {/* large sidebar start */}
      {/* if you chance width sidebar also chance main - left padding */}
      <div
        className={
          wideMode
            ? "hidden"
            : "hidden dark:bg-gray-900 lg:fixed lg:inset-y-0 lg:flex lg:w-52 lg:flex-col lg:border-r lg:border-gray-200 lg:bg-gray-100 lg:pt-5 lg:pb-4 dark:lg:border-gray-600"
        }
      >
        <LogoRender />

        <div className="mt-6 flex h-0 flex-1 flex-col overflow-y-auto">
          <Menu as="div" className="relative inline-block px-3 text-left">
            <ProfileMenu />
          </Menu>
          <SideBarNav wideMode={wideMode} setWideMode={handleSetWideMode} />
        </div>
      </div>
      {/* large sidebar end */}
    </>
  );
};

export default SideBar;
