import { useState, useEffect } from "react";
import { RadioGroup } from "@headlessui/react";

const settings = [
  { id: "owner", name: "Hesap Sahibi", description: "Hesabı oluşturan kullanıcı. Tüm işlemlere yetkili.", disabled: true },
  { id: "admin", name: "Yetkili", description: "Yetkili kullanıcılar tüm işlemlere yetkilidir." },
  {
    id: "user",
    name: "Kullanıcı",
    description: "Kullanıcıların hangi modülleri kullanabileceğni belirleyebilirsiniz. Yeni kullanıcı ekleyip silemez.",
  },
  {
    id: "limitedUser",
    name: "Kısıtlı Kullanıcı",
    description:
      "Kısıtlı Kullanıcılar hem Modül bazında hemde hesap bazında kısıtlanabilir. Sadece belli müşteri veya satıcılarınızın hesaplarını görmesine izin verebilirsiniz. Hesap bazlı yetkileri belirlemek için cari hesap ekranlarına gidiniz.",
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Example({ callback, initValue }) {
  const init = settings.find((s) => s.id === initValue) || settings[1];
  const [selected, setSelected] = useState(init);

  useEffect(() => {
    callback && callback(selected.id);
  }, [selected]);

  return (
    <RadioGroup value={selected} onChange={setSelected}>
      <RadioGroup.Label className="sr-only"> Privacy setting </RadioGroup.Label>
      <div className="-space-y-px rounded-md bg-white">
        {settings.map((setting, settingIdx) => (
          <RadioGroup.Option
            key={setting.name}
            disabled={setting.disabled}
            value={setting}
            className={({ checked }) =>
              classNames(
                settingIdx === 0 ? "rounded-tl-md rounded-tr-md" : "",
                settingIdx === settings.length - 1 ? "rounded-bl-md rounded-br-md" : "",
                checked ? "z-10 border-radical-red-200 bg-radical-red-50" : "border-gray-200",
                "relative flex cursor-pointer border p-4 focus:outline-none",
                setting.disabled && "cursor-not-allowed opacity-50"
              )
            }
          >
            {({ active, checked }) => (
              <>
                <span
                  className={classNames(
                    checked ? "border-transparent bg-radical-red-600" : "border-gray-300 bg-white",
                    active ? "ring-2 ring-radical-red-500 ring-offset-2" : "",
                    "mt-0.5 flex h-4 w-4 shrink-0 cursor-pointer items-center justify-center rounded-full border"
                  )}
                  aria-hidden="true"
                >
                  <span className="h-1.5 w-1.5 rounded-full bg-white" />
                </span>
                <span className="ml-3 flex flex-col">
                  <RadioGroup.Label
                    as="span"
                    className={classNames(checked ? "text-radical-red-900" : "text-gray-900", "block text-sm font-medium")}
                  >
                    {setting.name}
                  </RadioGroup.Label>
                  <RadioGroup.Description
                    as="span"
                    className={classNames(checked ? "text-radical-red-700" : "text-gray-500", "block text-sm")}
                  >
                    {setting.description}
                  </RadioGroup.Description>
                </span>
              </>
            )}
          </RadioGroup.Option>
        ))}
      </div>
    </RadioGroup>
  );
}
