import { createAsyncThunk } from '@reduxjs/toolkit';
import { httpsCallable } from 'firebase/functions';
import { db, auth, storage, functions, } from "features/firebase";
import {
    doc,
    getDoc,
    getDocs,
    updateDoc,
    query, where, deleteDoc,
    collection, setDoc
} from "firebase/firestore";
import { get } from 'react-hook-form';
import { loadSubsData } from "features/auth/slice";

import notify from 'features/ui/notify';


export const createNewTransaction = createAsyncThunk("transactions/createNewTransaction", async (payload, { dispatch, getState }) => {
    const { transactionTypes, selectedTransactions, creditToBeSpend } = payload;
    const { selectedCompany } = getState().companies;



    // set transaction object to firestore and get id
    const transactionRef = doc(collection(db, `companies/${selectedCompany.id}/transactions`));
    const transactionId = transactionRef.id;

    const transactionObject = {
        transactionTypes,
        id: transactionId,
        status: "draft",
        createdAt: new Date(),
        selectedTransactions,
        creditToBeSpend: creditToBeSpend || 0,
    }

    const transactionDocRef = doc(db, `companies/${selectedCompany.id}/transactions/${transactionId}`)
    await setDoc(transactionDocRef, transactionObject);

    return transactionId;


});

export const updateTransaction = createAsyncThunk("transactions/updateTransaction", async (payload, { dispatch, getState }) => {

    const { data } = payload;
    // let { transactionId } = payload;

    let { transactionTypes, id, selectedTransactions, creditToBeSpend } = getState().transactions.selectedTransaction
    const { stepIndex } = getState().transactions;


    if (!id) {
        const res = await dispatch(createNewTransaction({ transactionTypes, selectedTransactions, creditToBeSpend }));
        id = res.payload;
    }

    const { selectedCompany } = getState().companies;

    // set transaction object to firestore and get id
    const transactionRef = await doc(db, `companies/${selectedCompany.id}/transactions/${id}`);

    const snap = await getDoc(transactionRef);
    const isExist = snap.exists();

    if (isExist) {
        await updateDoc(transactionRef, { ...data, stepIndex });
    } else {
        await setDoc(transactionRef, { ...data, stepIndex });
    }

    return { ...data, id };

});

export const loadTransactions = createAsyncThunk("transactions/loadTransactions", async (payload, { dispatch, getState }) => {

    const { selectedCompany } = getState().companies;
    const { status } = payload;

    const transactionsRef = collection(db, `companies/${selectedCompany.id}/transactions`);

    const data = []

    // if status is not defined, get all transactions
    if (!status) {
        const transactionsSnap = await getDocs(transactionsRef);
        transactionsSnap.forEach((doc) => {
            data.push(doc.data());
        });

    } else {
        const q = query(transactionsRef, where("status", "==", status));
        const transactionsSnap = await getDocs(q);
        transactionsSnap.forEach((doc) => {
            data.push(doc.data());
        });
    }

    return data;

}
);


export const createDocument = createAsyncThunk("transactions/createDocumentsAdresDegisikligi", async (payload, { dispatch, getState }) => {
    // const companyData = getState().companyData;
    // const adresDegisikligi = getState().adresDegisikligi;

    // const { ortaklarKarariBilgileri, newAddress } = adresDegisikligi;
    // const { partners, authorities, sgkInfo } = companyData;
    const companyId = getState().companies.selectedCompany.id;
    const id = getState().transactions.selectedTransaction.id;

    const transactionObject = {
        id,
        companyId,
        createdAt: new Date(),
    }

    try {
        // call cloud function name "createDocuments"
        const createDocuments = httpsCallable(functions, "createDocuments");
        const result = await createDocuments(transactionObject);
        // wait 2 seconds
        await new Promise((resolve) => setTimeout(resolve, 2000));
        // update credit
        await dispatch(loadSubsData({ companyID: companyId }));
        return result.data;
    } catch (error) {
        console.log(error);
        notify(error.message, "error");
        await dispatch(loadSubsData({ companyID: companyId }));
        return error;
    }
});

export const loadTransactionFiles = createAsyncThunk("transactions/loadTransactionFiles", async (payload, { dispatch, getState }) => {
    const { id } = payload;
    const companyId = getState().companies.selectedCompany.id

    const transactionFilesRef = collection(db, `companies/${companyId}/transactions/${id}/files`);

    const data = []

    const transactionsFilesSnap = await getDocs(transactionFilesRef);
    transactionsFilesSnap.forEach((doc) => {
        data.push({
            id: doc.id,
            ...doc.data()
        });
    }
    );

    return data;
}
);

export const removeTransactionFromDb = createAsyncThunk("transactions/removeTransaction", async (payload, { dispatch, getState }) => {
    const { id } = payload;
    const { selectedCompany } = getState().companies;

    const transactionRef = doc(db, `companies/${selectedCompany.id}/transactions/${id}`);
    await deleteDoc(transactionRef);

    return id;
});

export const copyTransaction = createAsyncThunk("transactions/copyTransaction", async (payload, { dispatch, getState }) => {
    const { id } = payload;
    const { selectedCompany } = getState().companies;

    const copyTransaction = httpsCallable(functions, "copyTransaction");
    const result = await copyTransaction({ id, companyId: selectedCompany.id });
    return result.data;
});