import { useSelector, useDispatch } from "react-redux";

import Toggle from "features/ui/Toggle";
import { toggleDarkMode } from "features/ui/slice";

const DarkModeSwitch = (props) => {
  const { label } = props;
  const dispatch = useDispatch();
  const darkMode = useSelector((state) => state.ui.darkMode);

  const onChangeToggle = () => {
    dispatch(toggleDarkMode());
  };

  return (
    <>
      <Toggle label={label} enabled={darkMode} setEnabled={onChangeToggle} />
    </>
  );
};

export default DarkModeSwitch;
