import { getDoc, getFirestore, doc, updateDoc } from "firebase/firestore";
import { getStorage, ref, getDownloadURL } from "firebase/storage";
const storage = getStorage();
const db = getFirestore();



export const downloadFilesPDF = async ({ companyID, transactionID, fileId, download = true }) => {
    const fileDataRef = doc(db, `companies/${companyID}/transactions/${transactionID}/files/${fileId}`);

    const dataSnap = await getDoc(fileDataRef);
    const fileData = dataSnap.data();
    // if fileData object is empty return empty object
    if (!fileData || Object.keys(fileData).length === 0) return [];

    let url = fileData.fileUrl
    if (!url || url.startsWith("https://storage.googleapis")) {
        const storageRef = ref(storage);
        let pathName = fileData.meta.fullPath;
        const fileRef = ref(storageRef, pathName);
        url = await getDownloadURL(fileRef);
        // update fileData on firestore with new url
        await updateDoc(fileDataRef, { fileUrl: url });
    }

    // change file name before download 
    const link = document.createElement('a');
    link.href = url
    link.setAttribute('download', `${fileId}.pdf`);
    // set fileName  "muavinMizan.xlsx"
    document.body.appendChild(link);
    link.click();
    link.remove();

}

export const downloadFilesPDFDoc = async ({ fileData, companyID, transactionID, fileId, download = true }) => {

    // if fileData object is empty return empty object
    if (!fileData || Object.keys(fileData).length === 0) return [];

    let url = fileData.fileUrl
    if (!url || url.startsWith("https://storage.googleapis")) {
        const storageRef = ref(storage);
        let pathName = fileData.meta.fullPath;
        const fileRef = ref(storageRef, pathName);
        url = await getDownloadURL(fileRef);
        // update fileData on firestore with new url
        const fileDataRef = doc(db, `companies/${companyID}/transactions/${transactionID}/files/${fileId}`);
        await updateDoc(fileDataRef, { fileUrl: url });
    }

    // change file name before download 
    const link = document.createElement('a');
    link.href = url
    // new window open
    link.setAttribute('target', '_blank');
    link.setAttribute('download', `${fileId}.pdf`);
    // set fileName  "muavinMizan.xlsx"
    document.body.appendChild(link);
    link.click();
    link.remove();

}