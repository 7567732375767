import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { yup } from "util/yupLocale";
import ArrayForm from "../../ArrayForm";

import { prevStep, nextStep } from "features/transactions/slice";
import { updateTransaction } from "features/transactions/services";
import { loadAuthoritiesData, loadCapitalData } from "features/companyData/services";
import { loadCompanyDatas } from "features/companyData/services";

import numberFormat from "functions/numberFormat";

const SerArtSermayeOdemeleri = ({}) => {
  const dispatch = useDispatch();

  const { step } = useSelector((state) => state.transactions);
  const isRegistered = step.registered;

  const schemaObj = {
    tutar: yup.string().required().label("Tutar").meta({
      type: "text",
      className: "sm:w-2/3",
      component: "number",
    }),
    yevmiyeTarihi: yup.string().required().label("Yevmiye Tarihi").meta({
      type: "date",
      className: "sm:w-2/3",
      component: "input",
    }),
    yevmiyeNo: yup.string().required().label("Yevmiye No").meta({
      type: "text",
      className: "sm:w-2/3",
      component: "input",
    }),
  };

  // const schema = "mudurAtama": yup.array().of(yup.object().shape(schemaObj))
  const schema = yup.object().shape({
    serArtSermayeOdemeleri: yup.array().of(yup.object().shape(schemaObj)),
  });

  const onUpdate = async (form) => {
    // form.toplamSermayeArttirimi = form.serArtGecmisYilKar.reduce((acc, item) => {
    //   return acc + Number(item.tutar);
    // }, 0);

    // kayitlarin ait oldugu defterler
    form.defterler = form.serArtSermayeOdemeleri.map((item) => {
      return item.yevmiyeTarihi.split("-").reverse().join("").slice(4, 8);
    });

    // unique defterler
    form.defterler = [...new Set(form.defterler)];

    await dispatch(
      updateTransaction({
        data: {
          serArtSermayeOdemeleri: form,
        },
      })
    );
    dispatch(nextStep());
  };

  const onPrev = () => {
    dispatch(prevStep());
  };

  useEffect(() => {
    dispatch(loadAuthoritiesData());
    dispatch(loadCapitalData());
    dispatch(loadCompanyDatas({ documentName: "capital" }));
  }, []);

  const serArtSermayeOdemeleri = useSelector(
    (state) => state.transactions.selectedTransaction?.serArtSermayeOdemeleri?.serArtSermayeOdemeleri
  );

  const defaultItem = {
    adSoyad: "",
    tcVkn: "",
  };
  const capital = useSelector((state) => state.companyData.capital);

  const totalCapital = capital?.totalCapital || 0;
  return (
    <div className="mt-10 md:col-span-2 md:mt-0">
      <div className="flex flex-col items-center justify-center">
        <div
          className="text-base text-gray-600
    "
        >
          Toplam Sermaye: {numberFormat(totalCapital, 2, true)} TL
        </div>
      </div>
      <ArrayForm
        schema={schema}
        onSubmit={onUpdate}
        initData={serArtSermayeOdemeleri}
        itemLabel="serArtSermayeOdemeleri"
        defaultItem={defaultItem}
        title="Sermaye Ödeme Kayıtları"
        description={"Sermaye ödemelerinin yer aldığı yevmiye kayıtlarını ve tutarlarını ayrı ayrı giriniz."}
        onPrev={onPrev}
        isRegistered={isRegistered}
        totalFields={[{ label: "Sermaye ödemeleri toplamı", fieldName: "tutar" }]}
      />
    </div>
  );
};

export default SerArtSermayeOdemeleri;
