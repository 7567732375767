import { createSlice, createEntityAdapter } from '@reduxjs/toolkit';


// import notify from 'features/ui/notify';
import { loadUser, loadUsers } from 'features/admin/services';

const adminUserAdapter = createEntityAdapter()

const initialState = adminUserAdapter.getInitialState({
    // statuses: 'idle' | 'loading' | 'succeeded' | 'failed'
    status: 'idle',
    error: null,
    selectedUser: null,
    searchParam: {
        status: "pending",
    },
    searchQuery: "",
    latVisible: null,
})

export const adminUserSelector = adminUserAdapter.getSelectors(state => state.adminUsers)

export const filterdedAdminUsers = (state) => {
    const searchQuery = state.adminUsers.searchQuery;

    const filteredUsers = adminUserSelector.selectAll(state).filter(user => {
        const name = user.displayName.toLowerCase("tr-TR")
        const email = user.email.toLowerCase("tr-TR")
        const phone = user.phoneNumber.toLowerCase("tr-TR")
        const searchQueryLower = searchQuery.toLowerCase("tr-TR")

        if (name.includes(searchQueryLower)) {
            return true
        }
        if (email.includes(searchQueryLower)) {
            return true
        }
        if (phone.includes(searchQueryLower)) {
            return true
        }

        return false
    })
    return filteredUsers
}


const adminusersSlice = createSlice({
    name: 'adminUsers',
    initialState,
    reducers: {
        addAdminuser: adminUserAdapter.addOne,
        removeAdminuser: adminUserAdapter.removeOne,
        updateAdminuser: adminUserAdapter.updateOne,
        setadminusers: adminUserAdapter.setAll,
        setadminusersStatus: (state, action) => {
            state.status = action.payload
        },
        setSearchParam: (state, action) => {
            state.searchParam = action.payload
        },
        setselectedUser: (state, action) => {
            state.selectedUser = action.payload
        },
        removeAllusers: (state, action) => {
            adminUserAdapter.removeAll(state)
        },
        setSearchQuery: (state, action) => {
            state.searchQuery = action.payload
        },
        setLastVisible: (state, action) => {
            state.lastVisible = action.payload
        }
    },
    extraReducers: (builder) => {
        builder.addCase(loadUsers.pending, (state, action) => {
            state.status = 'loading'
        })
            .addCase(loadUsers.fulfilled, (state, action) => {
                state.status = 'succeeded'
                const data = action.payload
                adminUserAdapter.setAll(state, data)
            })
            .addCase(loadUsers.rejected, (state, action) => {
                state.status = 'failed'
                state.error = action.error.message
            })
            .addCase(loadUser.pending, (state, action) => {
                state.status = 'loading'
            })
            .addCase(loadUser.fulfilled, (state, action) => {
                state.status = 'succeeded'
                const data = action.payload
                state.selectedUser = data
            })
            .addCase(loadUser.rejected, (state, action) => {
                state.status = 'failed'
                state.error = action.error.message
            })
    }
})


export const {
    addAdminuser,
    removeAdminuser,
    updateAdminuser,
    setadminusers,
    setadminusersStatus,
    setSearchParam,
    setselectedUser,
    removeAllusers, setSearchQuery, setLastVisible
} = adminusersSlice.actions

export default adminusersSlice.reducer

