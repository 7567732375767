import React, { useState, useEffect } from "react";

import { useSelector, useDispatch } from "react-redux";
import { yup, yupResolver, useForm, Controller } from "util/yupLocale";
import { ExclamationTriangleIcon } from "@heroicons/react/20/solid";
import { useNavigate } from "react-router-dom";

import { updatebillingInfo, createPayment,createPaymentBank } from "features/shop/slice";
import ROUTES from "features/navigation/routes";
import Bag from "./Bag";

const schema = yup.object({
  tcVkn: yup.string().required().label("Vergi No / TC Kimlik No").min(10).max(11),
  taxAdmin: yup.string().required().label("Vergi Dairesi").min(5),
  name: yup.string().required().label("Adı").min(2),
  surname: yup.string().required().label("Soyadı").min(2),
  title: yup.string().required().label("Ünvan").min(5),
  address: yup.string().required().label("Adres"),
  city: yup.string().required().label("İl"),
  district: yup.string().required().label("İlçe"),
  country: yup.string().required().label("Ülke"),
  phoneNumber: yup.string().required().label("Yetkili Cep").min(10),
  email: yup.string().required().email().label("E-Posta"),
  paymentType: yup.string().required().label("Ödeme Tipi"),
});

export default function Example() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isAgreed, setIsAgreed] = useState(false);

  const { user } = useSelector((state) => state.auth);

  const {
    register,
    handleSubmit,
    reset,
    control,
    setValue,
    watch,
    getValues,
    formState: { errors, isDirty },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSave = async (form) => {
    isDirty && (await dispatch(updatebillingInfo({ form })));
    const paymentType = getValues("paymentType");

    if(paymentType === "bankTransfer") {
      await dispatch(createPaymentBank());
      navigate(ROUTES.BANKPAYMENT.path);
    }
    await dispatch(createPayment());
    navigate(ROUTES.CHECKOUT.path);
  };

  useEffect(() => {
    const { billingInfo } = user;
    if (billingInfo) {
      reset(billingInfo);
    }
  }, [user]);

  useEffect(() => {
    // if tcVkn is 11 digits set title to name + surname when name and surname is changed
    if (watch("tcVkn").length === 11) {
      setValue("title", watch("name") + " " + watch("surname"));
    }
  }, [watch("name"), watch("surname")]);


  return (
    <div className="bg-white ">
      {/* Background color split screen for large screens */}
      <div className=" hidden h-full w-1/2 bg-white lg:block" aria-hidden="true" />
      <div className=" right-0 hidden h-full w-1/2 bg-gray-50 lg:block" aria-hidden="true" />

      <div className="relative mx-auto grid max-w-7xl grid-cols-1 gap-x-16 lg:grid-cols-2 lg:px-8 xl:gap-x-48">
        <Bag
          showContrats={true}
          isAgreed={isAgreed}
          setIsAgreed={setIsAgreed}
          allowCheckout={
            <>
              <div className="mt-10 border-t border-gray-200 pt-6 sm:flex sm:items-center sm:justify-between">
                <p className="mt-4 text-center text-sm text-gray-500 sm:mt-0 sm:text-left">
                  Bu aşamadan sonra fatura bilgilerinizi değiştiremezsiniz.
                </p>
              </div>
              <div className="mt-10 border-t border-gray-200 pt-6 sm:flex sm:items-center sm:justify-between">
                {/* <button
                  disabled={!isAgreed}
                  onClick={() => setValue("paymentType", "bankTransfer")}
                  type="submit"
                  form="billing-info"
                  className="sm:w-30 w-full rounded-md border border-transparent bg-primary py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-secondary focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-50 disabled:cursor-not-allowed disabled:opacity-50 sm:ml-6"
                >
                  Banka Havalesi İle Öde
                </button> */}
                <button
                 onClick={() => setValue("paymentType", "card")}
                  disabled={!isAgreed}
                  type="submit"
                  form="billing-info"
                  className="sm:w-30 w-full rounded-md border border-transparent bg-primary py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-secondary focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-50 disabled:cursor-not-allowed disabled:opacity-50 sm:ml-6"
                >
                  Kart İle Öde
                </button> 
              </div>
            </>
          }
        />
        <form
          className="px-4 pt-6 pb-36 sm:px-6 lg:col-start-1 lg:row-start-1 lg:px-0 lg:pb-16"
          id="billing-info"
          onSubmit={handleSubmit(onSave)}
        >
          <div className="mx-auto max-w-lg lg:max-w-none">
            <section aria-labelledby="shipping-heading" className="mt-10">
              <h2 id="shipping-heading" className="text-lg font-medium text-gray-900">
                Fatura Bilgileri
              </h2>

              <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                <div className="sm:col-span-3">
                  <label htmlFor="city" className="block text-sm font-medium text-gray-700">
                    Vergi / TC Kimlik No
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      {...register("tcVkn")}
                      className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    />
                  </div>
                  <ErrorMessage error={errors?.tcVkn?.message} />
                </div>

                <div className="sm:col-span-3">
                  <label htmlFor="city" className="block text-sm font-medium text-gray-700">
                    Vergi Dairesi
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      {...register("taxAdmin")}
                      className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    />
                  </div>
                  <ErrorMessage error={errors?.taxAdmin?.message} />
                </div>

                <div className="sm:col-span-3">
                  <label htmlFor="company" className="block text-sm font-medium text-gray-700">
                    Adı
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      {...register("name")}
                      className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    />
                  </div>
                  <ErrorMessage error={errors?.name?.message} />
                </div>

                <div className="sm:col-span-3">
                  <label htmlFor="company" className="block text-sm font-medium text-gray-700">
                    Soyadı
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      {...register("surname")}
                      className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    />
                  </div>
                  <ErrorMessage error={errors?.surname?.message} />
                </div>

                <div className="sm:col-span-6">
                  <label htmlFor="company" className="block text-sm font-medium text-gray-700">
                    Firma Ünvanı
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      {...register("title")}
                      className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    />
                  </div>
                  <ErrorMessage error={errors?.title?.message} />
                </div>

                <div className="sm:col-span-6">
                  <label htmlFor="address" className="block text-sm font-medium text-gray-700">
                    Adres
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      {...register("address")}
                      autoComplete="street-address"
                      className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    />
                  </div>
                  <ErrorMessage error={errors?.address?.message} />
                </div>

                <div className="sm:col-span-2">
                  <label htmlFor="apartment" className="block text-sm font-medium text-gray-700">
                    İl
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      {...register("city")}
                      className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    />
                  </div>
                  <ErrorMessage error={errors?.city?.message} />
                </div>

                <div className="sm:col-span-2">
                  <label htmlFor="city" className="block text-sm font-medium text-gray-700">
                    İlçe
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      {...register("district")}
                      className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    />
                  </div>
                  <ErrorMessage error={errors?.district?.message} />
                </div>

                <div className="sm:col-span-2">
                  <label htmlFor="city" className="block text-sm font-medium text-gray-700">
                    Ülke
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      {...register("country")}
                      defaultValue="Türkiye"
                      className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    />
                  </div>
                  <ErrorMessage error={errors?.country?.message} />
                </div>

                <div className="sm:col-span-3">
                  <label htmlFor="city" className="block text-sm font-medium text-gray-700">
                    Cep Telefonu
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      {...register("phoneNumber")}
                      className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    />
                  </div>
                  <ErrorMessage error={errors?.phoneNumber?.message} />
                </div>
                <div className="sm:col-span-3">
                  <label htmlFor="city" className="block text-sm font-medium text-gray-700">
                    ePosta
                  </label>
                  <div className="mt-1">
                    <input
                      type="email"
                      {...register("email")}
                      autoComplete="email"
                      className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    />
                  </div>
                  <ErrorMessage error={errors?.email?.message} />
                </div>
              </div>
            </section>

            {/* <div className="mt-10 border-t border-gray-200 pt-6 sm:flex sm:items-center sm:justify-between">
              <button
                type="submit"
                className="w-full rounded-md border border-transparent bg-primary py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-secondary focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-50 sm:order-last sm:ml-6 sm:w-auto"
              >
                Onayla
              </button>
              <p className="mt-4 text-center text-sm text-gray-500 sm:mt-0 sm:text-left">
                Bu aşamadan sonra fatura bilgilerinizi değiştiremezsiniz.
              </p>
            </div> */}
          </div>
        </form>
      </div>
    </div>
  );
}

const ErrorMessage = (props) => {
  const { error } = props;
  if (!error) return null;
  return (
    <div className="mt-0 flex py-2 text-xs text-red-600 dark:text-red-400 " id="email-error">
      <ExclamationTriangleIcon className="mr-1 h-4  w-4 text-red-500 dark:text-red-400 " aria-hidden="true" /> {error}
    </div>
  );
};
