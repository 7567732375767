import React, { useState, useEffect } from "react";

const AydinlatmaMetni = ({}) => {
  const sellerCompany = {
    title: "SCRIPT YAZILIM LİMİTED ŞİRKETİ ",
    address: "19 MAYIS MAH. TURABOĞLU SK. HAMDIYE YAZGAN IS MERKEZI BLOK NO: 4 İÇ KAPI NO: 2 KADIKÖY / İSTANBUL",
    email: "destek@aposkal.com",
    tel: "+90 (216) 706 19 20",
    mersis: "0757091337800001",
    tcVkn: "7570913378",
  };
  return (
    <>
      <h1 className="text-xl font-bold text-gray-800 ">Kişisel Verilerin Korunması Aydınlatma Metni</h1>
      <p className="text-sm text-gray-800">Veri Sorumlusu: {sellerCompany.title} </p>
      <p className="text-sm text-gray-800">
        Biz, {sellerCompany.title} olarak; üyelerimiz ve çalışanlarımız dahil, Şirketimiz ile ilişkili şahısların kişisel
        verilerinin Türkiye Cumhuriyeti Anayasası, insan haklarına ilişkin ülkemizin tarafı olduğu uluslararası sözleşmeler ile
        6698 sayılı Kişisel Verilerin Korunması Kanunu (“KVKK”) başta olmak üzere, 6361 sayılı Finansal Kiralama, Faktoring ve
        Finansman Şirketleri Kanunu, BDDK Destek Hizmetleri Yönetmeliği, 5411 sayılı Bankacılık Kanunu ve ilgili diğer
        mevzuatlar çerçevesinde; güvence altına alınması ve işlenmesi konusuna hassasiyetle yaklaşmaktayız. Bu çerçevede, KVKK
        kapsamında Veri Sorumlusu sıfatıyla sizleri aydınlatmak istiyoruz. Şirketimiz ile paylaştığınız kişisel verileriniz
        KVKK’ya uygun şekilde, faaliyet ve hizmet amaçlarımız ile bağlantılı ve ölçülü olarak işlenebilecek, saklanacak,
        profilleme için kullanılabilecek ve sınıflandırılabilecektir. Kişisel Verilerinizin İşlenme Amacı Kişisel verileriniz;
      </p>

      <ol className="list-inside list-decimal text-sm text-gray-800">
        <li className="list-inside list-decimal text-sm text-gray-800">Hukuka ve dürüstlük kuralının öngördüğü biçimde,</li>
        <li className="list-inside list-decimal text-sm text-gray-800">
          İşlenme amaçları ile bağlantılı, sınırlı ve ölçülü olarak,
        </li>
        <li className="list-inside list-decimal text-sm text-gray-800">Doğru ve güncel olarak</li>
        <li className="list-inside list-decimal text-sm text-gray-800">Belirli açık ve meşru amaçlar ile işlenecektir.</li>
      </ol>
      <p className="text-sm text-gray-800">
        Veri sorumlusu olarak Şirketimiz tarafından kişisel verileriniz, uzun süreli çalışmalarımızdan ödün vermeden, sizleri
        daha iyi tanıyarak ihtiyaçlarınızı anlamak, isteklerinize daha hızlı cevap verebilmek ve sizlerle olan iletişimimizi
        geliştirerek sizlere daha iyi hizmet vermek ve daha kişisel ürün, hizmet ve teklif sunabilmek, bunları yapabilmek için
        analiz yapmak, sizleri ürün ve hizmetlerimiz hakkında bilgilendirebilmek, ürün ve hizmetlerimiz hakkında memnuniyetinizi
        ölçmek, istekleriniz ve ihtiyaçlarınız doğrultusunda ürün ve hizmetlerimizi geliştirmek ve çeşitlendirmek için bunlarla
        birlikte gerekli kalite ve standart denetimlerimizi ilgili kanun mevzuatların öngördüğü şekilde yapabilmek ya da bizim
        tabi olduğumuz kanun ve mevzuatlar tarafından öngörülen raporlama ve sair yükümlülüklerimizin yerine getirilmesi gibi
        amaçlar için kişisel verileriniz işlenebilecektir. Çalışanlarımızın verileri, İş Kanunu, çalışma ve sosyal güvenlik
        mevzuatı ile yürürlükte olan diğer mevzuatın öngördüğü zorunlulukların yanı sıra insan kaynakları politikamız dâhilinde
        veya performans düzeyini ve çalışan memnuniyetini arttırmak ve iş güvenliği ve iş barışının sağlanması gibi işletimsel
        nedenler ile Şirketimiz veya Şirketimizin iş birliği yaptığı veya yetkilendirdiği gerçek veya tüzel kişiler tarafından
        işlenebilecektir. Kişisel Verilerinizin Aktarılması Kişisel verileriniz yukarıda sayılan amaçlar dâhilinde, KVKK ve
        yürürlükte olan mevzuata uygun olarak, Şirket içerisinde paylaşılabileceği gibi, sayılanlar ile sınırlı olmamak üzere;
        iş ortaklarımız, iş bağlantılarımız, ifa yardımcılarımız ve alt yüklenicilerimiz ile sunduğumuz hizmetin amacı
        doğrultusunda ya da düzenleyici denetleyici kurumlar ve resmi mercilerle ilgili mevzuatın öngördüğü durumlarda yurtiçine
        veya yurtdışına aktarılabilecektir. Kişisel Verilerinizin Toplanma Yöntemi ve Hukuki Sebebi Kişisel verileriniz,
        yukarıda belirtilen amaç ve kapsamda sözlü, yazılı veya elektronik olarak toplanabilir. Kişisel verileriniz, Şirketimiz
        veya Şirketimiz adına veri işleyen gerçek ya da tüzel kişiler tarafından sayılanlarla sınırlı olmamak üzere, internet
        sitesi, muhtelif sözleşmeler, mobil uygulamalar, SMS bildirimleri, elektronik posta, başvuru formları gibi benzeri
        araçlar üzerinden, Şirketimiz ile yapılan yazılı veya sözlü iletişimler vb. kanallar aracılığıyla sözlü, yazılı veya
        elektronik ortamda toplanmaktadır.
      </p>

      <p className="text-sm text-gray-800"> KVKK ve yürürlükte bulunan diğer mevzuat çerçevesinde kalmak kaydıyla,</p>

      <ol className="list-inside list-decimal text-sm text-gray-800">
        <li className="list-inside list-decimal text-sm text-gray-800">Kişisel verilerinizin işlenip işlenmediğini öğrenme,</li>
        <li className="list-inside list-decimal text-sm text-gray-800">
          Kişisel verileriniz işlenmişse buna ilişkin bilgi talep etme,
        </li>
        <li className="list-inside list-decimal text-sm text-gray-800">
          Kişisel verilerin işlenme amacını ve bunların amacına uygun kullanılıp kullanılmadığını öğrenme,
        </li>
        <li className="list-inside list-decimal text-sm text-gray-800">
          Yurtiçinde veya yurtdışında kişisel verilerinizin aktarıldığı üçüncü kişileri bilme,
        </li>
        <li className="list-inside list-decimal text-sm text-gray-800">
          Kişisel verilerinizin eksik veya yanlış işlenmiş olması halinde bunların düzeltilmesini isteme,
        </li>
        <li className="list-inside list-decimal text-sm text-gray-800">
          KVKK mevzuatında öngörülen şartlar çerçevesinde kişisel verilerinizin silinmesini veya yok edilmesini isteme,
        </li>
        <li className="list-inside list-decimal text-sm text-gray-800">
          Eksik veya yanlış verilerin düzeltilmesi ile kişisel verilerinizin silinmesi veya yok edilmesini talep ettiğinizde, bu
          durumun kişisel verilerinizin aktarıldığı üçüncü kişilere bildirilmesini isteme,
        </li>
        <li className="list-inside list-decimal text-sm text-gray-800">
          İşlenen verilerin münhasıran otomatik sistemler vasıtasıyla analiz edilmesi suretiyle aleyhinize bir sonucun ortaya
          çıkmasına itiraz etme,
        </li>
        <li className="list-inside list-decimal text-sm text-gray-800">
          Kişisel verilerin kanuna aykırı olarak işlenmesi sebebiyle zarara uğramanız halinde bu zararın giderilmesini talep
          etme haklarına sahipsiniz.
        </li>
      </ol>
    </>
  );
};

export default AydinlatmaMetni;
