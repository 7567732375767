const ROUTES = {
        NOT_MATCH: { path: "*", name: "Sayfa Bulunamadı", hideSearch: true },

        SIGN_IN: { path: "/signin", name: "Giriş", hideSearch: true },
        SIGN_UP: { path: "/signup", name: "Kayıt", hideSearch: true },
        INVITE: { path: "/invite", name: "Davet", hideSearch: true },
        // admin
        ADMIN: { path: "/admin", name: "Admin", hideSearch: true },
        ADMIN_PAYMENTS: { path: "/admin/payments", name: "Ödemeler", parent: () => ROUTES.ADMIN },
        ADMIN_PAYMENTS_DETAIL: { path: "/admin/payments/:id/view", name: "Ödeme Detayı", parent: () => ROUTES.ADMIN_PAYMENTS },
        ADMIN_USERS: { path: "/admin/users", name: "Kullanıcılar", parent: () => ROUTES.ADMIN },
        ADMIN_USERS_DETAIL: { path: "/admin/users/:uid/view", name: "Kullanıcı Detayı", parent: () => ROUTES.ADMIN_USERS },
        ADMIN_PRODUCTS: { path: "/admin/products", name: "Ürünler", parent: () => ROUTES.ADMIN },
        ADMIN_STATISTICS: { path: "/admin/statistics", name: "İstatistikler", parent: () => ROUTES.ADMIN },


        COMPANIES_SELECT: { path: "/companySelect", name: "Firma Seç" },
        COMPANIES_NEW: { path: "/settings/companies/new", name: "Firma Ekle", parent: () => ROUTES.COMPANIES_SELECT },

        HOME: { path: "/", name: "Giriş", hideSearch: true },
        DASHBOARD: { path: "/", name: "Dashboard", hideSearch: true },
        // Ayarlar
        SETTINGS: { path: "/settings", name: "Ayarlar", hideSearch: true },
        PROFILE: { path: "/settings/profile", name: "Profil" },
        CHANGE_PASSWORD: { path: "/settings/changePassword", name: "Şifre Değiştir" },
        COMPANIES_EDIT: { path: "/settings/companies/edit", name: "Firma Düzenle", parent: () => ROUTES.HOME },

        SHOP: { path: "/shop", name: "Mağaza", parent: () => ROUTES.HOME },
        CHECKOUT: { path: "/shop/checkout", name: "Ödeme", parent: () => ROUTES.SHOP },
        SERVICES: { path: "/shop/services", name: "Hizmetler", parent: () => ROUTES.SHOP },
        BILLINGINFO: { path: "/shop/billinginfo", name: "Fatura Bilgileri", parent: () => ROUTES.SHOP },
        BANKPAYMENT: { path: "/shop/bankpayment", name: "Banka Ödemesi", parent: () => ROUTES.SHOP },

        USERS: { path: "/settings/users", name: "Kullanıcılar" },
        USER_EDIT: { path: "/settings/users/:uid/edit", name: "Kullanıcı Düzenle", parent: () => ROUTES.USERS },
        USER_NEW: { path: "/settings/users/new", name: "Kullanıcı Ekle", parent: () => ROUTES.USERS },
        // Gelir
        INCOME: { path: "/income", name: "Gelir" },
        // Teklif
        IN_OFFER: { path: "/income/offer", name: "Teklif" },
        IN_OFFER_EDIT: { path: "/income/offer/:id/edit", name: "Düzenle" },
        IN_OFFER_VIEW: { path: "/income/offer/:id/view", name: "Görüntüle" },
        IN_OFFER_NEW: { path: "/income/offer/new", name: "Yeni Teklif" },
        // Sipariş
        IN_ORDER: { path: "/income/order", name: "Sipariş" },
        IN_ORDER_EDIT: { path: "/income/order/:id/edit", name: "Düzenle" },
        IN_ORDER_NEW: { path: "/income/order/new", name: "Yeni Sipariş" },
        // İrsaliye
        IN_DELIVERY: { path: "/income/delivery", name: "İrsaliye" },
        IN_DELIVERY_EDIT: { path: "/income/delivery/:id/edit", name: "Düzenle" },
        IN_DELIVERY_NEW: { path: "/income/delivery/new", name: "Yeni İrsaliye" },
        // Fatura
        IN_INVOICE: { path: "/income/invoice", name: "Fatura" },
        IN_INVOICE_EDIT: { path: "/income/invoice/:id/edit", name: "Düzenle" },
        IN_INVOICE_NEW: { path: "/income/invoice/new", name: "Yeni Fatura" },

        TRANSACTIONS: { path: "/transaction", name: "İşlemler" },
        TRANSACTION_START: { path: "/transaction/start", name: "Yeni İşlem Başlat" },
        TRANSACTION_EDIT: { path: "/transaction/edit", name: "İşlem" },
        TRANSACTIONS_DRAFT: { path: "/transaction/list", name: "Devam Eden İşlemler" },
        TRANSACTIONS_DONE: { path: "/transaction/documentCreatedList", name: "Tamamlanan İşlemler" },
        TRANSACTIONS_SHOWDOC: { path: "/transaction/showDoc", name: "Belgeler" },
        //TRANSACTIONS

        ADRESDEGISIKLIGI: { path: "/transaction/adres-degisikligi", name: "Adres Değişikliği" },

};

export const ROUTES_ARRAY = Object.keys(ROUTES).map((key) => {
        const route = ROUTES[key];
        // if hideSearch is true, don't add to routes array
        if (route.hideSearch) return null;
        if (route.path.includes(":")) return null;
        return {
                link: route.path,
                name: route.name,
                category: "Sayfaya Git",
        };
}).filter((route) => route);

export default ROUTES;