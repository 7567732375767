import { Link } from "react-router-dom";

import Logo from "features/ui/Logo";
import packageJson from "../../../../package.json";

const LogoRender = () => {
  return (
    <div className="px-0">
      <Link to="/" className="flex flex-shrink-0 items-center">
        <Logo className="mx-auto h-36" />
      </Link>
      <p className="text-center text-sm text-gray-500">{packageJson.version}</p>
    </div>
  );
};

export default LogoRender;
