import { Link } from "react-router-dom";
import { ChevronRightIcon, HomeIcon } from "@heroicons/react/20/solid";

const BreadCrumbs = (props) => {
  const { data } = props;

  return (
    <nav className="flex" aria-label="Breadcrumb">
      <ol className="flex items-center space-x-4">
        {data &&
          data.map((page) => (
            <li key={page.name}>
              <div className="flex items-center">
                {page.path === "/" ? (
                  <Link to={page.path} className="text-gray-400 hover:text-gray-500 dark:text-gray-200">
                    <HomeIcon className="h-5 w-5 flex-shrink-0" aria-hidden="true" />
                    <span className="sr-only">{page.name}</span>
                  </Link>
                ) : (
                  <>
                    <ChevronRightIcon className="h-5 w-5 flex-shrink-0 text-gray-400 dark:text-gray-200 " aria-hidden="true" />
                    <Link
                      to={page.path}
                      className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700  dark:text-gray-100"
                      aria-current={page.current ? "page" : undefined}
                    >
                      {page.name}
                    </Link>
                  </>
                )}
              </div>
            </li>
          ))}
      </ol>
    </nav>
  );
};

export default BreadCrumbs;
