import { useSelector } from "react-redux";

const TermOfSale = ({ bag, totalProductsPrice, totalVat, total }) => {
  const { user } = useSelector((state) => state.auth);
  console.log(bag);
  const sellerCompany = {
    title: "SCRIPT YAZILIM LİMİTED ŞİRKETİ ",
    address: "19 MAYIS MAH. TURABOĞLU SK. HAMDIYE YAZGAN IS MERKEZI BLOK NO: 4 İÇ KAPI NO: 2 KADIKÖY / İSTANBUL",
    email: "destek@aposkal.com",
    tel: "+90 (216) 706 19 20",
    mersis: "0757091337800001",
    tcVkn: "7570913378",
  };

  if (!user?.billingInfo)
    return (
      <div className="text-center text-sm font-bold text-red-500">
        Sözleşmeyi görebilmek için lütfen önce fatura bilgilerinizi giriniz.
      </div>
    );
  return (
    <div className="text-sm">
      <h1 className="text-center text-2xl font-bold">MESAFELİ SATIŞ SÖZLEŞMESİ</h1>
      <h3 className="mt-2 font-medium">1.TARAFLAR</h3>
      <p>
        İşbu Sözleşme aşağıdaki taraflar arasında aşağıda belirtilen hüküm ve şartlar çerçevesinde imzalanmıştır.
        <br />
        Alıcı; Unvan:<span className="font-bold">{user?.billingInfo?.title}</span>; (sözleşmede bundan sonra "ALICI" olarak
        anılacaktır) <br />
        Vkn/TCkn:
        <span className="font-bold">{user?.billingInfo?.tcVkn}</span>
        <br />
        Ad Soyad:<span className="font-bold">{user?.billingInfo?.name}</span>
        <span className="font-bold">{user?.billingInfo?.surname}</span> <br />
        Adres: <span className="font-bold">{user?.billingInfo?.address}</span> <br />
        <br />
        Satıcı; Unvan:<span className="font-bold">{sellerCompany.title}</span>; (sözleşmede bundan sonra "SATICI" olarak
        anılacaktır) <br />
        Vkn/TCkn:<span className="font-bold">{sellerCompany.tcVkn}</span>
        <br />
        Adres: <span className="font-bold">{sellerCompany.address}</span> <br />
        <br />
        İş bu sözleşmeyi kabul etmekle ALICI, sözleşme konusu siparişi onayladığı takdirde sipariş konusu bedeli ve varsa kargo
        ücreti, vergi gibi belirtilen ek ücretleri ödeme yükümlülüğü altına gireceğini ve bu konuda bilgilendirildiğini peşinen
        kabul eder.
      </p>
      <h3 className="mt-2 font-medium">2.TANIMLAR</h3>
      <p>
        İşbu sözleşmenin uygulanmasında ve yorumlanmasında aşağıda yazılı terimler karşılarındaki yazılı açıklamaları ifade
        edeceklerdir. BAKAN : Gümrük ve Ticaret Bakanı’nı, BAKANLIK : Gümrük ve Ticaret Bakanlığı’nı, KANUN : 6502 sayılı
        Tüketicinin Korunması Hakkında Kanun’u, YÖNETMELİK : Mesafeli Sözleşmeler Yönetmeliği’ni (RG:27.11.2014/29188) HİZMET :
        Bir ücret veya menfaat karşılığında yapılan ya da yapılması taahhüt edilen mal sağlama dışındaki her türlü tüketici
        işleminin konusunu , SATICI : Ticari veya mesleki faaliyetleri kapsamında tüketiciye mal sunan veya mal sunan adına veya
        hesabına hareket eden şirketi, ALICI : Bir mal veya hizmeti ticari veya mesleki olmayan amaçlarla edinen, kullanan veya
        yararlanan gerçek ya da tüzel kişiyi, SİTE : SATICI’ya ait internet sitesini, SİPARİŞ VEREN: Bir mal veya hizmeti
        SATICI’ya ait internet sitesi üzerinden talep eden gerçek ya da tüzel kişiyi, TARAFLAR : SATICI ve ALICI’yı, SÖZLEŞME :
        SATICI ve ALICI arasında akdedilen işbu sözleşmeyi, MAL : Alışverişe konu olan taşınır eşyayı ve elektronik ortamda
        kullanılmak üzere hazırlanan yazılım, ses, görüntü ve benzeri gayri maddi malları ifade eder.
      </p>
      <h3 className="mt-2 font-medium">3.KONU</h3>
      <p>
        İşbu Sözleşme, ALICI’nın, SATICI’ya ait internet sitesi üzerinden elektronik ortamda siparişini verdiği aşağıda
        nitelikleri ve satış fiyatı belirtilen ürünü/hizmet satışı ve teslimi ile ilgili olarak 6502 sayılı Tüketicinin
        Korunması Hakkında Kanun ve Mesafeli Sözleşmelere Dair Yönetmelik hükümleri gereğince tarafların hak ve yükümlülüklerini
        düzenler. Listelenen ve sitede ilan edilen fiyatlar satış fiyatıdır. İlan edilen fiyatlar ve vaatler güncelleme yapılana
        ve değiştirilene kadar geçerlidir. Süreli olarak ilan edilen fiyatlar ise belirtilen süre sonuna kadar geçerlidir.
      </p>
      <h3 className="mt-2 font-medium">4. SATICI BİLGİLERİ</h3>
      <p>
        Unvanı : <span className="font-bold">{sellerCompany.title}</span>
      </p>
      <p>
        Adresi : <span className="font-bold">{sellerCompany.address}</span>
      </p>
      <p>
        Telefon : <span className="font-bold">{sellerCompany.tel}</span>
      </p>
      <p>
        Eposta : <span className="font-bold">{sellerCompany.email}</span>
      </p>
      <p>
        Mersis No : <span className="font-bold">{sellerCompany.mersis}</span>
      </p>
      <p>
        Vergi Dairesi : <span className="font-bold">{sellerCompany.tcVkn}</span>
      </p>
      <p>
        Vergi No : <span className="font-bold">{sellerCompany.tcVkn}</span>
      </p>

      <h3 className="mt-2 font-medium">5. ALICI BİLGİLERİ</h3>
      <p>
        Unvan : <span className="font-bold">{user?.billingInfo?.title}</span>
      </p>
      <p>
        Adı Soyadı : <span className="font-bold">{user?.billingInfo?.name}</span>
        <span className="font-bold">{user?.billingInfo?.surname}</span>
      </p>
      <p>
        Adresi : <span className="font-bold">{user?.billingInfo?.address}</span>
      </p>
      <p>
        Telefon : <span className="font-bold">{user?.billingInfo?.phoneNumber}</span>
      </p>
      <p>
        Eposta : <span className="font-bold">{user?.billingInfo?.email}</span>
      </p>
      <p>
        Tc Vkn : <span className="font-bold">{user?.billingInfo?.tcVkn}</span>
      </p>
      <p>
        Vergi Dairesi : <span className="font-bold">{user?.billingInfo?.taxAdmin}</span>
      </p>

      <h3 className="mt-2 font-medium">6. SİPARİŞ VEREN KİŞİ BİLGİLERİ</h3>
      <p>
        Kullanıcı Adı :<span className="font-bold">{user?.displayName}</span>
      </p>
      <p>
        ePosta :<span className="font-bold">{user?.email}</span>
      </p>
      <p>
        telefon :<span className="font-bold">{user?.phoneNumber}</span>
      </p>

      <h3 className="mt-2 font-medium">7. SÖZLEŞME KONUSU ÜRÜN/ÜRÜNLER BİLGİLERİ</h3>
      <p>
        1. Malın /Ürün/Ürünlerin/ Hizmetin temel özelliklerini (türü, miktarı, marka/modeli, rengi, adedi) SATICI’ya ait
        internet sitesinde yayınlanmaktadır. Satıcı tarafından kampanya düzenlenmiş ise ilgili ürünün temel özelliklerini
        kampanya süresince inceleyebilirsiniz. Kampanya tarihine kadar geçerlidir. 7.2. Listelenen ve sitede ilan edilen
        fiyatlar satış fiyatıdır. İlan edilen fiyatlar ve vaatler güncelleme yapılana ve değiştirilene kadar geçerlidir. Süreli
        olarak ilan edilen fiyatlar ise belirtilen süre sonuna kadar geçerlidir.
        <br />
        7.3. Sözleşme konusu mal ya da hizmetin tüm vergiler dâhil satış fiyatı aşağıda gösterilmiştir.
        <br />
        {/* render as table bag.map */}
        <table className="w-full table-auto border-collapse border border-gray-400">
          <thead className="border-collapse border border-gray-400">
            <tr className="border-collapse border border-gray-400">
              <th>Ürün Adı</th>
              <th>Ürün Açıklaması</th>
              {/* <th>Adet</th> */}
              <th>KDV hariç Fiyatı</th>
              <th>Kargo Tutarı</th>
              <th>KDV</th>
              <th>Toplam</th>
            </tr>
          </thead>
          <tbody className="border-collapse border border-gray-400">
            {bag.map((service) => (
              <tr key={service.id} className="border-collapse border border-gray-400">
                <td>{service.name}</td>
                <td>{service.description}</td>
                {/* <td>{service.credit}</td> */}
                <td>{service.price}</td>
                <td>0</td>
                <td>{service.price * (service.vatRate / 100)}</td>
                <td>{service.price + service.price * (service.vatRate / 100)}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <br />
        <p>
          Teslim Şekli: <span className="font-bold">Elektronik</span>
        </p>
        <p>
          Ödeme Şekli: <span className="font-bold">Kredi Kartı</span>
        </p>
      </p>

      <h3 className="mt-2 font-medium">8. FATURA BİLGİLERİ</h3>

      <p>
        Unvan : <span className="font-bold">{user?.billingInfo?.title}</span>
      </p>
      <p>
        Adı Soyadı : <span className="font-bold">{user?.billingInfo?.name}</span>
        <span className="font-bold">{user?.billingInfo?.surname}</span>
      </p>
      <p>
        Adresi : <span className="font-bold">{user?.billingInfo?.address}</span>
      </p>
      <p>
        Telefon : <span className="font-bold">{user?.billingInfo?.phoneNumber}</span>
      </p>
      <p>
        Eposta : <span className="font-bold">{user?.billingInfo?.email}</span>
      </p>
      <p>
        Tc Vkn : <span className="font-bold">{user?.billingInfo?.tcVkn}</span>
      </p>
      <p>
        Vergi Dairesi : <span className="font-bold">{user?.billingInfo?.taxAdmin}</span>
      </p>
      <p>Fatura ödeme tamamlandıktan sonra e-posta adresinize gönderilecektir.</p>

      <h3 className="mt-2 font-medium">9. GENEL HÜKÜMLER</h3>
      <p>
        9.1. ALICI, SATICI’ya ait internet sitesinde sözleşme konusu ürünün temel nitelikleri, satış fiyatı ve ödeme şekli ile
        teslimata ilişkin ön bilgileri okuyup, bilgi sahibi olduğunu, elektronik ortamda gerekli teyidi verdiğini kabul, beyan
        ve taahhüt eder. ALICI’nın; Ön Bilgilendirmeyi elektronik ortamda teyit etmesi, mesafeli satış sözleşmesinin
        kurulmasından evvel, SATICI tarafından ALICI' ya verilmesi gereken adresi, siparişi verilen ürünlere ait temel
        özellikleri, ürünlerin vergiler dâhil fiyatını, ödeme ve teslimat bilgilerini de doğru ve eksiksiz olarak edindiğini
        kabul, beyan ve taahhüt eder. 9.2. Sözleşme konusu her bir ürün, 30 günlük yasal süreyi aşmamak kaydı ile ALICI' nın
        yerleşim yeri uzaklığına bağlı olarak internet sitesindeki ön bilgiler kısmında belirtilen süre zarfında ALICI veya
        ALICI’nın gösterdiği adresteki kişi ve/veya kuruluşa teslim edilir. Bu süre içinde ürünün ALICI’ya teslim edilememesi
        durumunda, ALICI’nın sözleşmeyi feshetme hakkı saklıdır. 9.3. SATICI, Sözleşme konusu ürünü eksiksiz, siparişte
        belirtilen niteliklere uygun ve varsa garanti belgeleri, kullanım kılavuzları işin gereği olan bilgi ve belgeler ile
        teslim etmeyi, her türlü ayıptan arî olarak yasal mevzuat gereklerine göre sağlam, standartlara uygun bir şekilde işi
        doğruluk ve dürüstlük esasları dâhilinde ifa etmeyi, hizmet kalitesini koruyup yükseltmeyi, işin ifası sırasında gerekli
        dikkat ve özeni göstermeyi, ihtiyat ve öngörü ile hareket etmeyi kabul, beyan ve taahhüt eder. 9.4. SATICI, sözleşmeden
        doğan ifa yükümlülüğünün süresi dolmadan ALICI’yı bilgilendirmek ve açıkça onayını almak suretiyle eşit kalite ve
        fiyatta farklı bir ürün tedarik edebilir. 9.5. SATICI, sipariş konusu ürün veya hizmetin yerine getirilmesinin
        imkânsızlaşması halinde sözleşme konusu yükümlülüklerini yerine getiremezse, bu durumu, öğrendiği tarihten itibaren 3
        gün içinde yazılı olarak tüketiciye bildireceğini, 14 günlük süre içinde toplam bedeli ALICI’ya iade edeceğini kabul,
        beyan ve taahhüt eder. 9.6. ALICI, Sözleşme konusu ürünün teslimatı için işbu Sözleşme’yi elektronik ortamda teyit
        edeceğini, herhangi bir nedenle sözleşme konusu ürün bedelinin ödenmemesi ve/veya banka kayıtlarında iptal edilmesi
        halinde, SATICI’nın sözleşme konusu ürünü teslim yükümlülüğünün sona ereceğini kabul, beyan ve taahhüt eder. 9.7. ALICI,
        Sözleşme konusu ürünün ALICI veya ALICI’nın gösterdiği adresteki kişi ve/veya kuruluşa tesliminden sonra ALICI'ya ait
        kredi kartının yetkisiz kişilerce haksız kullanılması sonucunda sözleşme konusu ürün bedelinin ilgili banka veya finans
        kuruluşu tarafından SATICI'ya ödenmemesi halinde, ALICI Sözleşme konusu ürünü 3 gün içerisinde nakliye gideri SATICI’ya
        ait olacak şekilde SATICI’ya iade edeceğini kabul, beyan ve taahhüt eder. 9.8. SATICI, tarafların iradesi dışında
        gelişen, önceden öngörülemeyen ve tarafların borçlarını yerine getirmesini engelleyici ve/veya geciktirici hallerin
        oluşması gibi mücbir sebepler halleri nedeni ile sözleşme konusu ürünü süresi içinde teslim edemez ise, durumu ALICI'ya
        bildireceğini kabul, beyan ve taahhüt eder. ALICI da siparişin iptal edilmesini, sözleşme konusu ürünün varsa emsali ile
        değiştirilmesini ve/veya teslimat süresinin engelleyici durumun ortadan kalkmasına kadar ertelenmesini SATICI’dan talep
        etme hakkını haizdir. ALICI tarafından siparişin iptal edilmesi halinde ALICI’nın nakit ile yaptığı ödemelerde, ürün
        tutarı 14 gün içinde kendisine nakden ve defaten ödenir. ALICI’nın kredi kartı ile yaptığı ödemelerde ise, ürün tutarı,
        siparişin ALICI tarafından iptal edilmesinden sonra 14 gün içerisinde ilgili bankaya iade edilir. ALICI, SATICI
        tarafından kredi kartına iade edilen tutarın banka tarafından ALICI hesabına yansıtılmasına ilişkin ortalama sürecin 2
        ile 3 haftayı bulabileceğini, bu tutarın bankaya iadesinden sonra ALICI’nın hesaplarına yansıması halinin tamamen banka
        işlem süreci ile ilgili olduğundan, ALICI, olası gecikmeler için SATICI’yı sorumlu tutamayacağını kabul, beyan ve
        taahhüt eder. 9.9. SATICININ, ALICI tarafından siteye kayıt formunda belirtilen veya daha sonra kendisi tarafından
        güncellenen adresi, e-posta adresi, sabit ve mobil telefon hatları ve diğer iletişim bilgileri üzerinden mektup,
        e-posta, SMS, telefon görüşmesi ve diğer yollarla iletişim, pazarlama, bildirim ve diğer amaçlarla ALICI’ya ulaşma hakkı
        bulunmaktadır. ALICI, işbu sözleşmeyi kabul etmekle SATICI’nın kendisine yönelik yukarıda belirtilen iletişim
        faaliyetlerinde bulunabileceğini kabul ve beyan etmektedir. 9.10. ALICI, sözleşme konusu mal/hizmeti teslim almadan önce
        muayene edecek; ezik, kırık, ambalajı yırtılmış vb. hasarlı ve ayıplı mal/hizmeti kargo şirketinden teslim almayacaktır.
        Teslim alınan mal/hizmetin hasarsız ve sağlam olduğu kabul edilecektir. Teslimden sonra mal/hizmetin özenle korunması
        borcu, ALICI’ya aittir. Cayma hakkı kullanılacaksa mal/hizmet kullanılmamalıdır. Fatura iade edilmelidir. 9.11. ALICI
        ile sipariş esnasında kullanılan kredi kartı hamilinin aynı kişi olmaması veya ürünün ALICI’ya tesliminden evvel,
        siparişte kullanılan kredi kartına ilişkin güvenlik açığı tespit edilmesi halinde, SATICI, kredi kartı hamiline ilişkin
        kimlik ve iletişim bilgilerini, siparişte kullanılan kredi kartının bir önceki aya ait ekstresini yahut kart hamilinin
        bankasından kredi kartının kendisine ait olduğuna ilişkin yazıyı ibraz etmesini ALICI’dan talep edebilir. ALICI’nın
        talebe konu bilgi/belgeleri temin etmesine kadar geçecek sürede sipariş dondurulacak olup, mezkur taleplerin 24 saat
        içerisinde karşılanmaması halinde ise SATICI, siparişi iptal etme hakkını haizdir. 9.12. ALICI, SATICI’ya ait internet
        sitesine üye olurken verdiği kişisel ve diğer sair bilgilerin gerçeğe uygun olduğunu, SATICI’nın bu bilgilerin gerçeğe
        aykırılığı nedeniyle uğrayacağı tüm zararları, SATICI’nın ilk bildirimi üzerine derhal, nakden ve defaten tazmin
        edeceğini beyan ve taahhüt eder. 9.13. ALICI, SATICI’ya ait internet sitesini kullanırken yasal mevzuat hükümlerine
        riayet etmeyi ve bunları ihlal etmemeyi baştan kabul ve taahhüt eder. Aksi takdirde, doğacak tüm hukuki ve cezai
        yükümlülükler tamamen ve münhasıran ALICI’yı bağlayacaktır. 9.14. ALICI, SATICI’ya ait internet sitesini hiçbir şekilde
        kamu düzenini bozucu, genel ahlaka aykırı, başkalarını rahatsız ve taciz edici şekilde, yasalara aykırı bir amaç için,
        başkalarının maddi ve manevi haklarına tecavüz edecek şekilde kullanamaz. Ayrıca, üye başkalarının hizmetleri
        kullanmasını önleyici veya zorlaştırıcı faaliyet (spam, virus, truva atı, vb.) işlemlerde bulunamaz. 9.15. SATICI’ya ait
        internet sitesinin üzerinden, SATICI’nın kendi kontrolünde olmayan ve/veya başkaca üçüncü kişilerin sahip olduğu ve/veya
        işlettiği başka web sitelerine ve/veya başka içeriklere link verilebilir. Bu linkler ALICI’ya yönlenme kolaylığı
        sağlamak amacıyla konmuş olup herhangi bir web sitesini veya o siteyi işleten kişiyi desteklememekte ve Link verilen web
        sitesinin içerdiği bilgilere yönelik herhangi bir garanti niteliği taşımamaktadır. 9.16. İşbu sözleşme içerisinde
        sayılan maddelerden bir ya da birkaçını ihlal eden üye işbu ihlal nedeniyle cezai ve hukuki olarak şahsen sorumlu olup,
        SATICI’yı bu ihlallerin hukuki ve cezai sonuçlarından ari tutacaktır. Ayrıca; işbu ihlal nedeniyle, olayın hukuk alanına
        intikal ettirilmesi halinde, SATICI’nın üyeye karşı üyelik sözleşmesine uyulmamasından dolayı tazminat talebinde bulunma
        hakkı saklıdır.
      </p>
      <h3 className="mt-2 font-medium">10. CAYMA HAKKI</h3>
      <p>
        10.1. ALICI; mesafeli sözleşmenin mal satışına ilişkin olması durumunda, ürünün kendisine veya gösterdiği adresteki
        kişi/kuruluşa teslim tarihinden itibaren 14 (on dört) gün içerisinde, SATICI’ya bildirmek şartıyla hiçbir hukuki ve
        cezai sorumluluk üstlenmeksizin ve hiçbir gerekçe göstermeksizin malı reddederek sözleşmeden cayma hakkını kullanabilir.
        Hizmet sunumuna ilişkin mesafeli sözleşmelerde ise, bu süre sözleşmenin imzalandığı tarihten itibaren başlar. Cayma
        hakkı süresi sona ermeden önce, a) 3. kişiye veya ALICI’ ya teslim edilen ürünün faturası, (İade edilmek istenen ürünün
        faturası kurumsal ise, iade ederken kurumun düzenlemiş olduğu iade faturası ile birlikte gönderilmesi gerekmektedir.
        Faturası kurumlar adına düzenlenen sipariş iadeleri İADE FATURASI kesilmediği takdirde tamamlanamayacaktır.) b) İade
        formu, c) İade edilecek ürünlerin kutusu, ambalajı, varsa standart aksesuarları ile birlikte eksiksiz ve hasarsız olarak
        teslim edilmesi gerekmektedir. d) SATICI, cayma bildiriminin kendisine ulaşmasından itibaren en geç 10 günlük süre
        içerisinde toplam bedeli ve ALICI’yı borç altına sokan belgeleri ALICI’ ya iade etmek ve 20 günlük süre içerisinde malı
        iade almakla yükümlüdür. e) ALICI’ nın kusurundan kaynaklanan bir nedenle malın değerinde bir azalma olursa veya iade
        imkânsızlaşırsa ALICI kusuru oranında SATICI’ nın zararlarını tazmin etmekle yükümlüdür. Ancak cayma hakkı süresi içinde
        malın veya ürünün usulüne uygun kullanılması sebebiyle meydana gelen değişiklik ve bozulmalardan ALICI sorumlu değildir.
        f) Cayma hakkının kullanılması nedeniyle SATICI tarafından düzenlenen kampanya limit tutarının altına düşülmesi halinde
        kampanya kapsamında faydalanılan indirim miktarı iptal edilir.
      </p>
      <h3 className="mt-2 font-medium">11. CAYMA HAKKI KULLANILAMAYACAK ÜRÜNLER</h3>
      <p>
        ALICI’nın isteği veya açıkça kişisel ihtiyaçları doğrultusunda hazırlanan ve geri gönderilmeye müsait olmayan, iç giyim
        alt parçaları, mayo ve bikini altları, makyaj malzemeleri, tek kullanımlık ürünler, çabuk bozulma tehlikesi olan veya
        son kullanma tarihi geçme ihtimali olan mallar, ALICI’ya teslim edilmesinin ardından ALICI tarafından ambalajı açıldığı
        takdirde iade edilmesi sağlık ve hijyen açısından uygun olmayan ürünler, teslim edildikten sonra başka ürünlerle karışan
        vedoğası gereği ayrıştırılması mümkün olmayan ürünler, Abonelik sözleşmesi kapsamında sağlananlar dışında, gazete ve
        dergi gibi süreli yayınlara ilişkin mallar, Elektronik ortamda anında ifa edilen hizmetler veya tüketiciye anında teslim
        edilen gayrimaddi mallar,ile ses veya görüntü kayıtlarının, kitap, dijital içerik, yazılım programlarının, veri
        kaydedebilme ve veri depolama cihazlarının, bilgisayar sarf malzemelerinin, ambalajının ALICI tarafından açılmış olması
        halinde iadesi Yönetmelik gereği mümkün değildir. Ayrıca Cayma hakkı süresi sona ermeden önce, tüketicinin onayı ile
        ifasına başlanan hizmetlere ilişkin cayma hakkının kullanılması daYönetmelik gereği mümkün değildir. Kozmetik ve kişisel
        bakım ürünleri, iç giyim ürünleri, mayo, bikini, kitap, kopyalanabilir yazılım ve programlar, DVD, VCD, CD ve kasetler
        ile kırtasiye sarf malzemeleri (toner, kartuş, şerit vb.) iade edilebilmesi için ambalajlarının açılmamış, denenmemiş,
        bozulmamış ve kullanılmamış olmaları gerekir.
      </p>
      <h3 className="mt-2 font-medium">12. TEMERRÜT HALİ VE HUKUKİ SONUÇLARI</h3>
      <p>
        ALICI, ödeme işlemlerini kredi kartı ile yaptığı durumda temerrüde düştüğü takdirde, kart sahibi banka ile arasındaki
        kredi kartı sözleşmesi çerçevesinde faiz ödeyeceğini ve bankaya karşı sorumlu olacağını kabul, beyan ve taahhüt eder. Bu
        durumda ilgili banka hukuki yollara başvurabilir; doğacak masrafları ve vekâlet ücretini ALICI’dan talep edebilir ve her
        koşulda ALICI’nın borcundan dolayı temerrüde düşmesi halinde, ALICI, borcun gecikmeli ifasından dolayı SATICI’nın
        uğradığı zarar ve ziyanını ödeyeceğini kabul, beyan ve taahhüt eder
      </p>
      <h3 className="mt-2 font-medium">13. YETKİLİ MAHKEME</h3>
      <p>
        İşbu sözleşmeden doğan uyuşmazlıklarda şikayet ve itirazlar, aşağıdaki kanunda belirtilen parasal sınırlar dâhilinde
        tüketicinin yerleşim yerinin bulunduğu veya tüketici işleminin yapıldığı yerdeki tüketici sorunları hakem heyetine veya
        tüketici mahkemesine yapılacaktır. Parasal sınıra ilişkin bilgiler aşağıdadır: 28/05/2014 tarihinden itibaren geçerli
        olmak üzere: a) 6502 sayılı Tüketicinin Korunması Hakkında Kanun’un 68. Maddesi gereği değeri 2.000,00 (ikibin) TL’nin
        altında olan uyuşmazlıklarda ilçe tüketici hakem heyetlerine, b) Değeri 3.000,00(üçbin)TL’ nin altında bulunan
        uyuşmazlıklarda il tüketici hakem heyetlerine, c) Büyükşehir statüsünde bulunan illerde ise değeri 2.000,00 (ikibin) TL
        ile 3.000,00(üçbin)TL’ arasındaki uyuşmazlıklarda il tüketici hakem heyetlerine başvuru yapılmaktadır. İşbu Sözleşme
        ticari amaçlarla yapılmaktadır.
      </p>
      <h3 className="mt-2 font-medium">14. YÜRÜRLÜK</h3>
      <p>
        ALICI, Site üzerinden verdiği siparişe ait ödemeyi gerçekleştirdiğinde işbu sözleşmenin tüm şartlarını kabul etmiş
        sayılır. SATICI, siparişin gerçekleşmesi öncesinde işbu sözleşmenin sitede ALICI tarafından okunup kabul edildiğine dair
        onay alacak şekilde gerekli yazılımsal düzenlemeleri yapmakla yükümlüdür.
      </p>
      <div className="mt-4 text-right">
        <p>Satıcı: {sellerCompany.title}</p>
        <p>
          Alıcı: {user?.billingInfo?.title} - {user?.billingInfo?.name} {user?.billingInfo?.surname}
        </p>
        <p>Tarih: {new Date().toLocaleDateString()}</p>
      </div>
    </div>
  );
};

export default TermOfSale;
