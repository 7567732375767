import { createAsyncThunk } from '@reduxjs/toolkit';
import { httpsCallable } from 'firebase/functions';
import { db, auth, storage, functions } from "features/firebase";
import { getAuth, fetchSignInMethodsForEmail, sendEmailVerification, signInWithCustomToken } from "firebase/auth";
import {
    getFirestore,
    doc,
    collection,
    collectionGroup,
    documentId,
    query,
    serverTimestamp,
    where,
    getDocs,
    updateDoc,
    deleteDoc,
    getDoc,
    runTransaction,
} from "firebase/firestore";
import { signOut } from "features/auth/slice";


import notify from 'features/ui/notify';
import { signIn, loadProfile } from 'features/auth/slice';

export const loadUsers = createAsyncThunk("users/loadUsers", async (payload, { dispatch, getState }) => {

    const { selectedCompany } = getState().companies;
    const { id: companyId } = selectedCompany;

    const usersRef = doc(db, `companies/${companyId}/permissions/users`);
    const usersSnapshot = await getDoc(usersRef);
    const usersData = usersSnapshot.data();

    const userList = [];
    for (const user in usersData) {
        const userData = usersData[user];
        userList.push({ ...userData, id: user, uid: user });
    }
    return userList;
});


export const inviteUser = createAsyncThunk("users/inviteUser", async (payload, { dispatch, getState }) => {
    const { selectedCompany } = getState().companies;
    const { id: companyId } = selectedCompany;
    // add company id to payload
    payload.companyId = companyId;

    const userInvite = httpsCallable(functions, 'userInvite');
    try {
        const response = await userInvite({ ...payload });
        return response.data;
    } catch (error) {
        notify(error.message, "error")
    }
})

export const deleteUserFromCompany = createAsyncThunk("users/deleteUserFromCompany", async (payload, { dispatch, getState }) => {
    const { selectedCompany } = getState().companies;
    const { id: companyId } = selectedCompany;


    const deleteUserFromCompany = httpsCallable(functions, 'deleteUserFromCompany');
    try {
        await deleteUserFromCompany({ companyId, uid: payload.uid });
        return payload.uid
    } catch (error) {
        throw error
    }
})

export const checkIsEmailInUse = createAsyncThunk("users/checkIsEmailInUse", async (payload, { dispatch, getState }) => {
    const { email, companyID, inviteID } = payload;

    const isEmailUsed = await fetchSignInMethodsForEmail(auth, email);
    let result = null;

    if (isEmailUsed.length > 0) {
        result = `signin`;
    } else {
        result = `signup`;
    }

    await dispatch(signOut());
    return result;
})

export const acceptInvite = createAsyncThunk("users/acceptInvite", async (payload, { dispatch, getState }) => {
    const { companyID, inviteID, email, password, password2, mode } = payload;


    const acceptInvite = httpsCallable(functions, 'acceptInvite');
    try {
        const response = await acceptInvite({ companyID, inviteID, email, password, password2 });

        // if mode is signup
        if (mode === "signup") {
            // sign in with token
            const profile = response.data.profile
            if (profile) {
                // const token = userData.token
                // sign in with token
                await dispatch(signIn({ email, password }));
                await sendEmailVerification(auth.currentUser);
                window.location.href = "/";
                // await dispatch(loadProfile({ userData }));
            }
        }
        if (mode === "signin") {
            // const token = response.data.token
            await dispatch(signIn({ email, password }));
            window.location.href = "/";
            // await dispatch(loadProfile());
        }
        return response.data;
    } catch (error) {
        throw error
    }
})