import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { yup } from "util/yupLocale";
import ArrayForm from "../../ArrayForm";

import { prevStep, nextStep } from "features/transactions/slice";
import { updateTransaction } from "features/transactions/services";
import { updateAuthorities, loadAuthoritiesData, updateCapital, loadCapitalData } from "features/companyData/services";

const YonetimKuruluIbra = ({}) => {
  const dispatch = useDispatch();

  const { step } = useSelector((state) => state.transactions);
  const isRegistered = step.registered;
  // get last ten years only year like "2021"
  const years = Array.from({ length: 20 }, (_, i) => new Date().getFullYear() - i);

  const schemaObj = {
    yil: yup
      .string()
      .required()
      .label("Yılı")
      .meta({
        type: "select",
        className: "sm:w-2/3",
        component: "select",
        options: years.map((year) => ({ value: year, label: year })),
      }),
    yonetimKuruluAtamaTarihi: yup.string().required().label("İlgili Dönem için Yönetim Kurulu Atama Tarihi").meta({
      type: "date",
      className: "sm:w-2/3",
      component: "input",
    }),
    personelSayisi: yup.string().required().label("Personel Sayısı").meta({
      type: "number",
      className: "sm:w-2/3",
      component: "input",
    }),
    toplamHuzurHakki: yup.string().required().label("Toplam Huzur Hakkı (ilgili yılda tüm yöneticilere ödenen toplam)").meta({
      type: "text",
      className: "sm:w-2/3",
      component: "number",
    }),
    aktifToplami: yup.string().required().label("Aktif Toplamı (1 + 2)").meta({
      type: "text",
      className: "sm:w-2/3",
      component: "number",
    }),
    borclarToplami: yup.string().required().label("Borçlar Toplamı (3 + 4)").meta({
      type: "text",
      className: "sm:w-2/3",
      component: "number",
    }),
    toplamSabitKiymet: yup.string().required().label("Toplam Sabit Kıymet (25)").meta({
      type: "text",
      className: "sm:w-2/3",
      component: "number",
    }),
    toplamAmortisman: yup.string().required().label("Toplam Amortisman (257 açılış hariç)").meta({
      type: "text",
      className: "sm:w-2/3",
      component: "number",
    }),
    donemZarari: yup.string().required().label("Dönem Zararı").meta({
      type: "text",
      className: "sm:w-2/3",
      component: "number",
      // defaultValue: 0,
    }),
    donemKari: yup
      .string()
      .label("Dönem Karı (Vergi ÖNCESİ Kar)")
      .meta({
        type: "text",
        className: "sm:w-2/3",
        component: "number",
        defaultValue: 0,
        condition: {
          field: "donemZarari",
          value: 0,
        },
      })
      .when("donemZarari", {
        is: (value) => value === 0,
        then: yup.string().required(),
      }),
    donemVergi: yup
      .string()
      .label("Dönem Karı Vergi ve Yasal Yük.")
      .meta({
        type: "text",
        className: "sm:w-2/3",
        component: "number",
        defaultValue: 0,
        condition: {
          field: "donemZarari",
          value: 0,
        },
      })
      .when("donemZarari", {
        is: (value) => value === 0,
        then: yup.string().required(),
      }),
  };

  // const schema = "mudurAtama": yup.array().of(yup.object().shape(schemaObj))
  const schema = yup.object().shape({
    yonetimKuruluIbra: yup.array().of(yup.object().shape(schemaObj)),
  });

  const onUpdate = async (form) => {
    // kayitlarin ait oldugu defterler
    form.yillar = form.yonetimKuruluIbra.map((item) => {
      return item.yil;
    });

    // unique yillarString
    form.yillar = [...new Set(form.yillar)];
    // form.yillar sort ascending
    form.yillar.sort((a, b) => a - b);
    form.yillarString = form.yillar.join(", ");

    await dispatch(
      updateTransaction({
        data: {
          yonetimKuruluIbra: form,
        },
      })
    );
    dispatch(nextStep());
  };

  const onPrev = () => {
    dispatch(prevStep());
  };

  useEffect(() => {
    dispatch(loadAuthoritiesData());
    dispatch(loadCapitalData());
  }, []);

  const yonetimKuruluIbra = useSelector(
    (state) => state.transactions.selectedTransaction?.yonetimKuruluIbra?.yonetimKuruluIbra
  );

  const defaultItem = {
    yil: "",
  };

  return (
    <ArrayForm
      schema={schema}
      onSubmit={onUpdate}
      initData={yonetimKuruluIbra}
      itemLabel="yonetimKuruluIbra"
      defaultItem={defaultItem}
      title="Yönetim Kurulu İbra"
      description={
        "Genel kurul toplantısnın ait olduğu yılları, Genel kurul toplantısı ile yönetim kurulunu ibra edeceğiniz yılları giriniz.  Yıl bilgisinden sonra talep edilen bilgiler ilgili dönemin faaliyet raporunun hazırlanması için gerekli bilgilerdir."
      }
      onPrev={onPrev}
      isRegistered={isRegistered}
      totalFields={[{ label: "Sermaye arttırımının kardan karşılanan kısmı", fieldName: "tutar" }]}
    />
  );
};

export default YonetimKuruluIbra;
