import { createAsyncThunk } from '@reduxjs/toolkit';
import { httpsCallable } from 'firebase/functions';
import { db, auth, storage, functions } from "features/firebase";
import {
    doc,
    getDoc, getDocs,
    updateDoc,
    collection, where, query, orderBy, limit
} from "firebase/firestore";

import { removeAllPayments } from "features/admin/payments/slice"


export const loadPayments = createAsyncThunk("adminPayments/loadPayments", async (payload, { dispatch, getState }) => {
    const status = getState().adminPayments.searchParam.status;
    dispatch(removeAllPayments())
    const q = query(collection(db, "payments"), where("status", "==", status), orderBy("createdAt", "desc"));
    const paymentsSnapshot = await getDocs(q);
    const paymentsData = paymentsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

    return paymentsData;
});

export const loadPayment = createAsyncThunk("adminPayments/loadPayment", async (payload, { dispatch, getState }) => {
    const paymentId = payload;
    const paymentRef = doc(db, "payments", paymentId);
    const paymentSnapshot = await getDoc(paymentRef);
    const paymentData = paymentSnapshot.data();

    const uid = paymentData.uid;

    // get user data
    const userRef = doc(db, "users", uid);
    const userSnapshot = await getDoc(userRef);
    const userData = userSnapshot.data();
    paymentData.user = userData;


    return paymentData;
});

export const loadUsers = createAsyncThunk("adminusers/loadUsers", async (payload, { dispatch, getState }) => {
    // const status = getState().adminusers.searchParam.status;

    const { docLimit } = payload;

    const q = query(collection(db, "users"), orderBy("createdAt", "desc"), limit(docLimit));
    const usersSnapshot = await getDocs(q);
    const usersData = usersSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

    const subscriptions = usersData.map(user => {
        return getDoc(doc(db, "users", user.uid, "subs", "data")).then(subscriptionSnapshot => {
            if (subscriptionSnapshot.exists()) {
                user.subscription = subscriptionSnapshot.data();
            }
            return user;
        })
    })

    // /users/PuzAooC6at9LTLhB0K1y4lnCIMtu/permissions/companies
    const companies = usersData.map(user => {
        return getDoc(doc(db, "users", user.uid, "permissions", "companies")).then(companiesSnapshot => {
            if (companiesSnapshot.exists()) {
                user.companies = companiesSnapshot.data();
                user.companyLenth = Object.keys(companiesSnapshot.data()).length;
            }
            return user;
        })
    })

    await Promise.all(companies);
    await Promise.all(subscriptions);
    return usersData;
});

export const loadUser = createAsyncThunk("adminusers/loadUser", async (payload, { dispatch, getState }) => {
    const userId = payload;
    const userRef = doc(db, "users", userId);
    const userSubscriptionRef = doc(db, "users", userId, "subs", "data");
    const userCompaniesRef = doc(db, "users", userId, "permissions", "companies");
    const userSnapshot = await getDoc(userRef);
    const userData = userSnapshot.data();
    const userSubscriptionSnapshot = await getDoc(userSubscriptionRef);
    const userCompaniesSnapshot = await getDoc(userCompaniesRef);
    if (userSubscriptionSnapshot.exists()) {
        userData.subscription = userSubscriptionSnapshot.data();
    }
    if (userCompaniesSnapshot.exists()) {
        userData.companies = userCompaniesSnapshot.data();
        userData.companyLenth = Object.keys(userCompaniesSnapshot.data()).length;
    }

    return userData;
});


export const addCredit = createAsyncThunk("adminusers/addCredit", async (payload, { dispatch, getState }) => {
    const { userUid, creditAmount } = payload;
    const addCreditAdmin = httpsCallable(functions, 'addCreditAdmin');
    const result = await addCreditAdmin({ creditAmount, userUid });
    return result;
});

export const changePaymentStatus = createAsyncThunk("adminPayments/changePaymentStatus", async (payload, { dispatch, getState }) => {
    const { paymentId, status } = payload;
    const changePaymentStatus = httpsCallable(functions, 'changePaymentStatus');
    const result = await changePaymentStatus({ paymentId, status });
    return result;
});


export const getStatistics = createAsyncThunk("admin/getStatistics", async (payload, { dispatch, getState }) => {

    // user count
    // company count
    // transaction count

    // const userCount = await getDoc(doc(db, "statistics", "userCount"));

    let userCount = 0;
    let companyCount = 0;

    const companyColRef = collection(db, "companies");
    const companySnapshot = await getDocs(companyColRef);
    companyCount = companySnapshot.size;

    const userColRef = collection(db, "users");
    const userSnapshot = await getDocs(userColRef);
    userCount = userSnapshot.size;

    // // get all company transactions .size
    // const companyTransactions = await Promise.all(companySnapshot.docs.map(async company => {
    //     const companyTransactionColRef = collection(db, "companies", company.id, "transactions");
    //     const companyTransactionSnapshot = await getDocs(companyTransactionColRef);
    //     return companyTransactionSnapshot.size;
    // }))
    // transactionCount = companyTransactions.reduce((a, b) => a + b, 0);

    //92
    let statisticsData = {};
    const statisticsRef = doc(db, "statistics", "data");
    const statisticsSnapshot = await getDoc(statisticsRef);

    // is exists
    if (statisticsSnapshot.exists()) {
        statisticsData = statisticsSnapshot.data();
    }






    return { userCount, companyCount, statisticsData };
});


