export const convertDate = (date) => {
    const newDate = new Date(date);
    const day = newDate.getDate();
    const month = (newDate.getMonth() + 1).toString().padStart(2, "0");
    const year = newDate.getFullYear();
    return `${day}.${month}.${year}`;
};

export const dateForInput = (date) => {
    if (typeof date === "object" && date instanceof Date) {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const day = String(date.getDate()).padStart(2, "0");
        return `${year}-${month}-${day}`;
    }

    const regexDMY = /^\d{1,2}-\d{1,2}-\d{4}$/;
    if (regexDMY.test(date)) {
        const [day, month, year] = date.split("-").map(Number);
        return `${year}-${String(month).padStart(2, "0")}-${String(day).padStart(2, "0")}`;
    }

    const regexYMD = /^\d{4}-\d{2}-\d{2}$/;
    if (regexYMD.test(date)) {
        return date;
    }

    const regexISODate = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/;
    if (regexISODate.test(date)) {
        return date.slice(0, 10);
    }

    if (date.nanoseconds || date.seconds) {
        // if date is firebase timestamp
        const dateObj = new Date(date.seconds * 1000);
        const year = dateObj.getFullYear();
        const month = String(dateObj.getMonth() + 1).padStart(2, "0");
        const day = String(dateObj.getDate()).padStart(2, "0");
        return `${year}-${month}-${day}`;
    }

    return null;
};

export const dateForRender = (date) => {
    if (typeof date === "object" && date instanceof Date) {
        const day = String(date.getDate()).padStart(2, "0");
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const year = date.getFullYear();
        return `${day}.${month}.${year}`;
    }

    const regexYMD = /^\d{4}-\d{2}-\d{2}$/;
    if (regexYMD.test(date)) {
        const [year, month, day] = date.split("-");
        return `${day}.${month}.${year}`;
    }

    const regexDMY = /^\d{1,2}-\d{1,2}-\d{4}$/;
    if (regexDMY.test(date)) {
        return date;
    }

    const regexISODate = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/;
    if (regexISODate.test(date)) {
        const [year, month, day] = date.slice(0, 10).split("-");
        return `${day}.${month}.${year}`;
    }

    if (date.nanoseconds || date.seconds) {
        // if date is firebase timestamp
        const dateObj = new Date(date.seconds * 1000);
        const day = String(dateObj.getDate()).padStart(2, "0");
        const month = String(dateObj.getMonth() + 1).padStart(2, "0");
        const year = dateObj.getFullYear();
        const hours = String(dateObj.getHours()).padStart(2, "0");
        const minutes = String(dateObj.getMinutes()).padStart(2, "0");
        return `${day}.${month}.${year} ${hours}:${minutes}`;
    }

    return null;
};

export function isoToSecNano(isoDate) {
    const date = new Date(isoDate);
    const seconds = Math.floor(date.getTime() / 1000);
    const nanoseconds = (date.getTime() % 1000) * 1e6;
    return { seconds, nanoseconds };
}

export const convertToDateObj = (date) => {
    if (typeof date === "object" && date instanceof Date) {
        return date;
    }

    const regexDMY = /^\d{1,2}-\d{1,2}-\d{4}$/;
    if (regexDMY.test(date)) {
        const [day, month, year] = date.split("-").map(Number);
        return new Date(year, month - 1, day);
    }

    const regexYMD = /^\d{4}-\d{2}-\d{2}$/;
    if (regexYMD.test(date)) {
        const [year, month, day] = date.split("-").map(Number);
        return new Date(year, month - 1, day);
    }

    const regexISODate = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/;
    if (regexISODate.test(date)) {
        return new Date(date.slice(0, 10));
    }

    if (date.nanoseconds || date.seconds) {
        // if date is firebase timestamp
        return new Date(date.seconds * 1000);
    }

    return null;
};
