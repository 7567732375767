import { createAsyncThunk } from '@reduxjs/toolkit';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { getAuth } from "firebase/auth";
import { getFirestore, doc, setDoc, getDoc, updateDoc, collection, getDocs } from "firebase/firestore";
import { db, auth, storage, functions } from "features/firebase";

const searchColPath = (companyId, type) => `/companies/${companyId}/search/${type}/indexes`;


export const loadSearchData = createAsyncThunk("search/loadSearchData", async (payload, { dispatch, getState }) => {

    const companyId = getState().companies.selectedCompany.id;
    const types = getState().search.types;
    // for of types 
    const resultArray = [];

    for (const type of types) {
        const refPath = searchColPath(companyId, type)
        const searchColRef = collection(db, refPath);
        const snapShot = await getDocs(searchColRef);
        const searchData = snapShot.docs.map(doc => doc.data());

        searchData.forEach(object => {
            for (const key in object) {
                const id = key;
                const data = object[key];
                resultArray.push({ id, ...data, type });
            }
        });
    }

    return resultArray;
});

export const loadSingleSearchData = createAsyncThunk("search/loadSingleSearchData", async (payload, { dispatch, getState }) => {
    const companyId = getState().companies.selectedCompany.id;
    const { type, id,searchIndexId } = payload;
    const refPath = searchColPath(companyId, type)
    const searchDocRef = doc(db, refPath, searchIndexId.toString());
    const snapShot = await getDoc(searchDocRef);
    const searchData = snapShot.data();
    const data = searchData[id];
    return { id, ...data, type };
});
