import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import ROUTES from "features/navigation/routes";
import Input from "features/ui/Input";
import Button from "features/ui/Button";
import { resetPassword, changePassword } from "features/auth/slice";
import { yup, yupResolver, useForm } from "util/yupLocale";

const schema = yup.object({
  newPassword: yup
    .string()
    .required()
    .min(6)
    .label("Parola")
    .oneOf([yup.ref("newPassword2"), null], "Parolalar eşleşmiyor"),
  newPassword2: yup
    .string()
    .required()
    .min(6)
    .label("Parolayı Doğrula")
    .oneOf([yup.ref("newPassword"), null], "Parolalar eşleşmiyor"),
});

const ChangePassword = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const onCancel = () => navigate(ROUTES.PROFILE.path, { replace: true });
  const onChangePassword = async (form) => {
    await dispatch(changePassword(form));
    // navigate home
    navigate(ROUTES.HOME.path, { replace: true });
  };
  const onResetPassword = async (e) => {
    e.preventDefault();
    await dispatch(resetPassword());
    // navigate home
    navigate(ROUTES.HOME.path, { replace: true });
  };

  return (
    <div className="mt-10 sm:mt-0">
      <div className="md:grid md:grid-cols-3 md:gap-6">
        <div className="mt-5 md:col-span-2 md:mt-0">
          <form onSubmit={handleSubmit(onChangePassword)}>
            <div className="overflow-hidden shadow sm:rounded-md">
              <div className="bg-white px-4 py-5 dark:bg-gray-900 sm:p-6">
                <div className="sm:border-gray-200">
                  <Input
                    label="Yeni Parola"
                    inputType="label-left"
                    type="password"
                    error={errors.newPassword?.message}
                    {...register("newPassword")}
                  />
                  <Input
                    label="Yeni Parola Tekrar"
                    inputType="label-left"
                    type="password"
                    error={errors.newPassword2?.message}
                    {...register("newPassword2")}
                  />
                </div>
              </div>

              <div className="bg-gray-50 px-4 py-3 text-right dark:bg-gray-700 sm:px-6">
                <Button type="submit" color="secondary" onClick={onResetPassword}>
                  Parolamı Hatırlamıyorum
                </Button>
                <Button className="mx-2" color="secondary" onClick={onCancel}>
                  Vazgeç
                </Button>
                <Button type="submit" onClick={handleSubmit(onChangePassword)}>
                  Parola Değiştir
                </Button>
              </div>
            </div>
          </form>
        </div>
        <div className="mt-5 sm:mt-0 md:col-span-1">
          <div className="px-4 sm:px-0">
            <>
              <h3 className="text-lg font-medium leading-6 text-gray-900 dark:text-white">Parola Değiştir</h3>
              <p className="mt-1 text-sm text-gray-600 dark:text-gray-400"></p>
            </>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
