import {  useState } from "react";

import Button from "features/ui/Button";
import FormLayout from "features/ui/FormLayout";
/*
  This example requires some changes to your config:
  
  title: "adresSgkBilgileri"
  selected:[
    {
      
    }
  ]
*/
export default function Example({ title, description, initData, onSubmit, onPrev, dataList }) {
  const [selected, setSelected] = useState(initData || []);

  const handleSelect = (item) => {

    const index = selected.findIndex((i) => i.id === item.id);
    if (index === -1) {
      setSelected([...selected, item]);
    } else {
      const dc = [...selected];
      dc.splice(index, 1);
      setSelected(dc);
    }
  };

  if (!dataList) return null;
  if (!dataList.length) return null;
  
  return (
    <div className="mt-10 md:col-span-2 md:mt-0">
      <div className="overflow-hidden shadow sm:rounded-md">
        <div className=" col-span-12 overflow-y-scroll  bg-white shadow sm:rounded-lg"></div>
        <FormLayout title={title} description={description}>
          <fieldset>
            <legend className="sr-only">Notifications</legend>
            <div className="space-y-5">
              {dataList.map(({ id, label, description, obj }) => (
                <div key={id} className="relative flex items-start">
                  <div className="flex h-6 items-center">
                    <input
                      id={id}
                      aria-describedby={`${id}-description`}
                      name={id}
                      type="checkbox"
                      className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                      onChange={() => {
                        handleSelect(obj);
                      }}
                      checked={selected.findIndex((i) => i.id === obj.id) !== -1}
                    />
                  </div>
                  <div className="ml-3 text-sm leading-6">
                    <label htmlFor={id} className="font-medium text-gray-900">
                      {label}
                    </label>
                    <p id={`${id}-description`} className="text-gray-500">
                      {description}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </fieldset>
        </FormLayout>
        <div className="space-x-2 bg-gray-50 px-4 py-3 text-right dark:bg-gray-700 sm:px-6">
          <Button color="secondary" type="button" onClick={onPrev}>
            Geri
          </Button>
          <Button
            onClick={() => {
              onSubmit(selected);
            }}
          >
            Kaydet
          </Button>
        </div>
      </div>
    </div>
  );
}
