import { useSelector } from "react-redux";
import { ChevronUpDownIcon } from "@heroicons/react/20/solid";
import { Menu } from "@headlessui/react";

import ProfileMenuDropdown from "features/layout/navProfileMenu/ProfileMenuDropdown";
import UserPhoto from "features/ui/UserPhoto";

const ProfileMenu = ({ type }) => {
  const user = useSelector((state) => state.auth.user);
  const selectedCompany = useSelector((state) => state.companies?.selectedCompany);

  if (!user?.displayName) return null;
  if (type === "Mobile")
    return (
      <>
        <div>
          <Menu.Button className="flex max-w-xs items-center rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2">
            <span className="sr-only">Open user menu</span>
            <UserPhoto className="h-8 w-8 rounded-full" url={user.photoURL} />
          </Menu.Button>
        </div>
        <ProfileMenuDropdown className="absolute right-0 mt-2 w-48 origin-top-right divide-y divide-gray-200 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none dark:bg-gray-800" />
      </>
    );
  else
    return (
      <>
        <div>
          <Menu.Button className="group w-full rounded-md px-3.5 py-2 text-left text-sm font-medium text-gray-700  hover:bg-gray-200 focus:outline-none dark:text-gray-100 dark:hover:bg-gray-800 ">
            <span className="flex w-full items-center justify-between">
              <span className="flex min-w-0 items-center justify-between space-x-3">
                <UserPhoto className="h-10 w-10 flex-shrink-0 rounded-full bg-gray-300" url={user.photoURL} />

                <span className="flex min-w-0 flex-1 flex-col">
                  <span className="truncate text-sm font-medium text-gray-900 dark:text-gray-100">{user?.displayName}</span>
                  {selectedCompany && <span className="truncate text-sm text-gray-500">{selectedCompany.title}</span>}
                </span>
              </span>
              <ChevronUpDownIcon className="h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
            </span>
          </Menu.Button>
        </div>
        <ProfileMenuDropdown className="absolute right-0 left-0 z-10 mx-3 mt-1 origin-top divide-y divide-gray-200 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none dark:bg-gray-800" />
      </>
    );
};

export default ProfileMenu;
