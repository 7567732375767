import { Controller } from "util/yupLocale";
import { useSelector, useDispatch } from "react-redux";

import InputMask from "react-input-mask";

import Input from "features/ui/Input";
import Button from "features/ui/Button";
import Select from "features/ui/Select";
import Loading from "features/ui/Loading";
import Alert from "features/ui/Alert";

const CompanyInfo = ({
  mode,
  handleSubmit,
  onSaveCompany,
  loading,
  errors,
  register,
  control,
  onGetDataFromIvd,
  handleDeleteCompany,
  selectedCompany,
  errorMessage,
}) => {
  const { levhaStatus } = useSelector((state) => state.companies);
  const isErrorMessage = errorMessage ? true : false;
  if (levhaStatus === "loading") return <Loading text="Vergi Numarası Sorgulanıyor..." />;
  if (loading) return <Loading text="Firma Bilgileri Yükleniyor..." />;
  return (
    <div className="pt-3 md:grid md:grid-cols-3 md:gap-6">
      <div className="mt-10 md:col-span-2 md:mt-0">
        {!selectedCompany && mode === "edit" ? (
          <div className="dark:text-white">Duzenlemek icin bir firma secmelisiniz</div>
        ) : (
          <form onSubmit={handleSubmit(onSaveCompany)}>
            {/* <Alert> Uygulama şimdilik şahıs firmaları için kullanılamamaktadır. Limited veya Anonim şirket eklemeye çalışın.</Alert> */}
            <div className="overflow-hidden shadow sm:rounded-md">
              <div className="bg-white px-4 py-5 dark:bg-gray-900 sm:p-6">
                <div className="py-3">
                  {/* <h2 className="text-base font-semibold leading-7 text-gray-900">Firma Bilgileri</h2> */}
                  <p className="mt-1 text-sm leading-6 text-gray-600">
                    Adına ticaret sicil işlemi yapacağınız firmanın bilgilerini giriniz. ( mükellefe ait bilgiler )
                  </p>
                </div>
                <div className="grid grid-cols-6 gap-6">
                  <>
                    <Input
                      divClass="col-span-6 sm:col-span-3"
                      label="Vergi / TC Kimlik No"
                      inputType="label-top"
                      disabled={loading}
                      error={errors.tcVkn?.message}
                      {...register("tcVkn")}
                    />
                    <Input
                      divClass="col-span-6 sm:col-span-3"
                      label="Vergi Dairesi"
                      inputType="label-top"
                      disabled={loading}
                      error={errors.taxAdmin?.message}
                      {...register("taxAdmin")}
                    />{" "}
                    <Input
                      divClass="col-span-6 sm:col-span-6"
                      inputType="label-top"
                      label="Firma Ünvanı"
                      placeholder="Şahıs firmalarında ad soyad"
                      disabled={loading}
                      error={errors.title?.message}
                      {...register("title")}
                    />
                    <Input
                      divClass="col-span-6 sm:col-span-6"
                      label="Adres"
                      inputType="label-top"
                      disabled={loading}
                      error={errors.address?.message}
                      {...register("address")}
                    />
                    <Select
                      divClass="col-span-6 sm:col-span-3"
                      label="Firma Türü"
                      inputType="label-top"
                      disabled={loading}
                      // options={["Şahıs Firması", "Limited", "Anonim"]}
                      options={[
                        { value: "Şahıs Firması", label: "Şahıs Firması" },
                        { value: "Limited", label: "Limited" },
                        { value: "Anonim", label: "Anonim" },
                      ]}
                      error={errors.companyType?.message}
                      {...register("companyType")}
                    />
                    <Input
                      divClass="col-span-6 sm:col-span-3"
                      label="İşe Başlama Tarihi"
                      inputType="label-top"
                      type="date"
                      disabled={loading}
                      error={errors.startDate?.message}
                      {...register("startDate")}
                    />
                    <Input
                      divClass="col-span-6 sm:col-span-6"
                      label="Nace Kodu"
                      inputType="label-top"
                      error={errors.naceCode?.message}
                      disabled={loading}
                      {...register("naceCode")}
                    />
                  </>
                </div>
              </div>

              {errorMessage && <Alert> {errorMessage}</Alert>}

              <div className="space-x-2 bg-gray-50 px-4 py-3 text-right dark:bg-gray-700 sm:px-6">
                <Button type="submit" disabled={loading || isErrorMessage} onClick={handleSubmit(onSaveCompany)}>
                  {mode === "edit" ? "Güncelle" : "Ekle"}
                </Button>

                {/* {mode === "edit" && (
                  <Button color="danger" disabled={loading} onClick={handleDeleteCompany}>
                    Sil{" "}
                  </Button>
                )} */}
              </div>
            </div>
          </form>
        )}
      </div>
      <div className="mt-5 sm:mt-0 md:col-span-1 ">
        <div className="px-4 sm:px-0">
          <h3 className="text-lg font-medium leading-6 text-gray-900 dark:text-white ">Firma Bilgileri</h3>
          <p className="mt-1 text-sm text-gray-600 dark:text-gray-400 ">Firma bilgilerini doldurunuz</p>
          <p className="mt-5 text-sm text-gray-600 dark:text-gray-400 ">
            Şahıs firmalarında TC Kimlik No, Limited veya Anonim şirketlerde Vergi Kimlik Numarası giriniz.
          </p>
        </div>
      </div>
    </div>
  );
};

export default CompanyInfo;
