import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Logo from "features/ui/Logo";
import { Outlet, useNavigate } from "react-router-dom";
import { loadProfile } from "features/auth/slice";
import Loader from "features/ui/Loader";

import iyzicoLogo from "../../features/assets/iyzicoLogo.png";

import ROUTES from "features/navigation/routes";

const Services = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { selectedCompany } = useSelector((state) => state.companies);
  const user = useSelector((state) => state.auth.user);

  const { loading } = useSelector((state) => state.shop);

  useEffect(() => {
    (async () => {
      await dispatch(loadProfile());
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (Object.keys(user).length === 0) return;
    if (Object.keys(selectedCompany).length === 0) {
      navigate(ROUTES.HOME.path);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  if (loading)
    return (
      <div className="flex min-h-screen flex-col items-center justify-center bg-gray-200">
        <Logo className="h-36" />
        <div className="h-36 text-center">
          <Loader type="1" className="ml-10" />
        </div>
      </div>
    );

  return (
    <div className="min-h-screen bg-gray-50 dark:bg-gray-800">
      <div className="p-5 px-0">
        <div to="/" className="flex flex-shrink-0 items-center">
          <Logo className="mx-auto h-28" />
        </div>
      </div>
      <Outlet />
      {/* add image src is iyzicoLogo  as centered with div */}
      <div className="flex items-center justify-center pt-5">
        <img src={iyzicoLogo} alt="iyzico" className="h-6" />
      </div>
    </div>
  );
};

export default Services;
