const FormLayout = ({ children, title, description, formBadge }) => {
  return (
    <>
      <div className="flex items-center justify-center px-4 py-3 sm:px-6">{formBadge}</div>
      <div className="gap grid-cols-6 gap-3 border-b p-4 sm:grid sm:p-6">
        <div className="col-span-2 p-2 ">
          <h3 className="text-lg font-medium leading-6 text-gray-900">{title}</h3>
          <p className="mt-1 max-w-2xl text-sm text-gray-500">{description}</p>
        </div>
        <div className="col-span-4  space-y-3 px-2">{children}</div>
      </div>
    </>
  );
};

export default FormLayout;
