import React, { useEffect } from "react";

import { useSelector, useDispatch } from "react-redux";
import Button from "features/ui/Button";
import { loadTransactionFiles, removeTransactionFromDb, copyTransaction } from "features/transactions/services";
import { PaperClipIcon } from "@heroicons/react/20/solid";
import Loading from "features/ui/Loading";
import { router } from "features/navigation/Navigator";

import { downloadFilesPDFDoc } from "functions/downloadFiles";
import Alert from "features/ui/Alert";
import DeleteTransaction from "features/transactions/DeleteTransaction";
import ROUTES from "features/navigation/routes";

export default function ShowDocuments() {
  const dispatch = useDispatch();

  const { id, transactionTypes } = useSelector((state) => state.transactions.selectedTransaction);
  const attachments = useSelector((state) => state.transactions.attachments);
  const companyID = useSelector((state) => state.companies.selectedCompany.id);

  const status = useSelector((state) => state.transactions.status);
  const loading = status === "loading";

  useEffect(() => {
    // update Attachments
    handleUpdateFiles();
  }, []);

  const handleUpdateFiles = async () => {
    await dispatch(
      loadTransactionFiles({
        id,
      })
    );
  };

  const convertToMb = (bytes) => {
    return (bytes / (1024 * 1024)).toFixed(2) + " Mb";
  };

  const downloadFile = (fileData) => {
    downloadFilesPDFDoc({ fileData, companyID, transactionID: id, fileId: fileData.id, download: true });
  };

  const hendleCopyTransaction = async () => {
    await dispatch(copyTransaction({ id }));
    router.navigate("/transaction/list");
  };

  if (loading) return <Loading />;

  return (
    <div className="mt-10 md:col-span-2 md:mt-0">
      <div className=" col-span-12 overflow-y-scroll bg-white  shadow sm:rounded-lg">
        <div className="p-4">
          <div className="px-4 sm:px-0">
            <h3 className="text-base font-semibold leading-7 text-gray-900">Özet</h3>
            <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">İşlem Özeti</p>
          </div>
          <div className="mt-6 border-t border-gray-100">
            <dl className="divide-y divide-gray-100">
              <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                <dt className="text-sm font-medium leading-6 text-gray-900">İşlem Tipi</dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{transactionTypes.join(", ")}</dd>
              </div>

              <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                <dt className="text-sm font-medium leading-6 text-gray-900">Uyarılar:</dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                  <Alert color="yellow-50">
                    <p className="text-yellow-700">Lütfen oluşturulan belgeleri dikkatlice kontrol ediniz.</p>
                    <p className="text-yellow-700">Belgelerin doğruluğunu kontrol etmek sizin sorumluluğunuzdadır. </p>
                    <p className="text-yellow-700">
                      tescilge.com ticaret sicil işlemlerinizde hazırladığınız belgeleri daha pratik bir şekilde oluşturmanız ve
                      zaman kazanmanız için yardımcı bir araçtır. Belgelerin doğruluğunu garanti etmez.
                    </p>
                    <p className="text-yellow-700">Bu belgeleri indirerek bu koşulları kabul etmiş sayılırsınız. </p>
                  </Alert>
                </dd>
                <dt className="text-sm font-medium leading-6 text-gray-900">Bilgi:</dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                  <Alert color="teal-50">
                    <p className="text-teal-700">
                      Tüm belgeler PDF oalrak oluşturulmaktaadır. Belgeleri bilgisayarınıza indirdikten sonra sağ tıklayıp WORD
                      ile açarak belgeleri dilediğiniz gibi düzenleyebilirsiniz.
                    </p>
                  </Alert>
                </dd>
              </div>

              {/* <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                <dt className="text-sm font-medium leading-6 text-gray-900">Yeni Adres</dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{newAddress?.newAddress}</dd>
              </div> */}
              <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                <dt className="text-sm font-medium leading-6 text-gray-900">Belgeler</dt>

                <dd className="mt-2 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                  <ul className="divide-y divide-gray-100 rounded-md border border-gray-200">
                    {attachments.map((attachment) => (
                      <li className="flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6">
                        <div className="flex w-0 flex-1 items-center">
                          <PaperClipIcon className="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                          <div className="ml-4 flex min-w-0 flex-1 gap-2">
                            <span className="truncate font-medium">{attachment.name}</span>
                            <span className="flex-shrink-0 text-gray-400">{convertToMb(attachment.meta.size)}</span>
                          </div>
                        </div>
                        <div className="ml-4 flex-shrink-0">
                          <span
                            onClick={() => downloadFile(attachment)}
                            className="cursor-pointer font-medium text-indigo-600 hover:text-indigo-500"
                          >
                            indir
                          </span>
                        </div>
                      </li>
                    ))}
                  </ul>
                </dd>
              </div>
            </dl>
            {/* Button "Belgeleri Oluştur" */}
          </div>
        </div>
        <div className="overflow-hidden shadow sm:rounded-md">
          <div className="space-x-2 bg-gray-50 px-4 py-3 text-right dark:bg-gray-700 sm:px-6">
            <DeleteTransaction
              id={id}
              modalButtonComponent={
                <Button
                  // onClick={handleRemoveTransaction}
                  // size="sm"
                  className="inline-flex items-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none"
                >
                  Sil
                </Button>
              }
              status={"documentCreated"}
              navigateTo={ROUTES.TRANSACTIONS_DONE.path}
            />
            <Button
              onClick={hendleCopyTransaction}
              // size="sm"
              className="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none"
            >
              Kopyala
            </Button>

            <Button
              onClick={handleUpdateFiles}
              // size="sm"
              className="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none"
            >
              Belgeleri Güncelle
            </Button>
            <Button
              onClick={() => {
                router.navigate(ROUTES.TRANSACTIONS_DONE.path);
              }}
              color="secondary"
              type="button"
            >
              Listeye Dön
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
