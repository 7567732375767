import React, { Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Menu, Transition } from "@headlessui/react";

import ROUTES from "features/navigation/routes";
import { signOut } from "features/auth/slice";
import { router } from "features/navigation/Navigator";

import DarkModeSwitch from "features/ui/DarkModeSwitch";

const ProfileMenuDropdown = (props) => {
  const dispatch = useDispatch();

  const onSignOut = async () => {
    await dispatch(signOut());
    router.navigate(ROUTES.SIGN_IN.path, { replace: true });
  };

  const { className } = props;
  const profileMenuItems = [
    {
      title: "Ayarlar",
      disabled: false,
      subMenu: [
        {
          title: "Firma Değiştir",
          page: ROUTES.COMPANIES_SELECT.path,
          disabled: true,
          icon: null,
          notification: 5,
          // onClick: onCompaniesList,
        },

        // {
        //   title: "Kullanıcılar",
        //   page: ROUTES.USERS.path,
        //   disabled: true,
        //   icon: null,
        //   notification: 5,
        // },
      ],
    },
    {
      title: "Profil",
      disabled: false,
      subMenu: [
        {
          title: "Profil",
          page: ROUTES.PROFILE.path,
          disabled: true,
          icon: null,
          notification: 5,
        },
      ],
    },
    {
      title: "bölüm3",
      disabled: false,
      subMenu: [
        {
          title: "Çıkış Yap",
          page: "/",
          disabled: true,
          icon: null,
          notification: 5,
          onClick: onSignOut,
        },
      ],
    },
  ];

  return (
    <Transition
      as={Fragment}
      enter="transition ease-out duration-100"
      enterFrom="transform opacity-0 scale-95"
      enterTo="transform opacity-100 scale-100"
      leave="transition ease-in duration-75"
      leaveFrom="transform opacity-100 scale-100"
      leaveTo="transform opacity-0 scale-95"
    >
      <Menu.Items className={className}>
        {profileMenuItems.map((menu) => {
          return (
            <div className="py-1" key={menu.title}>
              <div>
                {" "}
                {menu.subMenu.map((x) => (
                  <MenuItem key={x.title} page={x.page} title={x.title} onClick={x.onClick} />
                ))}{" "}
              </div>
            </div>
          );
        })}
        <div className="py-2 pl-3">
          <DarkModeSwitch
            label="Koyu Mod"
            //enabled={darkMode} setEnabled={setDarkMode}
          />
        </div>
      </Menu.Items>
    </Transition>
  );
};

export default ProfileMenuDropdown;

const MenuItem = ({ page, title, onClick }) => {
  return (
    <Menu.Item>
      {({ active }) => (
        <Link
          onClick={onClick && onClick}
          className={`${
            active ? "bg-gray-100 text-gray-900 dark:bg-gray-800 dark:text-gray-50  " : "text-gray-700 dark:text-gray-300"
          } 
                block px-4 py-2 text-sm`}
          to={page || "#"}
        >
          {title}
        </Link>
      )}
    </Menu.Item>
  );
};
