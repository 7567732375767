import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { yup } from "util/yupLocale";
import ArrayForm from "features/transactions/ArrayForm";

import { prevStep, nextStep } from "features/transactions/slice";
import { updateCapital, loadCapitalData } from "features/companyData/services";
import yupObjGenerator from "features/transactions/yupObjGenerator";

const schemaObj = {
  adSoyad: yup
    .string()
    .label("Ad Soyad")
    .required("Ad Soya Zorunlu Alandır")
    .meta({
      type: "text",
      className: "sm:w-2/3",
      component: "input",
      transactionTypes: [
        "Adres Değişikliği",
        "Unvan Değişikliği",
        "Tasfiye Başlangıcı",
        "Tasfiye Sonu",
        "Merkez Nakli",
        "Şube Açılışı",
        "Şube Kapanışı",
        "Müdür Atama",
        "Müdür İstifa",
        "Hisse Devri",
        "Sermaye Arttırımı",
        "Olağan Genel Kurul",
        "Yönetim Kurulu Üye İstifa",
      ],
    }),
  tcVkn: yup
    .string()
    .required()
    .label("TC Kimlik No")
    .min(11)
    .max(11)
    .meta({
      type: "text",
      className: "sm:w-2/3",
      component: "input",
      transactionTypes: [
        "Adres Değişikliği",
        "Unvan Değişikliği",
        "Tasfiye Başlangıcı",
        "Tasfiye Sonu",
        "Merkez Nakli",
        "Şube Açılışı",
        "Şube Kapanışı",
        "Müdür Atama",
        "Müdür İstifa",
        "Hisse Devri",
        "Sermaye Arttırımı",
        "Olağan Genel Kurul",
        "Yönetim Kurulu Üye İstifa",
      ],
    }),
  uyruk: yup
    .string()
    .required()
    .label("Uyruk")
    .meta({
      type: "text",
      className: "sm:w-2/3",
      component: "input",
      defaultValue: "T.C.",
      transactionTypes: ["Hisse Devri", "Sermaye Arttırımı", "Olağan Genel Kurul", "Tasfiye Başlangıcı"],
    }),
  adres: yup
    .string()
    .required()
    .label("Adres")
    .meta({
      type: "text",
      className: "sm:w-2/3",
      component: "input",
      transactionTypes: ["Hisse Devri", "Sermaye Arttırımı", "Olağan Genel Kurul", "Tasfiye Başlangıcı"],
    }),
  payToplamDeger: yup
    .string()
    .required()
    .label("Pay Toplam Değer")
    .meta({
      type: "number",
      currency: "TL",
      className: "sm:w-2/3",
      component: "number",
      transactionTypes: ["Hisse Devri", "Sermaye Arttırımı", "Olağan Genel Kurul", "Tasfiye Başlangıcı"],
    }),
  toplamPayAdedi: yup
    .string()
    .required()
    .label("Toplam Pay Adedi")
    .meta({
      type: "number",
      currency: "Adet",
      className: "sm:w-2/3",
      component: "number",
      transactionTypes: ["Hisse Devri", "Sermaye Arttırımı", "Olağan Genel Kurul", "Tasfiye Başlangıcı"],
    }),

  birPayDegeri: yup
    .string()

    .label("Bir Pay Değeri")
    .meta({
      type: "number",
      currency: "TL",
      disabled: true,
      className: "sm:w-2/3",
      component: "number",
      transactionTypes: ["Hisse Devri", "Sermaye Arttırımı", "Olağan Genel Kurul", "Tasfiye Başlangıcı"],
      calculateCallback: (form) => {
        const payToplamDeger = Number(form.payToplamDeger);
        const toplamPayAdedi = Number(form.toplamPayAdedi);
        return payToplamDeger / toplamPayAdedi;
      },
    }),

  payEdinmeTarihi: yup
    .string()
    .required()
    .label("Pay Edinme Tarihi (ortağın son hisse devri veya kuruluş gazetesi tarihi)")
    .meta({
      type: "date",
      className: "sm:w-2/3",
      component: "input",
      transactionTypes: ["Hisse Devri", "Sermaye Arttırımı", "Olağan Genel Kurul", "Tasfiye Başlangıcı"],
    }),
  payEdinmeSekli: yup
    .string()
    .required()
    .label("Pay Edinme Şekli")
    .meta({
      type: "text",
      className: "sm:w-2/3",
      component: "input",
      defaultValue: "Borsa Dışı",
      transactionTypes: ["Hisse Devri", "Sermaye Arttırımı", "Olağan Genel Kurul", "Tasfiye Başlangıcı"],
    }),
};

const CompanyInfo = ({}) => {
  const dispatch = useDispatch();

  const { transactionTypes } = useSelector((state) => state.transactions.selectedTransaction);

  const schema = yupObjGenerator({
    yup,
    schemaObj,
    transactionTypes,
    arrName: "partners",
  });

  const { step } = useSelector((state) => state.transactions);
  const isRegistered = step.registered;

  const onUpdate = async (form) => {
    const deepCopy = JSON.parse(JSON.stringify(form));

    deepCopy.partners.map((partner) => {
      if (partner.payToplamDeger && partner.toplamPayAdedi) {
        partner.payToplamDeger = Number(partner.payToplamDeger);
        partner.toplamPayAdedi = Number(partner.toplamPayAdedi);
        partner.birPayDegeri = partner.payToplamDeger / partner.toplamPayAdedi;
      }
      return partner;
    });

    deepCopy.totalCapital = deepCopy.partners.reduce((acc, partner) => {
      return acc + partner.payToplamDeger;
    }, 0);

    deepCopy.toplamPayAdedi = deepCopy.partners.reduce((acc, partner) => {
      return acc + partner.toplamPayAdedi;
    }, 0);

    deepCopy.birPayDegeri = deepCopy.totalCapital / deepCopy.toplamPayAdedi;

    await dispatch(updateCapital(deepCopy));
    dispatch(loadCapitalData());
    dispatch(nextStep());
  };

  const onPrev = () => {
    dispatch(prevStep());
  };

  useEffect(() => {
    dispatch(loadCapitalData());
  }, []);

  const partners = useSelector((state) => state.companyData.partners);

  const defaultItem = {
    adSoyad: "",
    tcVkn: "",
  };

  return (
    <ArrayForm
      schema={schema}
      onSubmit={onUpdate}
      title="Ortak Bilgileri"
      initData={partners}
      itemLabel="partners"
      defaultItem={defaultItem}
      description={"Tescilli Ortak Bilgilerini Giriniz"}
      onPrev={onPrev}
      isRegistered={isRegistered}
    />
  );
};

export default CompanyInfo;
