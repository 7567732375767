import { useState, useEffect } from "react";

// import ROUTES from "features/navigation/routes";
import { navElements } from "features/navigation/Navigator";

function createBreadcrumb(routes, path) {
  let breadcrumb = [];

  if (!routes || !path) return null;

  // routes arrayinde path değerini arama
  for (let route of routes) {
    const pathArr = path?.split("/");
    // if pathArr last item is empty, delete it
    if (pathArr[pathArr.length - 1] === "") {
      pathArr.splice(pathArr.length - 1, 1);
    }
    const routePathArr = route?.path?.split("/");
    // find all indexes routePathArr element if is include ":"
    const indexArr = [];
    if (routePathArr && routePathArr.length > 0) {
      routePathArr.forEach((item, index) => {
        if (item.includes(":")) {
          indexArr.push(index);
        }
      });
    }

    // delete indexes from pathArr and routePathArr
    if (indexArr.length > 0) {
      indexArr.forEach((item) => {
        pathArr.splice(item, 1);
        routePathArr.splice(item, 1);
      });
    }

    if (pathArr && routePathArr && pathArr.join("/") === routePathArr.join("/")) {
      // Eğer bulunursa, breadcrumb arrayine ekle ve döngüden çık
      breadcrumb.push({ name: route.name, path: route.path, element: route.element.type.name });
      break;
    } else if (route.children) {
      // Eğer bulunamazsa, children altında arama yap
      let result = createBreadcrumb(route.children, path);
      if (result.length > 0) {
        // Eğer altında bulunursa, breadcrumb arrayine ekle ve döngüden çık
        breadcrumb.push({ name: route.name, path: route.path, element: route.element.type.name });
        breadcrumb.push(...result);
        break;
      }
    }
  }

  return breadcrumb;
}

const useCrumbs = ({ pathname }) => {
  const [title, setTitle] = useState(null);
  const [data, setData] = useState(null);

  useEffect(() => {
    const crumbs = createBreadcrumb(navElements, pathname);

    if (!crumbs) return;
    setData(crumbs);
    const titleText = crumbs[crumbs.length - 1]?.name;
    setTitle(titleText);
  }, [pathname]);

  return { title, data };
};

export default useCrumbs;
