import { useEffect, useState } from "react";

import { yup, yupResolver, useForm, useFieldArray } from "util/yupLocale";
import Input from "features/ui/Input";
import ComboboxInput from "features/ui/ComboboxImput";
import Button from "features/ui/Button";
import Select from "features/ui/Select";
import FormLayout from "features/ui/FormLayout";
import NumberInput from "features/ui/NumberInput";
import TextArea from "features/ui/TextArea";
import numberFormat from "functions/numberFormat";

const Form = ({
  schema,
  onSubmit,
  title,
  description,
  initData,
  onPrev,
  itemLabel,
  defaultItem,
  isRegistered,
  totalFields,
}) => {
  const {
    register,
    handleSubmit,
    reset,
    control,
    setValue,
    watch,
    getValues,
    formState: { errors },
    // validationSchema,
  } = useForm({
    resolver: yupResolver(schema || yup.object({})),
    defaultValues: {
      [itemLabel]: [{ ...defaultItem }],
    },
  });

  const { fields, append, remove } = useFieldArray({
    name: itemLabel,
    control,
  });

  useEffect(() => {
    if (!initData || !initData.length) return;
    reset({ [itemLabel]: initData });
  }, [initData]);

  useEffect(() => {
    // if error is empty object return
    if (!Object.keys(errors).length) return;
    console.log({ errors });
  }, [errors]);

  const [totals, setTotals] = useState(null);

  const calculateTotals = () => {
    if (!totalFields) return;
    const formData = getValues();
    const totalObj = [];

    totalFields.forEach((field) => {
      const total = formData[itemLabel].reduce((acc, item) => {
        return acc + Number(item[field.fieldName]);
      }, 0);
      totalObj.push({ label: field.label, value: numberFormat(total, 2, true) });
    });
    setTotals(totalObj);
  };

  useEffect(() => {
    calculateTotals();
  }, [initData]);

  const itemsFields = Object.keys(schema.fields[itemLabel].innerType.fields);

  const formBgColor = "";
  //isRegistered ? "bg-solitaire-100" : "bg-white";
  const formBadge = isRegistered ? (
    <span className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
      Tescilli Mevcut Bilgiler
    </span>
  ) : (
    <span className="inline-flex items-center rounded-md bg-yellow-50 px-2 py-1 text-xs font-medium text-yellow-800 ring-1 ring-inset ring-yellow-600/20">
      Değişecek Yeni Bilgiler
    </span>
  );

  return (
    <div className="mt-10 md:col-span-2 md:mt-0">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="overflow-hidden shadow sm:rounded-md">
          <div className={`col-span-12 overflow-y-scroll  shadow sm:rounded-lg ${formBgColor || "bg-white"}`}>
            {" "}
            <div className="flex items-center justify-center px-4 py-3 sm:px-6">
              {totals &&
                totals.map((total) => (
                  <div className="text-base text-gray-600" key={total.label}>
                    {total.label}: <span className="font-bold">{total.value}</span>
                  </div>
                ))}
            </div>
            <FormLayout title={title} description={description} formBadge={formBadge}>
              {fields.map((field, index) => (
                <div key={field.id}>
                  {itemsFields.map((itemField) => {
                    const fieldOjb = schema.fields[itemLabel].innerType.fields[itemField];
                    const {
                      component,
                      calculateCallback,
                      currency,
                      className,
                      updateOtherFields,
                      type,
                      options,
                      disabled,
                      onChange,
                      defaultValue,
                      condition,
                      placeholder,
                    } = fieldOjb.spec?.meta;
                    const { label } = fieldOjb.spec;
                    let hidden = false;
                    let calculatedValue = 0;
                    // const value = watch(`${itemLabel}.${index}.${itemField}`);
                    if (condition?.field) {
                      const conditionValue = watch(`${itemLabel}.${index}.${condition.field}`);
                      if (typeof condition?.value !== "undefined") hidden = conditionValue !== condition.value;
                      // if(typeof condition?.valueNot !== "undefined")  hidden = conditionValue === condition.valueNot;
                    }
                    if (calculateCallback) {
                      const form = watch(`${itemLabel}.${index}`);
                      calculatedValue = calculateCallback(form);
                    }

                    if (hidden) return null;
                    if (component === "input") {
                      return (
                        <Input
                          key={itemField}
                          label={label}
                          inputType="label-top"
                          type={type}
                          className={className}
                          placeholder={placeholder}
                          disabled={disabled}
                          {...register(`${itemLabel}.${index}.${itemField}`)}
                          defaultValue={field?.[itemField] || defaultValue}
                          error={errors?.[itemLabel]?.[index]?.[itemField]?.message}
                        />
                      );
                    }
                    if (component === "number") {
                      return (
                        <NumberInput
                          key={itemField}
                          currency={currency}
                          initValue={calculatedValue || field?.[itemField] || defaultValue}
                          label={label}
                          inputType="label-top"
                          className={className}
                          disabled={disabled}
                          changeCallback={(value) => {
                            setValue(`${itemLabel}.${index}.${itemField}`, value);
                            calculateTotals();
                          }}
                          {...register(`${itemLabel}.${index}.${itemField}`)}
                          defaultValue={field?.[itemField] || defaultValue}
                          error={errors?.[itemLabel]?.[index]?.[itemField]?.message}
                        />
                      );
                    }

                    if (component === "select") {
                      return (
                        <Select
                          key={itemField}
                          label={label}
                          inputType="label-top"
                          className={className}
                          options={options}
                          disabled={disabled}
                          {...register(`${itemLabel}.${index}.${itemField}`)}
                          defaultValue={field?.[itemField] || defaultValue}
                          error={errors?.[itemLabel]?.[index]?.[itemField]?.message}
                        />
                      );
                    }
                    if (component === "combobox") {
                      const formData = getValues();
                      return (
                        <ComboboxInput
                          data={options}
                          label={label}
                          createNew={false}
                          initValue={{ name: field[itemField], id: field[itemField] }}
                          setCallback={(obj) => {
                            if (!obj) return;
                            const id = obj?.id;
                            if (!id) return;
                            const name = obj?.name;
                            // set name to form

                            const data = onChange(name);
                            // set data to form with all fields
                            // if (updateOtherFields === false ) return
                            const newFormData = { ...formData[itemLabel][index], ...data };
                            setValue(`${itemLabel}.${index}`, newFormData);
                          }}
                          queryCallback={(query) => {
                            if (!query) return;
                            setValue(`${itemLabel}.${index}.${itemField}`, query);
                          }}
                        />
                      );
                    }
                    if (component === "textarea") {
                      return (
                        <TextArea
                          key={itemField}
                          label={label}
                          inputType="label-top"
                          className={className}
                          disabled={disabled}
                          {...register(`${itemLabel}.${index}.${itemField}`)}
                          defaultValue={field?.[itemField] || defaultValue}
                          error={errors?.[itemLabel]?.[index]?.[itemField]?.message}
                        />
                      );
                    }
                  })}
                  <div className="flex space-x-2 py-3">
                    {/* if isn't first item show delete button */}
                    {fields.length !== 1 && (
                      <Button type="button" color="warning" onClick={() => remove(index)}>
                        Sil
                      </Button>
                    )}
                    {/* if last item show add button */}
                    {index === fields.length - 1 && (
                      <Button
                        type="button"
                        onClick={() => {
                          append({ ...defaultItem });
                        }}
                      >
                        Ekle
                      </Button>
                    )}
                  </div>
                </div>
              ))}
              <div className="col-span-12">
                <div className="flex justify-start"></div>
              </div>
            </FormLayout>
          </div>

          <div className="space-x-2 bg-gray-50 px-4 py-3 text-right dark:bg-gray-700 sm:px-6">
            <Button color="secondary" type="button" onClick={onPrev}>
              Geri
            </Button>
            <Button type="submit">Kaydet</Button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default Form;
