import { initializeApp, getApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
// const { initializeAppCheck, ReCaptchaV3Provider } = require("firebase/app-check");

import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";
import { getFunctions } from "firebase/functions";
import emulator from "./emulator";

const config = {
  apiKey: "AIzaSyD8nuPyb8lmhIer-yqyGLen_a1qRkzhxw8",
  authDomain: "sicil-2552d.firebaseapp.com",
  projectId: "sicil-2552d",
  storageBucket: "sicil-2552d.appspot.com",
  messagingSenderId: "517534319432",
  appId: "1:517534319432:web:4aa0437a834c7984c8a97d",
  measurementId: "G-B8CYP41S89"
};

const firebase = initializeApp(config);
const db = getFirestore();
const auth = getAuth();
const storage = getStorage();
const functions = getFunctions(firebase, "europe-west1");
// Initialize Analytics and get a reference to the service
const analytics = getAnalytics(firebase);


emulator({
  runEmilator: false,
  db,
  auth,
  storage,
  functions,
  firebase
});

export default firebase;
export { db, auth, storage, functions, analytics, logEvent };

// import {logEvent, analytics} from 'features/firebase'