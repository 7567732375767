import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { yup } from "util/yupLocale";
import ArrayForm from "../../ArrayForm";

import { prevStep, nextStep } from "features/transactions/slice";
import { updateAuthorities, loadAuthoritiesData, updateCapital, loadCapitalData } from "features/companyData/services";

import yupObjGenerator from "features/transactions/yupObjGenerator";

const Authorities = ({}) => {
  const dispatch = useDispatch();

  const partners = useSelector((state) => state.companyData.partners);
  const selectedCompany = useSelector((state) => state.companies.selectedCompany);
  const companyType = selectedCompany?.companyType;
  const { step } = useSelector((state) => state.transactions);
  const isRegistered = step.registered;

  const partnerComboOptions = partners.map((partner) => ({
    name: partner.adSoyad,
    id: partner.tcVkn,
  }));

  let yetkiTurleri = [
    { value: "Müdürler Kurulu Başkanı", label: "Müdürler Kurulu Başkanı" },
    { value: "Müdür", label: "Müdür" },
  ];

  if (companyType === "Anonim") {
    yetkiTurleri = [
      { value: "Yönetim Kurulu Başkanı", label: "Yönetim Kurulu Başkanı" },
      { value: "Yönetim Kurulu Başkan Yardımcısı", label: "Yönetim Kurulu Başkan Yardımcısı" },
      { value: "Yönetim Kurulu Üyesi", label: "Yönetim Kurulu Üyesi" },
    ];
  }

  const getPersonFromPartners = (name) => {
    const partner = partners.find((partner) => partner.adSoyad === name);
    return partner;
  };
  //  transactionTypes: ["Adres Değişikliği","Merkez Nakli", "Müdür Atama", "Müdür İstifa" ,"Hisse Devri", "Şube Açılışı","Şube Kapanışı"],
  const { transactionTypes } = useSelector((state) => state.transactions.selectedTransaction);

  const schemaObj = {
    gercekTuzel: yup
      .string()
      .required()
      .label("Gerçek/Tüzel")
      .meta({
        type: "select",
        className: "sm:w-2/3",
        component: "select",
        defaultValue: "Gerçek",
        options: [
          { value: "Gerçek", label: "Gerçek" },
          { value: "Tüzel", label: "Tüzel" },
        ],
        transactionTypes: [
          "Adres Değişikliği",
          "Unvan Değişikliği",
          "Tasfiye Başlangıcı",
          "Tasfiye Sonu",
          "Merkez Nakli",
          "Müdür Atama",
          "Müdür İstifa",
          "Hisse Devri",
          "Şube Açılışı",
          "Şube Kapanışı",
          "Sermaye Arttırımı",
          "Olağan Genel Kurul",
          "Yönetim Kurulu Üye İstifa",
        ],
      }),
    // if gercekTuzel === "Tüzel"
    ticaretSicilMudurlugu: yup
      .string()
      .label("Ticaret Sicil Müdürlüğü")
      .meta({
        type: "text",
        className: "sm:w-2/3",
        component: "input",
        condition: {
          field: "gercekTuzel",
          value: "Tüzel",
        },
        transactionTypes: [
          "Adres Değişikliği",
          "Unvan Değişikliği",
          "Tasfiye Başlangıcı",
          "Tasfiye Sonu",
          "Merkez Nakli",
          "Müdür Atama",
          "Müdür İstifa",
          "Hisse Devri",
          "Şube Açılışı",
          "Şube Kapanışı",
          "Sermaye Arttırımı",
          "Olağan Genel Kurul",
          "Yönetim Kurulu Üye İstifa",
        ],
      })
      .when("gercekTuzel", {
        is: "Tüzel",
        then: yup.string().required(),
      }),
    tuzelAdres: yup
      .string()
      .label("Tüzel Adres")
      .meta({
        type: "text",
        className: "sm:w-2/3",
        component: "input",
        condition: {
          field: "gercekTuzel",
          value: "Tüzel",
        },
        transactionTypes: [
          "Adres Değişikliği",
          "Unvan Değişikliği",
          "Tasfiye Başlangıcı",
          "Tasfiye Sonu",
          "Merkez Nakli",
          "Müdür Atama",
          "Müdür İstifa",
          "Hisse Devri",
          "Şube Açılışı",
          "Şube Kapanışı",
          "Sermaye Arttırımı",
          "Olağan Genel Kurul",
          "Yönetim Kurulu Üye İstifa",
        ],
      })
      .when("gercekTuzel", {
        is: "Tüzel",
        then: yup.string().required(),
      }),
    ticaretSicilNo: yup
      .string()
      .label("Ticaret Sicil No")
      .meta({
        type: "text",
        className: "sm:w-2/3",
        component: "input",
        condition: {
          field: "gercekTuzel",
          value: "Tüzel",
        },
        transactionTypes: [
          "Adres Değişikliği",
          "Unvan Değişikliği",
          "Tasfiye Başlangıcı",
          "Tasfiye Sonu",
          "Merkez Nakli",
          "Müdür Atama",
          "Müdür İstifa",
          "Hisse Devri",
          "Şube Açılışı",
          "Şube Kapanışı",
          "Sermaye Arttırımı",
          "Olağan Genel Kurul",
          "Yönetim Kurulu Üye İstifa",
        ],
      })
      .when("gercekTuzel", {
        is: "Tüzel",
        then: yup.string().required(),
      }),
    title: yup
      .string()
      .label("Şirket Ünvanı")
      .meta({
        type: "text",
        className: "sm:w-2/3",
        component: "input",
        condition: {
          field: "gercekTuzel",
          value: "Tüzel",
        },
        transactionTypes: [
          "Adres Değişikliği",
          "Unvan Değişikliği",
          "Tasfiye Başlangıcı",
          "Tasfiye Sonu",
          "Merkez Nakli",
          "Müdür Atama",
          "Müdür İstifa",
          "Hisse Devri",
          "Şube Açılışı",
          "Şube Kapanışı",
          "Sermaye Arttırımı",
          "Olağan Genel Kurul",
          "Yönetim Kurulu Üye İstifa",
        ],
      })
      .when("gercekTuzel", {
        is: "Tüzel",
        then: yup.string().required(),
      }),

    yetkiTuru: yup
      .string()
      .required()
      .label("Yetki Türü")
      .meta({
        type: "select",
        className: "sm:w-2/3",
        component: "select",
        options: yetkiTurleri,
        transactionTypes: [
          "Adres Değişikliği",
          "Unvan Değişikliği",
          "Tasfiye Başlangıcı",
          "Tasfiye Sonu",
          "Merkez Nakli",
          "Müdür Atama",
          "Müdür İstifa",
          "Hisse Devri",
          "Şube Açılışı",
          "Şube Kapanışı",
          "Sermaye Arttırımı",
          "Olağan Genel Kurul",
          "Yönetim Kurulu Üye İstifa",
        ],
      }),

    adSoyad: yup
      .string()
      .required()
      .label("Ad Soyad")
      .meta({
        type: "text",
        className: "sm:w-2/3",
        component: "combobox",
        transactionTypes: [
          "Adres Değişikliği",
          "Unvan Değişikliği",
          "Tasfiye Başlangıcı",
          "Tasfiye Sonu",
          "Merkez Nakli",
          "Müdür Atama",
          "Müdür İstifa",
          "Hisse Devri",
          "Şube Açılışı",
          "Şube Kapanışı",
          "Sermaye Arttırımı",
          "Olağan Genel Kurul",
          "Yönetim Kurulu Üye İstifa",
        ],
        options: partnerComboOptions,
        onChange: getPersonFromPartners,
      }),
    uyruk: yup
      .string()
      .required()
      .label("Uyruk")
      .meta({
        type: "text",
        className: "sm:w-2/3",
        component: "input",
        transactionTypes: [
          "Adres Değişikliği",
          "Unvan Değişikliği",
          "Tasfiye Başlangıcı",
          "Tasfiye Sonu",
          "Merkez Nakli",
          "Müdür Atama",
          "Müdür İstifa",
          "Hisse Devri",
          "Şube Açılışı",
          "Şube Kapanışı",
          "Sermaye Arttırımı",
          "Olağan Genel Kurul",
          "Yönetim Kurulu Üye İstifa",
        ],
        defaultValue: "T.C.",
      }),
    tcVkn: yup
      .string()
      .required()
      .label("TC Kimlik No")
      .min(11)
      .max(11)
      .meta({
        type: "text",
        className: "sm:w-2/3",
        component: "input",
        transactionTypes: [
          "Adres Değişikliği",
          "Unvan Değişikliği",
          "Tasfiye Başlangıcı",
          "Tasfiye Sonu",
          "Merkez Nakli",
          "Müdür Atama",
          "Müdür İstifa",
          "Hisse Devri",
          "Şube Açılışı",
          "Şube Kapanışı",
          "Sermaye Arttırımı",
          "Olağan Genel Kurul",
          "Yönetim Kurulu Üye İstifa",
        ],
      }),

    adres: yup
      .string()
      .required()
      .label("Adres")
      .meta({
        type: "text",
        className: "sm:w-2/3",
        component: "input",
        transactionTypes: [
          "Adres Değişikliği",
          "Unvan Değişikliği",
          "Tasfiye Başlangıcı",
          "Tasfiye Sonu",
          "Merkez Nakli",
          "Müdür Atama",
          "Müdür İstifa",
          "Hisse Devri",
          "Şube Açılışı",
          "Şube Kapanışı",
          "Sermaye Arttırımı",
          "Olağan Genel Kurul",
          "Yönetim Kurulu Üye İstifa",
        ],
      }),
    cepTelefonu: yup
      .string()
      .required()
      .label("Cep Telefonu")
      .min(10)
      .max(10)
      .meta({
        type: "text",
        className: "sm:w-2/3",
        component: "input",
        transactionTypes: [
          "Adres Değişikliği",
          "Unvan Değişikliği",
          "Tasfiye Başlangıcı",
          "Tasfiye Sonu",
          "Merkez Nakli",
          "Müdür Atama",
          "Müdür İstifa",
          "Hisse Devri",
          "Şube Açılışı",
          "Şube Kapanışı",
          "Sermaye Arttırımı",
          "Olağan Genel Kurul",
          "Yönetim Kurulu Üye İstifa",
        ],
      }),
    munferidMusterek: yup
      .string()
      .required()
      .label("Münferit/Müşterek")
      .meta({
        type: "select",
        className: "sm:w-2/3",
        component: "select",
        options: [
          { value: "Münferit", label: "Münferit" },
          { value: "Müşterek", label: "Müşterek" },
        ],
        transactionTypes: [
          "Adres Değişikliği",
          "Unvan Değişikliği",
          "Tasfiye Başlangıcı",
          "Tasfiye Sonu",
          "Merkez Nakli",
          "Müdür Atama",
          "Müdür İstifa",
          "Hisse Devri",
          "Şube Açılışı",
          "Şube Kapanışı",
          "Sermaye Arttırımı",
          "Olağan Genel Kurul",
          "Yönetim Kurulu Üye İstifa",
        ],
      }),
    // if munferidMusterek === "Müşterek"
    musterekKisiler: yup
      .string()
      .label("Müşterek Kişiler")
      .meta({
        type: "text",
        className: "sm:w-2/3",
        component: "input",
        ifNotRequiredHide: true,
        condition: {
          field: "munferidMusterek",
          value: "Müşterek",
        },
        transactionTypes: [
          "Adres Değişikliği",
          "Unvan Değişikliği",
          "Tasfiye Başlangıcı",
          "Tasfiye Sonu",
          "Merkez Nakli",
          "Müdür Atama",
          "Müdür İstifa",
          "Hisse Devri",
          "Şube Açılışı",
          "Şube Kapanışı",
          "Sermaye Arttırımı",
          "Olağan Genel Kurul",
          "Yönetim Kurulu Üye İstifa",
        ],
      })

      .when("munferidMusterek", {
        is: "Müşterek",
        then: yup.string().required(),
      }),
  };

  const schema = yupObjGenerator({
    yup,
    schemaObj,
    transactionTypes,
    arrName: "authorities",
  });

  const onUpdate = async (form) => {
    await dispatch(updateAuthorities(form));
    dispatch(loadAuthoritiesData());
    dispatch(nextStep());
  };

  const onPrev = () => {
    dispatch(prevStep());
  };

  useEffect(() => {
    dispatch(loadAuthoritiesData());
    dispatch(loadCapitalData());
  }, []);

  const authorities = useSelector((state) => state.companyData.authorities);
  const defaultItem = {
    adSoyad: "",
    tcVkn: "",
  };

  return (
    <ArrayForm
      schema={schema}
      onSubmit={onUpdate}
      title="Yetkili Bilgileri"
      initData={authorities}
      itemLabel="authorities"
      defaultItem={defaultItem}
      description={"Tescilli Yetkili Bilgilerini Giriniz"}
      onPrev={onPrev}
      isRegistered={isRegistered}
    />
  );
};

export default Authorities;
