import { useSelector, useDispatch } from "react-redux";
import React, { useState, useEffect } from "react";

import { yup } from "util/yupLocale";
import Form from "features/transactions/Form";
import { updateCompanyDatas, catchCpaInfoFromOtherCompany } from "features/companyData/services";
import { prevStep, nextStep } from "features/transactions/slice";
import yupObjGenerator from "features/transactions/yupObjGenerator";
// import numberFormat from "functions/numberFormat";

const CpaInfo = () => {
  const { transactionTypes } = useSelector((state) => state.transactions.selectedTransaction);

  const schemaObj = {
    adiSoyadi: yup
      .string()
      .required()
      .label("Adı Soyadı")
      .meta({
        type: "text",
        className: "sm:w-2/3",
        component: "input",
        transactionTypes: ["Sermaye Arttırımı"],
      }),
    oda: yup
      .string()
      .required()
      .label("Bağlı Bulunduğu Oda")
      .meta({
        type: "text",
        className: "sm:w-2/3",
        component: "input",
        transactionTypes: ["Sermaye Arttırımı"],
      }),
    sicilNo: yup
      .string()
      .required()
      .label("Oda Sicil No")
      .meta({
        type: "text",
        className: "sm:w-2/3",
        component: "input",
        transactionTypes: ["Sermaye Arttırımı"],
      }),
    buroSicilNo: yup
      .string()
      .required()
      .label("Büro Sicil No")
      .meta({
        type: "text",
        className: "sm:w-2/3",
        component: "input",
        transactionTypes: ["Sermaye Arttırımı"],
      }),
    ruhsatNo: yup
      .string()
      .required()
      .label("Ruhsat No")
      .meta({
        type: "text",
        className: "sm:w-2/3",
        component: "input",
        transactionTypes: ["Sermaye Arttırımı"],
      }),
    kaseNo: yup
      .string()
      .required()
      .label("Kaşe No")
      .meta({
        type: "text",
        className: "sm:w-2/3",
        component: "input",
        transactionTypes: ["Sermaye Arttırımı"],
      }),
    telefon: yup
      .string()
      .required()
      .label("Telefon")
      .min(10)
      .max(11)
      .meta({
        type: "text",
        className: "sm:w-2/3",
        component: "input",
        transactionTypes: ["Sermaye Arttırımı"],
      }),
    adres: yup
      .string()
      .required()
      .label("Adres")
      .min(10)
      .meta({
        type: "text",
        className: "sm:w-2/3",
        component: "textarea",
        transactionTypes: ["Sermaye Arttırımı"],
      }),
    vergiDairesi: yup
      .string()
      .required()
      .label("Vergi Dairesi")
      .min(3)
      .meta({
        type: "text",
        className: "sm:w-2/3",
        component: "input",
        transactionTypes: ["Sermaye Arttırımı"],
      }),
    tcVkn: yup
      .string()
      .required()
      .label("Vergi No / TC Kimlik No")
      .min(10)
      .max(11)
      .meta({
        type: "text",
        className: "sm:w-2/3",
        component: "input",
        transactionTypes: ["Sermaye Arttırımı"],
      }),
  };

  const schema = yupObjGenerator({
    yup,
    schemaObj,
    transactionTypes,
  });

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(catchCpaInfoFromOtherCompany({ documentName: "cpaInfo" }));
  }, []);

  const onUpdate = async (form) => {
    const deepCopy = JSON.parse(JSON.stringify(form));

    await dispatch(
      updateCompanyDatas({
        form: deepCopy,
        documentName: "cpaInfo",
      })
    );
    dispatch(catchCpaInfoFromOtherCompany({ documentName: "cpaInfo" }));
    dispatch(nextStep());
  };

  const onPrev = () => {
    dispatch(prevStep());
  };

  const { step } = useSelector((state) => state.transactions);
  const isRegistered = step.registered;

  const cpaInfo = useSelector((state) => state.companyData.cpaInfo);

  return (
    <div className="mt-10 md:col-span-2 md:mt-0">
      <Form
        schema={schema}
        onSubmit={onUpdate}
        title="Mali Müşavir Bilgileri"
        initData={cpaInfo}
        description={"Mali müşavir bilgilerini giriniz."}
        onPrev={onPrev}
        isRegistered={isRegistered}
      />
    </div>
  );
};

export default CpaInfo;
