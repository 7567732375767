import { createSlice, createEntityAdapter } from '@reduxjs/toolkit';


// import notify from 'features/ui/notify';
import { loadPayments, loadPayment } from 'features/admin/services';

const adminPaymentAdapter = createEntityAdapter()

const initialState = adminPaymentAdapter.getInitialState({
    // statuses: 'idle' | 'loading' | 'succeeded' | 'failed'
    status: 'idle',
    error: null,
    selectedPayment: null,
    searchParam: {
        status: "pending",
    },
})

export const adminPaymentselector = adminPaymentAdapter.getSelectors(state => state.adminPayments)

const adminPaymentsSlice = createSlice({
    name: 'adminPayments',
    initialState,
    reducers: {
        addAdminPayment: adminPaymentAdapter.addOne,
        removeAdminPayment: adminPaymentAdapter.removeOne,
        updateAdminPayment: adminPaymentAdapter.updateOne,
        setadminPayments: adminPaymentAdapter.setAll,
        setadminPaymentsStatus: (state, action) => {
            state.status = action.payload
        },
        setSearchParam: (state, action) => {
            state.searchParam = action.payload
        },
        setSelectedPayment: (state, action) => {
            state.selectedPayment = action.payload
        },
        removeAllPayments: (state, action) => {
            adminPaymentAdapter.removeAll(state)
        }
    },
    extraReducers: (builder) => {
        builder.addCase(loadPayments.pending, (state, action) => {
            state.status = 'loading'
        })
            .addCase(loadPayments.fulfilled, (state, action) => {
                state.status = 'succeeded'
                const data = action.payload
                adminPaymentAdapter.setAll(state, data)
            })
            .addCase(loadPayments.rejected, (state, action) => {
                state.status = 'failed'
                state.error = action.error.message
            })
            .addCase(loadPayment.pending, (state, action) => {
                state.status = 'loading'
            })
            .addCase(loadPayment.fulfilled, (state, action) => {
                state.status = 'succeeded'
                const data = action.payload
                state.selectedPayment = data
            })
            .addCase(loadPayment.rejected, (state, action) => {
                state.status = 'failed'
                state.error = action.error.message
            })
    }
})


export const {
    addAdminPayment,
    removeAdminPayment,
    updateAdminPayment,
    setadminPayments,
    setadminPaymentsStatus,
    setSearchParam,
    setSelectedPayment,
    removeAllPayments
} = adminPaymentsSlice.actions

export default adminPaymentsSlice.reducer

