import { Navigate, useLocation } from "react-router-dom";
import ROUTES from "features/navigation/routes";
import useAuthListener from "features/auth/useAuthListener";
import Logo from "features/ui/Logo";
import Loader from "features/ui/Loader";

const PrivateRoute = ({ children }) => {
  const location = useLocation();
  const { user, loading } = useAuthListener();

  if (loading)
    return (
      <div className="flex min-h-screen flex-col items-center justify-center bg-gray-50">
        <Logo className="h-36" />
        <div className="h-36 text-center">
          <Loader type="1" className="ml-10" />
        </div>
      </div>
    );
  else if (!user && !loading) return <Navigate to={ROUTES.SIGN_IN.path} state={{ from: location }} replace />;
  else return children;
};

export default PrivateRoute;
