import { configureStore } from "@reduxjs/toolkit";

// import auth from "features/auth/slice";
import ui from "features/ui/slice";
import auth from "features/auth/slice";

import companies from "features/companies/slice";
import users from "features/users/slice";
import search from "features/search/slice";

import transactions from "features/transactions/slice";
import companyData from "features/companyData/slice";

// admin
import adminPayments from "features/admin/payments/slice";
import adminUsers  from "features/admin/users/slice";
import statistics from "features/admin/statistics/slice";

import shop from "features/shop/slice";



export default configureStore({
    reducer: {
        auth,
        ui,
        search,
        companies,
        users,
        shop,
        adminPayments,
        adminUsers,
        statistics,
        companyData,

        transactions
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }),
});
