export const transactions = [
    {
        id: 1,
        title: "Adres Değişikliği",
        companyTypes: ["Limited", "Anonim"],
        describtion: "Aynı ilde",
        karar: {
            "Limited": "Genel Kurul Kararı",
            "Anonim": "Yönetim Kurulu Kararı"
        },

    },
    {
        id: 11,
        title: "Merkez Nakli",
        describtion: "İl değiştirme durumunda",
        companyTypes: ["Limited", "Anonim"],
        karar: {
            "Limited": "Genel Kurul Kararı",
            "Anonim": "Genel Kurul Toplantı Tutanağı",
        },

    },
    {
        id: 2,
        title: "Müdür Atama",
        companyTypes: ["Limited"],
        karar: {
            "Limited": "Genel Kurul Kararı",
        },

    },
    {
        id: 3,
        title: "Müdür İstifa",
        companyTypes: ["Limited"],
        karar: {
            "Limited": "Genel Kurul Kararı",
        },

    },
    {
        id: 19,
        title: "Olağan Genel Kurul",
        companyTypes: ["Anonim"],
        describtion: "Yönetim Kurulu Üye Atama ile birlikte seçin.",
        karar: {
            "Anonim": "Genel Kurul Toplantı Tutanağı",
            // "Anonim": "Yönetim Kurulu Kararı",
        },
        selectWith: ["Yönetim Kurulu Üye Atama"],
    },
    {
        id: 4,
        title: "Yönetim Kurulu Üye Atama",
        companyTypes: ["Anonim"],
        karar: {
            "Anonim": "Genel Kurul Toplantı Tutanağı"
        },

    },
    {
        id: 5,
        title: "Yönetim Kurulu Üye İstifa",
        companyTypes: ["Anonim"],
        karar: {
            "Anonim": "Genel Kurul Toplantı Tutanağı"
        },

    },
    {
        id: 6,
        title: "Hisse Devri",
        companyTypes: ["Limited", "Anonim"],
        karar: {
            "Limited": "Genel Kurul Kararı",
            "Anonim": "Yönetim Kurulu Kararı",
        },
    },

    {
        id: 9,
        title: "Şube Açılışı",
        companyTypes: ["Limited", "Anonim"],
        karar: {
            "Limited": "Genel Kurul Kararı",
            "Anonim": "Yönetim Kurulu Kararı",
        },
    },
    {
        id: 10,
        title: "Şube Kapanışı",
        companyTypes: ["Limited", "Anonim"],
        karar: {
            "Limited": "Genel Kurul Kararı",
            // "Anonim": "Genel Kurul Toplantı Tutanağı",
            "Anonim": "Yönetim Kurulu Kararı",
        },
    },

    {
        id: 12,
        title: "Tasfiye Başlangıcı",
        companyTypes: ["Limited", "Anonim"],
        karar: {
            "Limited": "Genel Kurul Kararı",
            "Anonim": "Genel Kurul Toplantı Tutanağı",
            // "Anonim": "Yönetim Kurulu Kararı",
        },
        dontSelectWith: ["Tasfiye Sonu"]
    },
    {
        id: 13,
        title: "Tasfiye Sonu",
        companyTypes: ["Limited", "Anonim"],
        karar: {
            "Limited": "Genel Kurul Kararı",
            "Anonim": "Genel Kurul Toplantı Tutanağı",
            // "Anonim": "Yönetim Kurulu Kararı",
        },
        dontSelectWith: ["Tasfiye Başlangıcı"]
    },

    {
        id: 14,
        title: "Unvan Değişikliği",
        companyTypes: ["Limited", "Anonim"],
        karar: {
            "Limited": "Genel Kurul Kararı",
            // "Anonim": "Genel Kurul Toplantı Tutanağı",
            "Anonim": "Yönetim Kurulu Kararı",
        },
    },
    {
        id: 15,
        title: "Ana Sözleşme Değişikliği",
        companyTypes: ["Limited", "Anonim"],
        karar: {
            "Limited": "Genel Kurul Kararı",
            "Anonim": "Genel Kurul Toplantı Tutanağı",
            // "Anonim": "Yönetim Kurulu Kararı",
        },
        disabled: true,
    },

    {
        id: 7,
        title: "Sermaye Arttırımı",
        companyTypes: ["Limited", "Anonim"],
        karar: {
            "Limited": "Genel Kurul Kararı",
            "Anonim": "Genel Kurul Toplantı Tutanağı",
            // "Anonim": "Yönetim Kurulu Kararı",
        },
    },
    {
        id: 8,
        title: "Sermaye Azaltımı",
        companyTypes: ["Limited", "Anonim"],
        karar: {
            "Limited": "Genel Kurul Kararı",
            "Anonim": "Genel Kurul Toplantı Tutanağı",
            // "Anonim": "Yönetim Kurulu Kararı",
        },
        disabled: true,
    },

];


export const transactionCredits = {
    "Adres Değişikliği": 5,
    "Merkez Nakli": 5,
    "Müdür Atama": 10,
    "Müdür İstifa": 5,
    "Yönetim Kurulu Üye Atama": 10,
    "Yönetim Kurulu Üye İstifa": 5,
    "Hisse Devri": 15,
    "Şube Açılışı": 10,
    "Şube Kapanışı": 5,
    "Tasfiye Başlangıcı": 15,
    "Tasfiye Sonu": 5,
    "Unvan Değişikliği": 5,
    "Ana Sözleşme Değişikliği": 5,
    "Olağan Genel Kurul": 15,
    "Sermaye Arttırımı": 25,
    "Sermaye Azaltımı": 25,
}

// get credit of transaction by titleArray
export const getTransactionCredits = (titleArray) => {
    let totalCredit = 0
    titleArray.forEach(title => {
        totalCredit += transactionCredits[title]
    })
    return totalCredit
}