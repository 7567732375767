import React, { useEffect, useState } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import Logo from "../ui/Logo";
import Loader from "../ui/Loader";
import Input from "features/ui/Input";
import Button from "features/ui/Button";
import { checkIsEmailInUse, acceptInvite } from "features/users/services";
import notify from "features/ui/notify";

const Invitation = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  let [searchParams] = useSearchParams();
  const companyID = searchParams.get("company");
  const inviteID = searchParams.get("invite");
  const emailQ = searchParams.get("email");

  const [type, setType] = useState(null);
  const [password, setPassword] = useState(null);
  const [password2, setPassword2] = useState(null);

  const handleAccept = async (e) => {
    e.preventDefault();

    if (type === "signup" && password !== password2) {
      return notify("Şifreler uyuşmuyor", "error");
    }
    dispatch(acceptInvite({ companyID, inviteID, email: emailQ, password, password2, mode: type }));
  };

  useEffect(() => {
    if (!inviteID || !companyID || !emailQ) return;
    (async () => {
      const inviteType = await dispatch(checkIsEmailInUse({ email: emailQ, companyID, inviteID }));
      setType(inviteType.payload);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inviteID, companyID, emailQ]);

  if (!type)
    return (
      <div className="flex min-h-screen flex-col items-center justify-center bg-gray-200">
        <Logo className="h-36" />
        <div className="h-36 text-center">
          <Loader type="1" className="ml-10" />
        </div>
      </div>
    );

  return (
    <div className="flex min-h-screen justify-center bg-gray-50 px-4 pt-10 sm:px-6 md:pt-36 lg:px-8">
      <div className="w-full max-w-md space-y-8 ">
        <Logo className="mx-auto h-40 w-auto" />
        {type === "signin" && (
          <div>
            <h2 className="mt-6 text-center text-3xl font-extrabold text-secondary">Giriş Yapın</h2>
            <p className="mt-2 text-center text-sm text-gray-600">Daveti kabul etmek için giriş yapın</p>
          </div>
        )}

        {type === "signup" && (
          <div>
            <h2 className="mt-6 text-center text-3xl font-extrabold text-secondary">Kayıt Olun</h2>
            <p className="mt-2 text-center text-sm text-gray-600">Daveti kabul etmek için kayıt olun</p>
          </div>
        )}

        <form className="mt-8 space-y-6">
          <input type="hidden" name="remember" defaultValue="true" />
          <div className="-space-y-px rounded-md shadow-sm">
            <Input
              className="relative block w-full appearance-none rounded-none rounded-t-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-primary focus:outline-none focus:ring-primary sm:text-sm"
              label="E-Posta"
              type="e-mail"
              disabled={true}
              value={emailQ}
            />
            <Input
              className={
                type === "signup"
                  ? "relative block w-full appearance-none rounded-none border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-primary focus:outline-none focus:ring-primary sm:text-sm"
                  : "relative block w-full appearance-none rounded-none rounded-b-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-primary focus:outline-none focus:ring-primary sm:text-sm"
              }
              label="Parola"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            {type === "signup" && (
              <Input
                className="relative block w-full appearance-none rounded-none rounded-b-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-primary focus:outline-none focus:ring-primary sm:text-sm"
                label="Parola Doğrula"
                type="password"
                value={password2}
                onChange={(e) => setPassword2(e.target.value)}
              />
            )}
          </div>

          <div className="flex items-center justify-between">
            <div className="text-sm">
              {/* <button
              className="font-medium text-primary hover:text-secondary"
              to={ROUTES.RESET_PASSWORD.path}
              onClick={onResetPassword}
            >
              Şifremi Unuttum
            </button> */}
            </div>
          </div>

          <div>
            <Button
              className="group relative flex w-full focus:ring-primary"
              // loading={loading}
              type="submit"
              onClick={handleAccept}
            >
              Daveti Kabul Et
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Invitation;
