import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { loadUser, addCredit } from "features/admin/services";
import { router } from "features/navigation/Navigator";
import ROUTES from "features/navigation/routes";

const showPhoneNumber = (phoneNumber) => {
  //+905321234567

  // as +90 532 123 45 67
  const countryCode = phoneNumber.substring(0, 3);
  const firstThree = phoneNumber.substring(3, 6);
  const secondThree = phoneNumber.substring(6, 9);
  const lastTwo = phoneNumber.substring(9, 11);
  const lastTwo2 = phoneNumber.substring(11, 13);

  return `${countryCode} ${firstThree} ${secondThree} ${lastTwo} ${lastTwo2}`;
};

const PaymentDetail = ({}) => {
  const dispatch = useDispatch();
  const { uid } = useParams();

  const selectedUser = useSelector((state) => state.adminUsers.selectedUser);
  useEffect(() => {
    dispatch(loadUser(uid));
  }, [uid]);

  const handleAddCredit = (userUid) => {
    dispatch(addCredit({ userUid, creditAmount }));
  };

  const [creditAmount, setCreditAmount] = useState(0);

  const [companyList, setCompanyList] = useState([]);

  useEffect(() => {
    if (selectedUser?.companies) {
      const companies = selectedUser?.companies;
      const companiesArray = companies ? Object.keys(companies).map((key) => companies[key]) : [];
      setCompanyList(companiesArray);
    }
  }, [selectedUser]);

  const handleOpenCompanyOnDb = (id) => {
    // open on new tab
    window.open(`https://console.firebase.google.com/u/0/project/sicil-2552d/firestore/data/~2Fcompanies~2F${id}`);
  };

  const handleNavigateToBack = () => {
    router.navigate(ROUTES.ADMIN_USERS.path);
  };

  if (!selectedUser) return <div>loading...</div>;

  return (
    <div className="space-y-2">
      <div>User detail</div>

      <button
        className="rounded bg-blue-500 py-2 px-2 text-xs font-bold text-white hover:bg-blue-700"
        onClick={handleNavigateToBack}
      >
        Listeye Dön
      </button>

      {/* add credit input and button */}

      <div className="flex flex-row space-x-2">
        <input
          type="number"
          className="rounded border border-gray-300 py-1 px-2 text-sm text-gray-700 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500"
          placeholder="Kredi Ekle"
          value={creditAmount}
          onChange={(e) => setCreditAmount(e.target.value)}
        />
        <button
          className="rounded bg-blue-500 py-2 px-2 text-xs font-bold text-white hover:bg-blue-700"
          onClick={() => handleAddCredit(selectedUser.uid)}
        >
          Kredi Ekle
        </button>
      </div>

      {/* render user details */}

      <hr />

      <div className="flex flex-col space-y-2">
        <div className="flex flex-col space-y-2">
          <div className="flex flex-row space-x-2">
            <div className="font-bold">Adı Soyadı</div>
            <div>{selectedUser.displayName}</div>
          </div>
          <div className="flex flex-row space-x-2">
            <div className="font-bold">Email</div>
            <div className="text-blue-500" mailto={`mailto:${selectedUser.email}`}>
              {selectedUser.email}
            </div>
          </div>
          <div className="flex flex-row space-x-2">
            <div className="font-bold">Telefon</div>
            <div>
              <phone className="text-blue-500" href={`tel:${selectedUser.phoneNumber}`}>
                {showPhoneNumber(selectedUser.phoneNumber)}
              </phone>
            </div>
          </div>
        </div>

        <div className="flex flex-col space-y-2">
          <div className="flex flex-row space-x-2">
            <div className="font-bold">Üyelik Tarihi</div>
            <div>{selectedUser.createdAt && new Date(selectedUser.createdAt.seconds * 1000).toLocaleDateString()}</div>
          </div>
          <div className="flex flex-row space-x-2">
            <div className="font-bold">Son Güncelleme</div>
            <div>{selectedUser.updatedAt && new Date(selectedUser.updatedAt.seconds * 1000).toLocaleDateString()}</div>
          </div>
        </div>

        <hr />
        {selectedUser.subscription && (
          <div className="flex flex-col space-y-2">
            <div className="flex flex-row space-x-2">
              <div className="font-bold">Üyelik Tipi</div>
              <div>{selectedUser.subscription.active ? "Aktif" : "Pasif"}</div>
            </div>
            <div className="flex flex-row space-x-2">
              <div className="font-bold">Kredi</div>
              <div>{selectedUser.subscription.credits}</div>
            </div>
            <div className="flex flex-row space-x-2">
              <div className="font-bold">Üyelik Başlangıç Tarihi</div>
              <div>
                {selectedUser.subscription.createdDate &&
                  new Date(selectedUser.subscription.createdDate.seconds * 1000).toLocaleDateString()}
              </div>
            </div>
            <div className="flex flex-row space-x-2">
              <div className="font-bold">Üyelik Bitiş Tarihi</div>
              <div>
                {selectedUser.subscription.subsExprialDate &&
                  new Date(selectedUser.subscription.subsExprialDate.seconds * 1000).toLocaleDateString()}
              </div>
            </div>
            <div className="flex flex-row space-x-2">
              <div className="font-bold">Ücretsiz Deneme Bitiş Tarihi</div>
              <div>
                {selectedUser.subscription.trialExprialDate &&
                  new Date(selectedUser.subscription.trialExprialDate.seconds * 1000).toLocaleDateString()}
              </div>
            </div>
          </div>
        )}
        <hr />

        <div className="flex flex-col space-y-2">
          <div className="flex flex-row space-x-2">
            <div className="font-bold">Firma Sayısı</div>
            <div>{selectedUser.companyLenth}</div>
          </div>
        </div>
        <hr />

        {selectedUser.billingInfo && (
          <div className="flex flex-col space-y-2">
            <div className="flex flex-row space-x-2">
              <div className="font-bold">Fatura Adı</div>
              <div>{selectedUser.billingInfo.title}</div>
            </div>
            <div className="flex flex-row space-x-2">
              <div className="font-bold">Fatura Adresi</div>
              <div>{selectedUser.billingInfo.address}</div>
            </div>
            <div className="flex flex-row space-x-2">
              <div className="font-bold">Fatura Şehri</div>
              <div>{selectedUser.billingInfo.city}</div>
            </div>
            <div className="flex flex-row space-x-2">
              <div className="font-bold">Fatura İlçesi</div>
              <div>{selectedUser.billingInfo.district}</div>
            </div>
            <div className="flex flex-row space-x-2">
              <div className="font-bold">Fatura Vergi Dairesi</div>
              <div>{selectedUser.billingInfo.taxAdmin}</div>
            </div>
            <div className="flex flex-row space-x-2">
              <div className="font-bold">Fatura Vergi No</div>
              <div>{selectedUser.billingInfo.tcVkn}</div>
            </div>
            <div className="flex flex-row space-x-2">
              <div className="font-bold">Fatura Adı</div>
              <div>{selectedUser.billingInfo.title}</div>
            </div>
            <div className="flex flex-row space-x-2">
              <div className="font-bold">Fatura Adı</div>
              <div>{selectedUser.billingInfo.title}</div>
            </div>
          </div>
        )}

        {companyList?.length && (
          <div className="flex flex-col space-y-2">
            <div className="font-bold">Firmalar</div>
            {companyList.map((company) => (
              <>
                {/* <div className="flex flex-row space-x-2">
                  <div className="font-bold">title</div>
                  <div>{company.title}</div>
                </div>
                <div className="flex flex-row space-x-2">
                  <div className="font-bold">id</div>
                  <div>{company.id}</div>
                </div>
                <div className="flex flex-row space-x-2">
                  <div className="font-bold">tcVkn</div>
                  <div>{company.tcVkn}</div>
                </div> */}

                {/* render as table */}
                <table className="min-w-full divide-y divide-gray-200">
                  <thead>
                    <tr>
                      <th className="bg-gray-50 px-6 py-3 text-left text-xs font-medium uppercase leading-4 tracking-wider text-gray-500">
                        Firma Adı
                      </th>
                      <th className="bg-gray-50 px-6 py-3 text-left text-xs font-medium uppercase leading-4 tracking-wider text-gray-500">
                        Firma ID
                      </th>
                      <th className="bg-gray-50 px-6 py-3 text-left text-xs font-medium uppercase leading-4 tracking-wider text-gray-500">
                        Vergi No
                      </th>
                      <th className="bg-gray-50 px-6 py-3 text-left text-xs font-medium uppercase leading-4 tracking-wider text-gray-500">
                        Link
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">
                    <tr>
                      <td className="whitespace-no-wrap px-6 py-4 text-sm font-medium leading-5 text-gray-900">
                        {company.title}
                      </td>
                      <td className="whitespace-no-wrap px-6 py-4 text-sm leading-5 text-gray-500">{company.id}</td>
                      <td className="whitespace-no-wrap px-6 py-4 text-sm leading-5 text-gray-500">{company.tcVkn}</td>
                      <td className="whitespace-no-wrap px-6 py-4 text-sm leading-5 text-gray-500">
                        <button
                          className="rounded bg-blue-500 py-2 px-2 text-xs font-bold text-white hover:bg-blue-700"
                          onClick={() => handleOpenCompanyOnDb(company.id)}
                        >
                          Firma DB
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default PaymentDetail;
