import React, { useState, useEffect } from "react";

import { Menu, Transition } from "@headlessui/react";
import { EllipsisVerticalIcon } from "@heroicons/react/20/solid";
import { loadUsers } from "features/admin/services";
import { useDispatch, useSelector } from "react-redux";
import { adminUserSelector, setSearchQuery, filterdedAdminUsers } from "features/admin/users/slice";
import { router } from "features/navigation/Navigator";
import Button from "features/ui/Button";
import Select from "features/ui/Select";

const statuses = {
  SUCCESS: "text-green-700 bg-green-50 ring-green-600/20",
  pending: "text-gray-600 bg-gray-50 ring-gray-500/10",
  INVOICED: "text-yellow-800 bg-yellow-50 ring-yellow-600/20",
  FAIL: "text-red-800 bg-red-50 ring-red-600/20",
};

const showPhoneNumber = (phoneNumber) => {
  //+905321234567

  // as +90 532 123 45 67
  const countryCode = phoneNumber.substring(0, 3);
  const firstThree = phoneNumber.substring(3, 6);
  const secondThree = phoneNumber.substring(6, 9);
  const lastTwo = phoneNumber.substring(9, 11);
  const lastTwo2 = phoneNumber.substring(11, 13);

  return `${countryCode} ${firstThree} ${secondThree} ${lastTwo} ${lastTwo2}`;
};

export default function Example() {
  const dispatch = useDispatch();

  // const searchParam = useSelector((state) => state.adminPayments.searchParam);
  const users = useSelector(adminUserSelector.selectAll);
  const filteredUsers = useSelector(filterdedAdminUsers);
  const userLength = users?.length;
  const { error } = useSelector((state) => state.adminUsers);
  console.log(error);
  const [renderUsers, setRenderUsers] = useState(filteredUsers);
  const [limit, setLimit] = useState(10);

  useEffect(() => {
    setRenderUsers(filteredUsers);
  }, [filteredUsers]);

  const handleNavigateDetail = (id) => {
    // new tab
    window.open(`/admin/users/${id}/view`);
    // router.navigate(`/admin/users/${id}/view`);
  };
  const handleSetSearchQuery = (e) => {
    dispatch(setSearchQuery(e.target.value));
  };

  const handleOrderByKalanKontor = () => {
    // asc
    const sortedUsers = [...filteredUsers].sort((a, b) => a?.subscription?.credits - b?.subscription?.credits);
    setRenderUsers(sortedUsers);
  };

  const handleReload = () => {
    dispatch(loadUsers({ docLimit: limit }));
  };
  useEffect(() => {
    dispatch(loadUsers({ docLimit: limit }));
  }, [limit]);

  const getAllUsersEmailsAndCopyToClipboard = () => {
    const emails = users.map((user) => user.email).join(", ");
    navigator.clipboard.writeText(emails);
    window.open(`mailto:?bcc=${emails}`);
  };

  const summaryInfo = filteredUsers.map((user) => {
    return {
      email: user?.email,
      displayName: user?.displayName,
    };
  });

  console.log(summaryInfo);

  return (
    <div className="h-screen overflow-scroll">
      {/* search input */}
      <div className="flex justify-between px-4 sm:px-6 lg:px-8">
        <Button size="sm" onClick={() => handleReload()}>
          Yenile
        </Button>
        <Button size="sm" onClick={() => getAllUsersEmailsAndCopyToClipboard()}>
          Tüm Mailleri Kopyala
        </Button>
        <Button size="sm" onClick={() => handleOrderByKalanKontor()}>
          Kontör Sırala
        </Button>
        {/* change limit with input */}
        <div className="flex items-center">
          <Select
            id="limit"
            name="limit"
            value={limit}
            onChange={(e) => setLimit(e.target.value)}
            className="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
            options={[
              { label: "10", value: 10 },
              { label: "20", value: 20 },
              { label: "50", value: 50 },
              { label: "100", value: 100 },
              { label: "500", value: 500 },
            ]}
          ></Select>
        </div>
      </div>
      <div className="flex items-center justify-between px-4 py-4 sm:px-6 lg:px-8">
        <div className="min-w-0 flex-1">
          <label htmlFor="search" className="sr-only">
            Search
          </label>

          {/* kullanıcı sayısı */}
          <div className="text-sm leading-5 text-gray-500">{userLength} kullanıcı</div>
          <div className="relative rounded-md shadow-sm">
            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
              {/* Heroicon name: search */}
              <svg className="h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  // eslint-disable-next-line max-len
                  d="M8.25 16.5a8.25 8.25 0 100-16.5 8.25 8.25 0 000 16.5zM15.5 9a6.5 6.5 0 11-13 0 6.5 6.5 0 0113 0z"
                />
              </svg>
            </div>
            <input
              id="search"
              className="block w-full rounded-md border-gray-300 pl-10 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              placeholder="Ara"
              type="search"
              onChange={handleSetSearchQuery}
            />
          </div>
        </div>
      </div>

      <ul className="divide-y divide-gray-100 ">
        {renderUsers.map((user) => (
          <li key={user.email} className="flex justify-between gap-x-6 py-5">
            <div className="flex gap-x-4">
              <img className="h-12 w-12 flex-none rounded-full bg-gray-50" src={user.photoURL} alt="" />
              <div className="min-w-0 flex-auto">
                <p className="text-sm font-semibold leading-6 text-gray-900">{user.displayName}</p>
                <p className=" truncate text-xs leading-5 text-gray-500">{user.email}</p>
                <p className=" truncate text-xs leading-5 text-gray-500">{showPhoneNumber(user.phoneNumber)}</p>
                {/* <p className="mt-1 truncate text-xs leading-5 text-gray-500">{user.uid}</p> */}
              </div>
            </div>

            {user?.subscription?.credits > 0 ? (
              <p className="text-sm leading-6 text-gray-900">Kontör: {user?.subscription?.credits}</p>
            ) : (
              <p className="text-sm font-bold leading-6 text-red-900">Kontör: 0</p>
            )}

            {user?.subscription?.creditsSpent > 0 ? (
              <p className="text-sm leading-6 text-gray-900">Harcanan: {user?.subscription?.creditsSpent}</p>
            ) : (
              <p className="text-sm font-bold leading-6 text-red-900">Harcanan:0</p>
            )}

            {user?.companyLenth > 0 ? (
              <p className=" text-xs leading-5 text-gray-500 ">Firma Sayısı: {user?.companyLenth}</p>
            ) : (
              <p className=" text-xs font-bold leading-5 text-red-500">Firma Sayısı: 0</p>
            )}

            <div>
              <Button size="sm" onClick={() => handleNavigateDetail(user.uid)}>
                Detay
              </Button>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}
