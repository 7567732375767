import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ChevronRightIcon } from "@heroicons/react/20/solid";

import ROUTES from "features/navigation/routes";
import notify from "features/ui/notify";
import Input from "features/ui/Input";
import Button from "features/ui/Button";
import Alert from "features/ui/Alert";
import Logo from "features/ui/Logo";
import Loader from "features/ui/Loader";

import packageJson from "../../../package.json";
import { router } from "features/navigation/Navigator";

import { resetAll } from "features/companyData/slice";

import { filterCompanies, setSearchParam, companySelector, setNavigateToPage } from "features/companies/slice";
import { loadAuthorizedCompanies, onSelectCompany } from "features/companies/services";

const CompanySelect = () => {
  const dispatch = useDispatch();

  const allCompany = useSelector(companySelector.selectAll);
  const { status, error } = useSelector((state) => state.companies);
  const loading = status === "loading";
  const companyList = useSelector(filterCompanies);
  const onNewCompany = () => {
    dispatch(setNavigateToPage(null));
    router.navigate(ROUTES.COMPANIES_NEW.path);
  };
  const onSearch = (event) => dispatch(setSearchParam(event.target.value));

  useEffect(() => {
    if (allCompany.length === 0) dispatch(loadAuthorizedCompanies());
  }, []);

  const onSelect = async (item) => {
    // reset company data because company changed
    dispatch(resetAll());
    await dispatch(onSelectCompany(item));
    router.navigate(ROUTES.HOME.path);
    notify(
      <>
        <p>Firma Değiştirildi</p>
        <p>
          <strong>{item.title}</strong>
        </p>
      </>,
      "success"
    );
  };

  if (loading)
    return (
      <div className="flex min-h-screen flex-col items-center justify-center bg-gray-200">
        <Logo className="h-36" />
        <div className="h-36 text-center">
          <Loader type="1" className="ml-10" />
        </div>
      </div>
    );

  if (error) return <div>error</div>;

  return (
    <div className="min-h-screen bg-slate-50 p-10 md:px-36">
      <div className="xl:px-60 ">
        <div className="xl:mb-20">
          <div className="flex justify-center ">
            <Logo className="w-48" />
          </div>
          <p className="text-center text-sm text-gray-500">{packageJson.version}</p>
        </div>

        <div className="mb-2 grid grid-cols-5">
          <Input inputType="label-top" placeholder="Firma Ara" onChange={onSearch} />
          <Button className="sm:mt-1 sm:ml-1" onClick={onNewCompany}>
            Firma Ekle
          </Button>
        </div>
        {companyList.length === 0 && (
          <Alert>
            <p className="cursor-pointer" onClick={onNewCompany}>
              Firma Bulunamadı, yeni firma ekleyin.
            </p>
          </Alert>
        )}
        <div className="mt-5 overflow-hidden bg-white shadow sm:rounded-md">
          <ul className="divide-y divide-gray-200">
            {companyList.map((item) => (
              <li key={item.id} onClick={() => onSelect(item)}>
                <span className="block cursor-pointer hover:bg-gray-50 hover:text-primary">
                  <div className="flex items-center px-2 py-2 sm:px-6">
                    <div className="min-w-0 flex-1 sm:flex sm:items-center sm:justify-between">
                      <div className="truncate">
                        <div className="flex text-sm">
                          <p className="truncate  font-medium">{item.title}</p>
                        </div>
                        <div className="flex p-0.5">
                          <div className="flex items-center text-sm text-gray-500">
                            {item.tcVkn.length === 10 ? <p>Vkn:</p> : <p>TCno:</p>}
                            {item.tcVkn}
                          </div>
                        </div>
                      </div>
                    </div>
                    {item.documentsCount && (
                      <span className="inline-flex items-center rounded-full bg-yellow-100 px-2.5 py-0.5 text-xs font-medium text-yellow-800">
                        {item.documentsCount}
                      </span>
                    )}
                    <div className="ml-5 flex-shrink-0">
                      <ChevronRightIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                    </div>
                  </div>
                </span>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default CompanySelect;
