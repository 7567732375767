import { useSelector, useDispatch } from "react-redux";
import Button from "features/ui/Button";
import { prevStep, nextStep } from "features/transactions/slice";
import { createDocument } from "features/transactions/services";
import { router } from "features/navigation/Navigator";
// import Loading from "features/ui/Loading";
import notify from "features/ui/notify";

export default function Summary() {
  const dispatch = useDispatch();

  const { newAddress, ortaklarKarariBilgileri } = useSelector((state) => state.transactions.selectedTransaction);
  const genelKurul = useSelector((state) => state.transactions.selectedTransaction?.genelKurul);
  // const status = useSelector((state) => state.transactions.status);

  // const loading = status === "loading";

  const kararTarihi = ortaklarKarariBilgileri?.kararTarihi;
  const kararNo = ortaklarKarariBilgileri?.kararNo;
  const tutanakNo = genelKurul?.tutanakNo;
  const tutanakTarihi = genelKurul?.tutanakTarihi;
  const selectedTransaction = useSelector((state) => state.transactions.selectedTransaction);
  const { creditToBeSpend } = selectedTransaction;

  const { subscription } = useSelector((state) => state.auth);
  const credit = subscription?.credits || 0;
  const isCreditEnough = credit >= creditToBeSpend;

  const onAccept = async () => {
    if (!isCreditEnough) {
      notify("Kontörünüz yetersiz", "warning");
      setTimeout(() => {
        notify("Satınalma sayfasına yönlendiriliyorsunuz", "info");
      }, 2000);
      // await 1 sec
      setTimeout(() => {
        router.navigate("/shop/services");
      }, 3000);

      return;
    }
    await dispatch(createDocument());
    // navigate to transaction/documentCreatedList
    router.navigate("/transaction/documentCreatedList");
  };

  // if (loading) return <Loading />;

  return (
    <div className="mt-10 md:col-span-2 md:mt-0">
      <div className=" col-span-12 overflow-y-scroll bg-white  shadow sm:rounded-lg">
        <div className="p-4">
          <div className="px-4 sm:px-0">
            <h3 className="text-base font-semibold leading-7 text-gray-900">Özet</h3>
            <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">İşlem Özeti</p>
          </div>
          <div className="mt-6 border-t border-gray-100">
            <dl className="divide-y divide-gray-100">
              {kararNo && (
                <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                  <dt className="text-sm font-medium leading-6 text-gray-900">Karar Tarihi</dt>
                  <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{kararTarihi}</dd>

                  <dt className="text-sm font-medium leading-6 text-gray-900">Karar No</dt>
                  <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{kararNo}</dd>
                </div>
              )}

              {tutanakNo && (
                <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                  <dt className="text-sm font-medium leading-6 text-gray-900">Tutanak Tarihi</dt>
                  <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{tutanakTarihi}</dd>

                  <dt className="text-sm font-medium leading-6 text-gray-900">Tutanak No</dt>
                  <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{tutanakNo}</dd>
                </div>
              )}

              <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                <dt className="text-sm font-medium leading-6 text-gray-900">Yeni Adres</dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{newAddress?.newAddress}</dd>
              </div>

              <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                <dt className="text-sm font-medium leading-6 text-gray-900">Kullanılacak Kontör</dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                  <span className="inline-flex items-center rounded-md bg-yellow-50 px-2 py-1 text-xs font-medium text-yellow-800 ring-1 ring-inset ring-yellow-600/20">
                    {creditToBeSpend}
                  </span>
                </dd>
              </div>
            </dl>
            {/* Button "Belgeleri Oluştur" */}
          </div>
        </div>
        <div className="overflow-hidden shadow sm:rounded-md">
          <div className="space-x-2 bg-gray-50 px-4 py-3 text-right dark:bg-gray-700 sm:px-6">
            <Button
              onClick={() => {
                dispatch(prevStep());
              }}
              color="secondary"
              type="button"
            >
              Geri
            </Button>
            <Button onClick={onAccept} color="primary" type="button">
              Belgeleri Oluştur
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
