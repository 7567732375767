import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { yup } from "util/yupLocale";
import ArrayForm from "../../ArrayForm";

import { prevStep, nextStep } from "features/transactions/slice";
import { updateTransaction } from "features/transactions/services";
import { loadAuthoritiesData, loadCapitalData } from "features/companyData/services";

const SerArtOrtaklardanAlacaklar = () => {
  const dispatch = useDispatch();

  const { step } = useSelector((state) => state.transactions);
  const isRegistered = step.registered;
  // get last ten years only year like "2021"
  const years = Array.from({ length: 20 }, (_, i) => new Date().getFullYear() - i);

  const partners = useSelector((state) => state.companyData.partners);

  const partnerComboOptions = partners.map((partner) => ({
    name: partner.adSoyad,
    id: partner.tcVkn,
  }));

  const getPersonFromPartners = (name) => {
    const partner = partners.find((partner) => partner.adSoyad === name);
    const deepCopy = JSON.parse(JSON.stringify(partner));
    const newObj = {};
    newObj.adSoyad = deepCopy.adSoyad;
    newObj.toplamPayAdedi = deepCopy.toplamPayAdedi;
    newObj.tcVkn = deepCopy.tcVkn;
    return newObj;
  };

  const schemaObj = {
    adSoyad: yup.string().required().label("Ortak Ad Soyad").meta({
      type: "text",
      className: "sm:w-2/3",
      component: "combobox",
      options: partnerComboOptions,
      onChange: getPersonFromPartners,
      updateOtherFields: false,
    }),
    bakiye331: yup.string().required().label("331 Hesap Bakiyesi").meta({
      type: "text",
      className: "sm:w-2/3",
      component: "number",
    }),
    bakiye431: yup.string().required().label("431 Hesap Bakiyesi").meta({
      type: "text",
      className: "sm:w-2/3",
      component: "number",
    }),
    arttirimTutari: yup.string().required().label("Sermaye Arttırımında Kullanılacak tutar").meta({
      type: "text",
      className: "sm:w-2/3",
      component: "number",
    }),
  };

  // const schema = "mudurAtama": yup.array().of(yup.object().shape(schemaObj))
  const schema = yup.object().shape({
    serArtOrtaklardanAlacaklar: yup.array().of(yup.object().shape(schemaObj)),
  });

  const onUpdate = async (form) => {
    const deepCopy = JSON.parse(JSON.stringify(form));
    deepCopy.toplamSermayeArttirimi = deepCopy.serArtOrtaklardanAlacaklar.reduce((acc, item) => {
      return acc + Number(item.arttirimTutari);
    }, 0);

    deepCopy.serArtOrtaklardanAlacaklar.map((item) => {
      item.toplamAlacak = Number(item.bakiye331) + Number(item.bakiye431);
      return item;
    });

    // toplam331Bakiye
    deepCopy.toplam331Bakiye = deepCopy.serArtOrtaklardanAlacaklar.reduce((acc, item) => {
      return acc + Number(item.bakiye331);
    }, 0);

    // toplam431Bakiye
    deepCopy.toplam431Bakiye = deepCopy.serArtOrtaklardanAlacaklar.reduce((acc, item) => {
      return acc + Number(item.bakiye431);
    }, 0);
      
    await dispatch(
      updateTransaction({
        data: {
          serArtOrtaklardanAlacaklar: deepCopy,
        },
      })
    );
    dispatch(nextStep());
  };

  const onPrev = () => {
    dispatch(prevStep());
  };

  useEffect(() => {
    dispatch(loadAuthoritiesData());
    dispatch(loadCapitalData());
  }, []);

  const serArtOrtaklardanAlacaklar = useSelector(
    (state) => state.transactions.selectedTransaction?.serArtOrtaklardanAlacaklar?.serArtOrtaklardanAlacaklar
  );

  const defaultItem = {
    adSoyad: "",
    tcVkn: "",
  };

  return (
    <ArrayForm
      schema={schema}
      onSubmit={onUpdate}
      initData={serArtOrtaklardanAlacaklar}
      itemLabel="serArtOrtaklardanAlacaklar"
      defaultItem={defaultItem}
      title="Ortaklardan Alacaklar"
      description={
        "İlgili sermaye arttırımında kullanılacak 331 / 431 hesap bakiyelerini ve sermaye arttırımında kullanılacak tutarı giriniz."
      }
      onPrev={onPrev}
      isRegistered={isRegistered}
      totalFields={[{ label: "Sermaye arttırımının kardan karşılanan kısmı", fieldName: "tutar" }]}
    />
  );
};

export default SerArtOrtaklardanAlacaklar;

// SerArtOrtaklardanAlacaklar
