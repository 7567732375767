import { createSlice } from "@reduxjs/toolkit";

export const uiSlice = createSlice({
  name: "ui",
  initialState: {
    sidebarOpen: false,
    darkMode: false,
    wideMode: false,
    dbReadCount: 0,
  },
  reducers: {
    toggleDarkMode: (state, action) => {
      state.darkMode = !state.darkMode;
    },
    setDbReadCount: (state, action) => {
      // increment the read count by payload
      state.dbReadCount += action.payload;
    },
    toggleWideMode: (state, action) => {
      state.wideMode = !state.wideMode;
    },
    setWideMode: (state, action) => {
      state.wideMode = action.payload;
    },
    setSidebarOpen: (state, action) => {
      state.sidebarOpen = action.payload;
    }
  },
});


export const { toggleDarkMode, setDbReadCount, toggleFileUploadModal, toggleFileDeleteModal, toggleWideMode, setWideMode, setSidebarOpen } = uiSlice.actions;
export default uiSlice.reducer;
