import React, { useState, useEffect } from "react";

import { Menu, Transition } from "@headlessui/react";
import { EllipsisVerticalIcon } from "@heroicons/react/20/solid";
import { loadPayments } from "features/admin/services";
import { useDispatch, useSelector } from "react-redux";
import { adminPaymentselector, setSearchParam } from "features/admin/payments/slice";
import { router } from "features/navigation/Navigator";
const statuses = {
  SUCCESS: "text-green-700 bg-green-50 ring-green-600/20",
  pending: "text-gray-600 bg-gray-50 ring-gray-500/10",
  INVOICED: "text-yellow-800 bg-yellow-50 ring-yellow-600/20",
  FAIL: "text-red-800 bg-red-50 ring-red-600/20",
};

/*
payment object
{
    "id": "aa738d86-3713-420e-99ec-f5325c14e4ef",
    "uid": "PuzAooC6at9LTLhB0K1y4lnCIMtu",
    "createdAt": {
        "seconds": 1685371514,
        "nanoseconds": 670000000
    },
    "total": 354,
    "bag": [
        {
            "price": 300,
            "vatRate": 18,
            "intervalDay": null,
            "name": "50 Kontör",
            "description": "50 Kontör",
            "id": "kontor-50",
            "category": "kontor",
            "credit": 50,
            "referanceCode": null
        }
    ],
    "token": "aa738d86-3713-420e-99ec-f5325c14e4ef",
    "status": "pending"
}
*/

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Example() {
  const dispatch = useDispatch();

  const searchParam = useSelector((state) => state.adminPayments.searchParam);
  const payments = useSelector(adminPaymentselector.selectAll);

  const handleSetSearchParam = (status) => {
    dispatch(
      setSearchParam({
        status,
      })
    );
    dispatch(loadPayments());
  };

   const handleNavigateDetail = (id) => {
    router.navigate(`/admin/payments/${id}/view`);
    };

  useEffect(() => {
    dispatch(loadPayments());
  }, []);

  return (
    <>
      <span className="isolate inline-flex rounded-md shadow-sm">
        <button
          type="button"
          onClick={() => handleSetSearchParam("pending")}
          className="relative inline-flex items-center rounded-l-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10"
        >
          Bekliyor
        </button>
        <button
          type="button"
          onClick={() => handleSetSearchParam("FAIL")}
          className="relative -ml-px inline-flex items-center bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10"
        >
          Hatalı
        </button>
        <button
          type="button"
          onClick={() => handleSetSearchParam("SUCCESS")}
          className="relative -ml-px inline-flex items-center bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10"
        >
          Tamamlandı
        </button>
        <button
          type="button"
          onClick={() => handleSetSearchParam("INVOICED")}
          className="relative -ml-px inline-flex items-center rounded-r-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10"
        >
          Faturalandı
        </button>
      </span>

      <ul className="divide-y divide-gray-100">
        {payments.map((payment) => (
          <li key={payment.id} className="flex items-center justify-between gap-x-6 py-5">
            <div className="min-w-0">
              <div className="flex items-start gap-x-3">
                <p className="text-sm font-semibold leading-6 text-gray-900">{payment.token}</p>
                <p className="text-sm font-semibold leading-6 text-gray-900">{payment.total}</p>
                <p
                  className={classNames(
                    statuses[payment.status],
                    "mt-0.5 whitespace-nowrap rounded-md px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset"
                  )}
                >
                  {payment.status}
                </p>
              </div>
              <div className="mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500">
                <p className="whitespace-nowrap">{/* Tarih <time dateTime={payment.createdAt}>{payment.createdAt}</time> */}</p>
                <svg viewBox="0 0 2 2" className="h-0.5 w-0.5 fill-current">
                  <circle cx={1} cy={1} r={1} />
                </svg>
                <p className="truncate">Created by {payment.uid}</p>
              </div>
            </div>
            <div className="flex flex-none items-center gap-x-4">
              <button
                onClick={() => handleNavigateDetail(payment.id)}
                className="hidden rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:block"
              >
                Detay<span className="sr-only">, {payment.id}</span>
              </button>
              {/* <Menu as="div" className="relative flex-none">
              <Menu.Button className="-m-2.5 block p-2.5 text-gray-500 hover:text-gray-900">
                <span className="sr-only">Open options</span>
                <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />
              </Menu.Button>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute right-0 z-10 mt-2 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                  <Menu.Item>
                    {({ active }) => (
                      <a
                        href="#"
                        className={classNames(
                          active ? 'bg-gray-50' : '',
                          'block px-3 py-1 text-sm leading-6 text-gray-900'
                        )}
                      >
                        Edit<span className="sr-only">, {payment.name}</span>
                      </a>
                    )}
                  </Menu.Item>
                  <Menu.Item>
                    {({ active }) => (
                      <a
                        href="#"
                        className={classNames(
                          active ? 'bg-gray-50' : '',
                          'block px-3 py-1 text-sm leading-6 text-gray-900'
                        )}
                      >
                        Move<span className="sr-only">, {payment.name}</span>
                      </a>
                    )}
                  </Menu.Item>
                  <Menu.Item>
                    {({ active }) => (
                      <a
                        href="#"
                        className={classNames(
                          active ? 'bg-gray-50' : '',
                          'block px-3 py-1 text-sm leading-6 text-gray-900'
                        )}
                      >
                        Delete<span className="sr-only">, {payment.name}</span>
                      </a>
                    )}
                  </Menu.Item>
                </Menu.Items>
              </Transition>
            </Menu> */}
            </div>
          </li>
        ))}
      </ul>
    </>
  );
}
