import { createSlice, createEntityAdapter, createSelector } from '@reduxjs/toolkit'

import { loadSearchData, loadSingleSearchData } from './services'

const searchAdapter = createEntityAdapter({
    // selectId: (search) => search.id,
    // sortComparer: (a, b) => a.id.localeCompare(b.id),
})

const initialState = searchAdapter.getInitialState({
    // statuses: 'idle' | 'loading' | 'succeeded' | 'failed'
    status: 'idle',
    error: null,
    searchParam: "",
    types: [
        "accounts",
        "projects",
        "products",
        "offers"
    ],
})

export const searchSelector = searchAdapter.getSelectors(state => state.search)
// get only accounts
export const accountSearchSelector = createSelector(
    searchSelector.selectAll,
    (search) => search.filter(search => search.type === "accounts")
)

export const projectSearchSelector = createSelector(
    searchSelector.selectAll,
    (search) => search.filter(search => search.type === "projects")
)

export const productSearchSelector = createSelector(
    searchSelector.selectAll,
    (search) => search.filter(search => search.type === "products")
)

export const filterSearch = (state) => {
    const search = searchSelector.selectAll(state)
    const searchParam = state.search.searchParam
    if (searchParam === "") return search
    return search.filter(search => search.name.toLocaleLowerCase("tr").includes(searchParam?.toLocaleLowerCase("tr")))
}

const searchSlice = createSlice({
    name: 'search',
    initialState,
    reducers: {
        addSearch: searchAdapter.addOne,
        removeSearch: searchAdapter.removeOne,
        updateSearch: searchAdapter.updateOne,
        setSearch: searchAdapter.setAll,
        setSearchStatus: (state, action) => {
            state.status = action.payload
        }
    },
    extraReducers: (builder) => {
        builder.addCase(loadSearchData.pending, (state, action) => {
            state.status = 'loading'
        })
            .addCase(loadSearchData.fulfilled, (state, action) => {
                state.status = 'succeeded'
                searchAdapter.setAll(state, action.payload)
            })
            .addCase(loadSearchData.rejected, (state, action) => {
                state.status = 'failed'
                state.error = action.error.message
            })
            .addCase(loadSingleSearchData.pending, (state, action) => {
                state.status = 'loading'
            })
            .addCase(loadSingleSearchData.fulfilled, (state, action) => {
                state.status = 'succeeded'
                searchAdapter.addOne(state, action.payload)
            })
            .addCase(loadSingleSearchData.rejected, (state, action) => {
                state.status = 'failed'
                state.error = action.error.message
            })
    }
})

export const { addSearch, removeSearch, updateSearch, setSearch, setSearchStatus } = searchSlice.actions

export default searchSlice.reducer