/* eslint-disable no-template-curly-in-string */
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import * as yup from "yup";

yup.setLocale({
        mixed: {
                default: "${path} geçerli değil",
                required: "${path} zorunlu alandır",
                oneOf: "${path} değeri , örnekteki değerler gibi olmalıdır: ${values}",
                notOneOf: "${path} değeri, örnekteki değerler gibi olmamalıdır: ${values}",
        },
        string: {
                length: "${path} değeri ${length} karakter olmalıdır",
                min: "${path} değeri minimum ${min} karakter olmalıdır",
                max: "${path} değeri maksimum ${max} karakter olmalıdır",
                matches: '${path} değerlerine uymalıdır: "${regex}"',
                email: "Geçerli bir email adresi olmalıdır",
                url: "${path} geçerli bir URL olmalıdır",
                uuid: "${path} geçerli bir UUID olmalıdır",
                trim: "${path} kısaltılmış string olmalıdır",
                lowercase: "${path} küçük harf olmalıdır",
                uppercase: "${path} büyük harf olmalıdır",
        },
        number: {
                min: "${path} değeri ${min} değerinden büyük veya eşit olmalıdır",
                max: "${path} değeri ${max} değerinden  küçük veya eşit olmalıdır",
                lessThan: "${path} değeri  ${less} değerinden daha az olmalıdır",
                moreThan: "${path} değeri  ${more} değerinden daha büyük olmalıdır",
                notEqual: "${path} değeri ${notEqual} değeri ile eşit olamaz",
                positive: "${path} pozitif bir değer olmalıdır",
                negative: "${path} negatif bir değer olmalıdır",
                integer: "${path} değeri integer olmalıdır.",
        },
        date: {
                min: "${path} tarihi ${min} tarihinden sonra olmalıdır",
                max: "${path} tarihi ${max} tarihinden önce olmalıdır",
        },
});

export { yup, yupResolver, useForm, Controller, useFieldArray };
