import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { loadPayment, changePaymentStatus } from "features/admin/services";
import { router } from "features/navigation/Navigator";
import ROUTES from "features/navigation/routes";

const PaymentDetail = ({}) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const statuses = ["SUCCESS", "FAIL", "pending", "INVOICED"];

  const selectedPayment = useSelector((state) => state.adminPayments.selectedPayment);

  useEffect(() => {
    dispatch(loadPayment(id));
  }, [id]);

  const handleNavigateToBack = () => {
    router.navigate(ROUTES.ADMIN_PAYMENTS.path);
  };

  const handlePaymentStatusChange = async (status) => {
    await dispatch(changePaymentStatus({ paymentId: id, status }));
    // await 2 sec
    await new Promise((resolve) => setTimeout(resolve, 2000));
    dispatch(loadPayment(id));
  };

  const billingInfo = selectedPayment?.user?.billingInfo;
  const user = selectedPayment?.user;

  if (!selectedPayment) return <div>loading...</div>;

  return (
    <div className="space-y-2">
      <div>PaymentDetail</div>

      <button
        className="rounded bg-blue-500 py-2 px-2 text-xs font-bold text-white hover:bg-blue-700"
        onClick={handleNavigateToBack}
      >
        Listeye Dön
      </button>

      {/* render bag as table */}
      <div>
        <div>uid: {selectedPayment.uid}</div>
        {/* <div>createdAt: {selectedPayment.createdAt}</div> */}
        <div>total: {selectedPayment.total}</div>
        <div>token: {selectedPayment.token}</div>
        <div>status: {selectedPayment.status}</div>
      </div>

      {/* render user */}
      <div>
        <h1 className="text-lg font-bold">User</h1>
        <div>uid: {user.uid}</div>
        <div>email: {user.email}</div>
        <div>displayName: {user.displayName}</div>
        <div>phoneNumber: {user.phoneNumber}</div>
      </div>

      {billingInfo && (
        <div>
          <h1 className="text-lg font-bold">Billing Info</h1>
          <div>country: {billingInfo.country}</div>
          <div>address: {billingInfo.address}</div>
          <div>phoneNumber: {billingInfo.phoneNumber}</div>
          <div>city: {billingInfo.city}</div>
          <div>surname: {billingInfo.surname}</div>
          <div>district: {billingInfo.district}</div>
          <div>name: {billingInfo.name}</div>
          <div>taxAdmin: {billingInfo.taxAdmin}</div>
          <div>tcVkn: {billingInfo.tcVkn}</div>
          <div>title: {billingInfo.title}</div>
          <div>email: {billingInfo.email}</div>
          <div>paymentType: {billingInfo.paymentType}</div>
        </div>
      )}

      {/* selectedPayment.bag  render  table  */}
      <table className="min-w-full divide-y divide-gray-200">
        <thead className="bg-gray-50">
          <tr>
            <th scope="col" className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500">
              price
            </th>
            <th scope="col" className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500">
              vatRate
            </th>
            <th scope="col" className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500">
              intervalDay
            </th>
            <th scope="col" className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500">
              name
            </th>
            <th scope="col" className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500">
              description
            </th>
            <th scope="col" className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500">
              id
            </th>
            <th scope="col" className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500">
              category
            </th>
            <th scope="col" className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500">
              credit
            </th>
            <th scope="col" className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500">
              referanceCode
            </th>
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200 bg-white">
          {selectedPayment.bag.map((item) => (
            <tr key={item.id}>
              <td className="whitespace-nowrap px-6 py-4">{item.price}</td>
              <td className="whitespace-nowrap px-6 py-4">{item.vatRate}</td>
              <td className="whitespace-nowrap px-6 py-4">{item.intervalDay}</td>
              <td className="whitespace-nowrap px-6 py-4">{item.name}</td>
              <td className="whitespace-nowrap px-6 py-4">{item.description}</td>
              <td className="whitespace-nowrap px-6 py-4">{item.id}</td>
              <td className="whitespace-nowrap px-6 py-4">{item.category}</td>
              <td className="whitespace-nowrap px-6 py-4">{item.credit}</td>
              <td className="whitespace-nowrap px-6 py-4">{item.referanceCode}</td>
            </tr>
          ))}
        </tbody>
      </table>
      {/* render payment status buttons */}

      <div className="flex gap-x-2">
        {statuses.map((status) => (
          <button
            key={status}
            onClick={() => handlePaymentStatusChange(status)}
            // if selectedPayment.status === status
            className={`${
              selectedPayment.status === status ? "bg-blue-500" : "bg-gray-500"
            } rounded py-2 px-2 text-xs font-bold text-white hover:bg-blue-700`}
          >
            {status}
          </button>
        ))}
      </div>
    </div>
  );
};

export default PaymentDetail;
